/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';
import { 
  Button, 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter ,  
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,} from 'reactstrap';
  import DateTimePicker from "components/MaterialUI/DateTimePicker_CallClassChart.js";
  import SelectMultipleDepartment from "components/MaterialUI/SelectMultiple_CallClassChart.js";
  import SelectMultCallClass from "components/MaterialUI/SelectMultiple_CallClass.js";

const ModalExample = (props) => {
  return (
    <div>
    <Modal isOpen={props.open} toggle={props.handleClose} modalClassName={props.className}>
    <div className="modal-header">
      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.handleClose}>
        <i className="tim-icons icon-simple-remove"></i>
      </button>
      <h5 className="modal-title">Filters</h5>
    </div>
    <ModalBody>
    
    <Col lg="12">
      <Card style={{backgroundColor:'transparent'}}>
        <CardHeader>
        <CardTitle tag="h4">
          Date Range
        </CardTitle>
        </CardHeader>
        <CardBody style={{marginTop:"-20px"}}>    
          <Row>
          <Col sm="6"> <DateTimePicker label = "From Date:"  activeModeClass={props.activeModeClass}/> </Col>
          <Col sm="6"> <DateTimePicker label = "To Date:"  activeModeClass={props.activeModeClass}/> </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>        
    <Col lg="12">
      <Card style={{backgroundColor:'transparent'}}>
        <CardHeader>
        <CardTitle tag="h4">
          Call Class
        </CardTitle>
        </CardHeader>
        <CardBody style={{marginTop:"-20px"}}>    
          <Row>
          <Col sm="12"> <SelectMultCallClass  activeModeClass={props.activeModeClass}/> </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>        
    <Col lg="12" style={{marginTop:"-15px"}}>
      <Card style={{backgroundColor:'transparent'}}>
        <CardHeader>
        <CardTitle tag="h4">
          Departments
        </CardTitle>
        </CardHeader>
        <CardBody style={{marginTop:"-15px"}}>
          <Row>
          <Col sm="12"> <SelectMultipleDepartment  activeModeClass={props.activeModeClass}/> </Col> 
          </Row>             
        </CardBody>
      </Card>
    </Col>      
    </ModalBody>
    <ModalFooter>
    <Button color="warning" onClick={props.handleClose}>
      Close
    </Button>
    <Button color="warning" onClick={props.handleClose}>
      Save changes
    </Button>
    </ModalFooter>
    </Modal>
    </div>
  );
}

export default ModalExample;