/* eslint-disable no-use-before-define */
import React, {useContext} from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useState, useLayoutEffect} from 'react';
import {ExpoApiContext} from "Helper/Api.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function SelectMultiple(props) {
  const classes = useStyles();
  const [themeType,setThemeType] = useState('dark');
  const context = useContext(ExpoApiContext);
  const[divBData, setdivBData] = useState([{}])
  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){setThemeType("light")}
    else{setThemeType("dark")}
    
  }, []);
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#4caf50',
      },
    },
  });    

  
  const divBLookup = [ ];
  const getDivB = async ()=> {
    //dont bother with api call and rendering until divs are loaded
    if(context.divsB.length === 0 ) {
        return;
    }
    context.divsB.forEach((div) => {
        const divObj =  { }
        divObj.title = div.name;
        divObj.value = div.id
        divBLookup.push(divObj)
    });
    setdivBData(divBLookup)
  };

  React.useEffect(() => {  
    getDivB().finally();
  }, [context.divsB]);


  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={THEME}>
      <Autocomplete
        multiple
        id="tags-standard"
        options= {divBData}
        limitTags={2}
        getOptionLabel={(option) => option.title}
        defaultValue={props.selItems}
        onChange = {props.onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Select a Branch"
            placeholder=""
          />
        )}
      />
      </MuiThemeProvider>
    </div>
  );
}
