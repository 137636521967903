import React, { useState, useLayoutEffect } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from '@material-ui/core/TextField';
import {InputAdornment} from "@material-ui/core";

  const TextFieldComponent = (props) => {
    const [themeType,setThemeType] = useState('dark');
    
    useLayoutEffect(() => {
        if(props.activeModeClass === "white-content"){  setThemeType("light")}
        else{setThemeType("dark")}
        
      }, []);
      const THEME = createMuiTheme({
        palette: {
          type: themeType,
          primary: {
            main: '#f8955e',
          },
          secondary: {
            main: '#1d8cf8',
          },
        },
      });
      return (
          <div>
              <MuiThemeProvider theme={THEME}>
               <CssBaseline/>   
               {props.fieldState === "enabled" &&
                 <TextField
                 required
                 id= {props.id}
                 label= {props.label}
                 helperText = {props.helperText}
                 defaultValue= {props.value}
                 variant="standard"
                 className={props.classType}
                />
               }
               {props.fieldState === "disabled" &&
                  <TextField
                  disabled
                  id= {props.id}
                  label= {props.label}
                  helperText = {props.helperText}
                  defaultValue= {props.value}
                  variant="standard"
                  className={props.classType}
                />
               }
               
              </MuiThemeProvider>
          </div>
      )
  }
  export default TextFieldComponent;