import React, {useLayoutEffect} from "react";

/* Imports */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {useExpoApi} from "../../Helper/Api.js"

am4core.options.autoDispose = true;
export default function TimeBasedChart(props) {

    const getApi = useExpoApi();
    useLayoutEffect(() => {
        am4core.unuseAllThemes();
        if (props.activeModeClass === "white-content") {
            am4core.unuseTheme(am4themes_dark);
        } else {
            am4core.useTheme(am4themes_dark);
        }

        am4core.useTheme(am4themes_animated);
        am4core.addLicense("CH277440643");

        let chart = am4core.create("monthlyChartDiv", am4charts.XYChart);

        const getData = async () => {
            const api = await getApi();
            api
            .getMonthlyCalls(props.filters)
            .then((response) => {
                chart.data = response.data.monthlyCalls;
            })
            .catch((err) => {console.log(err)});   
        }

        chart.xAxes.push(new am4charts.DateAxis());

        let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis1.title.text = "Calls";

        let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.title.text = "";
        valueAxis2.renderer.opposite = true;
        valueAxis2.renderer.grid.template.disabled = true;

        // Create series
        let series1 = chart.series.push(new am4charts.ColumnSeries());
        series1.dataFields.valueY = "Incoming";
        series1.dataFields.dateX = "date";
        series1.yAxis = valueAxis1;
        series1.name = "Incoming";
        series1.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]";
        series1.fill = chart.colors.getIndex(0);
        series1.strokeWidth = 0;
        series1.clustered = false;
        series1.columns.template.width = am4core.percent(40);

        let series2 = chart.series.push(new am4charts.ColumnSeries());
        series2.dataFields.valueY = "Outgoing";
        series2.dataFields.dateX = "date";
        series2.yAxis = valueAxis1;
        series2.name = "Outgoing";
        series2.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]";
        series2.fill = chart.colors.getIndex(0).lighten(0.5);
        series2.strokeWidth = 0;
        series2.clustered = false;
        series2.toBack();

        let series3 = chart.series.push(new am4charts.LineSeries());
        series3.dataFields.valueY = "Local";
        series3.dataFields.dateX = "date";
        series3.name = "Local";
        series3.strokeWidth = 2;
        series3.tensionX = 0.7;
        series3.yAxis = valueAxis2;
        series3.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]";

        let bullet3 = series3.bullets.push(new am4charts.CircleBullet());
        bullet3.circle.radius = 3;
        bullet3.circle.strokeWidth = 2;
        bullet3.circle.fill = am4core.color("#fff");

        let series4 = chart.series.push(new am4charts.LineSeries());
        series4.dataFields.valueY = "International";
        series4.dataFields.dateX = "date";
        series4.name = "International";
        series4.strokeWidth = 2;
        series4.tensionX = 0.7;
        series4.yAxis = valueAxis2;
        series4.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]";
        series4.stroke = chart.colors.getIndex(0).lighten(0.5);
        series4.strokeDasharray = "3,3";

        let bullet4 = series4.bullets.push(new am4charts.CircleBullet());
        bullet4.circle.radius = 3;
        bullet4.circle.strokeWidth = 2;
        bullet4.circle.fill = am4core.color("#fff");

        // Add cursor
        chart.cursor = new am4charts.XYCursor();

        // Add legend
        chart.legend = new am4charts.Legend();
        chart.legend.position = "top";

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "left";
        chart.exporting.menu.verticalAlign = "bottom";
        am4core.color("#f00", 0);
        getData().catch();
    }, [props.totalCalls]);
    return (
        <div id="monthlyChartDiv" style={{width: "100%", height: "100%"}} />
    );
}