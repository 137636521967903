import React, { useState, useLayoutEffect } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { 
  Button, 
  Form, 
  FormGroup, 
  Label, 
  Input
} from 'reactstrap';

const useStyles = makeStyles((theme) => ({
    textField: {
      width: '100%',
    },
    selectField: {
      width: '400px',
    },
  }));
  
  const SwitchEmailComponent = (props) => {
    const classes = useStyles();
    const [themeType,setThemeType] = useState('dark');
    const [stateTitle, setStateTitle] = useState(props.activeState);
    const [sendEmailEn, setSendEmailEn] = useState(false);

    const [state, setState] = React.useState({
        checkedEn: true,
    });
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        if(state.checkedEn == true)
         setStateTitle("Disabled");
        else if(state.checkedEn == false )
         setStateTitle("Enabled");
    };
    const handleEnableEmailChange = (event) => {
      setSendEmailEn(event.target.checked);
    }
    useLayoutEffect(() => {
        if(props.activeModeClass === "white-content"){  setThemeType("light")}
        else{setThemeType("dark")}
        
      }, []);
      const THEME = createMuiTheme({
        palette: {
          type: themeType,
          primary: {
            main: '#f8955e',
          },
          secondary: {
            main: '#1d8cf8',
          },
        },
      });
      return (
          <div>
              <MuiThemeProvider theme={THEME}>
               <CssBaseline/>   
               <FormControlLabel
                control={
                <Switch
                  checked={state.sendEmailEn}
                  onChange={handleEnableEmailChange}
                  color="primary"
                  name="checkedEn"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                }
                label= {props.label}
               />
               {sendEmailEn === true && 
                <Form>
                <FormGroup>
                  <Label for="exampleEmail">Email</Label>
                  <Input type="email" name="email" id="exampleEmail" placeholder=""  style={{ width:"400px"}}/>
                </FormGroup>
                <FormGroup style={{marginTop:"-10px"}}>
                  <Label for="examplePassword">Subject</Label>
                  <Input type="text" name="subject" id="subject" placeholder=""  style={{ width:"400px"}}/>
                </FormGroup>
                <FormGroup style={{marginTop:"-10px"}}>
                  <Label for="exampleText">Message</Label>
                  <Input type="textarea" name="emailMsg" id="emailMsg"  style={{ width:"400px"}}/>
                </FormGroup>
                {/* <Button>Submit</Button> */}
                </Form>
          
               }
                {sendEmailEn === false && 
                <Form>
                <FormGroup>
                  <Label for="exampleEmail">Email</Label>
                  <Input type="email" name="email" id="exampleEmail" placeholder="" disabled style={{ width:"400px"}}/>
                </FormGroup>
                <FormGroup style={{marginTop:"-10px"}}>
                  <Label for="examplePassword">Subject</Label>
                  <Input type="text" name="subject" id="subject" placeholder="" disabled style={{ width:"400px"}}/>
                </FormGroup>
                <FormGroup style={{marginTop:"-10px"}}>
                  <Label for="exampleText">Message</Label>
                  <Input type="textarea" name="emailMsg" id="emailMsg" disabled style={{ width:"400px"}}/>
                </FormGroup>
                {/* <Button>Submit</Button> */}
                </Form>
          
               }
              </MuiThemeProvider>
          </div>
      )
  }
  export default SwitchEmailComponent;