import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Row,
  Col,
  Collapse
} from "reactstrap";

import FiltersDialog from "components/MaterialUI/Dialogs/FiltersDialog_TreeMap.js";
import {TimePickerFromDate} from "Helper/Utils.js";
import {TimePickerToDate} from "Helper/Utils.js";
import InteractionTable from "components/ReportTable/InteractionTable";
import ApiCall from "Helper/Api.js";
import InteractionChordChart from "components/AmCharts/InteractionChordChart";
import ReportTable from "components/ReportTable/ReportTable.js";
import {withAuth0} from "@auth0/auth0-react";
import InteractionFilterForm  from '../components/ReportFilterForm/InteractionFilterForm';
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import Tooltip from '@material-ui/core/Tooltip';
import dayjs from "dayjs";

function get_filters(obj) {
    return {
        timeRangeStart: obj.state.dateFromFilter,
        timeRangeEnd: obj.state.dateToFilter,
        divAIds: obj.state.divAids,
        divBIds: obj.state.divBids,
        divCIds: obj.state.divCids,
        externalCallTypes: obj.state.externalCallTypes,
    }
}


function getDateStart() {
    return dayjs().startOf('day');
}
function getDateEnd() {
    return dayjs().endOf('day');
}
class Graphs extends React.Component {
  constructor(props) {
    super(props);

    const searchParams = new URLSearchParams(this.props.location.search);
    this.reportFilters = {
      divAIds: searchParams.get('divAIds'),
      divBIds: searchParams.get('divBIds'),
      divCIds: searchParams.get('divCIds'),
      extIds: '',
      huntGIds: searchParams.get('huntGIds'),
      CallClassCode: searchParams.get('CallClassCode'),
      ExternalCallClassCode: searchParams.get('ExternalCallClassCode'),
      selExtObj: searchParams.get('selExtObj'),
      seldivAObj: searchParams.get('seldivAObj'),
      seldivBObj: searchParams.get('seldivBObj'),
      seldivCObj: searchParams.get('seldivCObj'),
      callClastSelObj: searchParams.get('callClassSelObj'),
      huntGroupSelObj: searchParams.get('huntGroupSelObj'),
      timeRangeStart: getDateStart().format("YYYY-MM-DDTHH:mm:ss"),
      timeRangeEnd: getDateEnd().format("YYYY-MM-DDTHH:mm:ss"),
      dayOfWeekFilter: searchParams.get('dayOfWeekFilter'),
      dayOfWeekFilterSelObj: searchParams.get('dayOfWeekFilterSelObj'),
      startTimeOfDay:searchParams.get('startTimeOfDay'),
      endTimeOfDay:searchParams.get('endTimeOfDay'),
    };

    this.state = {
      apiToken:null,
      open:true,
      classnameVar: "modal-black",
      activeModeClass:"dark-content",
      dateFromFilter: TimePickerFromDate,
      dateToFilter: TimePickerToDate,
      selDepartments: "0",
      divAids:"",
      divBids:"",
      divCids:"",
      totalCalls:0,
      deptSelected: [],
      externalCallTypes:[],
      userIdDrillDown: {},
      isOpen:false,
      reportFilters: this.reportFilters   
    };
  }

  handleReportFilterChange = (field, value) => {this.reportFilters = {...this.reportFilters }; this.reportFilters[field] = value;};

  applyFilter = () => {
     this.setState({reportFilters: this.reportFilters});
  }
  componentDidMount() {
    /*this.interval=setInterval(()=>{
      this.getCallClassStats();
     },10000)*/
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  
  getCallClassStats = async() => {
    const token = await this.props.auth0.getAccessTokenSilently();
    const api = new ApiCall(token);

  api
  .getCallClassStatistics()
  .then((response)=> {
    this.setState({totalCalls:response.data.totalCalls });
    this.setState({totalCost:response.data.totalCost });
    this.setState({avgCost:response.data.avgCost });
    this.setState({talkAvg:response.data.avgTalking });
      
  })
  .catch((err)=>console.log(err))
  }

  openFilterTab =()=>{this.setState({ isOpen: !this.state.isOpen, is2Open: false})};
  openHeatMapTab =()=>{this.setState({ is2Open: !this.state.is2Open, isOpen: false})};
  setGraphView = () => {this.setState({graphView:true, tableTop:'0px'}) }
  setTableView = () => {this.setState({graphView:false, tableTop:'-150px'}) }
  setBgChartData = name => {this.setState({bigChartData: name});};
  setClassType = () =>{
    if(document.body.classList.contains("white-content")){this.setState({classnameVar: "",activeModeClass:"white-content"})}
    else if(document.body.classList.contains("dark-content")){this.setState({classnameVar: "modal-black", activeModeClass:"dark-content"})}
  }

  render() {
    return (
      <>
        <div className="content" >
       
       <Row>
          <Col md="6" style={{marginLeft:"0px"}}>
          <div id = "filterBtn" style={{marginTop:"-35px"}}>
          <Tooltip title="Dashboard Filters">
          <IconButton style={{color:"white"}} aria-label="Filters" onClick={() => this.openFilterTab()}>
          <FilterListIcon />
          <h5>Filters</h5>
          </IconButton>
          </Tooltip>
          </div>      
          </Col> 
        </Row>
        <Row>
          <Collapse isOpen={this.state.isOpen}>
            <InteractionFilterForm  {...this.reportFilters} onChange={this.handleReportFilterChange} onApply={this.applyFilter} />
          </Collapse>
        </Row>
            <Card className="card-chart" >
            <CardHeader>
            <CardTitle tag="h4">
              Internal Call Interactions
            </CardTitle>
            </CardHeader>
            <CardBody >
              <Row>
                <Col>
                  <InteractionChordChart reportFilters={this.state.reportFilters} userSelectDrillDown={this.state.userSelectDrillDown} />
                </Col>
              </Row>
            </CardBody>
            </Card>
        </div>
      </>
    );
  }
}
/*
      /code for dropdown gear
              <UncontrolledDropdown>
             <DropdownToggle
              caret
              className="btn-icon"
              color="link"
              data-toggle="dropdown"
              type="button"
              >
              <i className="tim-icons icon-settings-gear-63" />
              </DropdownToggle>
              <DropdownMenu aria-labelledby="dropdownMenuLink" right>
              <DropdownItem
              href=""
              onClick={()=>{ }}
              >
              Settings
              </DropdownItem>
              </DropdownMenu>
              </UncontrolledDropdown>

            <Col>
              <Card className="card-chart">
                <CardBody>
                <div className="chart-area" style={{marginTop:"-0px", height:"100%"}}>
                <InteractionTable
                    activeModeClass={document.body.classList.contains("white-content") ? "white-content" : "dark-content"}
                    reportId={this.props.reportId}
                    reportFilters={this.state.reportFilters} /> :
                </div>
                </CardBody>
              
              </Card>
            </Col>
*/
export default Graphs;