import React, { useState, useLayoutEffect } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles((theme) => ({
  selectField: {
      width: '200px',
    }
  }));
  
  const SelectSimpleComponent = (props) => {
    const classes = useStyles();
    const [themeType,setThemeType] = useState('dark');
    const [condition, setCondition] = React.useState("Actual Cost");

    const handleChange = (e) => {
      setCondition(e.target.value);
    };
    useLayoutEffect(() => {
        console.log(props.selVal);
        if(props.activeModeClass === "white-content"){  setThemeType("light")}
        else{setThemeType("dark")}
        
      }, []);
      const THEME = createMuiTheme({
        palette: {
          type: themeType,
          primary: {
            main: '#f8955e',
          },
          secondary: {
            main: '#1d8cf8',
          },
        },
      });
      return (
          <div>
              <MuiThemeProvider theme={THEME}>
               <CssBaseline/>   
               <FormControl >
                <InputLabel shrink id="condition-select">
                  Select a Statistic
                </InputLabel>
                <Select
                  labelId="condition-label"
                  id="condition-select"
                  value= {props.selVal}
                  onChange={props.onChange}
                  className={classes.selectField}
                  displayEmpty
                >
                  <MenuItem value="TotalCalls">Total Calls</MenuItem>
                  <MenuItem value="Incoming">Incoming</MenuItem>
                  <MenuItem value="Outgoing">Outgoing</MenuItem>
                  <MenuItem value="Internal">Internal</MenuItem>
                  <MenuItem value="Abandoned">Abandoned</MenuItem>
                  <MenuItem value="Answered">Answered</MenuItem>
                  <MenuItem value="Busy">Busy</MenuItem>
                  <MenuItem value="HandledRate">Handled Rate</MenuItem>
                 
                </Select>
              </FormControl>
               
              </MuiThemeProvider>
          </div>
      )
  }
  export default SelectSimpleComponent;