/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import TabsComponent from "components/TabsComponents/ServiceChargeTabs.js";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

class ServiceCharge extends React.Component {
  render() {
    return (
      <>
      <div className="content">
      <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <h5 className="card-category"></h5>
                  <CardTitle tag="h2"></CardTitle>
                </Col>
                <Col sm="6">

                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div style={{height: window.innerHeight - 140, width: "100%", marginTop:"-10px" }}>
               <TabsComponent />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </div>
      </>
    );
  }
}

export default ServiceCharge;
