import React, { useState, useLayoutEffect } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";


const useStyles = makeStyles((theme) => ({
    textField: {
      width: '100%',
    },
    selectField: {
      width: '400px',
    },
  }));
  
  const RadioBtnComponent = (props) => {
    const classes = useStyles();
    const [themeType,setThemeType] = useState('dark');
    const [frequency, setFrequency] = React.useState("Once a Day");
    const [freqSelValue, setFreqSelValue] = React.useState('Once a Day');
    
    const handleFreqChange = (event) => {
      setFrequency(event.target.value);
    };
    const handleFreqSelValChange = (event) => {
      setFreqSelValue(event.target.value);
    };
    useLayoutEffect(() => {
        if(props.activeModeClass === "white-content"){  setThemeType("light")}
        else{setThemeType("dark")}
        
      }, []);
      const THEME = createMuiTheme({
        palette: {
          type: themeType,
          primary: {
            main: '#f8955e',
          },
          secondary: {
            main: '#1d8cf8',
          },
        },
      });
      return (
          <div>
              <MuiThemeProvider theme={THEME}>
               <CssBaseline/>   
               <FormControl component="fieldset">
                <FormLabel component="legend">Apply To:</FormLabel>
                  <RadioGroup
                    aria-label=""
                    name=""
                    frequency={frequency}
                    onChange={handleFreqChange}
                    
                  >
                    <FormControlLabel
                      value="User(S)"
                      control={<Radio />}
                      label="User(S)"
                      onChange={handleFreqSelValChange}
                      checked={freqSelValue === 'User(S)'}
                    />
                    <FormControlLabel
                      value="Department(s)"
                      control={<Radio />}
                      label="Department(s)"
                      onChange={handleFreqSelValChange}
                      checked={freqSelValue === 'Department(s)'}
                    />
                  </RadioGroup>
                </FormControl>
              </MuiThemeProvider>
          </div>
      )
  }
  export default RadioBtnComponent;