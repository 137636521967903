import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useState, useLayoutEffect} from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: "100%",
    fontSize: '18px'
  },
}));

export default function DateAndTimePickers(props) {
  const classes = useStyles();
  const [themeType,setThemeType] = useState('dark');
  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")}
    
  }, []);
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#4caf50',
      },
    },
  });
  return (
    <div>
    <MuiThemeProvider theme={THEME}>
    <form className={classes.container} noValidate>
      <TextField
        id="datetime-local"
        label={props.label}
        type="date"
        defaultValue= {props.date}
        onChange={props.onChange}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      
      />
    </form>
    </MuiThemeProvider>
    </div>
  );
}
