import React from "react";
import MaterialTable from "material-table";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { forwardRef, useState, useLayoutEffect, useEffect} from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {useExpoApi} from "../../Helper/Api.js"

const tableIcons = {
Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} color='action'/>),
Check: forwardRef((props, ref) => <Check {...props} ref={ref} color='action'/>),
Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} color='action'/>),
Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} color='action'/>),
DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} color='action'/>),
Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} color='action'/>),
Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} color='action'/>),
Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} color='action'/>),
FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} color='action'/>),
LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} color='action'/>),
NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} color='action'/>),
PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} color='action'/>),
ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} color='action'/>),
Search: forwardRef((props, ref) => <Search {...props} ref={ref} color='action'/>),
SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} color='action'/>),
ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} color='action'/>),
ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} color='action'/>)
};

export default function Top10MostExpensiveTable(props) {
  const getApi = useExpoApi();
  const[recentCalls, setRecentCalls] = useState([]) ;
  const columns = [
      { title: "Date", field: "date" },
      { title: "Ext.", field: "ext"},
      { title: "Name", field: "name"},
      { title: "Digits", field: "digits"},
      { title: "Dur.", field: "dur"},  
      { title: "Loc.", field: "loc"}, 
      { title: "Dir.", field: "dir"}, 
      { title: "Cost", field: "cost"}, 
      { title: "Charge", field: "charge"}, 
      { title: "Total Tax", field: "totalTax"},  
      { title: "Total Charge", field: "totalCharge"}   
  ];
  const [themeType,setThemeType] = useState('dark');

  useEffect(() => {
        const parseTableData = async(data) => {
            const tabledata = data.map((newItem) => {
                let newItemObj = {};
                const date = new Date(newItem.date);
                const month = date.getMonth() + 1;
                const day =  date.getDate();
                const hour = date.getHours();
                const minute = date.getMinutes();
                newItemObj.date =   month + "/" + day + " " + hour + ":" + minute;
                newItemObj.ext = newItem.ext
                newItemObj.name = newItem.name
                newItemObj.digits = newItem.digits
                newItemObj.dur = newItem.duration
                newItemObj.loc = newItem.location
                newItemObj.dir = newItem.direction;
                newItemObj.cost = newItem.cost;
                newItemObj.charge = newItem.charge;
                newItemObj.totalTax = newItem.totaltax;
                newItemObj.totalCharge = newItem.totalcharge;

                return newItemObj;
            })
            setRecentCalls(tabledata);
        }

        const apiCallRecentCalls = async () => {
            const api = await getApi();
                api.getTopTenMostExpensive(props.filters)
                    .then((response) => {
                        parseTableData(response.data.calls);

                    })
                    .catch((err) => {console.log(err)});
        };


      apiCallRecentCalls().catch();
    }, []); 

  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")}

  }, []);

  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#4caf50',
      },
    },
  });
  return (
    <div>
      <MuiThemeProvider theme={THEME}>
          <MaterialTable
            style= {{
                backgroundColor: 'transparent',
                boxShadow: 'none',
            }}
            icons={tableIcons}
            title=""
            columns={columns}
            data={recentCalls}
            options={{
                headerStyle: {
                  backgroundColor: "rgba(0,0,0,0)",  // #6ca6c2; //67b7dc        
                  fontWeight: 'bold'
                }
              }}
            editable={{
  
            }}
          />
    
      </MuiThemeProvider>
    </div>
  );
}
