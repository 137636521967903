/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create a form with multiple steps
import ReactWizard from "react-bootstrap-wizard";

// reactstrap components
import { Col } from "reactstrap";

// wizard steps
import Step1 from "./WizardSteps/Step1.js";
import Step2 from "./WizardSteps/Step2.js";
import Step3 from "./WizardSteps/Step3.js";
import {withAuth0} from "@auth0/auth0-react";
import ApiCall from "Helper/Api.js";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName:"",
      lastName:'',
      email:"",
      extension:"",
      departments: [],
      branches:[],
      accessRole: [3],
      totalDivA:0,
      totalDivB:0,
      steps : [
        {
          stepName: "About",
          stepIcon: "tim-icons icon-single-02",
          component: <Step1 
          firstName = {this.firstName} 
          lastName= {this.lastName}
          email={this.email} 
          extension={this.extension}
          onFirstNameChange = {this.onChangeName} 
          onLastNameChange = {this.onChangeLastName} 
          onChangeEmail = {this.onChangeEmail}
          onChangeExtension = {this.onChangeExtension}
          />
          
        },
        // {
        //   stepName: "Picture",
        //   stepIcon: "tim-icons icon-settings-gear-63",
        //   component: Step2
        // },
        {
          stepName: "Department & More",
          stepIcon: "tim-icons icon-delivery-fast",
          component: <Step3 
           deptOnChange = {this.departmentsOnChange} 
           branchOnChange = {this.branchesOnChange} 
           accessRoleOnChange = {this.accessRoleOnChange}
          />
        }
      ]
    }
  }


   getTotals = async () =>{
    
    const token = await this.props.auth0.getAccessTokenSilently();
     const api = new ApiCall(token);


    const result = await api.getDivs('A');
    const totalDivsA = result.data.divs.length;
    this.setState({totalDivA:totalDivsA})

    const result1 = await api.getDivs('B');
    const totalDivsB = result1.data.divs.length;
    this.setState({totalDivB:totalDivsB})

  }

  componentDidMount(){
    this.getTotals();
    this.userData = JSON.parse(localStorage.getItem('user'));
    if(localStorage.getItem('user')){
      this.setState({firstName: this.userData.firstName});
      this.setState({lastName: this.userData.lastName});
      this.setState({email: this.userData.email});
      this.setState({extension: this.userData.extension});
    }
    else{
      this.setState({firstName: ''});
      this.setState({lastName: ''});
      this.setState({email: ''});
      this.setState({extension: ''});
    }
  }

  componentWillUpdate(nextProps,nextState){
    localStorage.setItem('user', JSON.stringify({"firstName": this.state.firstName ,"lastName":this.state.lastName,"email":this.state.email,"extension":this.state.extension}));
  }
 
  onChangeName = (e) =>{
    this.setState({ firstName: e.target.value});   
  }

  onChangeEmail = (e) =>{
    this.setState({ email: e.target.value});
     
  }
  onChangeLastName = (e) =>{
    this.setState({ lastName: e.target.value});    
  }

  onChangeExtension =  (e) => {
    this.setState({ extension: e.target.value});     
  }

  departmentsOnChange = (e, value) => { 
    let selVal = "";
    selVal = Array.prototype.map.call(value, function(item) { return item.value; }).join(",");
    this.setState({ departments: selVal.split(',') });
    if(selVal.split(',').length == this.state.totalDivA)
      this.setState({ departments: [] });
  }

  branchesOnChange = (e, value) => { 
    let selVal = "";
    selVal = Array.prototype.map.call(value, function(item) { return item.value; }).join(",");
    this.setState({ branches: selVal.split(',') });
    if(selVal.split(',').length == this.state.totalDivB)
      this.setState({ branches: [] });
  }

  accessRoleOnChange = (e, value) => { 
    let selVal = "";
    selVal = Array.prototype.map.call(value, function(item) { return item.value; }).join(",");   
    this.setState({ accessRole: selVal.split(',') });

  }
  onWizardFinish = async () => {
		try{      
			const token = await this.props.auth0.getAccessTokenSilently();
          const api = new ApiCall(token);

      const newUser = { 
        name: this.state.firstName + " " + this.state.lastName,
        extension: this.state.extension,
        email: this.state.email,
        divs: this.state.departments.concat(this.state.branches),
        roles: this.state.accessRole
      };
      console.log(newUser);
			const res = await api.postCreateWebUser(newUser);
			if(res.status === 200) {
        window.location.reload(false);
			  return;
		  }
      console.log("error"); 
		}
		catch(err) {
			console.log(err);
      console.log(err);
		}  
  }
  render() {
    return (
      <>
        <div className="content">
          <Col className="mr-auto ml-auto" md="10">
            <ReactWizard
              steps={this.state.steps}
              navSteps
              validate
              title="Add a new user"
              description=""
              headerTextCenter
              finishButtonClasses="btn-wd btn-warning"
              nextButtonClasses="btn-wd btn-warning"
              previousButtonClasses="btn-wd"
              progressbar
              color="orange"  
              finishButtonClick={this.onWizardFinish}
            />
          </Col>
        </div>
      </>
    );
  }
}

export default withAuth0(Wizard);
