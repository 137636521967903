import React from "react";
import { MultiSelect } from "react-multi-select-component";
import { makeStyles } from '@material-ui/core/styles';
import { MuiThemeProvider  } from '@material-ui/core/styles';
import { useState, useLayoutEffect} from 'react';
import "assets/css/multiselect-dark-mode.css";
import {
    CssBaseline,
    createMuiTheme
  } from "@material-ui/core";
import "assets/css/filterDropDown.css";


const useStyles = makeStyles(() => ({
    root: {
      width: 240,
      marginTop:"20px"
    },
}));

const MultiSelectExternalCallTypeCheckBox = (props) => {
  const classes = useStyles();
  const [themeType,setThemeType] = useState('dark');
  const [compData] = useState(
    [
      { label: 'Internal', value: 6 },
      { label: 'External', value: 7 },
    ]);

  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#4caf50',
      },
    },
  });    
  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){setThemeType("light")}
    else{setThemeType("dark")}
    
  }, [props.activeModeClass]);

  return (
    <div className={classes.root} style={{marginTop: '0px'}}>
    <p>{props.label}</p>
    <MuiThemeProvider theme={THEME}>
      <CssBaseline /> 
      <MultiSelect 
        options={compData}
        value={props.value || []}
        onChange={props.onChange}
        labelledBy="Select "
      />
    </MuiThemeProvider>
    </div>
  );
};

export default MultiSelectExternalCallTypeCheckBox;
