import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';


const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));


export default function IconBreadcrumbs(props) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
 
  return (
      <div>
    <Breadcrumbs aria-label="breadcrumb">
      <Link 
      color="inherit" 
      onClick={props.onClickAdd} 
      className={classes.link}>
        <AddIcon className={classes.icon} />
        Add
      </Link>
      <Link
        color="inherit"
        onClick={props.onClickEdit}
        className={classes.link}
      >
        <EditIcon className={classes.icon} />
        Edit
      </Link>
      <Link 
      color="inherit" 
      onClick={props.onClickDelete} 
      className={classes.link}>
        <DeleteIcon className={classes.icon} />
        Delete Rate Plan
      </Link>
    </Breadcrumbs>
    </div>
  );
}
