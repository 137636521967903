import React, { Component } from "react";
import ReactDOM from "react-dom";

import FlatfileImporter from "@flatfile/adapter";

import "assets/css/flat-file-custom.css";
import ApiCall from "Helper/Api.js";
import {withAuth0} from "@auth0/auth0-react";
const LICENSE_KEY = "aa921983-4db2-4da1-a580-fbca0b1c75b2";

class App extends Component {
  constructor() {
    super();
    this.getSites = this.getSites.bind(this);
    this.launch = this.launch.bind(this);
    this.importer = new FlatfileImporter(LICENSE_KEY, {
      fields: [
        {
          label: "Extension",
          key: "extension",
          description: "User's Extension",
          validators: [
            {
              validate: "unique",
              error: "User's Extension must be unique"
            },
            {
              validate: "required",
              error: "This is a required field"
            },
            {
              validate: "regex_matches",
              regex: "^[0-9]*$",
              error: "Must conatin only numbers."
            }
          ]
        },
        {
          label: "User Id",
          key: "userId",
          description: "User's Extension",
          validators: [
            {
              validate: "unique",
              error: "User's Extension must be unique"
            },
            {
              validate: "required",
              error: "This is a required field"
            },
            {
              validate: "regex_matches",
              regex: "^[0-9]*$",
              error: "Must conatin only numbers."
            }
          ]
        },
        {
          label: "Name",
          key: "name",
          description: "User's Name",
          validators: [
            {
              validate: "required",
              error: "This is a required field"
            }
          ]
        },
        {
          label: "Email",
          key: "email",
          description: "Email",
        },
        {
          label: "Site",
          key: "siteId",
          description: "Use the Site's Long Name",
          validators: [
            {
              validate: "required",
              error: "This is a required field"
            }
          ]
        },
        {
          label: "Div A",
          key: "divA",
          description: "User's Department",
          validators: [
            {
              validate: "required",
              error: "This is a required field"
            }
          ]
        },
        {
          label: "Div B",
          key: "divB",
          description: "User's Branch",
        },
        {
          label: "Div C",
          key: "divC",
          description: "User's Branch",
        },
        {
          label: "Div D",
          key: "divD",
          description: "User's Organization",
        },
        {
          label: "Div E",
          key: "divE",
          description: "User's Region",
        },
        // {
        //   label: "Rate Plan",
        //   key: "RatePlan",
        //   description: "Rate Plan",
        // },
        // {
        //   label: "Auth/Account Code",
        //   key: "Auth/Account Code",
        //   description: "Auth/Account Code",
        // },
       
      
        {
          label: "Notes",
          key: "notes",
          description: "Notes",
        }
      ],
      type: "Extensions",
      allowInvalidSubmit: true,
      managed: true,
      allowCustom: true,
      disableManualInput: false
    });
    this.state = {
      results: "Your raw output will appear here.",
      sitesObj:[],
    };

    this.importer.registerRecordHook((record, index) => {
      let out = {};
      if (record.name.includes(" ")) {
        out.name = {
          value: record.name.replace(" ", "_"),
          info: [
            {
              message:
                "No spaces allowed. Replaced all spaces with underscores",
              level: "warning"
            }
          ]
        };
      }
      return out;
    });

    this.importer.setCustomer({
      userId: "19235",
      name: "MC"
    });
  }

  componentDidMount() {
    this.getSites();    
                    
    
  }
  getSites = async ()=> { //add the filters
    const siteObj = [];
    const token = await this.props.auth0.getAccessTokenSilently();
    const api = new ApiCall(token);

       api
         .getSites()
         .then((response) => {
            response.data.sites.map((item)=>{
               let objItem = {};
               objItem.name = item.name;
               objItem.id = item.id;
               siteObj.push(objItem);
               this.setState({sitesObj:siteObj})
               
            }) 
          }) 
         .catch((err) => {console.log(err)});      
  };  
  launch = async () => {
    this.importer
      .requestDataFromUser()
      .then(results => {
        this.importer.displayLoader();
         setTimeout(() => {
          this.importer.displaySuccess("Success!");
          // this.setState({
          //   results: JSON.stringify(results.validData, null, 2)                                  
          // });
          let extArray = [];
          results.validData.map((item)=> {
            let siteNum = 1;
            this.state.sitesObj.map((objitem)=>{
              if(item.siteId == objitem.name)
                siteNum = objitem.id
            })          
            let ext = {};
            ext.extension = item.extension;
            ext.userId = item.userId;
            ext.name = item.name;
            ext.email = item.email;
            ext.divA = item.divA;
            ext.divB = item.divB;
            ext.divC = item.divC;
            ext.divD = item.divD;
            ext.divE = item.divE; 
            ext.siteId = siteNum;
            ext.notes = item.notes;
            extArray.push(ext);
          }) 
          const extsObj = { 
            method: "update",
            extensions: extArray,
          };
        
          this.addExtensions(extsObj);
          
        }, 1500);
      })
      .catch(function(error) {
        console.info(error || "window close");
      });
  }

  addExtensions = async (obj) =>{
    try{      
      const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);

      console.log(obj)
      const res = await api.importExtension(obj);
      if(res.status === 200) {
        console.log("success");
        return;
      }
      console.log("error"); 
      alert("Error");
      }
      catch(err) {
        console.log(err);
        alert("Error");
      }   
  }
  render() {
    return (
      <div >
        {LICENSE_KEY ? null : (
          <div className="licenseAsk">
            Obtain your license key from the
            <a href="https://flatfile.io/app" target="_blank">
              Flatfile Dashboard &rarr;
            </a>
            <p>
              Once you've found it, set the <code>{LICENSE_KEY}</code> variable on
              Line 8 before continuing.
            </p>
          </div>
        )}
        <input
          type="button"
          id="launch"
          className={LICENSE_KEY ? null : "disabled"}
          value="Launch Importer"
          onClick={this.launch}
        />
       
        <div className="download">
        {/* <a href="..\src\extensions.csv" target="_blank" rel="noopener noreferrer">
            Download a sample csv file here
        </a> */}
        </div>
        {/*<textarea id="raw_output" readOnly value={this.state.results} />*/}
      </div>
    );
  }
}

export default withAuth0(App);



