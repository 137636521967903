/* eslint-disable no-use-before-define */
import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useState, useLayoutEffect} from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function SelectMultiple(props) {
  const classes = useStyles();
  const [themeType,setThemeType] = useState('dark');
  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")}
    
  }, []);
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#4caf50',
      },
    },
  });    
  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={THEME}>
      <Autocomplete
        multiple
        limitTags={2}
        id="tags-standard"
        options={top100Films}
        getOptionLabel={(option) => option.title}
        defaultValue={[top100Films[0]]}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Select a Department"
            placeholder=""
          />
        )}
      />
      </MuiThemeProvider>
    </div>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: 'Acura', value: 0 },
  { title: 'Audi', value: 1 },
  { title: 'Cadillac', value: 2 },
  { title: 'Chevrolet', value: 3 },
  { title: 'Honda', value: 4 }
];
