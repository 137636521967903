/* eslint-disable no-use-before-define */
import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useState, useLayoutEffect} from 'react';
import ApiCall, {ExpoApiContext} from "Helper/Api.js";
import {useExpoApi} from "../../Helper/Api.js"

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function SelectMultiple(props) {
  const classes = useStyles();
  const [themeType,setThemeType] = useState('dark');
  const getApi = useExpoApi();
  const[roles, setRoles] = useState([{}])
  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){setThemeType("light")}
    else{setThemeType("dark")}
    
  }, []);
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#4caf50',
      },
    },
  });    
  const getRoles= async ()=> {
    const roles = [];
      const api = await getApi();
       api
         .getWebUserRole()
         .then((response) => {
           response.data.map((item)=>{
              let userRole = {}
              userRole.value = item.id;
              userRole.title = item.name;
              roles.push(userRole);
              setRoles(roles)
            })
          }) 
         .catch((err) => {console.log(err)});     
  };

  React.useEffect(() => {  
    getRoles();
  }, []);
  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={THEME}>
      <Autocomplete
        multiple
        id="tags-standard"
        options={roles}
        onChange = {props.onChange}
        getOptionLabel={(option) => option.title}
        defaultValue={props.selItems}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Access"
            placeholder=""
          />
        )}
      />
      </MuiThemeProvider>
    </div>
  );
}

