import React, { useState, useLayoutEffect } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox'
import {
  Button, 
  Modal, 
  ModalBody, 
  ModalFooter ,  
  Row,
  Col,
} from 'reactstrap';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import SelectMultDailyFreq from "components/MaterialUI/SelectMultipleDailyFreq.js";
import TimePicker from "components/MaterialUI/TimePickerRptSched.js";

const CallClassAlertsDialog = (props) => {
  const [themeType,setThemeType] = useState('dark');

  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")}
  }, [props.activeModeClass]);

  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#1d8cf8',
      },
    },
  });
  return (
    <div>
    <MuiThemeProvider theme={THEME}>
    <Modal isOpen={props.AdvFilterDlgOnOpen} toggle={props.AdvFilterDlgOnClose} modalClassName={props.className}>
    <div className="modal-header">
      <h5 className="modal-title">More Filters</h5>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.AdvFilterDlgOnClose}>
        <i className="tim-icons icon-simple-remove" />
      </button>
    </div>
    <ModalBody style={{marginTop: '10px'}}>
    <Col sm="12">
     <div style={{marginLeft:"-20px" ,fontSize:"12px"}}>
      <Row>
        <Col sm="12">
          <SelectMultDailyFreq label= "Select Specific Days" onChange = {props.onChangeDaysOfWeek} selVal = {props.daysOfWeek}/>
        </Col>
      </Row>
      <Row style = {{marginTop:"25px",marginLeft:"-20px"}}>
        <Col xs="6">  <TimePicker  activeModeClass="dark-content" label="From Time" value = {props.timeBegin} onChange = {props.onChangeFromTimePicker}/> </Col>
        <Col xs="6">  <TimePicker  activeModeClass="dark-content" label="To Time" value = {props.timeEnd} onChange = {props.onChangeToTimePicker}/> </Col>
      </Row>
       <Row style = {{marginTop: "10px"}}>
         <Col sm="12">
           <FormControlLabel control={<Checkbox onChange={props.onChangeBusinessHourFilter} checked={props.businessHourFilter} />} label="Filter by Branch Hours" />
         </Col>
       </Row>
       <Row>
         <div style = {{height:"20px"}} />
      </Row>
    </div>
    </Col>           
    </ModalBody>
    <ModalFooter>
    <Button color="warning" size='md' onClick={props.AdvFilterDlgOnClose}>
      OK 
    </Button>
    </ModalFooter>
    </Modal>
    </MuiThemeProvider>
    </div>
  );
}

export default CallClassAlertsDialog;