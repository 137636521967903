/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Wizard from "views/forms/Wizard.js";
import WebUserTable from "components/Tables/WebUser.js";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

class UserProfile extends React.Component {
  render() {
    return (
      <>
      <div className="content">
        <Wizard/> 
      <Col className="mr-auto ml-auto" md="10">
      {document.body.classList.contains("white-content") ? 
        <WebUserTable activeModeClass="white-content"></WebUserTable> : <WebUserTable activeModeClass="dark-content"></WebUserTable>           
      }
      </Col>
    </div>
      </>
    );
  }
}

export default UserProfile;
