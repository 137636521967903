import React, { useLayoutEffect} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import {useExpoApi} from "../../Helper/Api.js"

// Themes end

am4core.options.autoDispose = true;
export default function App(props) {
  const getApi = useExpoApi();

  useLayoutEffect(() => {
    am4core.unuseAllThemes();
    am4core.addLicense("CH277440643");
    if(props.activeModeClass === "white-content"){  
    am4core.unuseTheme(am4themes_dark);
    }
    else{
    am4core.useTheme(am4themes_dark);
    }
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create("pieChartDiv", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.padding(-20, 5, 55, 5);

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = "left";
    chart.exporting.menu.verticalAlign = "bottom";
    am4core.color("#f00", 0);

    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.radiusValue = "value";
    series.dataFields.category = "calltype";
    series.slices.template.cornerRadius = 6;

    series.colors.list = [
    am4core.color("#2dce89"),
    am4core.color("#1d8cf8"),
    am4core.color("#ff8d72"),
    am4core.color("#344675"),
    am4core.color("#e14eca"),
    am4core.color("#5e72e4"),
    am4core.color("#f5365c"),
    ];
    series.hiddenState.properties.endAngle = -90;

    const getData = async () => {   
      try{
          let graphData = [];
          const api = await getApi();
          const dataResponse = await api.getCallClassStatistics(props.filters);
          const json = await dataResponse.data;
          const parseGraphData = async(json) =>{
              json.calls.forEach((item) => {
                  let graphSubItem = {};
                  let str = props.selCallClass;
                  if(str.includes(item.calltype)){
                    graphSubItem.calltype = item.calltype
                    graphSubItem.value =  item.value;     
                    graphData.push(graphSubItem);
                  }
              })         
          }
          parseGraphData(json);
          chart.data = graphData;       
      }
      catch(err) {
          console.log(err);
       }     
  }

    getData();

  }, [props.totalCalls, props.selCallClass]);
  return (
    < div id="pieChartDiv" style={{ width: "100%", height: "100%" }}></div>
  );
}
