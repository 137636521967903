import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

export default function ErrorRadios(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(false);

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setError(false);
  };

  return (
    <form >
      <FormControl component="fieldset" error={error} className={classes.formControl}>
        <FormLabel component="legend">Ends Date</FormLabel>
        <RadioGroup aria-label="quiz" name="quiz" value={props.value} onChange={props.onChange}>
          <FormControlLabel value="Never" control={<Radio />} label="Never" />
          <FormControlLabel value="On" control={<Radio />} label="On" />
        </RadioGroup>
      </FormControl>
    </form>
  );
}
