import React from "react";
import classNames from "classnames";
import CallDetailTable from "components/Tables/CallDetailsTable.js";
import MostFreqDialedCountry from "components/Tables/MostFreqCountryDialedTable.js";
import CallingLocMap from "components/JVectorMap/CallingLocMap.js";
import CallingLocMapUSA from "components/JVectorMap/CallingLocMapUSA.js";
import DateTimePicker from "components/MaterialUI/DateTimePicker_MonthlyChart";
import CheckBoxList from "components/OthersCharts/CheckBoxList/multi-select-check.js"
import FilterListIcon from "@material-ui/icons/FilterList";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ApiCall from "Helper/Api.js";
import {withAuth0} from "@auth0/auth0-react";

// reactstrap components
import { 
  Card, 
  CardHeader, 
  CardBody, 
  CardTitle, 
  Row, 
  Col, 
  Button,
  ButtonGroup,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import dayjs from 'dayjs';

function get_filters(obj) {
  return {
    timeRangeStart: obj.state.startDateRange,
    timeRangeEnd: obj.state.endDateRange,
    divAIds: obj.state.divAIds,
    divBIds: obj.state.divBIds,
    divCIds: obj.state.divCIds,
    externalCallTypes: obj.state.externalCallTypes,
  }
}

class Charts extends React.Component {
  constructor(props){
    super(props)
    this.state={
      selMapType:"us_aea",
      boxSelText:"Calling Location Details",
      bigChartData: "data1",
      isOpen:false,
      filterDateFrom: dayjs().set('hour', 7).startOf('hour').toISOString(),
      startDateRange: dayjs().set('hour', 7).startOf('hour').toISOString(),
      endDateRange: dayjs().set('hour', 7).startOf('hour').add(1, 'day').toISOString(),
      deptSelected:[],
      divSelected:[],
      branchSelected:[],
      divAids:"",
      divBids:"",
      divCids:"",
      totalCalls:0,
    }
  }
  componentDidMount() {
    const getData = () => {
      this.getTotalCalls();
    }
    getData();
    this.intervalId = setInterval( getData ,10000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  getTotalCalls = async ()=> { //add the filters
    const token = await this.props.auth0.getAccessTokenSilently();
    console.log(this.state.startDateRange);
    const api = new ApiCall(token);

       api
         .getCallsStatistics(this.state.startDateRange,this.state.endDateRange,this.state.divAids,this.state.divBids,this.state.divCids)
         .then((response) => {
            console.log(response.data)
            this.setState({
              totalCalls: response.data.todayTotalCalls,
            });
            
          }) 
         .catch((err) => {console.log(err)});      
  };

  changeMapType = (maptype) =>{
    this.setState({
      selMapType:  maptype
    })
  };
  setBgChartData = name => {
    this.setState({
      bigChartData: name
    });
    if(name === "data1"){
      this.setState({startDateRange: this.formatDate()})
      this.setState({endDateRange: this.formatDate().substring(0,10) + "T23:59"})
    }
    if(name === "data2"){
      this.setState({startDateRange: this.getFirstDateWeek()})
      this.setState({endDateRange: this.getLastDateWeek()})
    }
    if(name === "data3"){
      this.setState({startDateRange: this.getFirstDateMonth()})
      this.setState({endDateRange: this.getLastDateMonth()})
    }
    this.getTotalCalls();
  };
  dateFromOnChange = (e) => {
    this.setState({filterDateFrom : e.target.value});
    this.setState({startDateRange:  e.target.value})
    this.setState({endDateRange : e.target.value.substring(1,10) + "T23:59"}); 
  }
  handleDivAOnChange = (obj)=>{
    this.setState({deptSelected: obj})
    const divs = obj.map((item) => item.value).join();
    this.setState({divAids:divs})
    
  }
  handleDivBOnChange = (obj)=>{
   this.setState({divSelected: obj})
   const divs = obj.map((item) => item.value).join();
   this.setState({divBids:divs})
 }
 handleDivCOnChange = (obj)=>{
    this.setState({branchSelected: obj})
    const divs = obj.map((item) => item.value).join();
    this.setState({divCids:divs})
 }

 handleOnApplyFiltersClick = () => {
  
  if(this.state.bigChartData == "data1"){
    this.setState({startDateRange: this.formatDate()})
    this.setState({endDateRange: this.formatDate().substring(0,10) + "T23:59"})
  }
  if(this.state.bigChartData == "data2"){
    this.setState({startDateRange: this.getFirstDateWeek()})
    this.setState({endDateRange: this.getLastDateWeek()})
  }
  if(this.state.bigChartData == "data3"){
    this.setState({startDateRange: this.getFirstDateMonth()})
    this.setState({endDateRange: this.getLastDateMonth()})
  }
  this.setState({isOpen:false})
  this.getTotalCalls();
}
 
formatDate = () => {
    const d = dayjs(this.state.filterDateFrom);
    return d.toISOString();
}

getFirstDateWeek = () => {
  const d = dayjs(this.state.filterDateFrom);
  return d.startOf('week').toISOString();
}

getLastDateWeek = () => {
  const d = dayjs(this.state.filterDateFrom);
    return d.endOf('week').toISOString();
}

getFirstDateMonth = () => {
  const d = dayjs(this.state.filterDateFrom);
  return d.startOf('month').toISOString();
}

getLastDateMonth = () => {
  const d = dayjs(this.state.filterDateFrom);
  return d.endOf('month').toISOString();
}
openFilterTab =()=>{this.setState({ isOpen: !this.state.isOpen})};
  render() {
    return (
      <>
        <div className="content">
          <Row>
          <Col md="6" style={{marginLeft:"0px"}}>
          <div id = "filterBtn" style={{marginTop:"-35px"}}>
          <Tooltip title="Dashboard Filters">
          <IconButton aria-label="Filters" onClick={() => this.openFilterTab()}>
          <FilterListIcon />
          <h5>Filters</h5>
          </IconButton>
          </Tooltip>
          </div>      
          </Col> 
          <Col sm="6" style={{marginTop:"-18px"}}>
          <ButtonGroup
                className="btn-group-toggle float-right"
                data-toggle="buttons"
              >
                <Button
                  color="warning"
                  id="0"
                  size="sm"
                  tag="label"
                  className={classNames("btn-simple", {
                    active: this.state.bigChartData === "data1"
                  })}
                  onClick={() => this.setBgChartData("data1")}
                >
                  <input defaultChecked name="options" type="radio" />
                  <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    Daily
                  </span>
                  <span className="d-block d-sm-none">
                    <i className="tim-icons icon-single-02" />
                  </span>
                </Button>
                <Button
                  color="warning"
                  id="1"
                  size="sm"
                  tag="label"
                  className={classNames("btn-simple", {
                    active: this.state.bigChartData === "data2"
                  })}
                  onClick={() => this.setBgChartData("data2")}
                >
                  <input name="options" type="radio" />
                  <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    Weekly
                  </span>
                  <span className="d-block d-sm-none">
                    <i className="tim-icons icon-gift-2" />
                  </span>
                </Button>
                <Button
                  color="warning"
                  id="2"
                  size="sm"
                  tag="label"
                  className={classNames("btn-simple", {
                    active: this.state.bigChartData === "data3"
                  })}
                  onClick={() => this.setBgChartData("data3")}
                >
                  <input name="options" type="radio" />
                  <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    Monthly
                  </span>
                  <span className="d-block d-sm-none">
                    <i className="tim-icons icon-tap-02" />
                  </span>
                </Button>
              </ButtonGroup>
          </Col>
          </Row>
          <Row>
          <Collapse isOpen={this.state.isOpen}>
          { <div style={{height:'350px', marginTop:'15px', marginLeft:'20px'}}>
          <Row >
            <Col sm="6">
            <DateTimePicker  activeModeClass="dark-content" label="From Date"  date = {this.state.filterDateFrom} onChange= {this. dateFromOnChange}   ></DateTimePicker>
            </Col>
          </Row>
          <Row style={{marginTop:'30px', marginLeft:'-7px'}}>
          <Col sm="6">
            <CheckBoxList onChange = {(obj)=>this.handleDivAOnChange(obj)} value= {this.state.deptSelected} level="A" activeModeClass="dark-content" label="Select Department(s)"></CheckBoxList>
          </Col>  
          <Col sm="6">
            <CheckBoxList onChange = {(obj)=>this.handleDivBOnChange(obj)} value= {this.state.divSelected} level="B" activeModeClass="dark-content" label="Select Branch(s)"></CheckBoxList>
          </Col>  
          </Row>
          <Row style={{marginTop:'30px', marginLeft:'-7px'}}>
          <Col sm="6">
            <CheckBoxList onChange = {(obj)=>this.handleDivCOnChange(obj)} value= {this.state.branchSelected} level="C" activeModeClass="dark-content" label="Select Region(s)"></CheckBoxList>
          </Col>  
          </Row>
          
        <Row style={{marginTop:'25px', marginLeft:'-20px'}}>
         <Col sm="6"> </Col>
         <Col sm="6">  </Col>
        </Row>
        <Row style={{marginTop:'25px', marginLeft:'-20px'}}>
          <Col sm="6"><Button color="warning" onClick={ ()=>this.handleOnApplyFiltersClick()}>Apply</Button></Col>   
        </Row>
        </div> }  
        </Collapse>
        </Row>  
        <Row>
        <Col lg="12">
          <Card>
            <CardHeader>
            <Row>
            <Col className="text-left" sm="11">
              <CardTitle tag="h4">Most Frequent Location Dialed</CardTitle>
            </Col>
          
            <Col sm="1">
            <UncontrolledDropdown>
            <DropdownToggle
              caret
              className="btn-icon"
              color="link"
              data-toggle="dropdown"
              type="button"
            >
              <i className="tim-icons icon-settings-gear-63" />
            </DropdownToggle>
            <DropdownMenu aria-labelledby="dropdownMenuLink" right>
              <DropdownItem
             
                onClick={()=>{this.changeMapType("world_mill")}}
              >
                World Map
              </DropdownItem>
              <DropdownItem
            
                onClick={()=>{this.changeMapType("us_aea")}}
              >
                USA Map
              </DropdownItem>
            
            </DropdownMenu>
            </UncontrolledDropdown>
            </Col>
          </Row> 
            </CardHeader>
          
            <CardBody>
              <Row>
                <Col md="5" style={{marginTop:"-10px"}}>
                  <MostFreqDialedCountry activeModeClass="dark-content" totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                </Col>
                {this.state.selMapType == "world_mill" && 
                  <Col className="ml-auto mr-auto" md="7">
                    <CallingLocMap  totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                  </Col>
                }
                {this.state.selMapType == "us_aea" && 
                  <Col className="ml-auto mr-auto" md="7">
                    <CallingLocMapUSA  totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                  </Col>
                }
                
              </Row>
            </CardBody>
          </Card>
        </Col>
        </Row>
        <Row >
        <Col lg="12">
          <Card className="card-chart">
            <CardHeader>
              <CardTitle tag="h4">{this.state.boxSelText}</CardTitle>
              <p className="card-category">       
              </p>
            </CardHeader>
            {/*<UncontrolledDropdown>
            <DropdownToggle
              caret
              className="btn-icon"
              color="link"
              data-toggle="dropdown"
              type="button"
            >
              <i className="tim-icons icon-settings-gear-63" />
            </DropdownToggle>
            <DropdownMenu aria-labelledby="dropdownMenuLink" right>
              <DropdownItem
                href=""
                onClick={()=>this.changeMapType("world-map", "Calling Locations")}
              >
                Calling Locations
              </DropdownItem>
              <DropdownItem
                href=""
                onClick={()=>this.changeMapType("usa-map", "USA's Calls")}
              >
                USA's Calls
              </DropdownItem>
            
            </DropdownMenu>
            </UncontrolledDropdown>*/}
            <CardBody>
              <Row>
                <Col className="ml-auto mr-auto" md="12">
                <div style={{marginTop:"-60px"}}>
                  {!document.body.classList.contains("white-content") && 
                  <CallDetailTable activeModeClass="dark-content" totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                  }  
                  {document.body.classList.contains("white-content") && 
                  <CallDetailTable activeModeClass="white-content" totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                  }  
                  
                </div> 
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        </Row>
        </div>
      </>
    );
  }
}

export default withAuth0(Charts);
