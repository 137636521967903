import React from 'react';
import { useState, useLayoutEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ScheduleIcon from '@material-ui/icons/Schedule';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles({
  root: {
    width: 315
  }
});

export default function TypographyMenu(props) {
  const classes = useStyles();
  const [themeType,setThemeType] = useState('dark');
  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")}
    
  }, []);
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#4caf50',
      },
    },
  });
  
  return (
    <div>
      <MuiThemeProvider theme={THEME}> 
      <MenuList className={classes.root}>
        <MenuItem onClick = {props.onClickMenu1}>
          <ListItemIcon>
            <DoneIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Apply a one-time SVC</Typography>
        </MenuItem>
        <MenuItem onClick = {props.onClickMenu2}>
          <ListItemIcon>
            <DoneOutlineIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">
            Apply a one-time credit
          </Typography>
        </MenuItem>
        <MenuItem onClick = {props.onClickMenu3}>
          <ListItemIcon>
            <ScheduleIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Schedule a recurring SVC
          </Typography>
        </MenuItem>
        <MenuItem onClick = {props.onClickMenu4}>
          <ListItemIcon>
            <BookmarkBorderIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Remember a SVC for later
          </Typography>
        </MenuItem>
        <MenuItem onClick = {props.onClickMenu5}>
          <ListItemIcon>
            <CheckCircleOutlineIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Apply all Memorized Charges
          </Typography>
        </MenuItem>
      </MenuList>
   
      </MuiThemeProvider>
    </div>
    
  );
}
