import React, { Component, useState,useLayoutEffect } from "react";
import Chart from "react-apexcharts";
import {useExpoApi} from "Helper/Api.js"

export default function ChartGraph(props) {
  const getApi = useExpoApi();
  const[options, setOptions] = useState({});
  const[series, setSeries] = useState([]);
  
  useLayoutEffect(() => {

    const getData = async () => {
      //fetching data from the api

      const api = await getApi();
      api
      .getTopTenMostExpChart(props.filters)
      .then((response) => {
        setSeries([{
          data: response.data.totalCharge
        }])
        setOptions( {
          chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          background:'transparent',  
          foreColor:'#9A9A9A',
          fontSize: '16px',
          toolbar: {
          show: true
          },
          zoom: {
          enabled: true
          }
          },
          plotOptions: {
          bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true,
          dataLabels: {
          position: 'bottom'
          },
          }
          },
          colors: ['#2dce89', '#11cdef', '#2bffc6', '#1d8cf8', '#ff8d72', '#5e72e4', '#f9a3a4', '#5603ad','#e14eca', '#fb6340'],
          grid: {
  
          yaxis: {
          lines: {
          show: false
          }
          },  
          },
          responsive: [{
          breakpoint: 480,
          options: {
          legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
          }
          }
          }],
  
          theme: {
          mode: 'dark', 
          palette: 'palette1', 
          } ,
          dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
          colors: ['#fff']
          },
          formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  $" + val
          },
          offsetX: 0,
          dropShadow: {
          enabled: true
          }
          },
          stroke: {
          width: 1,
          colors: ['#fff']
          },
          xaxis: {
          categories: response.data.ext,
          },
          yaxis: {
          labels: {
          show: false
          }
          },
          title: {
          text: '',
          align: 'center',
          floating: true
          },
          subtitle: {
          text: '',
          align: 'center',
          },
          tooltip: {
          theme: 'dark',
          x: {
          show: false
          },
          y: {
          title: {
          formatter: function () {
          return ''
          }
          }
          }
          },
          legend: {
          position: 'bottom',
          offsetY: 4,        
          },
          fill: {
          opacity: 1
          }
          } ) 

      })
      .catch((err) => {console.log(err)});

    }

    getData();

  }, [props.totalCalls]);
  return (
  <div style={{marginLeft:'0px', marginTop:'10px', width: '100%',  height: '100%'}}>
  <Chart
  options={options}
  series={series}
  type="bar"

  />
  </div>
  );
}