import React, { useState, useLayoutEffect } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles((theme) => ({
  selectField: {
      width: '200px',
    }
  }));
  
  const SelectSimpleComponent = (props) => {
    const classes = useStyles();
    const [themeType,setThemeType] = useState('dark');
    const [condition, setCondition] = React.useState('13');
    const handleConditionChange = (event) => {
      setCondition(event.target.value);
    };
    useLayoutEffect(() => {
        if(props.activeModeClass === "white-content"){  setThemeType("light")}
        else{setThemeType("dark")}
        
      }, []);
      const THEME = createMuiTheme({
        palette: {
          type: themeType,
          primary: {
            main: '#f8955e',
          },
          secondary: {
            main: '#1d8cf8',
          },
        },
      });
      return (
          <div>
              <MuiThemeProvider theme={THEME}>
               <CssBaseline/>   
               <FormControl >
                <InputLabel shrink id="condition-select">
                  Rate Table
                </InputLabel>
                <Select
                  labelId="condition-label"
                  id="condition-select"
                  value={props.selectedval}
                  onChange={props.onChange}
                  className={classes.selectField}
                  displayEmpty
                >
                                   <MenuItem value={0}>AT&T Direct Dial</MenuItem>
                  <MenuItem value={1}>AT&T Direct Peak</MenuItem>
                  <MenuItem value={2}>AT&T Op.Ast</MenuItem>
                  <MenuItem value={3}>AT&T Op.Ast.Peak</MenuItem>
                  <MenuItem value={4}>Verizon Direct</MenuItem>
                  <MenuItem value={5}>Verizon Direct Peak</MenuItem>
                  <MenuItem value={6}>Verizon Opt.Ast</MenuItem>
                  <MenuItem value={7}>Verizon Op.Ast.Peak</MenuItem>
                  <MenuItem value={8}>Spring Direct</MenuItem>
                  <MenuItem value={9}>Spring Direct Peak</MenuItem>
                  <MenuItem value={10}>Spring Opt.Ast</MenuItem>
                  <MenuItem value={11}>Spring Op.Ast.Peak</MenuItem>
                  <MenuItem value={12}>AT&T ILATA</MenuItem>
                  <MenuItem value={13}>Per-minute(simple)</MenuItem>
                  <MenuItem value={14}>Per-minute(2 steps)</MenuItem>
                  <MenuItem value={15}>Per-minute(3 steps)</MenuItem>
                  <MenuItem value={16}>All-In-One</MenuItem>
                  <MenuItem value={17}>Reserved</MenuItem>
                  <MenuItem value={18}>Reserved</MenuItem>
                  <MenuItem value={19}>Reserved</MenuItem>
                  <MenuItem value={20}>User-Defined 1</MenuItem>
                  <MenuItem value={21}>User-Defined 2</MenuItem>
                  <MenuItem value={22}>User-Defined 3</MenuItem>
                  <MenuItem value={23}>User-Defined 4</MenuItem>
                  <MenuItem value={24}>User-Defined 5</MenuItem>
                  <MenuItem value={25}>User-Defined 6</MenuItem>
                  <MenuItem value={26}>User-Defined 7</MenuItem>
                  <MenuItem value={27}>User-Defined 8</MenuItem>
                  <MenuItem value={28}>User-Defined 9</MenuItem> 
                </Select>
              </FormControl>
               
              </MuiThemeProvider>
          </div>
      )
  }
  export default SelectSimpleComponent;