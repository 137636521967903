import React from "react";
import MaterialTable from "material-table";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { forwardRef, useState, useLayoutEffect, useRef} from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {useExpoApi} from "../../Helper/Api.js"

import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(LocalizedFormat);

const tableIcons = {
Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} color='action'/>),
Check: forwardRef((props, ref) => <Check {...props} ref={ref} color='action'/>),
Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} color='action'/>),
Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} color='action'/>),
DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} color='action'/>),
Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} color='action'/>),
Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} color='action'/>),
Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} color='action'/>),
FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} color='action'/>),
LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} color='action'/>),
NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} color='action'/>),
PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} color='action'/>),
ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} color='action'/>),
Search: forwardRef((props, ref) => <Search {...props} ref={ref} color='action'/>),
SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} color='action'/>),
ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} color='action'/>),
ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} color='action'/>)
};

export default function LiveViewTable(props) {
  const _isMounted = useRef(true);
  const getApi = useExpoApi();
  const[recentCalls, setRecentCalls] = useState([]) ;
  const [columns] = useState([
  { title: "Date", field: "start_time"},
  { title: "Extension", field: "ext"} ,
  { title: "Name", field: "name"} ,
  { title: "Digits", field: "digits"} ,
  { title: "Duration", field: "duration"} ,
  { title: "Location", field: "location"} ,  
  { title: "Direction", field: "direction"} ,    
  ]) ;

  const [themeType,setThemeType] = useState('dark');
  const apiCallRecentCalls = async ()=> {
     if (_isMounted.current) {
       const api = await getApi();
    api.getRecentCalls(props.filters)
         .then((response) => {       
            parseTableData(response.data.calls)   ;
               
          }) 
         .catch((err) => {console.log(err)});   
      }
    };

    const parseTableData = async(data) => { 
      const tabledata = data.map((newItem)=>{
        let newItemObj = {}; 
        
        const date = dayjs(newItem.start_time);
        newItemObj.start_time =  date.format('M/DD LT');
        newItemObj.ext = newItem.ext;
        newItemObj.name = newItem.name;
        newItemObj.digits = newItem.digits;
        newItemObj.duration = newItem.duration;
        newItemObj.location = newItem.location;
        newItemObj.direction = newItem.direction;

        return newItemObj;
      })
      setRecentCalls(tabledata);
    }
    React.useEffect(() => {  
      apiCallRecentCalls();
    }, [props.totalCalls]); 

   
  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")}

  }, []);

  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#4caf50',
      },
    },
  });
  return (
    <div>
      <MuiThemeProvider theme={THEME}>
      
          <MaterialTable
            style= {{
                backgroundColor: 'transparent',
                boxShadow: 'none',
            }}
            icons={tableIcons}
            title=""
            columns={columns}
            data={recentCalls}
            options={{
                headerStyle: {
                  backgroundColor: "rgba(0,0,0,0)",  // #6ca6c2; //67b7dc        
                  fontWeight: 'bold'
                }
              }}
            editable={{
  
            }}
          />
      </MuiThemeProvider>
    </div>
  );
}
