import React, {useState, useEffect} from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import {useAuth0, Auth0Provider} from "@auth0/auth0-react";
import {ExpoApiProvider, useExpoApi} from "./Helper/Api"

import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";

import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.1.0";
import "assets/demo/demo.css";

const hist = createBrowserHistory();

const onRedirectCallback = (appState) => {
    hist.push(appState?.returnTo || window.location.pathname);
};

const Login = () => {
  const {loginWithRedirect} = useAuth0();
  useEffect(() => {
      loginWithRedirect({appState: {returnTo: window.location.pathname + window.location.search}}).catch();
  });

  return <div />
}

const Logout = () => {
 const {logout}  = useAuth0();
 logout({returnTo: window.location.origin});
}

const ProtectedRoute = ({...args}) => {
  const {isAuthenticated, isLoading} = useAuth0();
  if(isAuthenticated) {
    return <Route {...args} />
  }

  if(isLoading) {
    return null;
  }

  return <Login />;
}

const CallbackPage = () => {
	const {isLoading, isAuthenticated, error} = useAuth0();

	if(isLoading) {
		return <h2>Loading</h2>;
	}

	if(!isAuthenticated) {
		return <div><h2>Not Authenticated</h2></div>;
	}

	if(error) {
	  return <h2>Error {error.message}</h2>;
  }

	return <Redirect to="/" />;
}

const MsSaasLandingPage = () => {
    window.location.replace(`/api/v1/saas-ms-callback${window.location.search}`);
}

const MsTeamsCallback = (props) => {
    const {isLoading, isAuthenticated, error}	= useAuth0();
    const [status, setStatus]  = useState('waiting');
    const getApi = useExpoApi();

    useEffect(() => {
        console.log('msteams useeffect called');
        const sendToken = async () => {
            const api = await getApi();
           const res = await api.postMsTeamsApiToken(props.location.search);

           if(res.status === 200) {
               setStatus('ok');
               console.log(status);
               return;
           }

           setStatus('error');
           console.log(status);
        };
        sendToken().catch(()=>{
            setStatus('error');
            console.log(status);
        });
    }, []);

    if(isLoading) {
        return <h2>Loading...</h2>;
    }

    if(!isAuthenticated) {
        return <div><h2>Not Authenticated</h2></div>
    }

    if(error) {
        return <h2>Error {error.message}</h2>
    }

   if(status === 'waiting') {
       return <h2>Processing</h2>
   }

   if(status !== 'ok') {
       return <h2>An error was encountered processing your request.</h2>
   }

    return <Redirect to="/admin/real-time" />;
}

ReactDOM.render(
  <Auth0Provider
	domain="metroclouds.us.auth0.com"
	clientId="8UNynO5625pSl4b23ee67doltguaGLds"
	redirectUri={`${window.location.origin}/auth0-callback`}
	onRedirectCallback={onRedirectCallback}
    audience={"https://metroclouds.com/api/v1"}
    cacheLocation="localstorage"
  >
  <ExpoApiProvider>
  <Router history={hist}>
    <Switch>
      <Route path="/saas-ms-callback"><MsSaasLandingPage /></Route>
      <Route path="/auth0-callback"><CallbackPage /></Route>
      <Route path="/auth0-logout"><Logout /></Route>
      <ProtectedRoute path="/msteams-callback" component={MsTeamsCallback} />
      <ProtectedRoute path="/admin" render={props=><AdminLayout {...props} />}/>
      <Route path="/rtl" render={props => <RTLLayout {...props} />} />
      <Redirect from="*" to="/admin" />
    </Switch>
  </Router>
  </ExpoApiProvider>
  </Auth0Provider>,
  document.getElementById("root")
);
