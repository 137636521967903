/* eslint-disable no-use-before-define */
import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useState, useLayoutEffect} from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "350px",
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function SelectMultiple(props) {
  const classes = useStyles();
  const [themeType,setThemeType] = useState('dark');
  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")}
    
  }, []);
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#4caf50',
      },
    },
  });    
  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={THEME}>
      <Autocomplete
        multiple
        limitTags={3}
        id="tags-standard"
        options={callClassObj}
        getOptionLabel={(option) => option.title}
        onChange = {props.onChange}
        // defaultValue={[callClassObj[0],callClassObj[1], callClassObj[3]]}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Select a Call Class"
            placeholder=""
          />
        )}
      />
      </MuiThemeProvider>
    </div>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const callClassObj = [
  { title: 'INC', value: 'INC' },
  { title: 'LOC', value: 'LOC' },
  { title: 'LTOLL', value: 'LTOLL' },
  { title: 'STATE', value: 'STATE' },
  { title: 'LD', value: 'LD' },
  { title: 'INTL', value: 'INTL' },
  { title: 'EMG', value: 'EMG' }
 
];

