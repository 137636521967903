/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classNames from "classnames";
import ApiCall from "Helper/Api.js";
import RatePlanComponent  from "components/MaterialUI/SelectSimpleRatePlan.js";
import CallMethodComponent from "components/MaterialUI/SelectSimpleCallMethods.js";
import SpecNumbersTable from "components/Tables/SpecialNumbersTable.js";

import { 
  Card, 
  CardHeader, 
  CardBody, 
  CardTitle, 
  Row, 
  Col, 
  Table, 
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Button,
  ButtonGroup,
} from "reactstrap";

class SpecialNumbers extends React.Component {
  constructor(props){
    super(props)
    this.state={
      selRatePlanText: "Actual Cost",
      selCallMethodText: "Per-Minute(1 Step)",
      tableColumns: [
        { title: "Prefix", field: "prefix" },
        { title: "Descr", field: "descr" },
        { title: "Class", field: "class" , lookup: { 0: 'Internal', 1: 'Incoming', 2:'Local', 3:'Local Toll', 4:'In-State', 5:'Long Distance', 6:'International', 7:'Emergency', 8:'Service', 9:'Other1', 10:'Other2' }},
        { title: "SecsA", field: "secsA" },
        { title: "CostA", field: "costA" }
      ],
    }
  }
  onChangeRatePlanSelectComp = (e) => {
    this.setState({selRatePlanText: e.target.value});
  }
  onChangeCallMethodSelectComp = (e) => {
    this.setState({selCallMethodText: e.target.value});   
    if(e.target.value === "Per-Minute(1 Step)"){
      this.setState({
        tableColumns: [
          { title: "Prefix", field: "prefix" },
          { title: "Descr", field: "descr" },
          { title: "Class", field: "class" , lookup: { 0: 'Internal', 1: 'Incoming', 2:'Local', 3:'Local Toll', 4:'In-State', 5:'Long Distance', 6:'International', 7:'Emergency', 8:'Service', 9:'Other1', 10:'Other2' }},
          { title: "SecsA", field: "secsA" },
          { title: "CostA", field: "costA" }
        ]
      })  
    }
    else if(e.target.value === "Per-Minute(2 Step)"){
      this.setState({
        tableColumns: [
          { title: "Prefix", field: "prefix" },
          { title: "Descr", field: "descr" },
          { title: "Class", field: "class" , lookup: { 0: 'Internal', 1: 'Incoming', 2:'Local', 3:'Local Toll', 4:'In-State', 5:'Long Distance', 6:'International', 7:'Emergency', 8:'Service', 9:'Other1', 10:'Other2' }},
          { title: "SecsA", field: "secsA" },
          { title: "CostA", field: "costA" },
          { title: "SecsB", field: "secsB" },
          { title: "CostB", field: "costB" }
        ]
      })        
    }
    else if(e.target.value === "Per-Minute(3 Step)"){
      this.setState({
        tableColumns: [
          
        ]
      })  
    }
  }
  render() {
    return (
      <>
        <div className="content">
        <Row>
        <Col lg="12">
          <Card>
            <CardHeader>
            <Row>
            <Col className="text-left" sm="6">
              <CardTitle tag="h4">Select a Rate Plan</CardTitle>
            </Col>
          </Row> 
            </CardHeader>
            <CardBody>
              <Row style={{height:"70px"}}>
              <Col md = "4">
                <RatePlanComponent activeModeClass="dark-content" onChange = {this.onChangeRatePlanSelectComp} selVal={this.state.selRatePlanText}> </RatePlanComponent>
               </Col>  
               <Col md = "4">
                <CallMethodComponent activeModeClass="dark-content" onChange = {this.onChangeCallMethodSelectComp} selVal={this.state.selCallMethodText}/>
               </Col>  
               <Col md = "4">
               </Col>  
              </Row>
            </CardBody>
          </Card>
        </Col>
        </Row>
        <Row >
        <Col lg="12">
          <Card className="card-chart">
            <CardHeader>
              <CardTitle tag="h4"></CardTitle>
              <p className="card-category">       
              </p>
            </CardHeader>
            <CardBody>
              <Row>
                <Col className="ml-auto mr-auto" md="12">
                <div style={{marginTop:"-20px"}}>
                 <SpecNumbersTable activeModeClass="dark-content" selColumns={this.state.tableColumns}/>
                  
                </div> 
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        </Row>
        </div>
      </>
    );
  }
}

export default SpecialNumbers;
