import React , {useContext} from "react";
import {MultiSelect} from "react-multi-select-component";
import { makeStyles } from '@material-ui/core/styles';
import { MuiThemeProvider  } from '@material-ui/core/styles';
import { useState, useLayoutEffect} from 'react';
import {ExpoApiContext} from "Helper/Api.js";
import {useExpoApi} from "Helper/Api.js"
import "assets/css/multiselect-dark-mode.css";
import {
    CssBaseline,
    createMuiTheme
  } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
      width: 240,
      '& > * + *': {
        marginTop: theme.spacing(3),
      },
    },
}));

const Example = (props) => {
  const classes = useStyles();
  const [themeType,setThemeType] = useState('dark');
  const context = useContext(ExpoApiContext);
  const getApi = useExpoApi();
  const [departments, setDepartments] = useState([]);
  const [open, setOpen] = useState(false);
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#4caf50',
      },
    },
  });    

  const loadData = async () => {
    const api = await getApi();
    const result = await api.getDivs(props.level || 'A');
    const divs = result.data.divs.map((div) => ({label: div.name ? div.name : 'Unknown', value: div.id}));

    setDepartments(divs);
  };


  React.useEffect(() => {  
    let active = true;
    loadData().finally();
    return () => {
      active = false;
    }
  }, []);

  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){setThemeType("light")}
    else{setThemeType("dark")}
    
  }, []);

  return (
    <div >
    <p>{props.label}</p>
    <MuiThemeProvider theme={THEME}>
      <CssBaseline /> 
      <MultiSelect className={classes.root}
        options={departments}
        value={props.value}
        onChange={props.onChange}
        labelledBy="Select "
      />
    </MuiThemeProvider>
    </div>
  );
};

export default Example;