import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Row,
  Col,
} from "reactstrap";

import TreeMap from "components/AmCharts/TreeMapWLegend.jsx"
import DrillDownTreeMap from "components/AmCharts/DrillDownTreeMap.jsx";
import FiltersDialog from "components/MaterialUI/Dialogs/FiltersDialog_TreeMap.js";
import {TimePickerFromDate} from "Helper/Utils.js";
import {TimePickerToDate} from "Helper/Utils.js";
import ApiCall from "Helper/Api.js";
import {withAuth0} from "@auth0/auth0-react";

function get_filters(obj) {
    return {
        timeRangeStart: obj.state.dateFromFilter,
        timeRangeEnd: obj.state.dateToFilter,
        divAIds: obj.state.divAids,
        divBIds: obj.state.divBids,
        divCIds: obj.state.divCids,
        externalCallTypes: obj.state.externalCallTypes,
    }
}
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiToken:null,
      bigChartData: "drilldown",
      open:false,
      deptOpen:false,
      classnameVar: "modal-black",
      activeModeClass:"dark-content",
      dateFromFilter: TimePickerFromDate,
      dateToFilter: TimePickerToDate,
      selDepartments: "0",
      divAids:"",
      divBids:"",
      divCids:"",
      totalCalls:0,
      deptSelected: [],
      externalCallTypes:[],
      reRender:true,
      prevDashSett:{},
    };
  }
  componentDidMount() {
    this.getTotalCalls().catch();
    this.getDashboardSett();
    this.interval=setInterval(()=>{
      this.getTotalCalls().catch();
    },20000)
  }

  componentWillUnmount() {
      clearInterval(this.interval);
  }

  getTotalCalls = async ()=> { //add the filters
   const token = await this.props.auth0.getAccessTokenSilently();
   const api = new ApiCall(token);
       api
         .getCallsStatistics(this.state.dateFromFilter,this.state.dateToFilter,this.state.divAids,this.state.divBids,this.state.divCids)
         .then((response) => {
            this.setState({
              totalCalls: response.data.todayTotalCalls,
              
            });
          }) 
         .catch((err) => console.log(err));      
  };
  getDashboardSett = async ()=> { //add the filters
    const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);

       api
         .getDashboardSettings()
         .then((response) => {
            this.setState({prevDashSett : {...response.data.dashboard}});
            if(response.data.dashboard.treeMapStartDate)
              this.setState({dateFromFilter:response.data.dashboard.treeMapStartDate});
            if(response.data.dashboard.treeMapEnddate)
              this.setState({dateToFilter:response.data.dashboard.treeMapEnddate});
            if(response.data.dashboard.treeMapSelDepts)
              this.setState({deptSelected:response.data.dashboard.treeMapSelDepts});
            if(response.data.dashboard.treeMapSelDivAIds)
              this.setState({divAids:response.data.dashboard.treeMapSelDivAIds});
          }) 
         .catch((err) => console.log(err));      
  };
  postDashboardSett= async () => { //add the filters
    try{      
      const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);
      this.state.prevDashSett.treeMapStartDate = this.state.dateFromFilter;
      this.state.prevDashSett.treeMapEnddate = this.state.dateToFilter;
      this.state.prevDashSett.treeMapSelDepts = this.state.deptSelected;
      this.state.prevDashSett.treeMapSelDivAIds = this.state.divAids;
    
      let dashSett = {};
      dashSett.dashboard = this.state.prevDashSett;
      const res = await api.postDashboardSettings(dashSett);
      if(res.status === 200) {
        console.log("success");
        return;
      }
      console.log("error"); 
      alert("Error");
    }
    catch(err) {
      console.log(err);
      
    }  
  };
  
  setBgChartData = name => {
    this.setState({
      bigChartData: name
    });
  };
  handleClickOpen = () => {
    this.setState({
      open: true
    });
    this.setClassType();
  };
 handleClose = () => {
    this.getTotalCalls();
    this.postDashboardSett();
    this.setState({
      open: false
    });
  };

  setClassType = () =>{
    if(document.body.classList.contains("white-content")){this.setState({classnameVar: "",activeModeClass:"white-content"})}
    else if(document.body.classList.contains("dark-content")){this.setState({classnameVar: "modal-black", activeModeClass:"dark-content"})}
  }
  dateFromOnChange = (e) => {
    this.setState({dateFromFilter : e.target.value}); 
    this.setState({dateToFilter : e.target.value.substring(0,10) + "T23:59"}); 
  }
  handleDivAOnChange = (obj)=>{
    this.setState({deptSelected: obj})
    const divs = obj.map((item) => item.value).join();
    this.setState({divAids:divs})
    
  }
  render() {
    return (
      <>
        <div className="content">
          <FiltersDialog 
            open={this.state.open} 
            handleClose={()=>this.handleClose()} 
            className={this.state.classnameVar} 
            activeModeClass={this.state.activeModeClass}
            dateOnChange={this.dateFromOnChange}
            dateValue={this.state.dateFromFilter}
            divAChange = {(obj)=>this.handleDivAOnChange(obj)} 
            divAvalue= {this.state.deptSelected}
          > 
          </FiltersDialog>
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="6">
                        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                      <h5 className="card-category" />
                      <CardTitle tag="h2" />
                    </Col>
                    <Col sm="6">
   
                    </Col>
                  </Row>
                  <UncontrolledDropdown style={{ marginTop:"-15px" }}>
                  <DropdownToggle
                    caret
                    className="btn-icon"
                    color="link"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <i className="tim-icons icon-settings-gear-63" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                    <DropdownItem
                      href=""
                      onClick={()=>this.handleClickOpen()}
                    >
                      Filters
                    </DropdownItem>
                   
                  </DropdownMenu>
                </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  <div style={{height: window.innerHeight - 140, width: "100%", marginTop:"-25px" }}>
                  {this.state.bigChartData === "multilevel" &&
                      <TreeMap/>
                  }
                  {this.state.bigChartData === "drilldown" &&
                     <DrillDownTreeMap 
                       filters={get_filters(this)}
                       reRender={this.state.reRender} 
                       ZoomInClick = {()=>{
                       setTimeout(function () {
                        this.setState({reRender: false});
                       }.bind(this), 1000)}} 
                       ZoomOutClick = {()=>{this.setState({reRender:true})}} 
                     />
                  }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withAuth0(Dashboard);
