/* eslint-disable no-use-before-define */
import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useState, useLayoutEffect, useEffect, useRef} from 'react';
import {useExpoApi} from "../../Helper/Api.js"
import ApiCall from "../../Helper/Api";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function SelectMultiple(props) {
  const classes = useStyles();
  const [themeType,setThemeType] = useState('dark');
  const [open, setOpen] = useState(false);
  const [departments, setDepartments] = useState([]);
  const getApi = useExpoApi();

  const loading = open && departments.length === 0;

  useEffect(() => {
    let active = true;

    if(!loading) {
      return undefined;
    }

    const loadData = async () => {
      const api = await getApi();
      const result = await api.getDivs(props.level || 'A');
      const divs = result.data.divs.map((div) => ({title: div.name ? div.name : 'Unknown', value: div.id}));

      if(active) {
        setDepartments(divs);
      }
    };

    loadData();

    return () => {
      active = false;
    }

  }, [loading]);
  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")}
    
  }, []);
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#4caf50',
      },
    },
  });    
  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={THEME}>
      <Autocomplete
        multiple
        limitTags={2}
        id={props.id}
        name= "selectOptions"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={departments}
        loading={loading}
        defaultValue={props.values ? props.values.split(',').map((value)=>({title: value, value})) : []}
        getOptionSelected={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.title}
        onChange={props.onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={props.label}
            placeholder=""
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                  <React.Fragment>
                    {loading ? <p>loading...</p>  : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
              )
            }}
          />
        )}
      />
      </MuiThemeProvider>
    </div>
  );
}