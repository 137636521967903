/* eslint-disable no-use-before-define */
import React, {useContext} from "react";
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useState, useLayoutEffect} from 'react';
import ApiCall, {ExpoApiContext} from "Helper/Api.js";
import {useExpoApi} from "../../Helper/Api.js"

const useStyles = makeStyles((theme) => ({
  root: {
    width: 240,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function SelectMultiple(props) {
  const classes = useStyles();
  const [themeType,setThemeType] = useState('dark');
  const context = useContext(ExpoApiContext);
  const[divCData, setdivCData] = useState([{}])
  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){setThemeType("light")}
    else{setThemeType("dark")}
    
  }, []);
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#4caf50',
      },
    },
  });    

   
  const divCLookup = [ ];
  const getdivC = async ()=> {
    if(context.divsC.length === 0 ) {
        return;
    }
    context.divsC.forEach((div) => {
        const divObj =  { }
        divObj.title = div.name;
        divObj.value = div.id
        divCLookup.push(divObj)
    });
    setdivCData(divCLookup)
  };

  React.useEffect(() => {  
    getdivC().finally();
  }, [context.divsC]);


  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={THEME}>
      <Autocomplete
        multiple
        id="tags-standard"
        options= {divCData}
        limitTags={2}
        getOptionLabel={(option) => option.title}
        // defaultValue={[divCData[0]]}
        onChange = {props.onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Select "
            placeholder=""
          />
        )}
      />
      </MuiThemeProvider>
    </div>
  );
}
