/* eslint-disable no-lone-blocks */
/* eslint-disable eqeqeq */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useLayoutEffect } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { 
  Button, 
  Modal, 
  ModalBody, 
  ModalFooter ,  
  Row,
  Col,
  Card,
  CardBody,
  Collapse,
  Form, 
  FormGroup, 
  Label, 
  Input
} from 'reactstrap';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import AlertListTotalCalls from "components/MaterialUI/AlertListTotalCalls.js";
import CssBaseline from "@material-ui/core/CssBaseline";
import SwithComponent from "components/MaterialUI/SwithComponent.js";
import TextFieldComponents from "components/MaterialUI/TextFieldComponent.js";
import SelectSimpleComp from "components/MaterialUI/SelectSimpleComponent.js";
import RadioBtnComponent from "components/MaterialUI/RadioBtnComponent.js";
import EmailSwithComponent from "components/MaterialUI/SwithEmailComponent.js";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '400px',
  },
  selectField: {
    width: '200px',
  },
}));

const TotalIntCallAlertsDialog = (props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [stateTitle, setStateTitle] = useState("Enabled");
  const [themeType,setThemeType] = useState('dark');
  
  const toggle = () => setIsOpen(!isOpen);
  const toggle1 = () => setIsOpen1(!isOpen1);
 
  const [state, setState] = React.useState({
    checkedEn: true,
  });

  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")}
    
  }, []);
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#1d8cf8',
      },
    },
  });
  return (
    <div>
    <MuiThemeProvider theme={THEME}>
    <Modal isOpen={props.open} toggle={props.handleClose} modalClassName={props.className}>
    <div className="modal-header">
      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.handleClose}>
        <i className="tim-icons icon-simple-remove"></i>
      </button>
      <h5 className="modal-title">Manage Alerts</h5>
    </div>
    <ModalBody>   
    <Col sm="12">
     <div style={{marginLeft:"-20px" ,fontSize:"12px"}}>
      <Button  onClick={toggle} style={{ marginBottom: '1rem' }}>Add New Alert</Button>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>
          <Row style={{marginLeft:"5px", marginRight:"5px", marginTop:"5px", width:"400px"}}>
            <TextFieldComponents id="alertTitleText" label="Alert Title" value = "Internal Calls Alert" fieldState="enabled" classType= {classes.textField} activeModeClass={props.activeModeClass}/> 
          </Row>
          <Row style={{marginLeft:"-10px", marginTop:"15px", width:"400px"}}>
          <Col sm="6">
             <TextFieldComponents id="fromTimeValueText" label="During The Time Of:" value = "08:00" fieldState="enabled" classType= {classes.selectField} activeModeClass={props.activeModeClass}/>  
          </Col>
          <Col sm="6">
             <TextFieldComponents id="toTimeValueText" label="To:" value = "18:00" fieldState="enabled" classType= {classes.selectField} activeModeClass={props.activeModeClass}/> 
          </Col>
          </Row>
          <Row style={{marginLeft:"-10px", marginTop:"10px", width:"400px"}}>
            <Col sm="6">
              <SwithComponent activeModeClass={props.activeModeClass} activeState="Enabled" label="Weekdays"/>     
            </Col>
            <Col sm="6">
              <SwithComponent activeModeClass={props.activeModeClass} activeState="Disabled" label="Weekends"/>          
            </Col>
          </Row>
          <Row style={{marginLeft:"-10px", marginRight:"5px", marginTop:"15px", width:"400px"}}>
          <Col sm="6">
            <SelectSimpleComp activeModeClass={props.activeModeClass}/>    
         </Col>
        <Col sm="6" >
          <div style={{marginLeft:"30px", width:"160px"}}>
           <TextFieldComponents id="thresValueText" label="Threshold" value = "5" fieldState="enabled" classType= {classes.selectField} activeModeClass={props.activeModeClass}/>  
          </div>       
        </Col>
        </Row>
        <Row style={{marginLeft:"5px", marginTop:"15px", width:"400px"}}>
           <RadioBtnComponent activeModeClass={props.activeModeClass}/> 
        </Row>
        <Row style={{marginLeft:"5px", marginTop:"0px", width:"400px"}}>
           <EmailSwithComponent activeModeClass={props.activeModeClass} activeState="Disabled" label="Send Email Notification"/>
        </Row>
        </CardBody>
        </Card>
      </Collapse>
      <Button  onClick={toggle1}  style={{ marginBottom: '1rem' }}>Edit/Delete Alerts</Button>
      <Collapse isOpen={isOpen1}>
        <Card>
          <CardBody>
            <Row>
              <AlertListTotalCalls activeModeClass={props.activeModeClass} ></AlertListTotalCalls>
            </Row>
          </CardBody>
        </Card>
      </Collapse>
    </div>
    </Col>           
    </ModalBody>
    <ModalFooter>
    <Button color="warning" onClick={props.handleClose}>
      Close
    </Button>
    <Button color="warning" onClick={props.handleClose}>
      Save changes
    </Button>
    
    </ModalFooter>
    </Modal>
    </MuiThemeProvider>
    </div>
  );
}

export default TotalIntCallAlertsDialog;