/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useLayoutEffect, useRef, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {useExpoApi} from "../../Helper/Api.js"
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

am4core.options.autoDispose = true;
export default function PeakStatusUtilization(props){

const getApi = useExpoApi();
const myChart = useRef(null);

useLayoutEffect(() => {
    am4core.unuseAllThemes();

    if (props.activeModeClass === "white-content") {
        am4core.unuseTheme(am4themes_dark);
    } else {
        am4core.useTheme(am4themes_dark);
    }
    am4core.useTheme(am4themes_animated);
    am4core.addLicense("CH277440643");
});

useEffect( () =>  {
    const getData = async ()  => {
        if (myChart.current != null) {
            try {
                myChart.current.dispose();
            } catch(e){}
            myChart.current = null;
        }
        myChart.current = am4core.create("peakAbandHandledChartDiv", am4charts.XYChart);

        // Increase contrast by taking evey second color
        myChart.current.colors.step = 2;

        myChart.current.exporting.menu = new am4core.ExportMenu();
        myChart.current.exporting.menu.align = "left";
        myChart.current.exporting.menu.verticalAlign = "bottom";
        am4core.color("#f00", 0);
        // Create chart instance
        try {
            const api = await getApi();
            const dataResponse = await api.getAbandVsHandledCalls(props.filters);
            const json = await dataResponse.data;

            const parseGraphData = (data) => {
                const statuses = data.statuses;
                const graphData = [];
                let startTime = dayjs.utc(props.filters.timeRangeEnd);
                let endTime = dayjs.utc(props.filters.timeRangeEnd);

                //get earliest time
                statuses.forEach((status) => {
                    if(status.data != null && status.data.length > 0 && dayjs(status.data[0].time) < startTime) {
                        startTime = dayjs(status.data[0].time);
                    }
                })

                const findPointWithStartTime = (item) => {
                    return dayjs(item.time).isSame(startTime);
                }

                while(startTime <= endTime) {
                    const dataPoint = {
                        date: startTime.toDate()
                    };

                    statuses.forEach((status) => {
                        const found = status.data.find(findPointWithStartTime);
                        if(found == null) {
                            dataPoint[status.status] = 0
                        } else {
                            dataPoint[status.status] = found.count;
                        }
                    });

                    graphData.push(dataPoint);
                    startTime = startTime.add(1, 'hour');
                }

                return graphData;
            }
            // Add data
            myChart.current.data = parseGraphData(json);

            const dateAxis = myChart.current.xAxes.push(new am4charts.DateAxis());

            dateAxis.renderer.grid.template.location = 0;

            myChart.current.yAxes.push(new am4charts.ValueAxis());

            // Create series
            function createSeries(field, name) {
                const series = myChart.current.series.push(new am4charts.LineSeries());
                series.dataFields.valueY = field;
                series.dataFields.dateX = "date";
                series.name = name;
                series.tooltipText = "{dateX}: [b]{valueY}[/]";
                series.strokeWidth = 2;

                if(props.dataType === "daily") {
                    const bullet = series.bullets.push(new am4charts.CircleBullet());
                    bullet.circle.stroke = am4core.color("#fff");
                    bullet.circle.strokeWidth = 2;
                }

                return series;
            }

            json.statuses.forEach((d) => {
                createSeries( d.status,  d.status);    
            });

            // Add legend
            myChart.current.legend = new am4charts.Legend();
            // Add cursor
            myChart.current.cursor = new am4charts.XYCursor();
        } catch(err) {
           console.log(err);
        }
   }
    getData().catch();
}, [props.totalCalls]);

return (
    <div id="peakAbandHandledChartDiv" style={{ width: "100%", height: "100%" }} />
);
}
