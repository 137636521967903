
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Collapse
} from "reactstrap";

import RatePlanComponent  from "components/MaterialUI/SelectSimpleRatePlan.js";
import BreadScrumbs from "components/MaterialUI/IconBreadCrumbs.js";
import RatesAccordion from "components/MaterialUI/RatesAccordion.js";
import TextField from '@material-ui/core/TextField';


class Rates extends React.Component {
  constructor(props) {
    super(props);
    this.openRatePlanLinksAdd = this.openRatePlanLinksAdd.bind(this);
    this.openRatePlanLinksEdit = this.openRatePlanLinksEdit.bind(this);
    this.openRatePlanLinksDelete = this.openRatePlanLinksDelete.bind(this);
    this.onChangeRatePlanSelectComp = this.onChangeRatePlanSelectComp.bind(this);
    this.state ={
      planRateNameOpen : false,
      ratePlanNameval:"",
      selRatePlanText: "Actual Cost",
    }
  }
  openRatePlanLinksAdd =()=>{
    this.setState({ planRateNameOpen: !this.state.planRateNameOpen});
    this.setState({ratePlanNameval:"" });
  };
  openRatePlanLinksEdit =()=>{
    this.setState({ratePlanNameval: this.state.selRatePlanText });
    this.setState({ planRateNameOpen: !this.state.planRateNameOpen});
    
  };
  openRatePlanLinksDelete =()=>{
    this.setState({ planRateNameOpen: false});
  };
  openRatePlanNameOnChange =(e)=>{
    this.setState({ratePlanNameval: e.target.value})
  };
  onChangeRatePlanSelectComp = (e) => {
    this.setState({selRatePlanText: e.target.value});
    this.setState({ratePlanNameval: this.state.selRatePlanText})
  };
  render() {
    return (
      <>
      <div className="content">
      <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <h5 className="card-category"></h5>
                  <CardTitle tag="h2"></CardTitle>
                </Col>
                <Col sm="6">

                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div style={{height: "900px" , width: "100%", marginTop:"-10px", marginLeft:"10px" }}>
                <Row>
                  <Col md = "8">
                    <RatePlanComponent activeModeClass="dark-content" onChange = {this.onChangeRatePlanSelectComp} selVal={this.state.selRatePlanText}> </RatePlanComponent>
                  </Col>
                  <Col md = "4"> 
                    <BreadScrumbs onClickAdd = {this.openRatePlanLinksAdd} onClickEdit = {this.openRatePlanLinksEdit} onClickDelete = {this.openRatePlanLinksDelete} />
                  </Col>

                </Row>
                <Row>
                 <Col md = "8">
                    
                  </Col>
                  <Col md = "4"> 
                  <Collapse isOpen={this.state.planRateNameOpen}>
                    <TextField id="ratePlanTitle" value={this.state.ratePlanNameval} label="Rate Plan Name" onChange = {this.openRatePlanNameOnChange}/>
                  </Collapse>
                  </Col>
                 
                </Row>
                <Row>
                <Col md = "12" style={{marginTop:"30px", marginLeft:"0px" }}>
                  <RatesAccordion/>
                 </Col>
                </Row>
                <Row style={{marginTop:"1px", marginLeft:"0px" }}>
                <Col sm="6"><Button color="warning" >Save Changes</Button>{' '}</Col>   
                </Row>

              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </div>
      </>
    );
  }
}

export default Rates;
