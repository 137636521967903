import React, { useState, useLayoutEffect } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import SelectEmailFreq from "components/MaterialUI/SelectSimpleEmailFreq.js"
import RadioBtnGroup from "components/MaterialUI/SchedRptRadioBtnGroup.js";
import { 
  Button, 
  Modal, 
  ModalBody, 
  ModalFooter ,  
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup, 
  Label, 
  Input,
} from 'reactstrap';

import EmailChips from "components/MaterialUI/EmailChips.js";
import SelectRptPage from "components/MaterialUI/SelectSimpleReportPage.js";
import SelectMultDailyFreq from "components/MaterialUI/SelectMultipleDailyFreq.js";
import SelectSimpleWeeklyFreq from "components/MaterialUI/SelectSimpleWeeklyFreq.js";
import SelectSimpleMonthlyFreq from "components/MaterialUI/SelectSimpleMonthlyFreq.js";
import TimePicker from "components/MaterialUI/TimePickerRptSched.js";
import DatePicker from "components/MaterialUI/DatePickerRptSched.js";
import CheckBoxList from "components/OthersCharts/CheckBoxList/multi-select-check.js";
import CheckBoxListExt from "components/OthersCharts/CheckBoxList/multi-select-check-ext.js";
import OnOffSwitch from "components/MaterialUI/OnOffSwitch.js";
import CheckBoxListCallType from "components/OthersCharts/CheckBoxList/multi-select-check-callTypes.js";
import CheckBoxListExternalCallType from "components/OthersCharts/CheckBoxList/multi-select-check-externalCallTypes.js";
import HuntGSelectComp from "components/OthersCharts/CheckBoxList/multi-select-check-huntG.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const SchedulerDialog = (props) => {
  const [themeType,setThemeType] = useState('dark');

  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")} 
  }, [props.activeModeClass]);

  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#1d8cf8',
      },
    },
  });

  return (
    <div>
    <MuiThemeProvider theme={THEME}>
    <Modal style={{position:"center", marginTop:"-250px"}} isOpen={props.open} toggle={props.handleClose} modalClassName={props.className}>
    <div className="modal-header">
      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.handleClose}>
        <i className="tim-icons icon-simple-remove" />
      </button>
      <h5 className="modal-title">Edit </h5>
    </div>
    <ModalBody>   
    <Col sm="12">
     <div style={{marginLeft:"-20px" ,fontSize:"12px"}}>
        <Card>
          <CardBody >
           <Row style={{height:"100%"}}>
            <Row>
            <Col xs="12"> 
            <Form style={{width:"380px", marginLeft:"15px", marginTop:"-20px"}}> 
            <FormGroup className="position-relative" style={{marginTop:"5px"}}>    
              <Label for="description">Report Description:</Label>
              <Input type="text" name="description" id="description" placeholder="" style={{width:"380px"}} onChange={props.onChangeRptDesc} value={props.rptDescription}/> 
              </FormGroup>     
              <FormGroup className="position-relative" style={{marginTop:"5px"}}>    
                <OnOffSwitch checked={props.checked} handleChange={props.switchOnChange} grid1Title="Disabled" grid2Title = "Enabled"/>
              </FormGroup>     
              <FormGroup className="position-relative" style={{marginTop:"-15px"}}> 
              <EmailChips  value={props.emailTo} items = {props.items} onChange = {props.onChangeEmailTo} onKeyDown = {props.onEmailKeyDown} handleDelete = {props.handleDelete}/>     
              </FormGroup>
              <FormGroup className="position-relative" style={{marginTop:"5px"}}>    
                <Label for="subject">Subject:</Label>
                <Input type="text" name="subject" id="subject" placeholder="" style={{width:"380px"}} value = {props.rptSubject} onChange = {props.rptSubjectOnChange}/>   
              </FormGroup>
              <FormGroup className="position-relative" style={{marginTop:"-15px"}}>  
                 <Label for="emailMsg">Message:</Label>
                 <Input type="textarea" name="emailMsg" id="emailMsg" style={{width:"380px", height:"50px"}} value = {props.rptMessage} onChange = {props.rptMessageOnChange} />      
              </FormGroup>
              <FormGroup className="position-relative" style={{marginTop:"-5px", marginLeft:"-10px"}}>  
               <Col xs="12">  <SelectRptPage selValue = {props.rptReportType} onChange = {props.onChangeRptPage}/> </Col>
              </FormGroup>
              <FormGroup className="position-relative" style={{marginTop:"15px", marginLeft:"-10px"}}>  
               <Col xs="12">  <SelectEmailFreq selVal={props.rptFrequency} onChange={props.onChangeRptEmailFreq}/> </Col>
              </FormGroup>      
              {props.frequency === 'Daily' &&
                <FormGroup className="position-relative" style={{marginTop:"15px", marginLeft:"-10px"}}>  
                 <Col xs="12">  <SelectMultDailyFreq label= "Generate on:" onChange = {props.onChangeDailyDaysFreq} selVal = {props.dailyFreqSelVal} />    </Col>
                </FormGroup>   
              }   
              {props.frequency === 'Weekly' &&
                <FormGroup className="position-relative" style={{marginTop:"15px" , marginLeft:"-10px"}}>  
                 <Col xs="12">  <SelectSimpleWeeklyFreq selVal = {props.selWeeklyDays} onChange={props.onChangeWeeklyDaysFreq} />    </Col>
                </FormGroup>   
              }   
               {props.frequency === 'Monthly' &&
                <FormGroup className="position-relative" style={{marginTop:"15px", marginLeft:"-10px"}}>  
                 <Col xs="12">  <SelectSimpleMonthlyFreq selVal = {props.selMonthlyDays} onChange={props.onChangeMonthlyDaysFreq} />    </Col>
                </FormGroup>   
              }   
              <FormGroup className="position-relative" style={{marginTop:"15px", marginLeft:"-10px"}}>  
                <Label style={{marginLeft:"15px"}}>Report Period: {props.periodText}</Label> 
              </FormGroup>   
              <FormGroup className="position-relative" style={{marginTop:"-5px", marginLeft:"-3px"}}>  
                <Row>
                  <Col xs="6">  <TimePicker  activeModeClass="dark-content" label="From Time" value = {props.timeBegin} onChange = {props.onChangeFromTimePicker}/> </Col>
                  <Col xs="6">  <TimePicker  activeModeClass="dark-content" label="To Time" value = {props.timeEnd} onChange = {props.onChangeToTimePicker}/> </Col>
                </Row>  
              </FormGroup> 
              {props.frequency === 'Weekly'  &&
                <FormGroup className="position-relative" style={{marginTop:"-5px", marginLeft:"0px"}}>  
                  <Row>
                    <Col xs="12">
                      <SelectMultDailyFreq label= "Include only specific days on the report" onChange = {props.onChangeDaysOfWeek} selVal = {props.daysOfWeek}/>
                    </Col>
                  </Row>
                </FormGroup>
              }
              {props.frequency === 'Monthly'  &&
                <FormGroup className="position-relative" style={{marginTop:"-5px", marginLeft:"0px"}}>  
                  <Row>
                    <Col xs="12">
                      <SelectMultDailyFreq label= "Include only specific days on the report" onChange = {props.onChangeDaysOfWeek} selVal = {props.daysOfWeek}/>
                    </Col>
                  </Row>
                </FormGroup>
              }
              <FormGroup className="position-relative" style={{marginTop:"15px", marginLeft:"-10px"}}>  
               <Col xs="12"> <CheckBoxListExt onChange = {(obj)=>props.handleExtOnChange(obj)} value= {props.extsSelected} level="" label="Select User(s)" activeModeClass="dark-content"/> </Col>
              </FormGroup>  
              <FormGroup className="position-relative" style={{marginTop:"15px", marginLeft:"-10px"}}>  
               <Col xs="12"> <HuntGSelectComp   label="Select Hunt Group(s)" onChange = {(obj)=>props.handleHuntGOnChange(obj)} value= {props.huntGSelected} activeModeClass="dark-content"/>
            </Col>
              </FormGroup>  
              
              <FormGroup className="position-relative" style={{marginTop:"10px", marginLeft:"-10px"}}>  
               <Col xs="12"> <CheckBoxList onChange = {(obj)=>props.handleDivAOnChange(obj)} value= {props.deptSelected} level="A" activeModeClass="dark-content" label="Select Department(s)"/> </Col>
              </FormGroup>  
              <FormGroup className="position-relative" style={{marginTop:"10px", marginLeft:"-10px"}}>  
               <Col xs="12"> <CheckBoxList onChange = {(obj)=>props.handleDivBOnChange(obj)} value= {props.divBSelected} level="B" activeModeClass="dark-content" label="Select Branch(s)" /> </Col>
              </FormGroup>  
              <FormGroup className="position-relative" style={{marginTop:"10px", marginLeft:"-10px"}}>  
               <Col xs="12"> <CheckBoxList onChange = {(obj)=>props.handleDivCOnChange(obj)} value= {props.divCSelected} level="C" activeModeClass="dark-content" label="Select Region(s)" /> </Col>
              </FormGroup> 
              <FormGroup className="position-relative" style={{marginTop:"10px", marginLeft:"-10px"}}>  
              <Col xs="12"><CheckBoxListCallType onChange = {(obj)=>props.handleCallClassOnChange(obj)} value= {props.callClassSel} level="" label="Select Call Type(s)" activeModeClass="dark-content" /> </Col>
              </FormGroup>
              <FormGroup className="position-relative" style={{marginTop:"10px", marginLeft:"-10px"}}>
                <Col xs="12"><CheckBoxListExternalCallType onChange = {(obj)=>props.handleExternalCallClassOnChange(obj)} value= {props.externalCallClassSel} level="" label="Internal/External" activeModeClass="dark-content" /> </Col>
              </FormGroup>
              <FormGroup className="position-relative" style={{marginTop:"10px", marginLeft:"5px"}}>
                <FormControlLabel control={<Checkbox onChange={props.onChangeBusinessHourFilter} checked={props.businessHourFilter} />} label="Filter by Branch Hours" />
              </FormGroup>
              <FormGroup className="position-relative" style={{marginTop:"25px", marginLeft:"-20px"}}>
              <Col xs="12"> <TimePicker  activeModeClass="dark-content" label="Scheduled Time" value = {props.schedTime} onChange = {props.onChangeTimePicker}/> </Col>
              </FormGroup>
              <FormGroup className="position-relative" style={{marginTop:"15px", marginLeft:"-10px"}}>
                <Col xs="12">
                  <FormControl>
                    <InputLabel shrink id="condition-select">
                      File type:
                    </InputLabel>
                    <Select
                        value={props.fileType} onChange={props.onChangeFileType}
                        labelId="condition-label"
                        id="condition-select"
                        displayEmpty
                    >
                      <MenuItem value="pdf">PDF</MenuItem>
                      <MenuItem value="html">HTML</MenuItem>
                      <MenuItem value="csv">CSV</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
              </FormGroup>
              <FormGroup className="position-relative" style={{marginTop:"-10px", marginLeft:"-35px"}}>
              <Col xs="12"> <RadioBtnGroup  value = {props.endDateVal} onChange={props.onChangeEndDate} /> </Col>
               </FormGroup>
               {props.endDateVal !== "Never" &&
                <FormGroup className="position-relative" style={{marginTop:"-40px", marginLeft:"-10px"}}> 
                <Col xs="12"> 
                 <DatePicker  
                  activeModeClass="dark-content"  
                  label="Specific Date "
                  date={props.subscExpireDate}
                  onChange={props.onChangeExpireDate}/> </Col>
                </FormGroup>
               }
              
              </Form>
              </Col>        
              </Row> 
              
           </Row>
          </CardBody>
        </Card>
    </div>
    </Col>           
    </ModalBody>
    <ModalFooter>
    <Button color="warning" onClick={props.handleClose}>
      Close
    </Button>
    <Button color="warning" onClick = {function(){
       props.onSaveDlg();
       props.handleClose();
     }}>
      Save
    </Button>
    </ModalFooter>
    </Modal>
    </MuiThemeProvider>
    </div>
  );
}

export default SchedulerDialog;