import React from "react";

function LoadingAnimation() {
  return (
    <div className="loaderDiv">
      <li className="loader"></li>
    </div>
  );
}

export default LoadingAnimation;
