import React from "react";
import MaterialTable from "material-table";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { forwardRef, useState, useLayoutEffect, useRef} from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ApiCall from "Helper/Api.js";
import {useExpoApi} from "../../Helper/Api.js"

const tableIcons = {
Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function AlarmTable(props) {
  const [columns, setRptColumns] = useState([
    { title: "Date", field: "date" },
    { title: "Time", field: "time" },
    { title: "User", field: "agent"} ,
    { title: "Receiving User", field: "receiverAgent"},
  ]);

  const [isLoading, setLoading] = useState(false);
  const [rptData, setRptData] = useState([{ date:"", time:"", agent:"", receiverAgent:""}]);
  
  const [themeType,setThemeType] = useState('dark');
  const getApi = useExpoApi();

   useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")}

    const getInteractionData = async() =>{
      setLoading(true);
      const api = await getApi();
      const response= await api.getInteractionDetails({...props.filters, ...props.users});
      if(response.data != null){
        const data = response.data.map(interaction=>{
          const dateTimeSplit = interaction.datetime.split("T");
          const row = {};
          row.date = dateTimeSplit[0];
          row.time = (dateTimeSplit[1]).replace('Z','');
          row.agent = interaction.agent;
          row.receiverAgent = interaction.receiverAgent;
          return row;
        });
        setRptData(data);
      }
    }
    getInteractionData().catch((err)=>console.log(err)).finally(()=>setLoading(false));
    
  }, []);
  
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
        color:'#9a9a9a',

      },
      secondary: {
        main: '#4caf50',
        color:'#9a9a9a',
      },
    },
  });
  return (
    <div >
      <MuiThemeProvider theme={THEME}>
        <MaterialTable 
          style= {{
            backgroundColor: 'transparent',
            color:'#fff',
            fontSize:'14px',
            boxShadow: 'none',
            width:"100%",
            margin:'auto',
            justifyContent:"center"
          }}
          icons={tableIcons}
          title=""
          data={rptData}
          columns={columns}
          isLoading={isLoading}
          options={{
            headerStyle: {
            backgroundColor: "rgba(0,0,0,0)",  // #6ca6c2; //67b7dc          
            // fontWeight: 'bold',
            color:'#fff',  //#9a9a9a
            fontSize:'14px'
            },
            pageSize: 5,
            pageSizeOptions: [5, 10, 15 ],
            search: false

          }}
        
        />
      </MuiThemeProvider>
    </div>
  );
}
