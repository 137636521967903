import React , {useContext} from "react";
import { MultiSelect } from "react-multi-select-component";
import { makeStyles } from '@material-ui/core/styles';
import { MuiThemeProvider  } from '@material-ui/core/styles';
import { useState, useLayoutEffect} from 'react';
import {ExpoApiContext} from "Helper/Api.js";
import {useExpoApi} from "Helper/Api.js"
import "assets/css/multiselect-dark-mode.css";
import {
    CssBaseline,
    createMuiTheme
  } from "@material-ui/core";
import "assets/css/filterDropDown.css"

const useStyles = makeStyles((theme) => ({
    root: {
      width: 240,
      marginTop:"20px"
    },
}));

const Example = (props) => {
  const classes = useStyles();
  const [themeType,setThemeType] = useState('dark');
  const context = useContext(ExpoApiContext);
  const getApi = useExpoApi();
  const [compData, setCompData] = useState([]);
  const [open, setOpen] = useState(false);
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#4caf50',
      },
    },
  });    

  const loadData = async () => {
    const api = await getApi();
    const result = await api.getHuntGroups();
      const huntG = result.data.huntGroups.map((ext) => ({label: `${ext.name}-${ext.number}`, value: ext.id}));
      setCompData(huntG);
  };


  React.useEffect(() => {  
    loadData().finally();
  }, []);

  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){setThemeType("light")}
    else{setThemeType("dark")}
    
  }, []);

  return (
    <div className={classes.root}>
    <p>{props.label}</p>
    <MuiThemeProvider theme={THEME}>
      <CssBaseline /> 
      <MultiSelect 
        options={compData}
        value={props.value}
        onChange={props.onChange}
        labelledBy="Select "
      />
    </MuiThemeProvider>
    </div>
  );
};

export default Example;