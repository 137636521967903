/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_sunburst from "@amcharts/amcharts4/plugins/sunburst";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";

am4core.options.autoDispose = true;
export default function App() {
  /* Chart code */
  useLayoutEffect(() => {
    am4core.unuseAllThemes();
    if(document.body.classList.contains("white-content")){  
        am4core.unuseTheme(am4themes_dark);
    }
    else{
        am4core.useTheme(am4themes_dark);
    }
    am4core.useTheme(am4themes_animated);
    am4core.addLicense("CH277440643");
    /* Create chart */
    var chart = am4core.create("treeMapWLegendChartDiv", am4charts.TreeMap);
    chart.data = [
      {
        name: "Acura",
        teamMembers: [
          { name: "Melanie", totalCalls: 100 },              
          { name: "John", totalCalls: 60 },
          { name: "Maria", totalCalls: 30 }
        ],
        teamColor: "#fb6340"   //FF7900
      },
      {
        name: "Audi",
        teamMembers: [
          { name: "Aaron", totalCalls: 135 },
          { name: "Marcia", totalCalls: 98 },
          { name: "Paul", totalCalls: 56 }
        ],
        teamColor: "#ff8d72"   //f8955e
      },
      {
        name: "Cadillac",
        teamMembers: [
          { name: "Denise", totalCalls: 335 },
          { name: "Carina", totalCalls: 148 },
          { name: "Jason", totalCalls: 126 },
          { name: "Mikel", totalCalls: 26 }
        ],
        teamColor: "#fbb68d"   //fbb68d
      },
      {
        name: "Chevrolet",
        teamMembers: [
          { name: "Joseph", totalCalls: 415 },
          { name: "Matt", totalCalls: 148 },
          { name: "Dylan", totalCalls: 89 },
          { name: "Lauren", totalCalls: 64 },
          { name: "Marcela", totalCalls: 16 }
        ],
        teamColor: "#5e72e4"   //80C31C
      },
      {
        name: "Honda",
        teamMembers: [
          { name: "Carlos", totalCalls: 687 },
          { name: "Nick", totalCalls: 148 }
        ],
        teamColor: "#1d8cf8"   //BCDD5A
      }
    ];

          // level 0 series template
    let level0SeriesTemplate = chart.seriesTemplates.create("0");
    level0SeriesTemplate.strokeWidth = 2;

    // by default only current level series bullets are visible, but as we need brand bullets to be visible all the time, we modify it's hidden state
    level0SeriesTemplate.bulletsContainer.hiddenState.properties.opacity = 1;
    level0SeriesTemplate.bulletsContainer.hiddenState.properties.visible = true;
    // create hover state
    let columnTemplate = level0SeriesTemplate.columns.template;
    let hoverState = columnTemplate.states.create("hover");

    // darken
    hoverState.adapter.add("fill", function (fill, target) {
        if (fill instanceof am4core.Color) {
            return am4core.color(am4core.colors.brighten(fill.rgb, -0.2));
        }
        return fill;
    })

    // add logo
    let image = columnTemplate.createChild(am4core.Image);
    image.opacity = 0.15;
    image.align = "center";
    image.valign = "middle";
    image.width = am4core.percent(80);
    image.height = am4core.percent(80);

    // add adapter for href to load correct image
    image.adapter.add("href", function (href, target) {
        let dataItem = target.parent.dataItem;
        if (dataItem) {
            return "https://www.amcharts.com/lib/images/logos/" + dataItem.treeMapDataItem.name.toLowerCase() + ".png";
        }
    });

    /* Set color step */
    chart.colors.step = 2;

    /* Define data fields */
    chart.dataFields.value = "totalCalls";
    chart.dataFields.name = "name";
    chart.dataFields.children = "teamMembers";
    chart.dataFields.color = "teamColor";

    /* Create top-level series */
    var level1 = chart.seriesTemplates.create("0");
    var level1_column = level1.columns.template;
    level1_column.fillOpacity = 0;
    level1_column.strokeOpacity = 0;

    /* Create second-level series */
    var level2 = chart.seriesTemplates.create("1");
    var level2_column = level2.columns.template;
    level2_column.column.cornerRadius(5, 5, 5, 5);
    level2_column.fillOpacity = 0.8;
    level2_column.stroke = am4core.color("#fff");
    level2_column.strokeWidth = 1;
    level2_column.strokeOpacity = 1;

    var level2_bullet = level2.bullets.push(new am4charts.LabelBullet());
    level2_bullet.locationY = 0.5;
    level2_bullet.locationX = 0.5;
    level2_bullet.label.text = "{name} | Calls: {totalCalls}";
    level2_bullet.label.fill = am4core.color("#fff");

    /* Add a navigation bar */
    chart.navigationBar = new am4charts.NavigationBar();

    /* Add a lagend */
    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    if(document.body.classList.contains("white-content")){
     chart.legend.labels.template.fill = am4core.color("#999DA0");
     chart.legend.valueLabels.template.fill = am4core.color("#999DA0"); 
    }
    else{
      chart.legend.labels.template.fill = am4core.color("#fff");
      chart.legend.valueLabels.template.fill = am4core.color("#fff");
    }

    
  }, []);
  return (
    <div
      id="treeMapWLegendChartDiv"
      style={{ width: "100%", height: "100%" }}
    ></div>
  );
}
