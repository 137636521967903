import React, { Component } from "react";
import ReactDOM from "react-dom";

import FlatfileImporter from "@flatfile/adapter";

import "assets/css/flat-file-custom.css";
import ApiCall from "Helper/Api.js";
import {withAuth0} from "@auth0/auth0-react";
const LICENSE_KEY = "aa921983-4db2-4da1-a580-fbca0b1c75b2";

class App extends Component {
  constructor() {
    super();
    this.getSites = this.getSites.bind(this);
    this.launch = this.launch.bind(this);
    this.importer = new FlatfileImporter(LICENSE_KEY, {
      fields: [
        {
          label: "Name",
          key: "name",
          description: "Hunt Group Name",
          validators: [
            {
              validate: "unique",
              error: "Name must be unique"
            },
            {
              validate: "required",
              error: "This is a required field"
            }
          ]
        },
        {
          label: "Hunt Group Ext",
          key: "ext",
          description: "Hunt Group DID",
          validators: [
            {
              validate: "unique",
              error: "Name must be unique"
            },
            {
              validate: "required",
              error: "This is a required field"
            }
          ]
        },
        {
          label: "Site",
          key: "siteId",
          description: "Use the Site's Long Name",
          validators: [
            {
              validate: "required",
              error: "This is a required field"
            }
          ]
        },
      

      ],
      type: "Hunt Groups",
      allowInvalidSubmit: true,
      managed: true,
      allowCustom: true,
      disableManualInput: false
    });
    this.state = {
      results: "Your raw output will appear here.",
      sitesObj:[],
    };

    this.importer.registerRecordHook((record, index) => {
      let out = {};
      if (record.name.includes(" ")) {
        out.name = {
          value: record.name.replace(" ", "_"),
          info: [
            {
              message:
                "No spaces allowed. Replaced all spaces with underscores",
              level: "warning"
            }
          ]
        };
      }
      return out;
    });

    this.importer.setCustomer({
      userId: "19235",
      name: "MC"
    });
  }

  componentDidMount() {
    this.getSites();       
  }
  
  launch = async () => {
    this.importer
      .requestDataFromUser()
      .then(results => {
        this.importer.displayLoader();
         setTimeout(() => {
          this.importer.displaySuccess("Success!");
       
          results.validData.map((item)=> {
            let siteNum = 1;
            this.state.sitesObj.map((objitem)=>{
              if(item.siteId == objitem.name)
                siteNum = objitem.id
            })          
            let huntGroup = {};
            huntGroup.name = item.name;
            huntGroup.number = item.ext;
            huntGroup.siteId = siteNum;
            this.addHuntGroup(huntGroup);
          }) 
             
        }, 1500);
      })
      .catch(function(error) {
        console.info(error || "window close");
      });
  }

  getSites = async ()=> { //add the filters
    const siteObj = [];
    const token = await this.props.auth0.getAccessTokenSilently();
    const api = new ApiCall(token);

    api
    .getSites()
    .then((response) => {
      response.data.sites.map((item)=>{
        let objItem = {};
        objItem.name = item.name;
        objItem.id = item.id;
        siteObj.push(objItem);
        this.setState({sitesObj:siteObj})
      }) 
    }) 
    .catch((err) => {console.log(err)});      
  };  

  addHuntGroup = async (huntGObj) => {
    try{      
      const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);

      const res = await api.addHuntGroup(huntGObj);
      if(res.status === 200) {
        console.log("success");
      return;
    }
    console.log("error"); 
    alert("Error");
    }
    catch(err) {
      console.log(err);
      alert("Error");
    }   
  }
  render() {
    return (
      <div >
        {LICENSE_KEY ? null : (
          <div className="licenseAsk">
            Obtain your license key from the
            <a href="https://flatfile.io/app" target="_blank">
              Flatfile Dashboard &rarr;
            </a>
            <p>
              Once you've found it, set the <code>{LICENSE_KEY}</code> variable on
              Line 8 before continuing.
            </p>
          </div>
        )}
        <input
          type="button"
          id="launch"
          className={LICENSE_KEY ? null : "disabled"}
          value="Launch Importer"
          onClick={this.launch}
        />
       
        <div className="download">
        {/* <a href="..\src\extensions.csv" target="_blank" rel="noopener noreferrer">
            Download a sample csv file here
        </a> */}
        </div>
        {/*<textarea id="raw_output" readOnly value={this.state.results} />*/}
      </div>
    );
  }
}

export default withAuth0(App);



