import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Row,
  Col,
} from "reactstrap";

import SchedRptTable from "components/Tables/ScheduledReportsTable.js";
import ApiCall from "Helper/Api.js";
import {withAuth0} from "@auth0/auth0-react";

class Assign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdminUser:false,
      showAllReports:false,
    }
  }
  
  componentDidMount() {
    this.IsUserAdmin().catch();
  }

  IsUserAdmin = async () => {
    let userId = 0;
    const token = await this.props.auth0.getAccessTokenSilently();
    const api = new ApiCall(token);

    api
      .getUserInfo()
      .then((response) => {
        userId = response.data.roles
        api
        .getRoleInfoById(userId)
        .then((response) => {
          if(response.data[0].name === "Admin"){this.setState({isAdminUser:true})}
        }) 
        .catch((err) => console.log(err));      
        }) 
      .catch((err) => console.log(err));    
  }

  OnCheckBoxClick = () => {
    this.setState({showAllReports:!this.state.showAllReports})
  }

  render() {
    return (
      <div className="content">
      <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="8">
                  <h5 className="card-category" />
                  <CardTitle tag="h4">Edit/Delete Scheduled Reports</CardTitle>
                </Col>
                <Col className="text-right" sm="4">
                  {this.state.isAdminUser === true &&
                    <div>
                      <Input type="checkbox" onClick={this.OnCheckBoxClick}/>{' '}
                      Show All Scheduled Reports  
                    </div>
                  }
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div style={{height: "100%", width: "100%", marginTop:"-40px" }}>
                  <SchedRptTable activeModeClass={document.body.classList.contains("white-content") ? "white-content" : "dark-content"} showAll={this.state.showAllReports} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
    );
  }
}

export default withAuth0(Assign);