/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {useRef, useLayoutEffect} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_sunburst from "@amcharts/amcharts4/plugins/sunburst";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import ApiCall from "Helper/Api.js"
import {useExpoApi} from "../../Helper/Api.js"

am4core.options.autoDispose = true;
export default function App(props) {
/* Chart code */
const getApi = useExpoApi();
let totalCallsNew = 0;
useLayoutEffect(() => {
    am4core.unuseAllThemes();
    if (props.activeModeClass === "white-content") {
    am4core.unuseTheme(am4themes_dark);
    } else {
    am4core.useTheme(am4themes_dark);
    }
    am4core.useTheme(am4themes_animated);
    am4core.addLicense("CH277440643");

    if(props.reRender == true){
    /* Create chart */
    const chart = am4core.create("drillDownChartDiv", am4charts.TreeMap);

    const getData = async () => {
        const api = await getApi();
        api
        .getDivDrilldownData(props.filters)
        .then((response) => {
            parseGraphData(response.data);
        })
        .catch((err) => {console.log(err)});                                

        let colorRange = ['#ff8d72','#00f2c3','#1d8cf8','#fd5d93','#adb5bd','#fb6340',
                        '#2dce89','#5e72e4','#5603ad','#8965e0','#f3a4b5','#f5365c',
                        '#11cdef','#2bffc6','#6c757d','#32325d','#ced4da',
                        ' #e9ecef','#e14eca','#212529','#344675','#ffd600'
                        ]
        const parseGraphData = async(data) => {
            let dataObject = [];
            let i = 0;
            
            data.map((newItem) => {
            
            let graphSizeVal = 10;
            let colorSel = "";
            let fontSize = "20px";

            if(newItem.totalCalls <= 5){colorSel = "#FF4500";}  
            if(newItem.totalCalls >  5 && newItem.totalCalls <= 10){colorSel = "#FF4500";}       
            else if(newItem.totalCalls > 10 && newItem.totalCalls <= 50){colorSel = "#fb6340";}          
            else if(newItem.totalCalls > 50 && newItem.totalCalls <= 100){colorSel = "#ff8d72";}          
            else if(newItem.totalCalls >  100 && newItem.totalCalls <= 500 ){colorSel = "#00f2c3";}
            else if(newItem.totalCalls >  500 ){colorSel = "#1BA68D";}

            i ++;
            // if(totalSelDept > 50){
            //     if(newItem.totalCalls <= 5){fontSize = "10px";}  
            //     if(newItem.totalCalls >  5 && newItem.totalCalls <= 10){fontSize = "12px";}       
            //     else if(newItem.totalCalls > 10 && newItem.totalCalls <= 50){fontSize = "14px";}          
            //     else if(newItem.totalCalls > 50 && newItem.totalCalls <= 100){fontSize = "16px";}          
            //     else if(newItem.totalCalls >  100 && newItem.totalCalls <= 500 ){fontSize = "18px";}
            //     else if(newItem.totalCalls >  500 ){fontSize = "20px";}
            // }
            
            let objectItem = {};
            objectItem.name = newItem.name;
            objectItem.totalCalls = newItem.totalCalls;
            objectItem.value = newItem.totalCalls;
            objectItem.color = colorSel ;
            objectItem.differentFontSize = fontSize;
            objectItem.children = [] ;
            
            let inccalls = 0;
            newItem.incomingCalls ? inccalls = newItem.incomingCalls : inccalls = 0;
            let outCalls = 0;
            newItem.outgoingCalls ? outCalls = newItem.outgoingCalls : outCalls = 0;
            let intCalls = 0;
            newItem.internalCalls ? intCalls = newItem.internalCalls : intCalls = 0;
            let emgCalls = 0;
            newItem.emergencyCalls ? emgCalls = newItem.emergencyCalls : emgCalls = 0;
            let intlCalls = 0;
            newItem.internationalCalls ? intlCalls = newItem.internationalCalls : intlCalls = 0;
            let ldCalls = 0;
            newItem.longDistanceCalls ? ldCalls = newItem.longDistanceCalls : ldCalls = 0;
            let stateCalls = 0;
            newItem.stateCalls ? stateCalls = newItem.stateCalls : stateCalls = 0;
            let lTollCalls = 0;
            newItem.tollCalls ? lTollCalls = newItem.tollCalls : lTollCalls = 0;
            let localCalls = 0;
            newItem.localCalls ? localCalls = newItem.localCalls : localCalls = 0;
            
            let childInc = {};
            childInc.name =  "Incoming"; 
            childInc.totalCalls =  inccalls ;
            childInc.value = inccalls;
            if(childInc.totalCalls!== 0){
                objectItem.children.push(childInc);
            }

            let childOut = {};
            childOut.name =  "Outgoing"; 
            childOut.totalCalls =  outCalls ;
            childOut.value = outCalls;
            if(childOut.totalCalls !==0){
                objectItem.children.push(childOut);
            }

            let childInt = {};
            childInt.name =  "Internal"; 
            childInt.totalCalls =  intCalls ;
            childInt.value = intCalls;
            if(childInt.totalCalls !==0){
                objectItem.children.push(childInt);
            }
            
            dataObject.push(objectItem);  
            })
            chart.data = dataObject;

        }           
        
    }

    getData();
    
    // add logo
    // let image = columnTemplate.createChild(am4core.Image);
    // image.opacity = 0.15;
    // image.align = "center";
    // image.valign = "middle";
    // image.width = am4core.percent(80);
    // image.height = am4core.percent(80);

    // // add adapter for href to load correct image
    // image.adapter.add("href", function (href, target) {
    //   let dataItem = target.parent.dataItem;
    //   if (dataItem) {
    //     return "https://www.amcharts.com/lib/images/logos/" + dataItem.treeMapDataItem.name.toLowerCase() + ".png";
    //   }
    // });

    chart.maxLevels = 1;

    /* Set color step */
    chart.colors.step = 1;

    /* Define data fields */
    chart.dataFields.value = "value";
    chart.dataFields.name = "name";
    chart.dataFields.children = "children";
    chart.dataFields.color = "color";

     // level 0 series template
     let level0SeriesTemplate = chart.seriesTemplates.create("0");
     level0SeriesTemplate.strokeWidth = 2;
 
     // by default only current level series bullets are visible, but as we need brand bullets to be visible all the time, we modify it's hidden state
     level0SeriesTemplate.bulletsContainer.hiddenState.properties.opacity = 1;
     level0SeriesTemplate.bulletsContainer.hiddenState.properties.visible = true;
     level0SeriesTemplate.tooltip.disabled = true;
     // create hover state
    //  let columnTemplate = level0SeriesTemplate.columns.template;
    //  let hoverState = columnTemplate.states.create("hover");
 
    //  // darken
    //  hoverState.adapter.add("fill", function (fill, target) {
    //      if (fill instanceof am4core.Color) {
    //      return am4core.color(am4core.colors.brighten(fill.rgb, -0.2));
    //      }
    //      return fill;
    //  })

    var level1 = chart.seriesTemplates.create("0");
    level1.tooltip.disabled =true;
    var level1_bullet = level1.bullets.push(new am4charts.LabelBullet());
    level1_bullet.locationY = 0.5;
    level1_bullet.locationX = 0.5;
    level1_bullet.label.text = "[font-size:{differentFontSize} bold ]{name}: {totalCalls} Calls";
    level1_bullet.tooltipText= "[#FFFFFF bold]{name}: {totalCalls} Calls";
 
    // drilldown on "bullet" text click
    level1_bullet.events.on('hit' , ev=>{
        let dataItem = ev.target.dataItem.dataContext;

        // clearing and resetting the tooltip b/c of sideeffect
        // where tooltip is still being displayed on dirlldown
        level1_bullet.tooltipText=  "";
        chart.zoomToChartDataItem(dataItem);
        level1_bullet.tooltipText= "[#FFFFFF bold]{name}: {totalCalls} Calls";
    });

    var level2 = chart.seriesTemplates.create("1");
    level2.tooltip.disabled = true;
    var level2_bullet = level2.bullets.push(new am4charts.LabelBullet());
    level2_bullet.locationY = 0.8;
    level2_bullet.locationX = 0.5;
    level2_bullet.label.text = "[bold]{name}: {totalCalls} Calls";
    level2_bullet.tooltipText= "[#FFFFFF bold]{name}: {totalCalls} Calls";

    var level1ColumnTemplate = level1.columns.template;
    /* Navigation bar */
    chart.homeText = "";
    chart.navigationBar = new am4charts.NavigationBar();

    level1ColumnTemplate.events.on("hit", function(ev) {
        props.ZoomInClick();
    });
    chart.zoomOutButton.events.on("hit", ()=>{
        props.ZoomOutClick();
    })
}   
}, [props.filters]);
return (
    <div
        id="drillDownChartDiv"
        style={{width: "100%", height: "100%"}}
    />
);
}
