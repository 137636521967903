import React, { useState, useLayoutEffect } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
    textField: {
      width: '100%',
    },
    selectField: {
      width: '400px',
    },
  }));
  
  const SwitchComponent = (props) => {
    const classes = useStyles();
    const [themeType,setThemeType] = useState('dark');
    const [stateTitle, setStateTitle] = useState(props.activeState);
    
    const [state, setState] = React.useState({
        checkedEn: true,
    });
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        if(state.checkedEn == true)
         setStateTitle("Disabled");
        else if(state.checkedEn == false )
         setStateTitle("Enabled");
    };
    useLayoutEffect(() => {
        if(props.activeModeClass === "white-content"){  setThemeType("light")}
        else{setThemeType("dark")}
        
      }, []);
      const THEME = createMuiTheme({
        palette: {
          type: themeType,
          primary: {
            main: '#f8955e',
          },
          secondary: {
            main: '#1d8cf8',
          },
        },
      });
      return (
          <div>
              <MuiThemeProvider theme={THEME}>
               <CssBaseline/>   
               <FormControlLabel
                control={
                <Switch
                    checked={state.checkedEn}
                    onChange={handleChange}
                    color="primary"
                    name="checkedEn"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                }
                label={props.label}
          />
              </MuiThemeProvider>
          </div>
      )
  }
  export default SwitchComponent;