import React, {useContext} from "react";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { forwardRef, useState, useLayoutEffect, useRef} from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ApiCall, {ExpoApiContext} from "Helper/Api.js";
import {useExpoApi} from "../../Helper/Api.js"
import EditWebUserDlg from "components/MaterialUI/Dialogs/EditWebUsersDialog.js";

const tableIcons = {
Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function AlarmTable(props) {
  const [state, setState] = React.useState({
    columns: [
      { title: "Name", field: "name" },
      { title: "Email", field: "email"} ,
      { title: "Department", field: "divs", lookup:{1:"Default"}},
      { title: "Role", field: "roles", lookup:{1:"Alarm", 2:"Report", 3:"Admin"}} 
    ],
    data: [
      // { name:"test",  email:"test@gmail.com",  divs:["1"], roles:["3"]}
    ]
  });
  const getApi = useExpoApi();
  const context = useContext(ExpoApiContext);
  const _isMounted = useRef(true);
  const [themeType,setThemeType] = useState('dark');
  const [selectUserId, setSelectUserId] = useState(0);
  const [editUserDlgOpen, setEditUserDlgOpen] = useState(false);
  const [classnameVar, setClassnameVar] = useState("modal-black");
  const [activeModeClass, setActiveModeClass] = useState("dark-content");
  const [userId, setUserId] = useState(0);
  const [userName, setUserName] = useState("");
  const [emailAdd, setEmailAdd] = useState("");
  const [departments, setDepartments] = useState([]);
  const [branches, setBranches] = useState([]);
  const [role, setRole] = useState([]);
  const [divAItems, setDivAItems] = useState([]);
  const [divBItems, setDivBItems] = useState([]);
  const [roleItems, setRoleItems] = useState([]);
  const[totalDivA, setTotalDivA] = useState(0);
  const[totalDivB, setTotalDivB] = useState(0);

  const getTotals = async () =>{
      const api = await getApi();
    const result = await api.getDivs('A');
    const totalDivsA = result.data.divs.length;
    setTotalDivA(totalDivsA)

    const result1 = await api.getDivs('B');
    const totalDivsB = result1.data.divs.length;
    setTotalDivB(totalDivsB)
  }

  const apiCallGetWebUsers = async ()=> {
    const divALookup = {};
    const divBLookup = {};
    const rolesLookup = {};

    context.divsA.forEach((div) => {
      divALookup[div.id] = div.name;
    });
    
    context.divsB.forEach((div) => {
      divBLookup[div.id] = div.name;
    });
      const api = await getApi();
       api
         .getWebUserRole()
         .then((response) => {
           response.data.map((item)=>{
              rolesLookup[item.id] = item.name;
            })

            
          }) 
         .catch((err) => {console.log(err)});     
    if (_isMounted.current) {
      
      
       api
         .getWebUsers()
         .then((response) => {
           setState({
              data: response.data.users,
              columns: [
                { title: "Name", field: "name" },
                { title: "Email", field: "email"} ,
                // { title: "Department", field: "divAIds"},
                // { title: "Branch", field: "divBIds"},
                { title: "Role", field: "roles", lookup:rolesLookup} 
              ]
           })      
            console.log(response.data.users) 
          }) 
          
         .catch((err) => {console.log(err)});  
      }      
    };

  const onDeleteUser = async (userId) => {
		try {
            const api = await getApi();
			const res = await api.postDeleteWebUser(userId);
			if(res.status === 200) {
				console.log("success");
			return;
		}

    console.log("error"); 
		}
		catch(err) {
			console.log(err);
		}  
		
  };

  const onEditWebUser = async (newUser, userId) => {
		try {
            const api = await getApi();
			const res = await api.postEditWebUser(newUser, userId);
			if(res.status === 200) {
				window.location.reload(false);
			  return;
		  }
		}
		catch(err) {
			console.log(err);
		}  
	
  };

  const nameOnChange = (e) => {
    setUserName(e.target.value) ; 
  }

  const emailOnChange = (e) => {
    setEmailAdd(e.target.value)
  }

  const departmentsOnChange = (e, value) => { 
    let selVal = "";
    selVal = Array.prototype.map.call(value, function(item) { return item.value; }).join(",");
    if(selVal.split(',').length == totalDivA || selVal == "")
      setDepartments([]);
    else
      setDepartments(selVal.split(','));
  }

  const branchOnChange = (e, value) => { 
    let selVal = "";
    selVal = Array.prototype.map.call(value, function(item) { return item.value; }).join(",");
    if(selVal.split(',').length == totalDivB || selVal == "")
      setBranches([]);
    else
      setBranches(selVal.split(','));
  }

  const accessRoleOnChange = (e, value) => { 
    let selVal = "";
    selVal = Array.prototype.map.call(value, function(item) { return item.value; }).join(",");
    setRole(selVal.split(','));
  }  

  const Save = async () => {
    if(emailAdd != "" && userName != ""){
      let divsArray = departments.concat(branches);
      const newUser = { 
        name: userName,
        extension: "",
        email: emailAdd,
        divs: divsArray,                           
        roles: role
      };
      console.log(newUser);
      onEditWebUser(newUser, userId) ;
      setEditUserDlgOpen(false);
    }
    else{
      alert("Name and Email can not be empty");
    }
    
  }

  React.useEffect(() => {  
    
    const dataArray = JSON.parse(localStorage.getItem('usersArray'));                                                                                                                       
    if(localStorage.getItem('usersArray')){
      setState( {
        data: dataArray,
        columns: [
          { title: "Name", field: "name" },
          { title: "Last Name", field: "lastname" },
          { title: "Email", field: "email"} ,                           
          { title: "Ext", field: "ext"} ,
          { title: "Department", field: "divs"},
          { title: "Access", field: "roles"} 
        ]})
     }
     else{
      localStorage.setItem("usersArray", JSON.stringify(state.data));
     }  
    getTotals();
    apiCallGetWebUsers()
  }, []);
  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")}
    
  }, []);
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#4caf50',
      },
    },
  });
  return (
    <div>
      <EditWebUserDlg
        className={classnameVar} 
        activeModeClass={activeModeClass} 
        open = {editUserDlgOpen}
        handleClose={()=>{setEditUserDlgOpen(false)}} 
        nameValue = {userName}
        nameOnChange = {nameOnChange}
        emailValue = {emailAdd}
        emailOnChange = {emailOnChange}
        deptOnChange = {departmentsOnChange}
        branchOnChange = {branchOnChange}
        accessRoleOnChange = {accessRoleOnChange}
        selDivAItems = {divAItems}
        selDivBItems = {divBItems}
        selRole = {roleItems}
        OnSave = {Save}
      >
  
      </EditWebUserDlg>
      <MuiThemeProvider theme={THEME}>
      
          <MaterialTable
            style= {{
                backgroundColor: 'transparent',
                boxShadow: 'none',
            }}
            icons={tableIcons}
            title="Edit/Delete an User"
            columns={state.columns}
            data={state.data}
            options={{
                headerStyle: {
                  backgroundColor: "rgba(0,0,0,0)",  // #6ca6c2; //67b7dc          
                  fontWeight: 'bold'
                }
              }}
              actions={[
                {
                  icon: ()=> <Edit/>,
                  tooltip: 'Edit Row',
                  onClick: (event, rowData) => {
                        
                        setUserId(rowData.id);
                        setUserName(rowData.name);
                        setEmailAdd(rowData.email);
                        setEditUserDlgOpen(true); 
                        if(rowData.divAIds.length > 0){
                          let deptStr = rowData.divAIds.toString() ;
                          let deptArray = deptStr.split(",");
                          setDepartments(deptArray);  
                        }
                        else{
                          setDepartments(rowData.divAIds) ; 
                        }
                        if(rowData.divBIds.length > 0){
                          let branchStr = rowData.divBIds.toString() ;
                          let branchArray = branchStr.split(",");
                          setBranches(branchArray);  
                        }
                        else{
                          setBranches(rowData.divBIds) ; 
                        }
                        setRole(rowData.roles);
                        const divALookup = [];
                        context.divsA.forEach((div) => {
                          const divAItem = {}
                          const divAIdArr = JSON.parse("[" + rowData.divAIds + "]");
                          divAIdArr.forEach((divId) => {
                            if(div.id == divId)  {
                              divAItem.title = div.name;
                              divAItem.value = div.id;
                              divALookup.push(divAItem);
                            }
                          })
                        });
                        setDivAItems(divALookup);

                        const divBLookup = [];
                        context.divsB.forEach((div) => {
                          const divBItem = {}
                          const divBIdArr = JSON.parse("[" + rowData.divBIds + "]");
                          divBIdArr.forEach((divId) => {
                            if(div.id == divId)  {
                              divBItem.title = div.name;
                              divBItem.value = div.id;
                              divBLookup.push(divBItem);
                            }
                          })   
                        });
                        
                        setDivBItems(divBLookup);
                        const divRoleLookup = [];
                        context.roles.forEach((role) => {
                          const divRoleItem = {}
                          const roleIdArr = JSON.parse("[" + rowData.roles + "]");
                          roleIdArr.forEach((roleId) => {
                            if(role.id == roleId)  {
                              divRoleItem.title = role.name;
                              divRoleItem.value = role.id;
                              divRoleLookup.push(divRoleItem);
                            }
                          })
                        }); 
                        setRoleItems(divRoleLookup);
                  }
                }
              ]}
            editable={{
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
               
                setTimeout(() => {
                  resolve();
                  let data = [];
                  let index = 0;
                  setState((prevState) => {
                    
                    data = [...prevState.data];
                    let pos = oldData.tableData.id;
                    index = data[pos].id;
                    onDeleteUser(index);
                    data.splice(data.indexOf(oldData), 1); 
                    return { ...prevState, data };
                   
                  });

                }, 600);
              })
  
            }}
          />
    
      </MuiThemeProvider>
    </div>
  );
}
