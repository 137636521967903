/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import { FormGroup, Input, Row, Col } from "reactstrap";
import DeptMultiSelect from "components/MaterialUI/SelMultDivAWebUser.js";
import DivBMultiSelect from "components/MaterialUI/SelMultDivBWebUser.js";
import DivCMultiSelect from "components/MaterialUI/SelMultDivCWebUser.js";
import SelMultAccess from "components/MaterialUI/SelMultAccessWebUser.js";
import SelMultReports from "components/MaterialUI/SelMultRptWebUser.js";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step3Select: null
    };
  }
  render() {
    return (
      <>
        <form>
        <Row style={{marginLeft:"0px"}}>
            <Col sm="4">   
              <FormGroup>
              {document.body.classList.contains("white-content") ? 
                <DeptMultiSelect activeModeClass="white-content" onChange={this.props.deptOnChange} selItems = {[]} ></DeptMultiSelect> : <DeptMultiSelect activeModeClass="dark-content" onChange={this.props.deptOnChange} selItems = {[]} ></DeptMultiSelect>           
              }
              </FormGroup>        
            </Col>
            <Col sm="4">
               
              {document.body.classList.contains("white-content") ? 
                <DivBMultiSelect activeModeClass="white-content" onChange={this.props.branchOnChange} selItems = {[]} ></DivBMultiSelect> : <DivBMultiSelect activeModeClass="dark-content" onChange={this.props.branchOnChange} selItems = {[]} ></DivBMultiSelect>           
              }
            </Col>
            <Col sm="4">
            {document.body.classList.contains("white-content") ? 
                  <SelMultAccess activeModeClass="white-content" onChange={this.props.accessRoleOnChange} selRole = {[]} ></SelMultAccess> : <SelMultAccess activeModeClass="dark-content" onChange={this.props.accessRoleOnChange} selRole = {[]}></SelMultAccess>           
                }
              {/* {document.body.classList.contains("white-content") ? 
                <DivCMultiSelect activeModeClass="white-content"></DivCMultiSelect> : <DivCMultiSelect activeModeClass="dark-content"></DivCMultiSelect>           
              } */}
            </Col>
          </Row>
          <Row className="justify-content-center" style={{marginLeft:"20px", marginTop:"15px"}}>
            
            <Col sm="3">
             <FormGroup>
             {/* {document.body.classList.contains("white-content") ? 
                  <SelMultReports activeModeClass="white-content"></SelMultReports> : <SelMultReports activeModeClass="dark-content"></SelMultReports>           
              } */}
             </FormGroup>
            </Col>
            <Col sm="3">
              {/* {document.body.classList.contains("white-content") ? 
                  <SelMultReports activeModeClass="white-content"></SelMultReports> : <SelMultReports activeModeClass="dark-content"></SelMultReports>           
              } */}
            </Col>
            <Col sm="3">
             
            </Col>
            <Col sm="3">
               
            </Col>
            
          </Row>
        </form>
      </>
    );
  }
}

export default Wizard;
