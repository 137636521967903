/* eslint-disable no-lone-blocks */
/* eslint-disable eqeqeq */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useLayoutEffect } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, 
  Modal, 
  ModalBody, 
  ModalFooter ,  
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup, 
  Label, 
  Input,
  FormFeedback, 
  FormText
} from 'reactstrap';

import {useExpoApi} from "Helper/Api.js"
import SelMultAccess from "components/MaterialUI/SelMultAccessWebUser.js";
import DeptMultiSelect from "components/MaterialUI/SelMultDivAWebUser.js";
import DivBMultiSelect from "components/MaterialUI/SelMultDivBWebUser.js";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '400px',
  },
 
}));

const SchedulerDialog = (props) => {
  const [themeType,setThemeType] = useState('dark');

  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")}
    
  }, []);

  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#1d8cf8',
      },
    },
  });

  return (
    <div>

    <MuiThemeProvider theme={THEME}>
    <Modal style={{position:"center", marginTop:"-250px"}} isOpen={props.open} toggle={props.handleClose} modalClassName={props.className}>
    <div className="modal-header">
      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.handleClose}>
        <i className="tim-icons icon-simple-remove"></i>
      </button>
      <h5 className="modal-title">Edit </h5>
    </div>
    <ModalBody>   
    <Col sm="12">
     <div style={{marginLeft:"-20px" ,fontSize:"12px"}}>
        <Card>
          <CardBody >
           <Row style={{height:"100%"}}>
            <Row>
            <Col xs="12"> 
            <Form style={{width:"380px", marginLeft:"15px", marginTop:"-20px"}}> 
              <FormGroup className="position-relative" style={{marginTop:"15px"}}>    
                <Label for="userName">Name</Label>
                <Input type="text" name="userName" id="userName" placeholder="" style={{width:"380px"}} onChange={props.nameOnChange} value={props.nameValue}/> 
              </FormGroup>        
              <FormGroup className="position-relative" style={{marginTop:"15px"}}> 
                <Label for="email">Email</Label>
                <Input type="text" name="email" id="email" placeholder="" style={{width:"380px"}} onChange={props.emailOnChange} value={props.emailValue}/> 
              </FormGroup>
              <FormGroup className="position-relative" style={{marginTop:"-5px", marginLeft:"-10px"}}>  
               <Col xs="12"> 
                {document.body.classList.contains("white-content") ? 
                  <DeptMultiSelect activeModeClass="white-content" onChange={props.deptOnChange} selItems = {props.selDivAItems}></DeptMultiSelect> : <DeptMultiSelect activeModeClass="dark-content" onChange={props.deptOnChange} selItems = {props.selDivAItems} ></DeptMultiSelect>           
                }
               </Col>
              </FormGroup>
              <FormGroup className="position-relative" style={{marginTop:"15px", marginLeft:"-10px"}}>  
                <Col xs="12"> 
                  {document.body.classList.contains("white-content") ? 
                    <DivBMultiSelect activeModeClass="white-content" onChange={props.branchOnChange} selItems = {props.selDivBItems} ></DivBMultiSelect> : <DivBMultiSelect activeModeClass="dark-content" onChange={props.branchOnChange} selItems = {props.selDivBItems}></DivBMultiSelect>           
                  }  
                </Col>
              </FormGroup>  
                  
              <FormGroup className="position-relative" style={{marginTop:"15px", marginLeft:"-10px"}}>  
              <Col xs="12">   
                {document.body.classList.contains("white-content") ? 
                  <SelMultAccess activeModeClass="white-content" onChange={props.accessRoleOnChange} selItems = {props.selRole}></SelMultAccess> : <SelMultAccess activeModeClass="dark-content" onChange={props.accessRoleOnChange} selItems = {props.selRole}></SelMultAccess>           
                } 
              </Col>
              </FormGroup>    
            </Form>
            </Col>        
            </Row> 
              
           </Row>
          </CardBody>
        </Card>
    </div>
    </Col>           
    </ModalBody>
    <ModalFooter>
    <Button color="warning" onClick={props.handleClose}>
      Close
    </Button>
    <Button color="warning" onClick = {function(event){   
      props.OnSave() 
     }}>
      Save
    </Button>
    </ModalFooter>
    </Modal>
    </MuiThemeProvider>
    </div>
  );
}

export default SchedulerDialog;