import React, { useState, useLayoutEffect } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles((theme) => ({
  selectField: {
      width: '350px',
    }
  }));
  
  const SelectSimpleComponent = (props) => {
    const classes = useStyles();
    const [themeType,setThemeType] = useState('dark');
    const [condition, setCondition] = React.useState("Actual Cost");

    const handleChange = (e) => {
      setCondition(e.target.value);
    };
    useLayoutEffect(() => {
        if(props.activeModeClass === "white-content"){  setThemeType("light")}
        else{setThemeType("dark")}
        
      }, []);
      const THEME = createMuiTheme({
        palette: {
          type: themeType,
          primary: {
            main: '#f8955e',
          },
          secondary: {
            main: '#1d8cf8',
          },
        },
      });
      return (
          <div>
              <MuiThemeProvider theme={THEME}>
               <CssBaseline/>   
               <FormControl >
                <InputLabel shrink id="condition-select">
                  Generate On: (Each Month)
                </InputLabel>
                <Select
                  labelId="condition-label"
                  id="condition-select"
                  value= {props.selVal}
                  onChange={props.onChange}
                  className={classes.selectField}
                  displayEmpty
                >
                  <MenuItem value="1">Day 1</MenuItem>
                  <MenuItem value="2">Day 2</MenuItem>
                  <MenuItem value="3">Day 3</MenuItem> 
                  <MenuItem value="4">Day 4</MenuItem> 
                  <MenuItem value="5">Day 5</MenuItem> 
                  <MenuItem value="6">Day 6</MenuItem> 
                  <MenuItem value="7">Day 7</MenuItem> 
                  <MenuItem value="8">Day 8</MenuItem> 
                  <MenuItem value="9">Day 9</MenuItem> 
                  <MenuItem value="10">Day 10</MenuItem> 
                  <MenuItem value="11">Day 11</MenuItem> 
                  <MenuItem value="12">Day 12</MenuItem> 
                  <MenuItem value="13">Day 13</MenuItem> 
                  <MenuItem value="14">Day 14</MenuItem> 
                  <MenuItem value="15">Day 15</MenuItem> 
                  <MenuItem value="16">Day 16</MenuItem> 
                  <MenuItem value="17">Day 17</MenuItem> 
                  <MenuItem value="18">Day 18</MenuItem> 
                  <MenuItem value="19">Day 19</MenuItem> 
                  <MenuItem value="20">Day 20</MenuItem> 
                  <MenuItem value="21">Day 21</MenuItem> 
                  <MenuItem value="22">Day 22</MenuItem> 
                  <MenuItem value="23">Day 23</MenuItem> 
                  <MenuItem value="24">Day 24</MenuItem> 
                  <MenuItem value="25">Day 25</MenuItem> 
                  <MenuItem value="26">Day 26</MenuItem> 
                  <MenuItem value="27">Day 27</MenuItem> 
                  <MenuItem value="28">Day 28</MenuItem> 
                  <MenuItem value="29">Day 29</MenuItem> 
                  <MenuItem value="30">Day 30</MenuItem> 
                  <MenuItem value="31">Day 31</MenuItem> 
                  <MenuItem value="32">Day 32</MenuItem> 
                  <MenuItem value="33">Day 33</MenuItem> 
                </Select>
              </FormControl>      
              </MuiThemeProvider>
          </div>
      )
  }
  export default SelectSimpleComponent;