import React, { Component,useContext } from "react"; 
import ReactDOM from "react-dom";

import FlatfileImporter from "@flatfile/adapter";

import "assets/css/flat-file-custom.css";
import ApiCall, {ExpoApiContext} from "Helper/Api.js";
import {withAuth0} from "@auth0/auth0-react";
const LICENSE_KEY = "aa921983-4db2-4da1-a580-fbca0b1c75b2";

class App extends Component {
  constructor() {
    super();
    this.getRoles = this.getRoles.bind(this);
    this.getDepartments = this.getDepartments.bind(this);
    this.getDivB = this.getDivB.bind(this);
    this.launch = this.launch.bind(this);
    this.importer = new FlatfileImporter(LICENSE_KEY, {
      fields: [
        {
          label: "Name",
          key: "name",
          description: "User's First Name",
          validators: [
            {
              validate: "required",
              error: "This is a required field"
            }
          ]
        },
        {
          label: "Email",
          key: "email",
          description: "Email",
          validators: [
            {
              validate: "required",
              error: "This is a required field"
            }
          ]
        },
        {
          label: "Div A",
          key: "divA",
          description: "Enter a comma separated list of departments,in case the user has more than one department association",
          validators: [
            {
              validate: "required",
              error: "This is a required field"
            }
          ]
        },
        {
          label: "Div B",
          key: "divB",
          description: "Enter a comma separated list of Branches,in case the user has more than one branch association",
        },
        {
          label: "User Acces",
          key: "access",
          description: "User's Acces Role Ex(Report, Admin)",
          validators: [
            {
              validate: "required",
              error: "This is a required field"
            }
          ]
        },

      ],
      type: "Users",
      allowInvalidSubmit: true,
      managed: true,
      allowCustom: true,
      disableManualInput: false
    });
    this.state = {
      results: "Your raw output will appear here.",
      rolesObj:[],
      divAArray:[],
      divBArray:[],
    };

    this.importer.registerRecordHook((record, index) => {
      let out = {};
      if (record.name.includes(" ")) {
        out.name = {
          value: record.name.replace(" ", "_"),
          info: [
            {
              message:
                "No spaces allowed. Replaced all spaces with underscores",
              level: "warning"
            }
          ]
        };
      }
      return out;
    });

    this.importer.setCustomer({
      userId: "19235",
      name: "MC"
    });
  }

  componentDidMount() {
    this.getRoles();  
    this.getDepartments() ; 
    this.getDivB();                
  }

  getRoles = async ()=> { //add the filters
    const roleObj = [];
    const token = await this.props.auth0.getAccessTokenSilently();
    const api = new ApiCall(token);

       api
         .getWebUserRole()
         .then((response) => {
            response.data.map((item)=>{
               let objItem = {};
               objItem.name = item.name;
               objItem.id = item.id;
               roleObj.push(objItem);
               this.setState({rolesObj:roleObj})
            }) 
          }) 
         .catch((err) => {console.log(err)});      
  };  

  getDepartments = async ()=> {
    const divObj = [];
    const token = await this.props.auth0.getAccessTokenSilently();
    const api = new ApiCall(token);

    api
      .getDivs("A")
      .then((response) => {
        response.data.divs.map((item)=>{
          let objItem = {};
          objItem.name = item.name;
          objItem.id = item.id;
          divObj.push(objItem);
          this.setState({divAArray:divObj})      
        })     
      })     
      .catch((err) => {console.log(err)});     
  };

  getDivB = async ()=> {
    const divObj = [];
    const token = await this.props.auth0.getAccessTokenSilently();
    const api = new ApiCall(token);

    api
      .getDivs("B")
      .then((response) => {
        console.log(response.data.divs)
        response.data.divs.map((item)=>{
          let objItem = {};
          objItem.name = item.name;
          objItem.id = item.id;
          divObj.push(objItem);
          this.setState({divBArray:divObj})
        })    
      })     
      .catch((err) => {console.log(err)});     
  };

  launch = async () => {
    this.importer
      .requestDataFromUser()
      .then(results => {
        this.importer.displayLoader();
         setTimeout(() => {
          this.importer.displaySuccess("Success!");
          // this.setState({
          //   results: JSON.stringify(results.validData, null, 2)                                  
          // });
          let userArray = [];
          results.validData.map((item)=> {
            let departments = [];
            let accessRole = []
            this.state.rolesObj.map((objitem)=>{
              if(item.access == objitem.name)
              accessRole.push(objitem.id);
            })        
            this.state.divAArray.map((objitem)=>{
              const array = item.divA.split(',');
              array.map((arrItem)=>{
                if(arrItem == objitem.name)
                  departments.push(objitem.id);
              })
            })         
            this.state.divBArray.map((objitem)=>{
              const array = item.divB.split(',');
              array.map((arrItem)=>{
                if(arrItem == objitem.name)
                  departments.push(objitem.id);
              })
              
            })        
            console.log(accessRole) ;
            const newUser = { 
              name: item.name,
              extension: "",
              email: item.email,
              divs: departments,
              roles: accessRole
            };
            console.log(newUser)  ;
            this.addUsers(newUser);
          }) 
          
        }, 1500);
      })
      .catch(function(error) {
        console.info(error || "window close");
      });
  }

  addUsers = async (newUser) =>{
    try{      
      const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);

      const res = await api.postCreateWebUser(newUser);
      if(res.status === 200) {
        console.log("success");
        return;
      }
      console.log("error"); 
      alert("Error");
      }
      catch(err) {
        console.log(err);
        alert("Error");
      }   
  }
  render() {
    return (
      <div >
        {LICENSE_KEY ? null : (
          <div className="licenseAsk">
            Obtain your license key from the
            <a href="https://flatfile.io/app" target="_blank">
              Flatfile Dashboard &rarr;
            </a>
            <p>
              Once you've found it, set the <code>{LICENSE_KEY}</code> variable on
              Line 8 before continuing.
            </p>
          </div>
        )}
        <input
          type="button"
          id="launch"
          className={LICENSE_KEY ? null : "disabled"}
          value="Launch Importer"
          onClick={this.launch}
        />
       
        <div className="download">
        {/* <a href="..\src\extensions.csv" target="_blank" rel="noopener noreferrer">
            Download a sample csv file here
        </a> */}
        </div>
        {/*<textarea id="raw_output" readOnly value={this.state.results} />*/}
      </div>
    );
  }
}

export default withAuth0(App);



