import dayjs from 'dayjs';

const today = new Date();
const y = today.getFullYear();
let m = today.getMonth() + 1;
let d = today.getDate();

if(m < 10)
 m = "0" + m;
if(d < 10)
 d = "0" + d;

let TimePickerFromDate = dayjs().set('hour', 7).startOf('hour').toISOString();
let TimePickerToDate = dayjs().set('hour', 7).startOf('hour').add(1, 'day').toISOString();
let TodaysDate = y + "-" + m + "-" + d;


export {TimePickerFromDate};
export {TimePickerToDate};
export {TodaysDate};




