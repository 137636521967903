import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle,  Row, Col } from 'reactstrap';
import classnames from 'classnames';
import {CardHeader,CardBody} from "reactstrap";
import OverviewNavLinks from "components/MaterialUI/IconBreadCrumbsServCharge.js"
import AppliedSVCTable from "components/Tables/AppliedSVCTable.js";
import TextField from '@material-ui/core/TextField';
import RadioBtnComponent from "components/MaterialUI/RadioBtnSVCApply.js";
import MultipleSelDept from "components/MaterialUI/SelMultSVCDept.js";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from "@material-ui/core/FormLabel";
import DateTimePickerApply from "components/MaterialUI/DateTimePickerSVCApply.js";
import SelectSimpleSchedday from "components/MaterialUI/SelectSimpleSVCSchedDay.js";
import ScheduledSVCTable from "components/Tables/ScheduledSVCTable.js";
import MemSVCTable from "components/Tables/MemSVCTable.js"

const ServiceChargeTabs = (props) => {
  const [activeTab, setActiveTab] = useState('1');
  const [optionCardOpen, setOptionCardOpen]  = useState(false);
  const [optionCardOpen1, setOptionCardOpen1]  = useState(false);
  const [optionCardOpen2, setOptionCardOpen2]  = useState(false);
  const [optionCardOpen3, setOptionCardOpen3]  = useState(false);
  const [optionCardOpen4, setOptionCardOpen4]  = useState(false);
  const [optionSelSchedDay, setOptionSchedDay] = useState("1st");
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  const toggleOption1 = (e) => {
    setOptionCardOpen(!optionCardOpen);
    setOptionCardOpen1(false);
    setOptionCardOpen2(false);
    setOptionCardOpen3(false);
    setOptionCardOpen4(false);   
  }
  const toggleOption2 = (e) => {
    setOptionCardOpen(false);
    setOptionCardOpen1(!optionCardOpen1);
    setOptionCardOpen2(false);
    setOptionCardOpen3(false);
    setOptionCardOpen4(false);
  }
  const toggleOption3 = (e) => {
    setOptionCardOpen(false);
    setOptionCardOpen1(false);
    setOptionCardOpen2(!optionCardOpen2);
    setOptionCardOpen3(false);
    setOptionCardOpen4(false);
  }
  const toggleOption4 = (e) => {
    setOptionCardOpen(false);
    setOptionCardOpen1(false);
    setOptionCardOpen2(false);
    setOptionCardOpen3(!optionCardOpen3);
    setOptionCardOpen4(false);
  }
  const toggleOption5 = (e) => {
    setOptionCardOpen(false);
    setOptionCardOpen1(false);
    setOptionCardOpen2(false);
    setOptionCardOpen3(false);
    setOptionCardOpen4(!optionCardOpen4);
  }
  const SchedDaySelOnChange = (e) => {
    setOptionSchedDay(e.target.value);
  }
  return (
    <div className="content">
      <Nav tabs>
        <NavItem>
          <NavLink
            style={{color: '#f8955e', textDecoration: 'none'}}
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            Overview
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{color: '#f8955e', textDecoration: 'none'}}
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            Scheduled Service Charges
          </NavLink>
        </NavItem>
      
        <NavItem>
        <NavLink
          style={{color: '#f8955e', textDecoration: 'none'}}
          className={classnames({ active: activeTab === '3' })}
          onClick={() => { toggle('3'); }}
        >
          Memorized Service Charges
        </NavLink>
      </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
        <Row>
        <Col xs="12">
          <Card className="card-chart">     
            <CardBody>
              <div style={{height: window.innerHeight - 250, width: "100%"}}>
                <Row>
                <Card className="card-chart">
                  <CardBody>
                    <Row>
                    <Col md="4">
                      <div style={{marginTop:"-10px"}}>
                        <OverviewNavLinks 
                        activeModeClass="dark-content" 
                        onClickMenu1 = {toggleOption1} 
                        onClickMenu2 = {toggleOption2} 
                        onClickMenu3 = {toggleOption3}
                        onClickMenu4 = {toggleOption4}
                        onClickMenu5 = {toggleOption5}
                        />
                      </div>                   
                    </Col>
                    <Col md="8">
                    { optionCardOpen && 
                        <Card className="card-chart">
                        <CardBody>
                        <div style={{ height:"220px"}}>  
                         <Row>
                           <Col md="4">
                           <Row style={{marginLeft:"10px"}}>
                             <TextField id="" value="Service Charge" label="Name" />
                           </Row>
                           <Row style={{marginLeft:"10px", marginTop:"10px"}}> 
                             <TextField id="" value="Service Charge" label="Description" />
                          </Row>
                          <Row style={{marginLeft:"10px", marginTop:"10px"}}>
                             <TextField id="" value="10.00" label="Charge" />
                          </Row>
                          <Row style={{marginLeft:"10px", marginTop:"10px"}}>
                             <TextField id="" value="0.00" label="Tax" />
                          </Row>
                           </Col>
                           <Col md="3">
                             <Row>
                                <RadioBtnComponent activeModeClass="dark-content"/> 
                             </Row>
                             <Row style={{marginTop:"20px"}}>
                               <FormControl component="fieldset">
                                 <FormLabel component="legend">Apply On</FormLabel>
                                 <div style={{marginTop:"10px", marginLeft:"-5px"}}><DateTimePickerApply activeModeClass="dark-content" label="Select Date/Time"/> </div>                              
                               </FormControl>
                             </Row>
                           </Col>
                           <Col md="5">
                             <Row style={{marginTop:"-10px"}}>
                                 <TextField id="" value="" label="Enter User Extensions:" />
                             </Row>    
                             <Row style={{marginTop:"10px"}}>
                                 <MultipleSelDept activeModeClass="dark-content" />
                             </Row> 
                           </Col>
                         </Row>
                        </div> 

                        </CardBody>
                      </Card>
                      }
                      { optionCardOpen1 && 
                        <Card className="card-chart">
                        <CardBody>
                        <div style={{ height:"220px"}}>  
                         <Row>
                           <Col md="4">
                           <Row style={{marginLeft:"10px"}}>
                             <TextField id="" value="Credit" label="Name" />
                           </Row>
                           <Row style={{marginLeft:"10px", marginTop:"10px"}}> 
                             <TextField id="" value="Credit" label="Description" />
                          </Row>
                          <Row style={{marginLeft:"10px", marginTop:"10px"}}>
                             <TextField id="" value="10.00" label="Charge" />
                          </Row>
                          <Row style={{marginLeft:"10px", marginTop:"10px"}}>
                             <TextField id="" value="0.00" label="Tax" />
                          </Row>
                           </Col>
                           <Col md="3">
                             <Row>
                                <RadioBtnComponent activeModeClass="dark-content"/> 
                             </Row>
                             <Row style={{marginTop:"20px"}}>
                               <FormControl component="fieldset">
                                 <FormLabel component="legend">Apply On</FormLabel>
                                 <div style={{marginTop:"10px", marginLeft:"-5px"}}><DateTimePickerApply activeModeClass="dark-content" label="Select Date/Time"/> </div>                              
                               </FormControl>
                             </Row>
                           </Col>
                           <Col md="5">
                             <Row style={{marginTop:"-10px"}}>
                                 <TextField id="" value="" label="Enter User Extensions:" />
                             </Row>    
                             <Row style={{marginTop:"10px"}}>
                                 <MultipleSelDept activeModeClass="dark-content" />
                             </Row> 
                           </Col>
                         </Row>
                        </div> 
                        </CardBody>
                      </Card>
                      }
                      { optionCardOpen2 && 
                        <Card className="card-chart">
                        <CardBody>
                        <div style={{ height:"220px"}}>  
                         <Row>
                           <Col md="4">
                           <Row style={{marginLeft:"10px"}}>
                             <TextField id="" value="Service Charge" label="Name" />
                           </Row>
                           <Row style={{marginLeft:"10px", marginTop:"10px"}}> 
                             <TextField id="" value="Service Charge" label="Description" />
                          </Row>
                          <Row style={{marginLeft:"10px", marginTop:"10px"}}>
                             <TextField id="" value="10.00" label="Charge" />
                          </Row>
                          <Row style={{marginLeft:"10px", marginTop:"10px"}}>
                             <TextField id="" value="0.00" label="Tax" />
                          </Row>
                           </Col>
                           <Col md="3">
                             <Row>
                                <RadioBtnComponent activeModeClass="dark-content"/> 
                             </Row>
                             <Row style={{marginTop:"20px"}}>
                               <FormControl component="fieldset">
                                 <FormLabel component="legend">Apply On</FormLabel>
                                 <div style={{marginTop:"10px"}}> 
                                   <SelectSimpleSchedday 
                                     activeModeClass="dark-content" 
                                     selVal = {optionSelSchedDay} 
                                     onChange = {SchedDaySelOnChange}
                                   /> 
                                 </div>                              
                               </FormControl>
                             </Row>
                           </Col>
                           <Col md="5">
                             <Row style={{marginTop:"-10px"}}>
                                 <TextField id="" value="" label="Enter User Extensions:" />
                             </Row>    
                             <Row style={{marginTop:"10px"}}>
                                 <MultipleSelDept activeModeClass="dark-content" />
                             </Row> 
                           </Col>
                         </Row>
                        </div> 
                        </CardBody>
                      </Card>
                      }
                      { optionCardOpen3 && 
                        <Card className="card-chart">
                        <CardBody>
                        <div style={{ height:"220px"}}>  
                         <Row>
                           <Col md="4">
                           <Row style={{marginLeft:"10px"}}>
                             <TextField id="" value="Service Charge" label="Name" />
                           </Row>
                           <Row style={{marginLeft:"10px", marginTop:"10px"}}> 
                             <TextField id="" value="Service Charge" label="Description" />
                          </Row>
                          <Row style={{marginLeft:"10px", marginTop:"10px"}}>
                             <TextField id="" value="10.00" label="Charge" />
                          </Row>
                          <Row style={{marginLeft:"10px", marginTop:"10px"}}>
                             <TextField id="" value="0.00" label="Tax" />
                          </Row>
                           </Col>
                           <Col md="3">
                             <Row>
                                <RadioBtnComponent activeModeClass="dark-content"/> 
                             </Row>
                           </Col>
                           <Col md="5">
                             <Row style={{marginTop:"-10px"}}>
                                 <TextField id="" value="" label="Enter User Extensions:" />
                             </Row>    
                             <Row style={{marginTop:"10px"}}>
                                 <MultipleSelDept activeModeClass="dark-content" />
                             </Row> 
                           </Col>
                         </Row>
                        </div> 
                        </CardBody>
                      </Card>
                      }
                      { optionCardOpen4 && 
                        <Card className="card-chart">
                        <CardBody>
                        <div style={{ height:"170px", marginLeft:"10px"}}>  
                         <Row>
                           This will apply all memorized service charges for the selected date:
                         </Row>
                         <Row style={{ marginTop:"20px"}}>
                           <FormControl component="fieldset">
                            <FormLabel component="legend">Apply On</FormLabel>
                            <div style={{marginTop:"10px", marginLeft:"-5px"}}><DateTimePickerApply activeModeClass="dark-content" label="Select Date/Time"/> </div>                              
                            </FormControl>
                         </Row>
                        </div> 
                        </CardBody>
                      </Card>
                      }

                    </Col>
                    </Row>
                  </CardBody>
                </Card> 
                </Row>
                <Row style={{marginTop:"-20px"}}>
                  <Col lg="12">
                  <Card className="card-chart">
                  <CardHeader>
                    <CardTitle tag="h4">Recently Applied Charges</CardTitle>
                    <p className="card-category">       
                    </p>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="ml-auto mr-auto" md="12">
                      <div style={{marginTop:"-40px"}}>
                        <AppliedSVCTable activeModeClass="dark-content"/>   
                        
                      </div> 
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                  </Col>
               
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
        </TabPane>
        <TabPane tabId="2">
        <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardBody>
              <div style={{height: window.innerHeight - 250, width: "100%"}}>
              <Row style={{marginTop:"-20px"}}>
                  <Col lg="12">
                  <Card className="card-chart">
                  <CardHeader>
                    <CardTitle tag="h4"></CardTitle>
                    <p className="card-category">       
                    </p>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="ml-auto mr-auto" md="12">
                      <div style={{marginTop:"-40px"}}>
                        <ScheduledSVCTable activeModeClass="dark-content"/>   
                        
                      </div> 
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                  </Col>
               
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
        </TabPane>
        <TabPane tabId="3">
        <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardBody>
            <div style={{height: window.innerHeight - 250, width: "100%"}}>
              <Row style={{marginTop:"-20px"}}>
                  <Col lg="12">
                  <Card className="card-chart">
                  <CardHeader>
                    <CardTitle tag="h4"></CardTitle>
                    <p className="card-category">       
                    </p>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="ml-auto mr-auto" md="12">
                      <div style={{marginTop:"-40px"}}>
                        <MemSVCTable activeModeClass="dark-content"/>   
                        
                      </div> 
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                  </Col>
               
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default ServiceChargeTabs;