import React, {useLayoutEffect} from "react";
/* Imports */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {useExpoApi} from "../../Helper/Api.js"

am4core.options.autoDispose = true;
export default function TimeBasedChart(props) {
    const getApi = useExpoApi();
    useLayoutEffect(() => {
        // Create chart
        am4core.unuseAllThemes();
        am4core.addLicense("CH277440643");
        if (props.activeModeClass === "white-content") {
            am4core.unuseTheme(am4themes_dark);
        } else {
            am4core.useTheme(am4themes_dark);
        }
        am4core.useTheme(am4themes_animated);
        let chart = am4core.create("timeBasedChartDiv", am4charts.XYChart);
        chart.paddingRight = 20;
        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "left";
        chart.exporting.menu.verticalAlign = "bottom";
        am4core.color("#f00", 0)
        
        const getData = async () => {   
            try{
                const api = await getApi();
                const dataResponse = await api.getDailyCallsByHour(props.filters);
                const json = await dataResponse.data;
                const parseGraphData = async (jsonObj) => {
                    let dataParsedObj = jsonObj.dailyCalls;
                    return dataParsedObj.map((item)=>{
                        let graphSubItem = {};
                        const date = new Date(item.date)
                        let year =  date.getFullYear();
                        let month = date.getMonth() ;
                        let dayStr = date.toString().substring(8,10);
                        let hour = date.getHours();
                        let minute = 0;
                        graphSubItem.date = new Date(year, month , dayStr , hour, minute);  
                        graphSubItem.calls =  item.calls;
                        return graphSubItem;
                    })
                }
                chart.data = await parseGraphData(json).catch();
            }
            catch(err) {
                console.log(err);
             }     
        }
        
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.baseInterval = {
            "timeUnit": "minute",
            "count": 1
        };
        dateAxis.tooltipDateFormat = "HH:mm, d MMMM";
        dateAxis.start = 0;
        dateAxis.keepSelection = true;     

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.title.text = "Total Calls";

        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.dateX = "date";
        series.dataFields.valueY = "calls";
        series.tooltipText = "Calls: [bold]{valueY}[/]";
        series.fillOpacity = 0.3;

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineY.opacity = 0;
        chart.scrollbarX = new am4charts.XYChartScrollbar();
        chart.scrollbarX.series.push(series);
        getData().catch();
   
    }, [props.totalCalls]);
    return (
        <div id="timeBasedChartDiv" style={{width: "100%", height: "100%"}} />
    );
}