import React, { useState, useLayoutEffect } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import SelectEmailFreq from "components/MaterialUI/SelectSimpleEmailFreq.js"
import RadioBtnGroup from "components/MaterialUI/SchedRptRadioBtnGroup.js";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import {
  Button,
  Modal, 
  ModalBody, 
  ModalFooter ,  
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

import EmailChips from "components/MaterialUI/EmailChips.js";
import SelectMultDailyFreq from "components/MaterialUI/SelectMultipleDailyFreq.js";
import SelectSimpleWeeklyFreq from "components/MaterialUI/SelectSimpleWeeklyFreq.js";
import SelectSimpleMonthlyFreq from "components/MaterialUI/SelectSimpleMonthlyFreq.js";
import {TodaysDate} from "Helper/Utils.js";
import TimePicker from "components/MaterialUI/TimePickerRptSched.js";
import DatePicker from "components/MaterialUI/DatePickerRptSched.js";
import {useExpoApi} from "Helper/Api.js"
import MenuItem from "@material-ui/core/MenuItem";

const SchedulerDialog = (props) => {
  const getApi = useExpoApi();
  const [themeType,setThemeType] = useState('dark');
  const [frequency,setFrequency] = useState('Daily');
  const [rptFrequency, setRptFrequency] = useState(2);
  const [metaRptFrequency, setMetaRptFrequency] = useState("Daily");
  const [periodText,setPeriodText] = useState("Current day");
  const [endDateVal, setEndDateval] = useState("Never");
  const [dateBegin, setDateBegin] = useState(2);
  const [dateEnd, setDateEnd] = useState(1);
  const [schedTime, setSchedTime] = useState("08:00");
  const [selDailyDays, setSelDailyDays] = useState([1]);
  const [selWeeklyDays, setSelWeeklyDays] = useState(1);
  const [selMonthlyDays, setSelMonthlyDays] = useState(1);
  const [monthlyDays, setMonthlyDays] = useState([1]);
  const [willExpire, setWillExpire] = useState(0);
  const [subscExpireDate, setSubscExpireDate] = useState(TodaysDate);
  const [emailTo, setEmailTo] = useState();
  const [emailArray, setEmailArray] = useState([]);
  const [description, setDescription] = useState("");
  const [dailyFreqSelVal, setDailyFreqSelVal] = useState([]);
  const [rptSubject, setRptEmailSubject] = useState("");
  const [rptText, setRptText] = useState("");
  const [fileType, setFileType] = useState("pdf");

  const onChangeDescValue = (e) => {
    setDescription(e.target.value)
  }
  const onChangeRptFileType = (e) => {
    setFileType(e.target.value)
  }

  const onChangeEmailSubj = (e) => {
    setRptEmailSubject(e.target.value)
  }

  const onChangeMessage = (e) => {
    setRptText(e.target.value)
  }

  const onChangeTimePicker = (e) => {
    setSchedTime(e.target.value)
  }

  const onChangeDailyDaysFreq = (e, value)=>{
    const values = value || [];
    const selVal = values.map((item) => item.value);
    setSelDailyDays(selVal);
    
    const selDays = values.map((item) => ({
        title: item.title,
        value: item.value,
      }));
    setDailyFreqSelVal(selDays);
 }
 const onChangeWeeklyDaysFreq = (e) => {
  setSelWeeklyDays(e.target.value);
 }
 const onChangeMonthlyDaysFreq = (e) => {
  setSelMonthlyDays(e.target.value);
  const array = [];
  array.push(e.target.value);
  setMonthlyDays(array);
 }
 const onChangeExpireDate = (e) =>{
  setSubscExpireDate(e.target.value);  
 }
  const onChangeRptEmailFreq = (e) => {
    setFrequency(e.target.value)
    if(e.target.value === "Daily"){
      setPeriodText("Current day");
      setRptFrequency(2);
      setMetaRptFrequency("Daily");
      setDateBegin(2);
      setDateEnd(1);
    }
    else if(e.target.value === "Weekly"){
      setPeriodText("Last 7 days");
      setRptFrequency(2);
      setMetaRptFrequency("Weekly");
      setDateBegin(8);
      setDateEnd(1);
    }
    else if(e.target.value === "Monthly"){
      setPeriodText("From same day last month");
      setRptFrequency(0);
      setMetaRptFrequency("Monthly");
      setDateBegin(32);
      setDateEnd(1);
    }
  }
  const onChangeEndDate = (e) => {
    setEndDateval(e.target.value);
    if(e.target.value === "Never")
      setWillExpire(0)
    else
      setWillExpire(1)
  }
  const onChangeEmailTo = (e) =>{
    setEmailTo(e.target.value);
  }

  const onEmailKeyDown = (e) =>{
    if (e.key === 'Enter') {
      setEmailArray(emailArray.concat([e.target.value]));
    }
    
  }
  const handleDelete = item => {
   setEmailArray(emailArray.filter(i => i !== item))
  };
  const onSaveDlg = async () => {
    let rptObj = {};
    rptObj.reportType = props.reportId;
    rptObj.reportNum = 0;
    rptObj.description = description;
    rptObj.enabled = true;
    rptObj.options = {};
    rptObj.options.emailText = rptText;
    rptObj.options.emailSubject = rptSubject;
    rptObj.options.intervalType = rptFrequency;
    rptObj.options.scheduledTime = schedTime;
    rptObj.options.monthDays = monthlyDays;
    if(frequency === 'Daily')
      rptObj.options.weekDays = selDailyDays;
    else if(frequency === 'Weekly')
      rptObj.options.weekDays = [selWeeklyDays];
    rptObj.options.intervalDays = [];
    rptObj.options.repeatMinutes = 0;
    rptObj.options.repeatDays = 0;
    rptObj.options.expireTime = "";
    rptObj.options.subscriptionStart = TodaysDate + "T00:00:00.000Z"; //"2020-02-21T00:00:00.000Z";
    if(willExpire === 0) {
      rptObj.options.subscriptionEnd = "";
    } else {
      rptObj.options.subscriptionEnd = subscExpireDate ; //"2020-02-21T00:00:00.000Z";
    }
    rptObj.options.dateBegin = dateBegin;
    rptObj.options.dateEnd = dateEnd;
    props.reportFilters.startTimeOfDay != null ? rptObj.options.startTimeOfDay = props.reportFilters.startTimeOfDay : rptObj.options.startTimeOfDay = "00:00";
    props.reportFilters.endTimeOfDay != null ? rptObj.options.endTimeOfDay = props.reportFilters.endTimeOfDay : rptObj.options.endTimeOfDay = "23:59";
    rptObj.options.businessHourFilter = props.reportFilters.businessHourFilter;
    rptObj.options.extList = props.reportFilters.extIds;
    rptObj.options.prefix = "";
    const callClassCodes = props.reportFilters.CallClassCode || [];
    const externalCallClassCodes = props.reportFilters.ExternalCallClassCode || [];
    rptObj.options.callClasses = callClassCodes;
    rptObj.options.externalCallClasses = externalCallClassCodes;
    rptObj.options.emailTo = emailArray;
    rptObj.options.divAIds = [];
    rptObj.options.divBIds = [];
    rptObj.options.divCIds = [];
    rptObj.options.divDIds = [];
    rptObj.options.divEIds = [];
    if(props.reportFilters.divAIds != null)
      rptObj.options.divAIds =  Array.from(props.reportFilters.divAIds.split(','),(n) => Number(n));  
    if(props.reportFilters.divBIds != null)
      rptObj.options.divBIds =  Array.from(props.reportFilters.divBIds.split(','),(n) => Number(n));  
    if(props.reportFilters.divCIds != null)
      rptObj.options.divCIds =  Array.from(props.reportFilters.divCIds.split(','),(n) => Number(n)); 
    if(props.reportFilters.divDIds != null)
      rptObj.options.divDIds =  Array.from(props.reportFilters.divDIds.split(','),(n) => Number(n)); 
    if(props.reportFilters.divEIds != null)
      rptObj.options.divEIds =  Array.from(props.reportFilters.divEIds.split(','),(n) => Number(n)); 
    rptObj.options.huntIds = props.reportFilters.huntGIds;
    rptObj.options.metaReportFrequency = metaRptFrequency;
    rptObj.options.metaReportSelDailyVal = dailyFreqSelVal;
    rptObj.options.metaReportSelMonthDayVal = selMonthlyDays;
    rptObj.options.metaSelExtObj =  props.reportFilters.selExtObj;
    rptObj.options.metaSelDivAObj = props.reportFilters.seldivAObj;
    rptObj.options.metaSelDivBObj = props.reportFilters.seldivBObj;
    rptObj.options.metaSelDivCObj = props.reportFilters.seldivCObj;

    rptObj.options.metaExternalCallClassObj = props.reportFilters.ExternalCallClassSelObj
    rptObj.options.metaSelHuntGObj = props.reportFilters.huntGroupSelObj;
    rptObj.options.dayOfWeekFilter = props.reportFilters.dayOfWeekFilter;
    rptObj.options.metaDayOfWeekFilterObj = props.reportFilters.dayOfWeekFilterSelObj;
    rptObj.options.fileFormat = fileType.toLowerCase();
    
    if(description === "" || emailArray.length === 0){
      alert("Description, email fields are required")
    }
    else{
      setDescription("");
      props.handleClose();
      try{      
        console.log(rptObj)

        const api = await getApi();
        const res = await api.postSchedReport(rptObj);
        if(res.status === 200) {
          console.log("success");
        return;
      }
        console.log("error"); 
        alert("Error");
      }
      catch(err) {
        console.log(err);
      } 
      
   } 
  }

  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")}
   // eslint-disable-next-line
  }, []);
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#1d8cf8',
      },
    },
  });
  return (
    <div>
    <MuiThemeProvider theme={THEME}>
    <Modal style={{position:"center", marginTop:"-250px"}} isOpen={props.open} toggle={props.handleClose} modalClassName={props.className}>
    <div className="modal-header">
      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.handleClose}>
        <i className="tim-icons icon-simple-remove" />
      </button>
      <h5 className="modal-title">Send Report Via Email</h5>
    </div>
    <ModalBody>   
    <Col sm="12">
     <div style={{marginLeft:"-20px" ,fontSize:"12px"}}>
        <Card>
          <CardBody >
           <Row style={{height:"830px"}}>
            <Row>
            <Col xs="12"> 
            <Form style={{width:"380px", marginLeft:"15px", marginTop:"-20px"}}> 
              <FormGroup className="position-relative" style={{marginTop:"5px"}}>    
              <Label for="description">Report Description:</Label>
              <Input type="text" name="description" id="description" placeholder="" style={{width:"380px"}} onChange={onChangeDescValue}/> 
              </FormGroup>     
              <FormGroup className="position-relative" style={{marginTop:"-15px"}}> 
              <EmailChips value={emailTo} items = {emailArray} onChange = {onChangeEmailTo} onKeyDown = {onEmailKeyDown} handleDelete = {handleDelete}/>     
              </FormGroup>
              <FormGroup className="position-relative" style={{marginTop:"5px"}}>    
                <Label for="subject">Subject:</Label>
                <Input type="text" name="subject" id="subject" placeholder="" style={{width:"380px"}} onChange = {onChangeEmailSubj}/>   
              </FormGroup>
              <FormGroup className="position-relative" style={{marginTop:"-15px"}}>  
                 <Label for="emailMsg">Message:</Label>
                 <Input type="textarea" name="emailMsg" id="emailMsg" onChange = {onChangeMessage} style={{width:"380px", height:"50px"}} />      
              </FormGroup>
              <FormGroup className="position-relative" style={{marginTop:"15px", marginLeft:"-10px"}}>
               <Col xs="12">  <SelectEmailFreq selVal={frequency} onChange={onChangeRptEmailFreq}/>    </Col>
              </FormGroup>      
              {frequency === 'Daily' &&
                <FormGroup className="position-relative" style={{marginTop:"15px", marginLeft:"-10px"}}>  
                 <Col xs="12">  <SelectMultDailyFreq label= "Generate on:" onChange = {onChangeDailyDaysFreq} selVal = {dailyFreqSelVal}/>    </Col>
                </FormGroup>   
              }   
              {frequency === 'Weekly' &&
                <FormGroup className="position-relative" style={{marginTop:"15px" , marginLeft:"-10px"}}>  
                 <Col xs="12">  <SelectSimpleWeeklyFreq selVal = {selWeeklyDays} onChange={onChangeWeeklyDaysFreq} />    </Col>
                </FormGroup>   
              }   
               {frequency === 'Monthly' &&
                <FormGroup className="position-relative" style={{marginTop:"15px", marginLeft:"-10px"}}>  
                 <Col xs="12">  <SelectSimpleMonthlyFreq selVal = {selMonthlyDays} onChange={onChangeMonthlyDaysFreq} />    </Col>
                </FormGroup>   
              }   
              <FormGroup className="position-relative" style={{marginTop:"15px", marginLeft:"-10px"}}>  
                <Label style={{marginLeft:"15px"}}>Report Period: {periodText}</Label> 
              </FormGroup>   
               <FormGroup className="position-relative" style={{marginTop:"25px", marginLeft:"-20px"}}>  
               <Col xs="12">  <TimePicker  activeModeClass="dark-content" label="Scheduled Time" value = {schedTime} onChange = {onChangeTimePicker}/> </Col>
              </FormGroup>
              <FormGroup className="position-relative" style={{marginTop:"15px", marginLeft:"-10px"}}>
                <Col xs="12">
                  <FormControl>
                    <InputLabel shrink id="condition-select">
                      File type:
                    </InputLabel>
                    <Select
                        value={fileType} onChange={onChangeRptFileType}
                        labelId="condition-label"
                        id="condition-select"
                        displayEmpty
                    >
                      <MenuItem value="pdf">PDF</MenuItem>
                      <MenuItem value="html">HTML</MenuItem>
                      <MenuItem value="csv">CSV</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
              </FormGroup>
              <FormGroup className="position-relative" style={{marginTop:"-10px", marginLeft:"-35px"}}>
              <Col xs="12"> <RadioBtnGroup  value = {endDateVal} onChange={onChangeEndDate} /> </Col>
               </FormGroup>
               {endDateVal !== "Never" &&
                <FormGroup className="position-relative" style={{marginTop:"-40px", marginLeft:"-10px"}}>
                <Col xs="12"> 
                 <DatePicker  
                  activeModeClass="dark-content"  
                  label="Specific Date "
                  date={subscExpireDate}
                  onChange={onChangeExpireDate}/> </Col>
                </FormGroup>
               }

            </Form>
              </Col>        
              </Row> 
              
           </Row>
          </CardBody>
        </Card>
    </div>
    </Col>           
    </ModalBody>
    <ModalFooter>
      <Button color="warning" onClick={props.handleClose}>Close</Button>
      <Button color="warning" onClick = {function(){ onSaveDlg().finally(); }}>Add New Subscription</Button>
    </ModalFooter>
    </Modal>
    </MuiThemeProvider>
    </div>
  );
}

export default SchedulerDialog;