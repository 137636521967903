/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/heading-has-content */
/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import HuntGroupsTable from "components/Tables/HuntGroupsTable.js";

class Assign extends React.Component {
  render() {
    return (
      <>
      <div className="content">
      <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <h5 className="card-category"></h5>
                  <CardTitle tag="h4">Add/Edit/Delete Hunt Groups</CardTitle>
                </Col>
                <Col sm="6">

                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div style={{height: "100%", width: "100%", marginTop:"-40px" }}>
              {document.body.classList.contains("white-content") &&           
                  <HuntGroupsTable activeModeClass="white-content"></HuntGroupsTable>
              }
              {!document.body.classList.contains("white-content") &&             
                  <HuntGroupsTable activeModeClass="dark-content"></HuntGroupsTable>
              }
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </div>
      </>
    );
  }
}

export default Assign;