/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { VectorMap } from "react-jvectormap";
import ApiCall from "Helper/Api.js";

import {useExpoApi} from "../../Helper/Api.js"

export default function CallingLocMap(props){
  const _isMounted = useRef(true);
  const [mapData, setMapData] = useState( 
  {  
    AU: 0,
    BR: 0,
    CA: 0,
    DE: 0,
    FR: 0,
    GB: 0,
    GE: 0,
    IN: 0,
    RO: 0,
    RU: 0,
    US: 0  
  }) ;
  const [mapMarkers, setMapMarkers] = useState([
    // {latLng: [43.4872, -112.0363], name: 'Idaho Falls', style: {fill: '#2dce89',stroke: '#383f47'}},
  ]);
  const [legendValues, setLegendValues] = useState([]);
  const [mapType, setMapType] = useState("us_aea");
  const getApi = useExpoApi();
  const apiCallRecentCalls = async ()=> {
    if (_isMounted.current) {
        const api = await getApi();
       api
         .getCallingLocationMap(props.filters)
         .then((response) => {
            console.log(response.data.callMarkerLoc)
            setMapMarkers(response.data.callMarkerLoc);  //wait until it is fixed
          }) 
         .catch((err) => {console.log(err)});   
    }
    };

    const getData = async () => {   
      try{
          let graphData = [];
          let values = [];
          const api = await getApi();
          const dataResponse = await api.getCallingLocationMap(props.filters);
          const json = await dataResponse.data;
          const parseGraphData = async(json) =>{
            let i = 0;
              json.callMarkerLoc.forEach((item) => {
                let graphSubItem = {}
                
                graphSubItem.latLng = item.latLng;
                graphSubItem.name = item.name;
                graphSubItem.totalCalls = item.totalCalls; 
                //showing only the top 10 loc in the map
                //if(i <= 10){
                  graphData.push(graphSubItem);
                  values.push(item.totalCalls);
                 
                //}       
                i++;
              })         
          }
          parseGraphData(json);  
          
          setMapMarkers(graphData)  ;
          //console.log(graphData)
          setLegendValues(values);
      }
      catch(err) {
          console.log(err);
       }     
  }

    React.useEffect(() => {  
      getData().catch();
     // apiCallRecentCalls().catch()
      const interval=setInterval(()=>{ 
        Array.from(document.getElementsByClassName("jvectormap-tip")).forEach((el) => { el.style.display = 'none' })
      },10000);
      return()=>clearInterval(interval) ;
           
    }, [props.totalCalls]); 
useLayoutEffect(() => {
 
}, []);
return (
   <div>
    <VectorMap
    map={'us_aea'} //world_mill //us_aea 
    backgroundColor="transparent"
    zoomOnScroll={false}
    containerStyle={{
        width: "100%",
        height: "390px"
    }}
    regionStyle={{
        initial: {
        fill: "#AAAAAA",
        "fill-opacity": 0.3,
        stroke: "none",
        "stroke-width": 0,
        "stroke-opacity": 0
        }
    }}
    
    markerStyle={{
        initial: {
          // fill: '#f3a4b5',
          // stroke: '#383f47',
          "stroke-width": 1,
          "stroke-opacity": 1,
          r: 3.5 
        }
    }}
    series={{
        regions: [
        {
            values: mapData,
            scale: ["#AAAAAA", "#444444"],
            normalizeFunction: "polynomial"
        }
        ],
        markers: [{
          attribute: 'fill',
          scale:  ['#93e3f1', '#8965e0'],        
          values:legendValues,
          min:1,
          max: 50,
          legend: {
            horizontal: true
          }
        },
        {
          attribute: 'r',
          scale: [3, 13],
          values: legendValues,
          min: 1,
          max: 50
        }],
       
    }}
    markers= {mapMarkers}
    onMarkerTipShow = { function(e, el, code){
      el.html(el.html()+' (Calls:'+legendValues[code]+')');
    }}
    
    />
   </div>
 );
}