/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";
// react plugin used to create google maps
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  InputGroup, 
  InputGroupAddon, 
  InputGroupText
} from "reactstrap";
import FlatFileImportExt from "components/ImportPlugins/ImportExtensions.jsx";
import FlatFileImportUser from "components/ImportPlugins/ImportUsers.jsx";
import FlatFileImportHuntGroup from "components/ImportPlugins/ImportHuntGroup.jsx";
import FlatFileImportDivA from "components/ImportPlugins/ImportDivA.jsx";
import FlatFileImportDivB from "components/ImportPlugins/ImportDivB.jsx";
import FlatFileImportDivC from "components/ImportPlugins/ImportDivC.jsx";
import FlatFileImportDivD from "components/ImportPlugins/ImportDivD.jsx";
import FlatFileImportDivE from "components/ImportPlugins/ImportDivE.jsx";

class Assign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templateType : "Ext",
      checkBoxExtChecked:true,
      checkBoxUserChecked:false,
      checkBoxHGChecked:false,
      divAChecked:false,
      divBChecked:false,
      divCChecked:false,
      divDChecked:false,
      divEChecked:false,
    }
  }
  templateOnChange = (selTemplate) => {
    this.setState({templateType:selTemplate})
    if(selTemplate === "Ext"){
      this.setState({checkBoxExtChecked:true})
      this.setState({checkBoxUserChecked:false})
      this.setState({checkBoxHGChecked:false})
      this.setState({divAChecked:false})
      this.setState({divBChecked:false})
      this.setState({divCChecked:false})
      this.setState({divDChecked:false})
      this.setState({divEChecked:false})
      
    }
    else if(selTemplate === "User"){
      this.setState({checkBoxUserChecked:true})
      this.setState({checkBoxExtChecked:false})
      this.setState({checkBoxHGChecked:false})
      this.setState({divAChecked:false})
      this.setState({divBChecked:false})
      this.setState({divCChecked:false})
      this.setState({divDChecked:false})
      this.setState({divEChecked:false})
    }
    else if(selTemplate === "Hunt Group"){
      this.setState({checkBoxUserChecked:false})
      this.setState({checkBoxExtChecked:false})
      this.setState({checkBoxHGChecked:true})
      this.setState({divAChecked:false})
      this.setState({divBChecked:false})
      this.setState({divCChecked:false})
      this.setState({divDChecked:false})
      this.setState({divEChecked:false})
    }
    else if(selTemplate === "DivA"){
      this.setState({checkBoxUserChecked:false})
      this.setState({checkBoxExtChecked:false})
      this.setState({checkBoxHGChecked:false})
      this.setState({divAChecked:true})
      this.setState({divBChecked:false})
      this.setState({divCChecked:false})
      this.setState({divDChecked:false})
      this.setState({divEChecked:false})
    }
    else if(selTemplate === "DivB"){
      this.setState({checkBoxUserChecked:false})
      this.setState({checkBoxExtChecked:false})
      this.setState({checkBoxHGChecked:false})
      this.setState({divAChecked:false})
      this.setState({divBChecked:true})
      this.setState({divCChecked:false})
      this.setState({divDChecked:false})
      this.setState({divEChecked:false})
    }
    else if(selTemplate === "DivC"){
      this.setState({checkBoxUserChecked:false})
      this.setState({checkBoxExtChecked:false})
      this.setState({checkBoxHGChecked:false})
      this.setState({divAChecked:false})
      this.setState({divBChecked:false})
      this.setState({divCChecked:true})
      this.setState({divDChecked:false})
      this.setState({divEChecked:false})
    }
    else if(selTemplate === "DivD"){
      this.setState({checkBoxUserChecked:false})
      this.setState({checkBoxExtChecked:false})
      this.setState({checkBoxHGChecked:false})
      this.setState({divAChecked:false})
      this.setState({divBChecked:false})
      this.setState({divCChecked:false})
      this.setState({divDChecked:true})
      this.setState({divEChecked:false})
    }
    else if(selTemplate === "DivE"){
      this.setState({checkBoxUserChecked:false})
      this.setState({checkBoxExtChecked:false})
      this.setState({checkBoxHGChecked:false})
      this.setState({divAChecked:false})
      this.setState({divBChecked:false})
      this.setState({divCChecked:false})
      this.setState({divDChecked:false})
      this.setState({divEChecked:true})
    }
  }
  render() {
    return (
      <>
      <div className="content">
        <Row>
        <Col xs="6">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <CardTitle tag="h2" style={{fontSize: "15px"}}>1- Select a Data Source Template</CardTitle>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
            <div style={{marginTop:"35px"}}>  
            <InputGroup onChange = {() => this.templateOnChange("Ext")}>
            <InputGroupAddon addonType="prepend">
            <InputGroupText>
            <Input addon type="checkbox" aria-label="" checked= {this.state.checkBoxExtChecked}/>
            </InputGroupText>
            </InputGroupAddon>
            <Input value="Import Extensions"  style={{fontSize: "15px"}} />
            </InputGroup>
            <br />
            <InputGroup onChange = {() => this.templateOnChange("User")}>
            <InputGroupAddon addonType="prepend">
            <InputGroupText>
            <Input addon type="checkbox" aria-label="" checked= {this.state.checkBoxUserChecked}/>
            </InputGroupText>
            </InputGroupAddon>
            <Input value="Import Web Users" style={{fontSize: "15px"}}  />
            </InputGroup>
            <br />
            <InputGroup onChange = {() => this.templateOnChange("Hunt Group")}>
            <InputGroupAddon addonType="prepend">
            <InputGroupText>
            <Input addon type="checkbox" aria-label="" checked= {this.state.checkBoxHGChecked}/>
            </InputGroupText>
            </InputGroupAddon>
            <Input value="Import Hunt Groups" style={{fontSize: "15px"}}  />
            </InputGroup>
            <br />
            <InputGroup onChange = {() => this.templateOnChange("DivA")}>
            <InputGroupAddon addonType="prepend">
            <InputGroupText>
            <Input addon type="checkbox" aria-label="" checked= {this.state.divAChecked}/>
            </InputGroupText>
            </InputGroupAddon>
            <Input value="Import Departments" style={{fontSize: "15px"}}  />
            </InputGroup>
            <br />
            <InputGroup onChange = {() => this.templateOnChange("DivB")}>
            <InputGroupAddon addonType="prepend">
            <InputGroupText>
            <Input addon type="checkbox" aria-label="" checked= {this.state.divBChecked}/>
            </InputGroupText>
            </InputGroupAddon>
            <Input value="Import Branches" style={{fontSize: "15px"}}  />
            </InputGroup>
            <br />
            <InputGroup onChange = {() => this.templateOnChange("DivC")}>
            <InputGroupAddon addonType="prepend">
            <InputGroupText>
            <Input addon type="checkbox" aria-label="" checked= {this.state.divCChecked}/>
            </InputGroupText>
            </InputGroupAddon>
            <Input value="Import Regions" style={{fontSize: "15px"}}  />
            </InputGroup>
            <br />
            <InputGroup onChange = {() => this.templateOnChange("DivD")}>
            <InputGroupAddon addonType="prepend">
            <InputGroupText>
            <Input addon type="checkbox" aria-label="" checked= {this.state.divDChecked}/>
            </InputGroupText>
            </InputGroupAddon>
            <Input value="Import Divisions" style={{fontSize: "15px"}}  />
            </InputGroup>
            <br />
            <InputGroup onChange = {() => this.templateOnChange("DivE")}>
            <InputGroupAddon addonType="prepend">
            <InputGroupText>
            <Input addon type="checkbox" aria-label="" checked= {this.state.divEChecked}/>
            </InputGroupText>
            </InputGroupAddon>
            <Input value="Import Organizations" style={{fontSize: "15px"}}  />
            </InputGroup>
            <br />
            {/*
            <InputGroup>
            <InputGroupAddon addonType="prepend">
            <InputGroupText>
            <Input addon type="checkbox" aria-label="" />
            </InputGroupText>
            </InputGroupAddon>
            <Input value="Service Charge Import Template" style={{fontSize: "15px"}}/>
            </InputGroup>
            <br />
            <InputGroup>
            <InputGroupAddon addonType="prepend">
            <InputGroupText>
            <Input addon type="checkbox" aria-label="" />
            </InputGroupText>
            </InputGroupAddon>
            <Input value="Caller Profile Import Template" style={{fontSize: "15px"}}/>
            </InputGroup>
            <br /> */}
            {/* <InputGroup>
            <InputGroupAddon addonType="prepend">
            <InputGroupText>
            <Input addon type="checkbox" aria-label="" />
            </InputGroupText>
            </InputGroupAddon>
            <Input value="Web User Import Template" style={{fontSize: "15px"}}/>
            </InputGroup>
            <br /> */}
    
            </div> 
            </CardBody>
          </Card>
        </Col>
        <Col xs="6">
        <Card className="card-chart">
          <CardHeader>
            <Row>
              <Col className="text-left" sm="6">
                <CardTitle tag="h2" style={{fontSize: "15px"}}>2- Launch Importer</CardTitle>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
          <div style={{marginTop:"35px"}}>  
            {this.state.templateType === "Ext" &&
              <FlatFileImportExt > </FlatFileImportExt>
            }
            {this.state.templateType === "User" &&
              <FlatFileImportUser > </FlatFileImportUser>
            }
            {this.state.templateType === "Hunt Group" &&
              <FlatFileImportHuntGroup > </FlatFileImportHuntGroup>
            }
            {this.state.templateType === "DivA" &&
              <FlatFileImportDivA > </FlatFileImportDivA>
            }
            {this.state.templateType === "DivB" &&
              <FlatFileImportDivB > </FlatFileImportDivB>
            }
            {this.state.templateType === "DivC" &&
              <FlatFileImportDivC > </FlatFileImportDivC>
            }
            {this.state.templateType === "DivD" &&
              <FlatFileImportDivC > </FlatFileImportDivC>
            }
            {this.state.templateType === "DivE" &&
              <FlatFileImportDivC > </FlatFileImportDivC>
            }
            
            
          </div> 
          </CardBody>
        </Card>
      </Col>
      </Row>

    </div>
      </>
    );
  }
}

export default Assign;