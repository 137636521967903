import React, {useEffect, useCallback} from "react";
import MaterialTable from "material-table";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { forwardRef, useState, useLayoutEffect, useRef} from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {useExpoApi} from "../../Helper/Api.js"
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const tableIcons = {
Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function AlarmTable(props) {
  const [columns, setRptColumns] = useState([
    { title: "Date", field: "date" },
    { title: "Time", field: "time" },
    { title: "Digits", field: "digits"} ,
    { title: "Duration", field: "duration"},
    { title: "Direction", field: "direction"},
  ]);

  const [isLoading, setLoading] = useState(false);
  const [rptData, setRptData] = useState([{ date:"", time:"",  digits:"", duration:"",  direction:""}]);
  
  const [themeType,setThemeType] = useState('dark');
  const getApi = useExpoApi();

  const currentRequest = useRef();

  const parseTableData = async(data) => {
    const headers = data.sections[0].headers;

    let dataObject = [];
    for(let i = 0; i < data.sections.length; ++i) {
        const labels = data.sections[i].labels || [];
        const itemFromRow = (row) => {
            const item = {};
            for(let j = 0; j < labels.length; ++j) {
                const [label, value] = labels[j].split(': ');
                item[label] = value;
            }
            for(let j = 0; j < headers.length; ++j) {
                item[headers[j]] = row[j];
            }
            return item;
        }

        dataObject = dataObject.concat(data.sections[i].rows.map(itemFromRow));
    }

    setRptData(dataObject);
  }

  const pdfExporter = useCallback((columns,data) => {
    const doc = new jsPDF({
      orientation:'l'
    }
    );

    // TODO add modal to allow the user to add a customer name
    // to the report
    let title = props.reportTitle;

    if(title === null){
      return;
    }

    if(title === ''){
      title= props.reportTitle;
    }
    const formatDate = (dNow) =>{
      let hours = (dNow.getHours()<10?'0':'') + dNow.getHours();
      let minutes = (dNow.getMinutes()<10?'0':'') + dNow.getMinutes();
      return ( ( dNow.getMonth() + 1 ) + '/' + dNow.getDate() + '/' + dNow.getFullYear() +
      ' ' + hours + ':' + minutes );
    }

    let dNow = new Date();
    doc.setFontSize(25);
    doc.text(20,20, title);

    // TODO put the daterange here
    doc.setFontSize(11);
    doc.text(20,27, `Generated On: ${formatDate(dNow)}`);

    let dateStart = new Date(props.reportFilters.timeRangeStart);
    let dateEnd = new Date(props.reportFilters.timeRangeEnd);
    doc.text(20,32, `Date Range: ${formatDate(dateStart)} to ${formatDate(dateEnd)}`);

    const columnTitles = columns
        .map(column => column.title);

    const pdfData = data.map(rowData =>
          columns.map(columnDef => rowData[columnDef.field]),
    );


    doc.autoTable({
       head: [columnTitles],
       body:pdfData,
       startY: 40,
       margin: 5,
       columnStyles:{ 0:{cellWidth:45}}
    });
    doc.save(`${title}.pdf`);
  }, [props.reportTitle, props.reportFilters]);

  useEffect(() => {
      const apiCallRecentCalls = async ()=> {
          setLoading(true);
          const api = await getApi();
          const request = api.getReportTables({ ...props.reportFilters, reportId: props.reportId, filetype:'json'});
          currentRequest.current = request;
              request.then((response) => {
                  //check if this call is still valid?
                  if(currentRequest.current !==  request) {
                      return;
                  }

                  setLoading(false);
                  if(!response.data.sections) {
                      setRptData([]);
                      return;
                  }

                  const labels = response.data.sections[0].labels || [];
                  const headers = [];
                  for(let i = 0; i < labels.length; ++i) {
                      const [label,] = labels[i].split(': ');
                      headers.push({title: label, field: label,
						  customSort: (a, b) => {
							  return a[label].localeCompare(b[label], undefined, {numeric: true, sensitivity: 'base', ignorePunctuation: true});
						  }
					  });
                  }
                  const normalHeaders = response.data.sections[0].headers.map((item) => {
					return {
					  title: item,
					  field: item,
					  align:'left',
					  customSort: (a, b) => {
						  return a[item].localeCompare(b[item], undefined, {numeric: true, sensitivity: 'base', ignorePunctuation: true});
					  }
					};
                  });

                  setRptColumns(headers.concat(normalHeaders));
                  parseTableData(response.data);
              })
              .catch((err) => {
                  console.log(err);
                  if(request === currentRequest.current) {
                  setLoading(false);}
              });
      };
      apiCallRecentCalls().catch();
  }, [props.reportFilters, props.reportId]);

  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")}
    
  }, []);
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
        color:'#9a9a9a',

      },
      secondary: {
        main: '#4caf50',
        color:'#9a9a9a',
      },
    },
  });
  return (
    <div >
      <MuiThemeProvider theme={THEME}>
        <MaterialTable 
          style= {{
            backgroundColor: 'transparent',
            color:'#fff',
            fontSize:'14px',
            boxShadow: 'none',
            width:"100%",
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          data={rptData}
          isLoading={isLoading}
          options={{
            headerStyle: {
            backgroundColor: "rgba(0,0,0,0)",  // #6ca6c2; //67b7dc          
            // fontWeight: 'bold',
            color:'#fff',  //#9a9a9a
            fontSize:'14px'
            },
            pageSize: 15,
            pageSizeOptions: [10, 15, 20, rptData.length],
            exportAllData :true,
            showTitle: false,
            exportButton: {
              csv: true,
              pdf: true,
           },
           exportPdf: pdfExporter
          }}
        
        />
      </MuiThemeProvider>
    </div>
  );
}
