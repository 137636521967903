import React from "react";
import ReactDOM from "react-dom";

import "assets/css/emailChipDarkM.css";
import {  
    Label, 
  } from 'reactstrap';

class EmailChips extends React.Component {
  state = {
    items: [],
    value: "",
    error: null
  };

  handleKeyDown = evt => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.props.value.trim();

      if (value && this.isValid(value)) {
        this.setState({
          items: [...this.props.items, this.props.value],
          value: ""
        });
      }
    }
  };

  handleChange = evt => {
    this.setState({
      value: evt.target.value,
      error: null
    });
  };

  handleDelete = item => {
    this.setState({
      items: this.props.items.filter(i => i !== item)
    });
  };

  handlePaste = evt => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter(email => !this.isInList(email));

      this.setState({
        items: [...this.props.items, ...toBeAdded]
      });
    }
  };

  isValid(email) {
    let error = null;

    if (this.isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!this.isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      this.setState({ error });

      return false;
    }

    return true;
  }

  isInList(email) {
    return this.props.items.includes(email);
  }

  isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  render() {
    return (
      <>
    <Label for="emailAdd">To:</Label>  
        {this.props.items.map(item => (
          <div className="tag-item" key={item}>
            {item}
            <button
              type="button"
              className="button"
              onClick={() => this.props.handleDelete(item)}
            >
              &times;
            </button>
          </div>
        ))}
        
        <input
          className={"input " + (this.state.error && " has-error")}
          id="emailAdd"
          value={this.props.value}
          placeholder="Type or paste email addresses and press `Enter`..."
          onKeyDown={this.props.onKeyDown}
          onChange={this.props.onChange}
          onPaste={this.handlePaste}
        />

        {this.state.error && <p className="error">{this.state.error}</p>}
      </>
    );
  }
}

export default EmailChips;
