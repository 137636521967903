import * as axios from "axios";
import React, {useState, createContext, useEffect} from 'react';
import {useAuth0} from "@auth0/auth0-react";

function array_to_vals(arr) {
    if(!arr) {
        return '';
    }

    return arr.map((arr) => (arr.value)).join();
}

function filters_to_query(f) {
    return `?timeRangeStart=${f.timeRangeStart || ''}&timeRangeEnd=${f.timeRangeEnd || ''}&divAIds=${f.divAIds || ''}&divBIds=${f.divBIds || ''}&divCIds=${f.divCIds|| ''}&externalCallTypes=${array_to_vals(f.externalCallTypes)}`;
}

class ApiClass {
    constructor(token) {
        this.client = axios.create({
            baseURL: '/api/v1'
        });
        this.client.defaults.headers.post['Content-Type'] = 'application/json';
        this.client.defaults.timeout = 14400;
        this.client.defaults.headers.Authorization = `Bearer ${token}`;
        this.iniPromise = null;
        this.ini = null;
    }

    getIni = async function(name) {
        if(this.ini != null) {
            return this.ini[name];
        }
        if(this.iniPromise == null) {
            this.iniPromise = this.client.get('/ini');
        }

        this.ini = (await this.iniPromise).data.ini;
        return this.ini[name];
    }

    getRecentCalls = async function(filters) {
        return await this.client.get(`/recentcalls${filters_to_query(filters)}`);
    };

    getCallsStatistics = async function(filters) {
        return await this.client.get(`/callsstatistics${filters_to_query(filters)}`);
    };

    getCallStatusStatistics = async function(filters) {
        return await this.client.get(`/callanalytics/callstatusstatistics${filters_to_query(filters)}`);
    };

    getCallClassStatistics = async function(filters) {
        return await this.client.get(`/callclassstatistics${filters_to_query(filters)}`);
    };

    getDivDrilldownData = async function(filters) {
        return await this.client.get(`/divcallsummary${filters_to_query(filters)}`);
    };

    getTopTenDepartments = async function(filters) {
        return await this.client.get(`/topdepartments${filters_to_query(filters)}`);
    };

    getDailyTrunkUtilization = async function(filters) {
        return await this.client.get(`/trunkusage/daily${filters_to_query(filters)}`);
    };

    getWeeklyTrunkUtilization = async function(filters) {
        return await this.client.get(`/trunkusage/weekly${filters_to_query(filters)}`);
    };

    getMonthlyTrunkUtilization = async function(filters) {
        return await this.client.get(`/trunkusage/monthly${filters_to_query(filters)}`);
    };

    getDailyCallsByHour = async function(filters) {
        return await this.client.get(`/dailyCallsByHour${filters_to_query(filters)}`);
    };

    getWeeklyCalls = async function(filters) {
        return await this.client.get(`/weeklyCalls${filters_to_query(filters)}`);
    };

    getMonthlyCalls = async function(filters) {
        return await this.client.get(`/monthlyCalls${filters_to_query(filters)}`);
    };

    getTopTenDialed = async function(filters) {
        return await this.client.get(`/topTenDialed${filters_to_query(filters)}`);
    };

    getTopTenReceived = async function(filters) {
        return await this.client.get(`/topTenReceived${filters_to_query(filters)}`);
    };

    getTopTenMostUsedExt = async function(filters) {
        return await this.client.get(`/topTenMostUsedExts${filters_to_query(filters)}`);
    };

    getTopTenMostExpensive = async function(filters) {
        return await this.client.get(`/topTenMostExpCalls${filters_to_query(filters)}`);
    };

    getRecentCallsLineChart = async function(filters) {
        return await this.client.get(`/recentCallsLineChart${filters_to_query(filters)}`);
    };

    getTopTenDialedChart = async function(filters) {
        return await this.client.get(`/topTenDialedChart${filters_to_query(filters)}`);
    };

    getTopTenMostUsedExtChart = async function(filters) {
        return await this.client.get(`/topTenMostUsedExtChart${filters_to_query(filters)}`);
    };

    getTopTenReceivedChart = async function(filters) {
        return await this.client.get(`/topTenReceivedChart${filters_to_query(filters)}`);
    };

    getTopTenMostExpChart = async function(filters) {
        return await this.client.get(`/topTenMostExpChart${filters_to_query(filters)}`);
    };

    getMostFreqDialedLoc = async function(filters) {
        return await this.client.get(`/mostFreqDialedLocations${filters_to_query(filters)}`);
    };

    getCallingLocationMap = async function(filters) {
        return await this.client.get(`/callingLocationMap${filters_to_query(filters)}`);
    };

    getCallingLocDetails = async function(filters) {
        return await this.client.get(`/callingLocDetails${filters_to_query(filters)}`);
    };

    getRealTimeData = async function() {
        return await this.client.get("/realTimeData");
    };

    getReportTables = async function(params) {
        return await this.client.post("/report/generated", params, {timeout: 60000});
    };

    getReportCallsBDHeatMapGraph = async function() {
        return await this.client.get("/reportCallsBDHeatMapGraph");
    };

    getReportGroupCallsByDayGraph = async function() {
        return await this.client.get("/reportGroupCallsByDayGraph");
    };

    getReportGroupCallsByUserGraph = async function() {
        return await this.client.get("/reportGroupCallsByUserGraph");
    };

    getReportDeptVolCostGraph = async function() {
        return await this.client.get("/reportDeptVolCostGraph");
    };

    getReportCallTypeVolCostGraph = async function() {
        return await this.client.get("/reportCallTypeVolCostGraph");
    };

    getTrunkCallsStatistics = async function() {
        return await this.client.get("/trunkCallsStatistics");
    };

    getReportTrunkConcCallsGraph = async function() {
        return await this.client.get("/reportTrunkConcCallsGraph");
    };

    getReportTrunkCallStatsGraph = async function() {
        return await this.client.get("/reportTrunkCallStatsGraph");
    };

    getReportTrunkUtilizGraph = async function() {
        return await this.client.get("/reportTrunkUtilizGraph");
    };

    getReportRouteCallsByDevGraph = async function() {
        return await this.client.get("/reportRouteCallsByDevGraph");
    };
 
    getReportRouteDevStatusGraph = async function() {
        return await this.client.get("/reportRouteDevStatusGraph");
    };

    getWebUserTable = async function() {
        return await this.client.get("/webUserTable");
    };

    getExtensionsTable = async function() {
        return await this.client.get("/extension");
    };

    createExtension = async function(extension) {
        const result = await this.client.post(`/extension`, extension);
        return result.data.id;
    };

    importExtension = async function(body) {
        return await this.client.post("/extension/import", body);
    };

    updateExtension = async function(id, extension) {
        return await this.client.put(`/extension/id/${id}`, extension);
    };

    deleteExtension = async function(id) {
        return await this.client.delete(`/extension/id/${id}`);
    };

    getDepartmentTable = async function() {
        return await this.client.get("/departmentTable");
    };

    getBranchTable = async function() {
        return await this.client.get("/BranchTable");
    };

    getRegionsTable = async function() {
        return await this.client.get("/regionsTable");
    };
    getBranchesTable = async () => {
        return await this.client.get("/branchesTable");
    };
    getOrganizationTable = async () => {
        return await this.client.get("/organizationTable");
    };
    getHuntGroupTable = async () => {
        return await this.client.get("/huntGroupTable");
    };
    getAccountCodeTable = async () => {
        return await this.client.get("/accountCodeTable");
    };
    getAuthCodeTable = async () => {
        return await this.client.get("/authCodeTable");
    };

   postMsTeamsApiToken = async (query) => {  
       return await this.client.post(`/source/msteams/token${query}`);
   };

    getWebUsers = async () => {
        return await this.client.get("/user");
    };
    postCreateWebUser = async (body) => {  
        return await this.client.post("/user", body);
    };

    postDeleteWebUser = async (id) => {  
        return await this.client.delete(`/user/id/${id}`);
    };

    postEditWebUser = async (body, id) => {  
        return await this.client.put(`/user/id/${id}`, body);
    };

    getWebUserRole = async () => {
        return await this.client.get("/role");
    };

    getDivs = async (divLevel) => {  
        return await this.client.get(`/div/level/${divLevel}`);
    };

    getSites = async () => {
        return await this.client.get(`/site`);
    };

    createNewDiv = async (body, divLevel) => {
        return await this.client.post(`/div/level/${divLevel}`, body);
    };

    postEditDiv = async (body, id) => {  
        return await this.client.put(`/div/id/${id}`, body);
    };

    postDeleteDiv = async (id) => {  
        return await this.client.delete(`/div/id/${id}`);
    };

    getSchedReports = async () => {
        return await this.client.get("/report/scheduled");
    };

    getSchedReportById = async (id) => {
        return await this.client.get(`/report/scheduled/id/${id}`);
    };

    deleteSchedReport = async (id) => {
        return await this.client.delete(`/report/scheduled/id/${id}`);
    };

    postEditSchedReport = async (body, id) => {
        return await this.client.put(`/report/scheduled/id/${id}`, body);
    };

    postSchedReport= async (body) => {  
        return await this.client.post("/report/scheduled", body);
    };

    getAllSchedRptDomain = async () => {
        return await this.client.get("/report/scheduled/domain");
    };

    getHuntGroups = async () => {
        return await this.client.get("/huntgroup");
    };

    addHuntGroup = async (body) => {
        return await this.client.post("/huntgroup", body);
    };
   
    editHuntGroup = async (id, body) => {
        return await this.client.put(`/huntgroup/id/${id}`, body);
    };
   
    deleteHuntGroup = async (id) => {
        return await this.client.delete(`/huntgroup/id/${id}`);
    };

    getDashboardSettings = async () => {
        return await this.client.get("/user/me/dashboard");
    }

    postDashboardSettings = async (body) => {
        return await this.client.put("/user/me/dashboard", body);
    }

    getAbandVsHandledCalls = async (filters) => {
        return await this.client.get(`/callanalytics/usagebystatus${filters_to_query(filters)}`);
    }

    getUserInfo = async () => {
        return await this.client.get("/user/me");
    }

    getRoleInfoById = async (id) => {
        return await this.client.get(`/role/id/${id}`);
    }

    getInteractions = async (filters) =>{
        const queryStr = `/interaction${filters_to_query(filters)}`;
        return await this.client.get(queryStr + `&extIds=${filters.extIds}`);
    }

    getInteractionDetails = async (filters) =>{
        let queryStr = `/interaction/detail${filters_to_query(filters)}`;
        queryStr+='&fromUser='+filters.fromUser+'&toUser='+filters.toUser;
        return await this.client.get(queryStr);
    }
}

export default ApiClass;
export const ExpoApiContext = createContext({
    token: null,
    divsA: [],
    divsB: [],
    divsC: [],
    divsD: [],
    divsE: [],
    sites: [],
    roles:[],  
});

export const ExpoApiProvider = ({children}) => {
    const [divsA, setDivsA] = useState([]);
    const [divsB, setDivsB] = useState([]);
    const [divsC, setDivsC] = useState([]);
    const [divsD, setDivsD] = useState([]);
    const [divsE, setDivsE] = useState([]);
    const [sites, setSites] = useState([]);
    const [roles, setRoles] = useState([]);
    const getApi = useExpoApi();

    useEffect(() => {
        (async () => {
            const api = await getApi();
            const divs = await Promise.all([
                api.getDivs('A'),
                api.getDivs('B'),
                api.getDivs('C'),
                api.getDivs('D'),
                api.getDivs('E'),
                api.getSites(),
                api.getWebUserRole(),
            ]);

            setDivsA(divs[0].data.divs);
            setDivsB(divs[1].data.divs);
            setDivsC(divs[2].data.divs);
            setDivsD(divs[3].data.divs);
            setDivsE(divs[4].data.divs);
            setSites(divs[5].data.sites);
            setRoles(divs[6].data);
        })();
    }, []);

    return(<ExpoApiContext.Provider
        value={{ divsA, divsB, divsC, divsD, divsE, sites, roles}}>
        {children}
    </ExpoApiContext.Provider>);
}

export function useExpoApi() {
    const {getAccessTokenSilently} = useAuth0();

    return (async () => {
        const accessToken = await getAccessTokenSilently();
        return new ApiClass(accessToken)
    })
}
