import React from "react";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { forwardRef, useState, useLayoutEffect,useRef} from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ApiCall from "Helper/Api.js";
import {useExpoApi} from "../../Helper/Api.js"

const tableIcons = {
Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function CallDetailTable(props) {
  const _isMounted = useRef(true);
  const[recentCalls, setRecentCalls] = useState([]) ;
  const [columns, setColumns] = useState( [
    { title: "Date", field: "date" },
    { title: "Time", field: "time" },
    { title: "Ext", field: "ext" },
    { title: "Digits", field: "digits" },
    { title: "Location", field: "location"} ,
    { title: "Duration", field: "duration"} ,
    { title: "Dir.", field: "direction"} 
  ]) ;
  const [tableData, setTableData] = useState([
    { date:"09/04/20", time:"10:09:00", ext:"344", digits:"2054610209", location:"Idaho Falls", duration:"00:20:00", direction:"I" },

  ]);
   
  const [themeType,setThemeType] = useState('dark');

    const getApi = useExpoApi();
    const apiCallRecentCalls = async ()=> {
    if (_isMounted.current) {
      const api = await getApi();
       api
         .getCallingLocDetails(props.filters)
         .then((response) => {
            parseApiData(response.data.calls);
          }) 
         .catch((err) => {console.log(err)}); 
     }  
     
    };
    const parseApiData = async(data) => {
      let dataObject = [];   
      data.map((dataItem)=> {
        let newItem = {};
        let completeDateStr = dataItem.date;
        newItem.date = completeDateStr.substring(0,10);
        newItem.time = completeDateStr.substring(11,19);
        newItem.ext = dataItem.ext; 
        newItem.digits = dataItem.digits; 
        newItem.location = dataItem.location; 
        newItem.duration = dataItem.duration; 
        newItem.direction = dataItem.direction;
        dataObject.push(newItem);
      });
      setRecentCalls(dataObject);
    }
    React.useEffect(() => {  
      apiCallRecentCalls();          
    }, [props.totalCalls]); 

  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")}
  }, []);
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#4caf50',
      },
    },
  });
  return (
    <div>
      <MuiThemeProvider theme={THEME}>
      
          <MaterialTable
            style= {{
                backgroundColor: 'transparent',
                boxShadow: 'none',
            }}
            icons={tableIcons}
            title=""
            columns={columns}
            data={recentCalls}
            options={{
                headerStyle: {
                  backgroundColor: "rgba(0,0,0,0)",  // #6ca6c2; //67b7dc          
                  fontWeight: 'bold'
                },
                filtering: false
              }}
            editable={{
  
            }}
          />
    
      </MuiThemeProvider>
    </div>
  );
}
