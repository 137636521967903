/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ApiCall from "Helper/Api.js";

import {useExpoApi} from "../../Helper/Api.js"
am4core.options.autoDispose = true;
export default function App(props) {
  const getApi = useExpoApi();

  useLayoutEffect(() => {
    am4core.unuseAllThemes();
    if(props.activeModeClass === "white-content"){  
        am4core.unuseTheme(am4themes_dark);
    }
    else{
        am4core.useTheme(am4themes_dark);
    }
    am4core.useTheme(am4themes_animated);
    am4core.addLicense("CH277440643");
    let chart = am4core.create("HeatMapChartDiv", am4charts.XYChart);
    chart.maskBullets = false;

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = "left";
    chart.exporting.menu.verticalAlign = "bottom";
    am4core.color("#f00", 0);

    const getData = async () => {
    try{
      const api = await getApi();
      const dataResponse = await api.getWeeklyCalls(props.filters)
      const json = await dataResponse.data;
      const graphData = [];
      const weekday = new Array(7);
      weekday[0] = "Sun";
      weekday[1] = "Mon";
      weekday[2] = "Tue";
      weekday[3] = "Wed";
      weekday[4] = "Thu";
      weekday[5] = "Fri";
      weekday[6] = "Sat";
      let dataParsedObj = [];
      const parseGraphData = async(jsonOnbj) => {
        const display = Object.keys(jsonOnbj).map(key =>
        {
          dataParsedObj = jsonOnbj[key];     
        }
        )     
        dataParsedObj.map((item)=>{
          let objItem = {};
          let dateTimeStr = item.date;
       
          let totalCalls = item.calls;

          var date = new Date(dateTimeStr);
          let year =  date.getFullYear();
          let month = date.getMonth() + 1;
          let day = date.getDate();
          let hour = date.getHours();
          let minute = 0;
          
          let dayOfWeek = weekday[date.getDay()];
          if(hour < 13)
           hour = hour + "am";
          else
           hour = hour + "pm";      
          objItem.hour = hour;
          objItem.weekday = dayOfWeek;
          objItem.value = totalCalls;
          graphData.push(objItem);
        })      
      }
      parseGraphData(json);

      chart.data = graphData;
      console.log(graphData);
      
      }catch(err) {
      console.log(err);
      } 
    }
  
    let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      let yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

      xAxis.dataFields.category = "weekday";
      yAxis.dataFields.category = "hour";

      xAxis.renderer.grid.template.disabled = true;
      xAxis.renderer.minGridDistance = 40;

      yAxis.renderer.grid.template.disabled = true;
      yAxis.renderer.inversed = true;
      yAxis.renderer.minGridDistance = 30;

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "weekday";
      series.dataFields.categoryY = "hour";
      series.dataFields.value = "value";
      series.sequencedInterpolation = true;
      series.defaultState.transitionDuration = 3000;

      let bgColor = new am4core.InterfaceColorSet().getFor("background");

      let columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 1;
      columnTemplate.strokeOpacity = 0.2;
      columnTemplate.stroke = bgColor;
      columnTemplate.tooltipText =
      "{weekday}, {hour}: {value.workingValue.formatNumber('#.')}";
      columnTemplate.width = am4core.percent(100);
      columnTemplate.height = am4core.percent(100);

      series.heatRules.push({
      target: columnTemplate,
      property: "fill",
      min: am4core.color(bgColor),
      max: chart.colors.getIndex(0)
      });

      // heat legend
      let heatLegend = chart.bottomAxesContainer.createChild(
      am4charts.HeatLegend
      );
      heatLegend.width = am4core.percent(100);
      heatLegend.series = series;
      heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
      heatLegend.valueAxis.renderer.minGridDistance = 30;

      // heat legend behavior
      series.columns.template.events.on("over", function(event) {
      handleHover(event.target);
      });

      series.columns.template.events.on("hit", function(event) {
      handleHover(event.target);
      });

      function handleHover(column) {
      if (!isNaN(column.dataItem.value)) {
      heatLegend.valueAxis.showTooltipAt(column.dataItem.value);
      } else {
      heatLegend.valueAxis.hideTooltip();
      }
      }

      series.columns.template.events.on("out", function(event) {
      heatLegend.valueAxis.hideTooltip();
      });
      
      getData();

    }, [props.totalCalls]);
    return (
      <div id="HeatMapChartDiv" style={{ width: "100%", height: "100%" }}></div>
    );
}
