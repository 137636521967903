/* eslint-disable no-undef */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-vars */
import React, {useRef, useLayoutEffect} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ApiCall from "Helper/Api.js";
import {useExpoApi} from "../../Helper/Api.js"

am4core.options.autoDispose = true;
export default function App(props) {
    const getApi = useExpoApi();

    let totalCallsNew = 0;

    useLayoutEffect(() => {
        am4core.unuseAllThemes();
        if (props.activeModeClass === "white-content") {
            am4core.unuseTheme(am4themes_dark);
        } else {
            am4core.useTheme(am4themes_dark);
        }
        am4core.useTheme(am4themes_animated);
        am4core.addLicense("CH277440643");    
        let chart = am4core.create("barChartDiv", am4charts.XYChart);
        chart.padding(5, 10, 10, 10);

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "left";
        chart.exporting.menu.verticalAlign = "bottom";
        am4core.color("#f00", 0);
        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "Department";
        categoryAxis.renderer.minGridDistance = 1;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.disabled = true;

        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;

        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = "Department";
        series.dataFields.valueX = "Calls";
        series.columns.template.propertyFields.fill = 'color';
        series.tooltipText = "{valueX.value}"
        series.columns.template.strokeOpacity = 0;
        series.columns.template.column.cornerRadiusBottomRight = 5;
        series.columns.template.column.cornerRadiusTopRight = 5;

        let labelBullet = series.bullets.push(new am4charts.LabelBullet())
        labelBullet.label.horizontalCenter = "left";
        labelBullet.label.dx = 10;
        labelBullet.label.text = "{values.valueX.workingValue}";
        labelBullet.locationX = 1;
        labelBullet.label.truncate = false;

        // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
        series.columns.template.adapter.add("fill", function (fill, target) {
            return chart.colors.getIndex(target.dataItem.index);
        });

        categoryAxis.sortBySeries = series;
        // Modify chart's colors
        chart.colors.list = [
            am4core.color("#5e72e4"),
            am4core.color("#1d8cf8"),
            am4core.color("#1d8cf8"),
            am4core.color("#11cdef"),
            am4core.color("#11cdef"),
            am4core.color("#11cdef"),
            am4core.color("#11cdef"),
            am4core.color("#11cdef"),
            am4core.color("#11cdef"),
            am4core.color("#11cdef")
        ];

        const getData = async () => {
            const api = await getApi();
            api
                .getTopTenDepartments(props.filters)
                .then((response) => {
                    chart.data = response.data.topDepartments;

                })
                .catch((err) => {console.log(err)}); 

            //chart.data = props.data
        }
        getData();
   
    }, [props.totalCalls]);
    return (
        <div id="barChartDiv" style={{width: "100%", height: "100%"}} />
    );
}
