import {Button, Col, Row} from "reactstrap";
import DateTimePicker from "../MaterialUI/DateTimePicker_MonthlyChart";
import React, { useState, useRef } from "react";
import CheckBoxList from "components/OthersCharts/CheckBoxList/multi-select-check.js";
import CheckBoxListExt from "components/OthersCharts/CheckBoxList/multi-select-check-ext.js";
import CheckBoxListCallType from "components/OthersCharts/CheckBoxList/multi-select-check-callTypes.js";
import CheckBoxExternalCallType from "components/OthersCharts/CheckBoxList/multi-select-check-externalCallTypes.js";
import HuntGSelectComp from "components/OthersCharts/CheckBoxList/multi-select-check-huntG.js";
import AdvRptFilterDlg from "components/MaterialUI/Dialogs/AdvReportFilter_Dialog.js";
import ApiCall from "Helper/Api.js";
import dayjs from "dayjs";
import {useExpoApi} from "../../Helper/Api.js"

export default function ReportFilterForm(params) {
    const getApi = useExpoApi();
    const[divAIds, setDivAIds] = useState([])
    const[divBIds, setDivBIds] = useState([])
    const[divCIds, setDivCIds] = useState([])
    const[extData, setExtData] = useState([])
    const[huntGData, setHuntGData] = useState([])
    const[callTypeData, setCallTypeData] = useState([])
    const [externalCallTypes, setExternalCallTypes] = useState([]);
    const[totalSelDivA, setTotalSelDivA] = useState(0)
    const[totalSelDivB, setTotalSelDivB] = useState(0)
    const[totalSelDivC, setTotalSelDivC] = useState(0)
    const[totalSelExt, setTotalSelExt] = useState(0)
    const[totalSelHG, setTotalSelHG] = useState(0)
    const[totalDivA, setTotalDivA] = useState(0)
    const[totalDivB, setTotalDivB] = useState(0)
    const[totalDivC, setTotalDivC] = useState(0)
    const[totalExts, setTotalExts] = useState(0)
    const [advDlgOpen, setAdvDlgOpen] = useState(false);
    const [weekendsSwitchEn, setWeekendsSwitchEn] = useState(false);
    const [daysOfWeekSelVal, setDaysOfWeekSelVal] = useState([]);
    const [timeBegin, setTimeBegin] = useState("00:00");
    const [timeEnd, setTimeEnd] = useState("23:59");
    const [businessHourFilter, setBusinessHourFilter] = useState(false);
    const timeRangeStart = useRef(params.timeRangeStart);
    const timeRangeEnd = useRef(params.timeRangeEnd);

    React.useEffect(() => {
        const getTotals = async () =>{
            const api = await getApi();
            const result = await api.getDivs('A');
            const totalDivsA = result.data.divs.length;
            setTotalDivA(totalDivsA)

            const result1 = await api.getDivs('B');
            const totalDivsB = result1.data.divs.length;
            setTotalDivB(totalDivsB)

            const result2 = await api.getDivs('C');
            const totalDivsC = result2.data.divs.length;
            setTotalDivC(totalDivsC)

            const result3 = await api.getExtensionsTable();
            const totalExt = result3.data.extensions.length;
            setTotalExts(totalExt);
        }
        getTotals().finally();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDivAOnChange = async (obj) =>{
        setDivAIds(obj)
        let divs = "";

        if(totalDivA === obj.length){
            setTotalSelDivA(0);
        } else{
            divs = obj.map((item) => item.value).join();
            setTotalSelDivA(obj.length);
        }
 
        params.onChange && params.onChange('divAIds', divs);
        const divArray = obj.map((item) => ({
            label: item.label,
            value: item.value,
        }));

        params.onChange && params.onChange('seldivAObj', divArray);
    
    }
    const handleDivBOnChange = async (obj) => {
     
        setDivBIds(obj)
        let divs = "";

        if(totalDivB === obj.length){
            setTotalSelDivB(0);
        }
        else{
            divs = obj.map((item) => item.value).join();
            setTotalSelDivB(obj.length);
        }
 
        params.onChange && params.onChange('divBIds', divs);
        const divArray = obj.map((item) => ({
            label: item.label,
            value: item.value,
        }));
        params.onChange && params.onChange('seldivBObj', divArray);
    }
    const handleDivCOnChange = async (obj)=>{
      
        setDivCIds(obj)
        let divs = "";

        if(totalDivC === obj.length){
            setTotalSelDivC(0);
        }
        else{
            divs = obj.map((item) => item.value).join();
            setTotalSelDivC(obj.length);
        }
 
        params.onChange && params.onChange('divCIds', divs);
        const divArray = obj.map((item) => ({
            label: item.label,
            value: item.value,
        }));
        params.onChange && params.onChange('seldivCObj', divArray);
    }

    const handleExtOnChange = async (obj)=>{
      
        const extArray = obj.map((item) =>
                ({
                    label: item.label,
                    value: item.value,
                }));

        setExtData(extArray);
        let exts = "";
        
        if(totalExts === obj.length){
            setTotalSelExt(0);
        }
        else{
            exts = obj.map((item) => item.value).join();
            setTotalSelExt(obj.length);   
        }
       
        params.onChange && params.onChange('extIds', exts);
        params.onChange && params.onChange('selExtObj', extArray);
        console.log('extension ids:');
        console.log(exts);
        console.log('extension ids:');
        console.log(extArray);
    }

    const handleHuntGOnChange = async (obj)=>{
        const api = await getApi();
        const result = await api.getHuntGroups();
        const totalHuntG = result.data.huntGroups.length;
        let exts = "";
        if(totalHuntG === obj.length){
            setTotalSelHG(0);
        }
        else{
            setTotalSelHG(obj.length);
            exts = obj.map((item) => item.value).join();
        }
        setHuntGData(obj);
        params.onChange && params.onChange('huntGIds', exts);
        const hutGItemsArray = obj.map((item) => ({
            label: item.label,
            value: item.value,
        }));

        params.onChange && params.onChange('huntGroupSelObj', hutGItemsArray);
        
    }

    const handleCallClassOnChange = (obj)=>{
        setCallTypeData(obj);
        const callClassArray = obj.map((item) => item.value);
        params.onChange && params.onChange ('CallClassCode', callClassArray);

        const callTypeArray = obj.map((item)=> ({label: item.label, value: item.value}));
        params.onChange && params.onChange('callClastSelObj', callTypeArray);
    }

    const handleExternalCallClassOnChange = (obj)=>{
        setExternalCallTypes(obj);
        const callClassArray = obj.map((item) => item.value);
        params.onChange && params.onChange ('ExternalCallClassCode', callClassArray);

        const callTypeArray = obj.map((item)=> ({label: item.label, value: item.value}));
        params.onChange && params.onChange('ExternalCallClassSelObj', callTypeArray);
    }

    const handleSwitchOnChange = (event) =>{
        setWeekendsSwitchEn(event.target.checked);
    }

    const onChangeDaysOfWeek = (e, value)=>{
        const selVal = Array.prototype.map.call(value, function(item) { return item.value; }).join(",");
        params.onChange && params.onChange('dayOfWeekFilter', selVal.split(','));
        
        const selDays = value.map((item)=> ({ title: item.title, value: item.value, }));
        setDaysOfWeekSelVal(selDays);

        params.onChange && params.onChange('dayOfWeekFilterSelObj', selDays);
     }

     const onChangeFromTimePicker = (e) => {
        setTimeBegin(e.target.value);
        params.onChange && params.onChange('startTimeOfDay', e.target.value);
      }

      const onChangeToTimePicker = (e) => {
        setTimeEnd(e.target.value);
        params.onChange && params.onChange('endTimeOfDay', e.target.value);
      }

    const onChangeBusinessHourFilter = (e) => {
        setBusinessHourFilter(e.target.checked);
        params.onChange && params.onChange('businessHourFilter', e.target.checked);
    }

    const handleTimeRangeStartChange = (event) => {
        let isoDateString = new Date(event.target.value).toISOString();
        timeRangeStart.current = isoDateString;
        params.onChange && params.onChange('timeRangeStart', isoDateString);
    }

    const handleTimeRangeEndChange = (event) => {
        let isoDateString = new Date(event.target.value).toISOString();
        timeRangeEnd.current = isoDateString;
        params.onChange && params.onChange('timeRangeEnd', isoDateString);
    }
    //
    return <div style={{height: '350px', marginTop: '-20px', marginLeft: '20px'}}>
        <AdvRptFilterDlg 
            AdvFilterDlgOnOpen= {advDlgOpen} 
            AdvFilterDlgOnClose={()=>{setAdvDlgOpen(false)}} 
            className= "modal-black" 
            switchEn = {weekendsSwitchEn}
            OnOffSwitch = {handleSwitchOnChange}
            daysOfWeek = {daysOfWeekSelVal}
            onChangeDaysOfWeek = {onChangeDaysOfWeek}
            timeBegin = {timeBegin}
            onChangeFromTimePicker = {onChangeFromTimePicker}
            timeEnd = {timeEnd}
            onChangeToTimePicker = {onChangeToTimePicker}
            businessHourFilter = {businessHourFilter}
            onChangeBusinessHourFilter = {onChangeBusinessHourFilter}
            />
        <Row>
            <Col sm="4">
                <CheckBoxList onChange = {(obj)=>handleDivAOnChange(obj)} value= {divAIds} level="A" label="Select Department(s)" activeModeClass="dark-content" />
            </Col>
        </Row>
        <Row>
            <Col sm="4">
                <CheckBoxListExt onChange = {(obj)=>handleExtOnChange(obj)} value= {extData} level="" label="Select User(s)" activeModeClass="dark-content" />
            </Col>
        </Row>
        <Row style={{marginTop: '0px', marginLeft: '-20px'}}>
            <Col sm="5" style={{paddingTop: '25px'}}> <DateTimePicker onChange={handleTimeRangeStartChange} date={params.timeRangeStart}
                                         label="Date Range From:" activeModeClass=''/> </Col>
            <Col sm="5" style={{paddingTop: '25px'}}> <DateTimePicker onChange={handleTimeRangeEndChange} date={params.timeRangeEnd}
                                         label="To:" activeModeClass=''/> </Col>
        </Row>
        <Row style={{marginTop: '25px'}}>
            <Col sm="6">
                <Button color="warning" 
                    onClick={() => {
                        if(totalSelDivA > 20 || totalSelDivB > 20 || totalSelDivC > 20 || totalSelExt > 20 || totalSelHG > 20 )
                        { 
                            alert("Only 20 items can be selected") 
                        } 
                        else if ( (dayjs(timeRangeEnd.current).diff(timeRangeStart.current, 'days') > 31) ){

                            alert("Time range can not be greater than 31 days") 
                        }
                        else{ params.onApply && params.onApply() } 
                        console.log("time ragne start: ");
                        console.log(timeRangeStart.current);
                        console.log(timeRangeEnd.current);
                        console.log(dayjs(timeRangeStart.current).diff(timeRangeEnd.current, 'days') )

                    }}>
                Apply</Button>{' '}
            </Col>
        </Row>
    </div>
}
