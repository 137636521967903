import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Row,
  Col,
} from "reactstrap";

import SelectLocalRateTable from "components/MaterialUI/SelectSimpleLocRateTable.js";
import SelectLocalTRateTable from "components/MaterialUI/SelectSimpleLocTRateTable.js";
import SelectInStRateTable from "components/MaterialUI/SelectSimpleInStRateTable.js";
import SelectLongDRateTable from "components/MaterialUI/SelectSimpleLongDRateTable.js";
import SelectIntRateTable from "components/MaterialUI/SelectSimpleIntRateTable.js";
import TextField from '@material-ui/core/TextField';

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: ["collapseOne","collapseTwo", "collapseThree", "collapseFour", "collapseFive"],
      localRateTableSel: 13,
      localTRateTableSel: 13,
      inStateRateTableSel: 13,
      longDistRateTableSel: 13,
      intRateTableSel: 13,
      isLocalPerMin2StepEn: false,
      isLocalPerMin3StepEn: false,
      isLocalTPerMin2StepEn: false,
      isLocalTPerMin3StepEn: false,
      isInStatePerMin2StepEn: false,
      isInStatePerMin3StepEn: false,
      isLondDistPerMin2StepEn: false,
      isLondDistPerMin3StepEn: false,
      isIntPerMin2StepEn: false,
      isIntPerMin3StepEn: false,
      mdVal: 1,
      isSurchargeLocalEn : false,
      isSurchargeLocalTEn : false,
      isSurchargeInStateEn : false,
      isSurchargeLongDistEn: false,
      isSurchargeIntEn : false,
    };
  }

  onSelectLocalRateTable = (e) => {
    this.setState({localRateTableSel: e.target.value});
    switch(e.target.value) {
      case 13:{
        this.setState({isLocalPerMin2StepEn: false});
        this.setState({isLocalPerMin3StepEn: false});
        this.setState({isSurchargeLocalEn: false});
        this.setState({mdVal: 1});
      }break;
      case 14:{
        this.setState({isLocalPerMin2StepEn: true});
        this.setState({isLocalPerMin3StepEn: false});
        this.setState({isSurchargeLocalEn: false});
        this.setState({mdVal: 1});
      }break;
      case 15:{
        this.setState({isLocalPerMin2StepEn: true});
        this.setState({isLocalPerMin3StepEn: true});
        this.setState({isSurchargeLocalEn: false});
        this.setState({mdVal: 1});
      }  break;
      default:
      {
        this.setState({isSurchargeLocalEn: true})
        this.setState({mdVal: 6})
      }
      break;
    }
   
  }

  onSelectLocalTRateTable = (e) => {
    this.setState({localTRateTableSel: e.target.value})
    switch(e.target.value) {
      case 13:{
        this.setState({isLocalTPerMin2StepEn: false});
        this.setState({isLocalTPerMin3StepEn: false});
        this.setState({isSurchargeLocalTEn: false});
        this.setState({mdVal: 1});
      }break;
      case 14:{
        this.setState({isLocalTPerMin2StepEn: true});
        this.setState({isLocalTPerMin3StepEn: false});
        this.setState({isSurchargeLocalTEn: false});
        this.setState({mdVal: 1});
      }break;
      case 15:{
        this.setState({isLocalTPerMin2StepEn: true});
        this.setState({isLocalTPerMin3StepEn: true});
        this.setState({isSurchargeLocalTEn: false});
        this.setState({mdVal: 1});
      }  break;   
      default:
      {
        this.setState({isSurchargeLocalTEn: true});
        this.setState({mdVal: 6});
      }
      break;  
    }
  }

  onSelectInStateRateTable = (e) => {
    this.setState({inStateRateTableSel: e.target.value})
    switch(e.target.value) {
      case 13:{
        this.setState({isInStatePerMin2StepEn: false});
        this.setState({isInStatePerMin3StepEn: false});
        this.setState({isSurchargeInStateEn: false});
        this.setState({mdVal: 1});
      }break;
      case 14:{
        this.setState({isInStatePerMin2StepEn: true});
        this.setState({isInStatePerMin3StepEn: false});
        this.setState({isSurchargeInStateEn: false});
        this.setState({mdVal: 1});
      }break;
      case 15:{
        this.setState({isInStatePerMin2StepEn: true});
        this.setState({isInStatePerMin3StepEn: true});
        this.setState({isSurchargeInStateEn: false});
        this.setState({mdVal: 1});
      }  break;  
      default:
      {
        this.setState({isSurchargeInStateEn: true});
        this.setState({mdVal: 6});
      }
      break;     
    }
  }

  onSelectLondDistRateTable = (e) => {
    this.setState({longDistRateTableSel: e.target.value})
    switch(e.target.value) {
      case 13:{
        this.setState({isLondDistPerMin2StepEn: false});
        this.setState({isLondDistPerMin3StepEn: false});
        this.setState({isSurchargeLongDistEn: false});
        this.setState({mdVal: 1});
      }break;
      case 14:{
        this.setState({isLondDistPerMin2StepEn: true});
        this.setState({isLondDistPerMin3StepEn: false});
        this.setState({isSurchargeLongDistEn: false});
        this.setState({mdVal: 1});
      }break;
      case 15:{
        this.setState({isLondDistPerMin2StepEn: true});
        this.setState({isLondDistPerMin3StepEn: true});
        this.setState({isSurchargeLongDistEn: false});
        this.setState({mdVal: 1});
      }  break;  
      default:
      {
        this.setState({isSurchargeLongDistEn: true});
        this.setState({mdVal: 6});
      }
      break;        
    }
  }

  onSelectInternRateTable = (e) => {
    this.setState({intRateTableSel: e.target.value})
    switch(e.target.value) {
      case 13:{
        this.setState({isIntPerMin2StepEn: false});
        this.setState({isIntPerMin3StepEn: false});
        this.setState({isSurchargeIntEn: false});
        this.setState({mdVal: 1});
      }break;
      case 14:{
        this.setState({isIntPerMin2StepEn: true});
        this.setState({isIntPerMin3StepEn: false});
        this.setState({isSurchargeIntEn: false});
        this.setState({mdVal: 1});
      }break;
      case 15:{
        this.setState({isIntPerMin2StepEn: true});
        this.setState({isIntPerMin3StepEn: true});
        this.setState({isSurchargeIntEn: false});
        this.setState({mdVal: 1});
      }  break;     
      default:
        {
          this.setState({isSurchargeIntEn: true});
          this.setState({mdVal: 6});
        }
        break;        
    }
  }
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: []
      });
    } else {
      this.setState({
        openedCollapses: [collapse]
      });
    }
  };
  render() {
    return (
      <>
        <div
          aria-multiselectable={true}
          className="card-collapse"
          id="accordion"
          role="tablist"
        >
          <Card className="card-plain">
            <CardHeader role="tab">
              <a
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseOne"
                )}
                href="#pablo"
                data-parent="#accordion"
                data-toggle="collapse"
                onClick={(e) => {e.preventDefault();}}
              >
                Local{" "}
                {/* <i className="tim-icons icon-minimal-down" /> */}
              </a>
            </CardHeader>
            <Collapse
              role="tabpanel"
              isOpen={this.state.openedCollapses.includes("collapseOne")}
            >
              <CardBody>
                <Row style={{height:"70px"}}>
                 <Row>
                 <Col md = "4">
                    <SelectLocalRateTable onChange = {this.onSelectLocalRateTable} selectedval = {this.state.localRateTableSel}/>
                 </Col>
                 <Col md = "2">
                  {!this.state.isSurchargeLocalEn && 
                   <TextField id="localChargeVal" label="Charge $" value="0"/>
                  }
                  {this.state.isSurchargeLocalEn &&
                   <TextField id="addLocalChargeVal" label="Add" value="0"/>
                  }
                 </Col>
                 <Col md = {this.state.mdVal}>
                   {!this.state.isSurchargeLocalEn && 
                     <TextField id="localChargeSecs" label="Per_Secs" value="60"/>
                   }
                   {this.state.isSurchargeLocalEn &&
                     <TextField id="surchargeLocalVal" label="% Markup Plus $_ Surcharge" value="0"/>
                   }
                </Col>
                <Col md = "1">
                  { (this.state.isLocalPerMin2StepEn || this.state.isLocalPerMin3StepEn )  &&
                    <TextField id="localChargeVal2" label="Plus $" value="0"/>
                  }
                </Col>
                <Col md = "1">
                  { (this.state.isLocalPerMin2StepEn  || this.state.isLocalPerMin3StepEn ) &&
                    <TextField id="localChargeSecs2" label="For the next_secs" value="60"/>
                  }
                </Col>
                <Col md = "1">
                    {this.state.isLocalPerMin3StepEn &&
                      <TextField id="localChargeVal3" label="Plus $" value="0"/>
                    }  
                 </Col>
                 <Col md = "2">
                   {this.state.isLocalPerMin3StepEn &&
                      <TextField id="localChargeSecs3" label="For each additional_secs" value="60"/>
                   }
                 </Col>
                </Row>      
              
                </Row>
              </CardBody>
            </Collapse>
          </Card>
          <Card className="card-plain">
            <CardHeader role="tab">
              <a
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseTwo"
                )}
                href="#pablo"
                data-parent="#accordion"
                data-toggle="collapse"
                onClick={(e) => {e.preventDefault();}}
              >
                Local Toll{" "}
                {/* <i className="tim-icons icon-minimal-down" /> */}
              </a>
            </CardHeader>
            <Collapse
              role="tabpanel"
              isOpen={this.state.openedCollapses.includes("collapseTwo")}
            >
              <CardBody>
                <Row style={{height:"70px"}}>
                <Row>
                 <Col md = "4">
                    <SelectLocalTRateTable onChange = {this.onSelectLocalTRateTable} selectedval = {this.state.localTRateTableSel} />
                 </Col>
                 <Col md = "2">
                    {!this.state.isSurchargeLocalTEn &&
                      <TextField id="localTChargeVal" label="Charge $" value="0"/>
                    }
                    {this.state.isSurchargeLocalTEn &&
                      <TextField id="addLocalTChargeVal" label="Add" value="0"/>
                    }
                 </Col>
                 <Col md = {this.state.mdVal}>
                   { !this.state.isSurchargeLocalTEn &&
                    <TextField id="localTChargeSecs" label="Per_Secs" value="60"/>
                   }
                   {this.state.isSurchargeLocalTEn &&
                    <TextField id="surchargeLocalTVal" label="% Markup Plus $_ Surcharge" value="0"/>
                  }
                 </Col>
                 <Col md = "1">
                  { (this.state.isLocalTPerMin2StepEn || this.state.isLocalTPerMin3StepEn )  &&
                    <TextField id="localTChargeVal2" label="Plus $" value="0"/>
                  }
                  </Col>
                  <Col md = "1">
                  { (this.state.isLocalTPerMin2StepEn  || this.state.isLocalTPerMin3StepEn ) &&
                    <TextField id="localTChargeSecs2" label="For the next_secs" value="60"/>
                  }
                   </Col>
                  <Col md = "1">
                    {this.state.isLocalTPerMin3StepEn &&
                      <TextField id="localTChargeVal3" label="Plus $" value="0"/>
                    }  
                 </Col>
                 <Col md = "2">
                   {this.state.isLocalTPerMin3StepEn &&
                      <TextField id="localTChargeSecs3" label="For each additional_secs" value="60"/>
                   }
                 </Col>
                </Row>   
                </Row>
              </CardBody>
            </Collapse>
          </Card>
          <Card className="card-plain">
            <CardHeader role="tab">
              <a
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseThree"
                )}
                href="#pablo"
                data-parent="#accordion"
                data-toggle="collapse"
                onClick={(e) => {e.preventDefault();}}
              >
                In State{" "}
                {/* <i className="tim-icons icon-minimal-down" /> */}
              </a>
            </CardHeader>
            <Collapse
              role="tabpanel"
              isOpen={this.state.openedCollapses.includes("collapseThree")}
            >
              <CardBody>
                <Row style={{height:"70px"}}>
                <Row>
                 <Col md = "4">
                    <SelectInStRateTable onChange = {this.onSelectInStateRateTable} selectedval = {this.state.inStateRateTableSel}/>
                 </Col>
                 <Col md = "2">
                    { !this.state.isSurchargeInStateEn &&
                      <TextField id="inStateChargeVal" label="Charge $" value="0"/>
                    }
                    {this.state.isSurchargeInStateEn &&
                     <TextField id="addInStateChargeVal" label="Add" value="0"/>
                    }
                 </Col>
                 <Col md = {this.state.mdVal}>
                  { !this.state.isSurchargeInStateEn &&
                    <TextField id="inStateChargeSecs" label="Per_Secs" value="60"/>
                  }
                  {this.state.isSurchargeInStateEn &&
                    <TextField id="surchargeInStateVal" label="% Markup Plus $_ Surcharge" value="0"/>
                  }
                 </Col>
                 <Col md = "1">
                  { (this.state.isInStatePerMin2StepEn || this.state.isInStatePerMin3StepEn )  &&
                    <TextField id="inStateChargeVal2" label="Plus $" value="0"/>
                  }
                  </Col>
                  <Col md = "1">
                  { (this.state.isInStatePerMin2StepEn  || this.state.isInStatePerMin3StepEn ) &&
                    <TextField id="inStateChargeSecs2" label="For the next_secs" value="60"/>
                  }
                   </Col>
                  <Col md = "1">
                    {this.state.isInStatePerMin3StepEn &&
                      <TextField id="inStateChargeVal3" label="Plus $" value="0"/>
                    }  
                 </Col>
                 <Col md = "2">
                   {this.state.isInStatePerMin3StepEn &&
                      <TextField id="inStateChargeSecs3" label="For each additional_secs" value="60"/>
                   }
                 </Col>
                </Row>   
                </Row>
              </CardBody>
            </Collapse>
          </Card>
          <Card className="card-plain">
            <CardHeader role="tab">
              <a
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseFour"
                )}
                href="#pablo"
                data-parent="#accordion"
                data-toggle="collapse"
                onClick={(e) => {e.preventDefault();}}
              >
                Long Distance{" "}
                {/* <i className="tim-icons icon-minimal-down" /> */}
              </a>
            </CardHeader>
            <Collapse
              role="tabpanel"
              isOpen={this.state.openedCollapses.includes("collapseFour")}
            >
              <CardBody>
                <Row style={{height:"70px"}}>
                <Row>
                 <Col md = "4">
                    <SelectLongDRateTable onChange = {this.onSelectLondDistRateTable} selectedval = {this.state.longDistRateTableSel}/>
                 </Col>
                 <Col md = "2">
                    { !this.state.isSurchargeLongDistEn &&
                      <TextField id="longDistChargeVal" label="Charge $" value="0"/>
                    }
                    {this.state.isSurchargeLongDistEn &&
                      <TextField id="addLongDistChargeVal" label="Add" value="0"/>
                    }
                 </Col>
                 <Col md = {this.state.mdVal}>
                    { !this.state.isSurchargeLongDistEn &&
                      <TextField id="longDistChargeSecs" label="Per_Secs" value="60"/>
                    }
                    {this.state.isSurchargeLongDistEn &&
                      <TextField id="surchargeLongDistVal" label="% Markup Plus $_ Surcharge" value="0"/>
                    }
                 </Col>
                 <Col md = "1">
                  { (this.state.isLondDistPerMin2StepEn || this.state.isLondDistPerMin3StepEn )  &&
                    <TextField id="longDistChargeVal2" label="Plus $" value="0"/>
                  }
                  </Col>
                  <Col md = "1">
                  { (this.state.isLondDistPerMin2StepEn  || this.state.isLondDistPerMin3StepEn ) &&
                    <TextField id="longDistChargeSecs2" label="For the next_secs" value="60"/>
                  }
                   </Col>
                  <Col md = "1">
                    {this.state.isLondDistPerMin3StepEn &&
                      <TextField id="longDistChargeVal3" label="Plus $" value="0"/>
                    }  
                 </Col>
                 <Col md = "2">
                   {this.state.isLondDistPerMin3StepEn &&
                      <TextField id="longDistChargeSecs3" label="For each additional_secs" value="60"/>
                   }
                 </Col>
                </Row>        
                </Row>
              </CardBody>
            </Collapse>
          </Card>
          <Card className="card-plain">
            <CardHeader role="tab">
              <a
                aria-expanded={this.state.openedCollapses.includes(
                  "collapseFive"
                )}
                href="#pablo"
                data-parent="#accordion"
                data-toggle="collapse"
                onClick={(e) => {e.preventDefault();}}
              >
                International{" "}
                {/* <i className="tim-icons icon-minimal-down" /> */}
              </a>
            </CardHeader>
            <Collapse
              role="tabpanel"
              isOpen={this.state.openedCollapses.includes("collapseFive")}
            >
              <CardBody>
                <Row style={{height:"70px"}}>
                <Row>
                 <Col md = "4">
                    <SelectIntRateTable onChange = {this.onSelectInternRateTable} selectedval = {this.state.intRateTableSel} />
                 </Col>
                 <Col md = "2">
                    { !this.state.isSurchargeIntEn &&
                      <TextField id="internationalChargeVal" label="Charge $" value="0"/>
                    }
                    {this.state.isSurchargeIntEn &&
                      <TextField id="addIntChargeVal" label="Add" value="0"/>
                    }
                 </Col>
                 <Col md = {this.state.mdVal}>
                    { !this.state.isSurchargeIntEn &&
                      <TextField id="internationalChargeSecs" label="Per_Secs" value="60"/>
                    }
                    {this.state.isSurchargeIntEn &&
                     <TextField id="surchargeIntVal" label="% Markup Plus $_ Surcharge" value="0"/>
                    }
                 </Col>
                 <Col md = "1">
                  { (this.state.isIntPerMin2StepEn || this.state.isIntPerMin3StepEn )  &&
                    <TextField id="internationalChargeVal2" label="Plus $" value="0"/>
                  }
                  </Col>
                  <Col md = "1">
                  { (this.state.isIntPerMin2StepEn  || this.state.isIntPerMin3StepEn ) &&
                    <TextField id="internationalChargeSecs2" label="For the next_secs" value="60"/>
                  }
                   </Col>
                  <Col md = "1">
                    {this.state.isIntPerMin3StepEn &&
                      <TextField id="internationalChargeVal3" label="Plus $" value="0"/>
                    }  
                 </Col>
                 <Col md = "2">
                   {this.state.isIntPerMin3StepEn &&
                      <TextField id="internationalChargeSecs3" label="For each additional_secs" value="60"/>
                   }
                 </Col>
                </Row>              
                </Row>
              </CardBody>
            </Collapse>
          </Card>
        </div>
      </>
    );
  }
}

export default Panels;