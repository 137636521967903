    let trunkUtilDataHourly = [];
    trunkUtilDataHourly = [{
      "date": new Date(2020, 8, 28, 8, 0),
      "Sip1": 2,
      "Sip2": 5,
      "Sip3": 3
    }, {
     "date": new Date(2020, 8, 28, 9, 0),
      "Sip1": 3,
      "Sip2": 2,
      "Sip3": 6
    }, {
      "date": new Date(2020, 8, 28, 10, 0),
      "Sip1": 4,
      "Sip2": 3,
      "Sip3": 5
    }, {
      "date": new Date(2020, 8, 28, 11, 0),
      "Sip1": 4,
      "Sip2": 2,
      "Sip3": 1
    }, {
      "date": new Date(2020, 8, 28, 12, 0),
      "Sip1": 5,
      "Sip2": 8,
      "Sip3": 9
    }, {
      "date": new Date(2020, 8, 28, 13, 0),
      "Sip1": 3,
      "Sip2": 10,
      "Sip3": 20
    },
    {
      "date": new Date(2020, 8, 28, 14, 0),
      "Sip1": 3,
      "Sip2": 30,
      "Sip3": 10
    }];
	
	let trunkUtilDataWeekly = [];
    trunkUtilDataWeekly = [{
      "date": "2020-08-31",
      "Sip1": 2,
      "Sip2": 5,
      "Sip3": 3
    }, {
     "date": "2020-09-01",
      "Sip1": 3,
      "Sip2": 2,
      "Sip3": 6
    }, {
      "date": "2020-09-02",
      "Sip1": 4,
      "Sip2": 3,
      "Sip3": 5
    }, {
      "date": "2020-09-03",
      "Sip1": 4,
      "Sip2": 2,
      "Sip3": 1
    }, {
      "date": "2020-09-04",
      "Sip1": 5,
      "Sip2": 8,
      "Sip3": 9
    }, {
      "date": "2020-09-05",
      "Sip1": 3,
      "Sip2": 10,
      "Sip3": 20
    },
    {
      "date": "2020-09-06",
      "Sip1": 3,
      "Sip2": 30,
      "Sip3": 10
    }];
	
	let trunkUtilDataMonthly = [];
	trunkUtilDataMonthly = [{
	  "date": "09-01",
	  "Sip1": 2,
	  "Sip2": 5,
	  "Sip3": 3
	}, {
	 "date": "09-02",
	  "Sip1": 3,
	  "Sip2": 2,
	  "Sip3": 6
	}, {
	  "date": "09-03",
	  "Sip1": 4,
	  "Sip2": 3,
	  "Sip3": 5
	}, {
	  "date": "09-04",
	  "Sip1": 4,
	  "Sip2": 2,
	  "Sip3": 1
	}, {
	  "date": "09-05",
	  "Sip1": 5,
	  "Sip2": 8,
	  "Sip3": 9
	}, {
	  "date": "09-06",
	  "Sip1": 3,
	  "Sip2": 10,
	  "Sip3": 20
	},
	{
	  "date": "09-07",
	  "Sip1": 3,
	  "Sip2": 30,
	  "Sip3": 10
	},
	{
	  "date": "09-08",
	  "Sip1": 13,
	  "Sip2": 3,
	  "Sip3": 1
	},
	{
	  "date": "09-09",
	  "Sip1": 5,
	  "Sip2": 7,
	  "Sip3": 9
	},
	{
	  "date": "09-10",
	  "Sip1": 4,
	  "Sip2": 13,
	  "Sip3": 10
	},
	{
	  "date": "09-11",
	  "Sip1": 5,
	  "Sip2": 23,
	  "Sip3": 10
	},
	{
	  "date": "09-12",
	  "Sip1": 5,
	  "Sip2": 4,
	  "Sip3": 7
	},
	{
	  "date": "09-13",
	  "Sip1": 8,
	  "Sip2": 12,
	  "Sip3": 5
	},
	{
	  "date": "09-14",
	  "Sip1": 35,
	  "Sip2": 3,
	  "Sip3": 10
	},
	{
	  "date": "09-15",
	  "Sip1": 13,
	  "Sip2": 20,
	  "Sip3": 4
	},
	{
	  "date": "09-16",
	  "Sip1": 23,
	  "Sip2": 5,
	  "Sip3": 8
	},
	{
	  "date": "09-17",
	  "Sip1": 8,
	  "Sip2": 3,
	  "Sip3": 15
	},
	{
	  "date": "09-18",
	  "Sip1": 13,
	  "Sip2": 3,
	  "Sip3": 7
	},
	{
	  "date": "09-19",
	  "Sip1": 6,
	  "Sip2": 7,
	  "Sip3": 8
	},{
	  "date": "09-20",
	  "Sip1": 3,
	  "Sip2": 30,
	  "Sip3": 10
	},
	{
	  "date": "09-21",
	  "Sip1": 3,
	  "Sip2": 30,
	  "Sip3": 10
	},{
	  "date": "09-22",
	  "Sip1": 3,
	  "Sip2": 30,
	  "Sip3": 10
	}
	,{
	  "date": "09-23",
	  "Sip1": 3,
	  "Sip2": 30,
	  "Sip3": 10
	}
	,{
	  "date": "09-24",
	  "Sip1": 3,
	  "Sip2": 30,
	  "Sip3": 10
	}
	,{
	  "date": "09-25",
	  "Sip1": 3,
	  "Sip2": 30,
	  "Sip3": 10
	}
	,{
	  "date": "09-26",
	  "Sip1": 3,
	  "Sip2": 30,
	  "Sip3": 10
	}
	,{
	  "date": "09-27",
	  "Sip1": 3,
	  "Sip2": 30,
	  "Sip3": 10
	}
	,{
	  "date": "09-28",
	  "Sip1": 3,
	  "Sip2": 30,
	  "Sip3": 10
	}
	,{
	  "date": "09-29",
	  "Sip1": 3,
	  "Sip2": 30,
	  "Sip3": 10
	}
	,{
	  "date": "09-30",
	  "Sip1": 3,
	  "Sip2": 30,
	  "Sip3": 10
	}];
	let topDeptDataDaily = [];
	topDeptDataDaily = [
	{
		"Department": "Accounting",
		"Calls": 25
	},
	{
		"Department": "Call Center",
		"Calls": 22
	},
	{
		"Department": "Customer Service",
		"Calls": 20
	},
	{
		"Department": "Finance",
		"Calls": 18
	},
	{
		"Department": "Human Resources",
		"Calls": 15
	},
	{
		"Department": "Information Services",
		"Calls": 13
	},
	{
		"Department": "Marketing",
		"Calls": 10
	},
	{
		"Department": "Sales",
		"Calls": 6
	},
	{
		"Department": "Security",
		"Calls": 5
	},
	{
		"Department": "Teller",
		"Calls": 5
	}
	];
	let topDeptDataWeekly = [];
	topDeptDataWeekly = [
	{
		"Department": "Accounting",
		"Calls": 100
	},
	{
		"Department": "Call Center",
		"Calls": 122
	},
	{
		"Department": "Customer Service",
		"Calls": 200
	},
	{
		"Department": "Finance",
		"Calls": 180
	},
	{
		"Department": "Human Resources",
		"Calls": 150
	},
	{
		"Department": "Information Services",
		"Calls": 130
	},
	{
		"Department": "Marketing",
		"Calls": 100
	},
	{
		"Department": "Sales",
		"Calls": 60
	},
	{
		"Department": "Security",
		"Calls": 50
	},
	{
		"Department": "Teller",
		"Calls": 30
	}
	];
	let topDeptDataMonthly = [];
	topDeptDataMonthly = [
	{
		"Department": "Accounting",
		"Calls": 350
	},
	{
		"Department": "Call Center",
		"Calls": 310
	},
	{
		"Department": "Customer Service",
		"Calls": 290
	},
	{
		"Department": "Finance",
		"Calls": 280
	},
	{
		"Department": "Human Resources",
		"Calls": 250
	},
	{
		"Department": "Information Services",
		"Calls": 230
	},
	{
		"Department": "Marketing",
		"Calls": 200
	},
	{
		"Department": "Sales",
		"Calls": 170
	},
	{
		"Department": "Security",
		"Calls": 160
	},
	{
		"Department": "Teller",
		"Calls": 150
	}
	];
	
	let callClassDataDaily = [];
	callClassDataDaily = [
	{
		calltype: "INC",
		value: 26
	},
	{
		calltype: "LOC",
		value: 23
	},
	{
		calltype: "LTOLL",
		value: 20
	},
	{
		calltype: "STATE",
		value: 16
	},
	{
		calltype: "LD",
		value: 13
	},
	{
		calltype: "INTL",
		value: 12
	},
	{
		calltype: "EMG",
		value: 5
	}
	];
	let callClassDataWeekly = [];
	callClassDataWeekly = [
	{
		calltype: "INC",
		value: 80
	},
	{
		calltype: "LOC",
		value: 50
	},
	{
		calltype: "LTOLL",
		value: 20
	},
	{
		calltype: "STATE",
		value: 30
	},
	{
		calltype: "LD",
		value: 120
	},
	{
		calltype: "INTL",
		value: 100
	},
	{
		calltype: "EMG",
		value: 4
	}
	];
	let callClassDataMonthly = [];
	callClassDataMonthly = [
	{
		calltype: "INC",
		value: 180
	},
	{
		calltype: "LOC",
		value: 80
	},
	{
		calltype: "LTOLL",
		value: 20
	},
	{
		calltype: "STATE",
		value: 40
	},
	{
		calltype: "LD",
		value: 200
	},
	{
		calltype: "INTL",
		value: 150
	},
	{
		calltype: "EMG",
		value: 6
	}
	];
	module.exports = {
		trunkUtilDataHourly, 
		trunkUtilDataWeekly,
		trunkUtilDataMonthly,
		topDeptDataDaily,
		topDeptDataWeekly,
		topDeptDataMonthly,
		callClassDataDaily,
		callClassDataWeekly,
		callClassDataMonthly
	};