import React from "react";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { forwardRef, useState, useLayoutEffect, useRef} from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ApiCall from "Helper/Api.js";
import {useExpoApi} from "../../Helper/Api.js"

const tableIcons = {
Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function MaterialTableDemo(props) {
  const [state, setState] = React.useState({
    columns: [],
    data: []
  });
  const _isMounted = useRef(true);
  const getApi = useExpoApi();
  const [themeType,setThemeType] = useState('dark');
  const [sites, setSites] =  useState([{}]);
  
  const divSiteLookup = {};
  const getSites = async ()=> { //add the filters
    const siteObj = [];
    const api = await getApi();
    api
         .getSites()
         .then((response) => {
            response.data.sites.map((item)=>{
               divSiteLookup[item.id] = item.name;
            }) 
          }) 
         .catch((err) => {console.log(err)});      
  }; 

  const apiCallGetHuntGroups = async ()=> {
    getSites();
    if (_isMounted.current) {
      const api = await getApi();
       api
         .getHuntGroups()
         .then((response) => {
          setState({
            data: response.data.huntGroups,
            columns: [
              { title: "Hunt", field: "number" },
              { title: "Name", field: "name" },
              { title: "Site", field: "siteId", lookup: divSiteLookup }
            ]
          })     
          console.log(response.data.huntGroups);  
          }) 
          
         .catch((err) => {console.log(err)});  
      }      
    };

    const addHuntGroup = async (huntGObj) => {
      try {
        const api = await getApi();
        const res = await api.addHuntGroup(huntGObj);
        if(res.status === 200) {
          console.log("success");
          apiCallGetHuntGroups();
        return;
      }
      console.log("error"); 
      alert("Error");
      }
      catch(err) {
        console.log(err);
        alert("Error");
      }   
    }

    const editHuntGroup = async (huntGObj, id) => {
      try {
        const api = await getApi();
        const res = await api.editHuntGroup(id,huntGObj);
        if(res.status === 200) {
          console.log("success");
          apiCallGetHuntGroups();
        return;
      }
      console.log("error"); 
      alert("Error");
      }
      catch(err) {
        console.log(err);
        alert("Error");
      }   
    }

    const deleteHuntGroup = async (id) => {
      try {
        const api = await getApi();
        const res = await api.deleteHuntGroup(id);
        if(res.status === 200) {
          console.log("success");
          apiCallGetHuntGroups();
        return;
      }
      console.log("error"); 
      alert("Error");
      }
      catch(err) {
        console.log(err);
        alert("Error");
      }   
    }
 
  React.useEffect(() => {  
    
    apiCallGetHuntGroups()
    // const interval=setInterval(()=>{
    //   apiCallGetHuntGroups()      
    //   },10000)
          
    //   return()=>{clearInterval(interval) ; _isMounted.current = false}
          
  }, []); 

  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content" ){  setThemeType("light")}
    else{setThemeType("dark")}
    
  }, []);

  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#4caf50',
      },
    },
  });
  return (
    <div>
      <MuiThemeProvider theme={THEME}>
      
          <MaterialTable
            style= {{
                backgroundColor: 'transparent',
                boxShadow: 'none',
            }}
            icons={tableIcons}
            title=""
            columns={state.columns}
            data={state.data}
            options={{
                headerStyle: {
                  backgroundColor: "rgba(0,0,0,0)",  // #6ca6c2; //67b7dc          
                  fontWeight: 'bold'
                },
                pageSizeOptions:[10,15,20],
              }}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    setState((prevState) => {
                      const data = [...prevState.data];
                      data.push(newData);
                      console.log(newData);
                      const newHuntG = { 
                        name: newData.name,
                        number:  newData.number,
                        siteId: newData.siteId
                      };
                      addHuntGroup(newHuntG);
                      return { ...prevState, data };
                    });
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    if (oldData) {
                      setState((prevState) => {
                        const data = [...prevState.data];
                        let pos = oldData.tableData.id;
                        let index = data[pos].id;
                        data[data.indexOf(oldData)] = newData;
                        const newHuntG = { 
                          name: data[pos].name,
                          number: data[pos].number,
                          siteId: data[pos].siteId,
                        };
                        editHuntGroup(newHuntG,index);
                        return { ...prevState, data };
                      });
                    }
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    setState((prevState) => {
                      const data = [...prevState.data];
                      let pos = oldData.tableData.id;
                      let index = data[pos].id;
                      deleteHuntGroup(index);
                      data.splice(data.indexOf(oldData), 1);
                      return { ...prevState, data };
                    });
                  }, 600);
                })
            }}
          />  
      </MuiThemeProvider>
    </div>
  );
}
