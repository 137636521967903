/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications over the dashboard

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";

import logo from "assets/img/react-logo.png";
import ApiCall from "Helper/Api.js";
import {withAuth0} from "@auth0/auth0-react";

let ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeColor: "orange",
      sidebarMini: true,
      opacity: 0,
      sidebarOpened: false,
      isAdminUser:false,
      showUserInteractions: 1,
      ratesEnabled:false,
      prevSett:{},
      trunkEnabled:0,
    };

    //getting showUserInteractions ini value
    this.props.auth0.getAccessTokenSilently().then( (token)=>{
      console.log(token);
      let api = new ApiCall(token);
      api.getIni('ShowUserInteractions').then( value => {
        this.setState({
          showUserInteractions: value
        });
        console.log("updated show User interactions")

      }).catch( (err)=>console.log("error getting ini", err))
    });


  }
  componentWillUpdate(nextProps,nextState){
    //this.isUserAdmin();
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.classList.add("perfect-scrollbar-on");
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
      this.refs.mainPanel.addEventListener(
        "ps-scroll-y",
        this.showNavbarButton
      );
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    window.addEventListener("scroll", this.showNavbarButton);
    this.getDashboardSettings().catch();
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className.add("perfect-scrollbar-off");
      document.documentElement.classList.remove("perfect-scrollbar-on");
      this.refs.mainPanel.removeEventListener(
        "ps-scroll-y",
        this.showNavbarButton
      );
    }
    window.removeEventListener("scroll", this.showNavbarButton);
  }
  componentDidUpdate(e) {
    
    if (e.location.pathname !== e.history.location.pathname) {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  getDashboardSettings = async () => {
    const token = await this.props.auth0.getAccessTokenSilently();
    const api = new ApiCall(token);
     api
       .getDashboardSettings()
       .then((response) => {
          const obj = {...response.data.dashboard};
          this.setState({prevSett : obj});
          if(response.data.dashboard.ratesEnabled == "0")
            this.setState({ratesEnabled: false});   
          else
            this.setState({ratesEnabled: true});   
          if(response.data.dashboard.trunkEnabled == "0")
            this.setState({trunkEnabled: false});   
          else
            this.setState({trunkEnabled: true});   
          console.log(this.state.prevSett);
        }) 
       .catch((err) => console.log(err));        
  }
  postDashboardSett= async (dashSett) => { 
    try{      
      const token = await this.props.auth0.getAccessTokenSilently();
        const api = new ApiCall(token);

      const res = await api.postDashboardSettings(dashSett);
      if(res.status === 200) {
        console.log("success");
        return;
      }
      console.log("error"); 
      alert("Error");
    }
    catch(err) {
      console.log(err);
      
    }  
  };
  handleRatesEnClick = () => {
    if(this.state.prevSett != ""){
      this.state.prevSett.firstWidgetTitle = "Total Calls";
      this.state.prevSett.secondWidgetTitle = "Incoming";
      this.state.prevSett.thirdWidgetTitle = "Outgoing";
      this.state.prevSett.fourthWidgetTitle = "Abandoned";
      this.state.prevSett.fithWidgetTitle = "Today's Calls";
      this.state.prevSett.sixWidgetTitle = "Abandoned Vs Handled Calls";
      this.state.prevSett.sevenWidgetTitle = "Top 10 Branches By Total Calls";
      this.state.prevSett.eightWidgetTitle = "Call distribution By Call Class";
      this.state.prevSett.nineWidgetTitle = "Calls Live View";
      this.state.prevSett.firstWidgetSelOpt = "TotalCalls";
      this.state.prevSett.secondWidgetSelOpt = "Incoming";
      this.state.prevSett.thirdWidgetSelOpt = "Outgoing";
      this.state.prevSett.fourthWidgetSelOpt = "Abandoned";
      this.state.prevSett.selCallClass = "INC,LOC,LTOLL,STATE,LD,EMG"
      this.state.prevSett.sixWidgetSelOpt = "1"; 
      this.state.prevSett.trunkEnabled = "0";
    }
    this.setState({ratesEnabled: !this.state.ratesEnabled})
    if(this.state.ratesEnabled == false)
     this.state.prevSett.ratesEnabled = "0";
    else if(this.state.ratesEnabled == true)
    this.state.prevSett.ratesEnabled = "1";
    let dashSett = {};
    dashSett.dashboard = this.state.prevSett;
    console.log(dashSett);
    this.postDashboardSett(dashSett).catch();
  }
  handleTrunkEnClick = () => {
    if(this.state.prevSett != ""){
      this.state.prevSett.firstWidgetTitle = "Total Calls";
      this.state.prevSett.secondWidgetTitle = "Incoming";
      this.state.prevSett.thirdWidgetTitle = "Outgoing";
      this.state.prevSett.fourthWidgetTitle = "Abandoned";
      this.state.prevSett.fithWidgetTitle = "Today's Calls";
      this.state.prevSett.sixWidgetTitle = "Abandoned Vs Handled Calls";
      this.state.prevSett.sevenWidgetTitle = "Top 10 Branches By Total Calls";
      this.state.prevSett.eightWidgetTitle = "Call distribution By Call Class";
      this.state.prevSett.nineWidgetTitle = "Calls Live View";
      this.state.prevSett.firstWidgetSelOpt = "TotalCalls";
      this.state.prevSett.secondWidgetSelOpt = "Incoming";
      this.state.prevSett.thirdWidgetSelOpt = "Outgoing";
      this.state.prevSett.fourthWidgetSelOpt = "Abandoned";
      this.state.prevSett.selCallClass = "INC,LOC,LTOLL,STATE,LD,EMG"
      this.state.prevSett.sixWidgetSelOpt = "1"; 
      this.state.prevSett.trunkEnabled = "0";
    }
    this.setState({trunkEnabled: !this.state.trunkEnabled})
    if(this.state.trunkEnabled == false)
     this.state.prevSett.trunkEnabled = "0";
    else if(this.state.trunkEnabled == true)
    this.state.prevSett.trunkEnabled = "1";
    let dashSett = {};
    dashSett.dashboard = this.state.prevSett;
    console.log(dashSett);
    this.postDashboardSett(dashSett).catch();
  }
  isUserAdmin = async () => {
    let userId = 0;
    const token = await this.props.auth0.getAccessTokenSilently();

    const api = new ApiCall(token);
    api
      .getUserInfo()
      .then((response) => {
        userId = response.data.roles
        api
        .getRoleInfoById(userId)
        .then((response) => {
          if(response.data[0].name == "Admin"){this.setState({isAdminUser:true})}
        }) 
        .catch((err) => console.log(err));      
        }) 
      .catch((err) => console.log(err));    
  }
  showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50 ||
      this.refs.mainPanel.scrollTop > 50
    ) {
      this.setState({ opacity: 1 });
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50 ||
      this.refs.mainPanel.scrollTop <= 50
    ) {
      this.setState({ opacity: 0 });
    }
  };
  getRoutes = routes => {
    return routes
    .filter( (prop)=>{
      if(prop.name === 'Visualizations' &&
        prop.views[0].name === 'User Interactions'&&
        this.state.showUserInteractions != 1 ){
          prop.views.splice(0,1);
        }
        return true;
    })
    .map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
          return (
            <Route
              path={prop.layout + prop.path}
              render={(props) => { return <prop.component {...props} {...prop.routeProps} />}}
              key={key}
            />
          );
      } else {
        return null;
      }
    });
  };
  getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };
  handleMiniClick = () => {
    let notifyMessage = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
      notifyMessage += "deactivated...";
    } else {
      this.setState({ sidebarMini: true });
      notifyMessage += "activated...";
    }
    let options = {};
    options = {
      place: "tr",
      message: notifyMessage,
      type: "primary",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    //this.refs.notificationAlert.notificationAlert(options);
    document.body.classList.toggle("sidebar-mini");
  };
  toggleSidebar = () => {
    this.setState({
      sidebarOpened: !this.state.sidebarOpened
    });
    document.documentElement.classList.toggle("nav-open");
  };
  closeSidebar = () => {
    this.setState({
      sidebarOpened: false
    });
    document.documentElement.classList.remove("nav-open");
  };
  render() {
    return (
      <div className="wrapper">
        {/* <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div> */}
        <div
          className="navbar-minimize-fixed"
          style={{ opacity: this.state.opacity }}
        >
          <button
            className="minimize-sidebar btn btn-link btn-just-icon"
            onClick={this.handleMiniClick}
          >
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
          </button>
        </div>
        <Sidebar
          {...this.props}
          routes={routes}
          activeColor={this.state.activeColor}
          logo={{
            outterLink: "/",
            text: "EXPO",
            imgSrc: logo
          }}
          closeSidebar={this.closeSidebar}
        />
        <div
          className="main-panel"
          ref="mainPanel"
          data={this.state.activeColor}
        >
          <AdminNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
            brandText={this.getActiveRoute(routes)}
            sidebarOpened={this.state.sidebarOpened}
            toggleSidebar={this.toggleSidebar}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/admin/dashboard" />
          </Switch>
          {// we don't want the Footer to be rendered on full screen maps page
          this.props.location.pathname.indexOf("full-screen-map") !==
          -1 ? null : (
            <Footer fluid />
          )}
        </div>
        <FixedPlugin 
          activeColor={this.state.activeColor}
          sidebarMini={this.state.sidebarMini}
          handleActiveClick={this.handleActiveClick}
          handleMiniClick={this.handleMiniClick}
          ratesEnabled = {this.state.ratesEnabled}
          handleRatesEnClick = {this.handleRatesEnClick}
          trunkEnabled = {this.state.trunkEnabled}
          handleTrunkEnClick = {this.handleTrunkEnClick}
        />
      </div>
    );
  }
}

export default withAuth0(Admin);
