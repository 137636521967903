import React, { useState, useLayoutEffect } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles((theme) => ({
  selectField: {
      width: '350px',
    }
  }));
  
  const SelectSimpleComponent = (props) => {
    const classes = useStyles();
    const [themeType,setThemeType] = useState('dark');
    const [condition, setCondition] = React.useState("Actual Cost");

    const handleChange = (e) => {
      setCondition(e.target.value);
    };
    useLayoutEffect(() => {
        if(props.activeModeClass === "white-content"){  setThemeType("light")}
        else{setThemeType("dark")}
        
      }, []);
      const THEME = createMuiTheme({
        palette: {
          type: themeType,
          primary: {
            main: '#f8955e',
          },
          secondary: {
            main: '#1d8cf8',
          },
        },
      });
      return (
          <div>
              <MuiThemeProvider theme={THEME}>
               <CssBaseline/>   
               <FormControl >
                <InputLabel shrink id="condition-select">
                  Generate On: (Each Week)
                </InputLabel>
                <Select
                  labelId="condition-label"
                  id="condition-select"
                  value= {props.selVal}
                  onChange={props.onChange}
                  className={classes.selectField}
                  displayEmpty
                >
                  <MenuItem value="0">Sunday</MenuItem>
                  <MenuItem value="1">Monday</MenuItem>
                  <MenuItem value="2">Tuesday</MenuItem> 
                  <MenuItem value="3">Wednesday</MenuItem> 
                  <MenuItem value="4">Thursday</MenuItem> 
                  <MenuItem value="5">Friday</MenuItem> 
                  <MenuItem value="6">Saturday</MenuItem> 
                </Select>
              </FormControl>      
              </MuiThemeProvider>
          </div>
      )
  }
  export default SelectSimpleComponent;