import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

import SwithComponent from "components/MaterialUI/SwithComponent.js";
import TextFieldComponents from "components/MaterialUI/TextFieldComponent.js";

class Assign extends React.Component {
  render() {
    return (
      <>
      <div className="content">
      <Row>
        <Col xs="6">
          <Card className="card-chart">
          <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <CardTitle tag="h6">Configure an Alarm</CardTitle>
                </Col>
                <Col sm="6">
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div style={{height: "640px", width: "100%", marginTop:"0px", marginLeft:"10px" }}>
                <Row style={{marginLeft:"5px", marginRight:"5px", marginTop:"5px", width:"400px"}}>
                <Col sm="6"><TextFieldComponents id="alertTitleText" style={{ width:"200px"}} label="Alarm Title" value="My 911 Alarm" fieldState="enabled" activeModeClass="dark-content"/> </Col>
                <Col sm="6"> <TextFieldComponents id="alertdigits" label="Digits that will trigger the alarm" helperText="Ex:911,411" fieldState="enabled"  activeModeClass="dark-content"/>  </Col>
                </Row>  
                <Row style={{marginLeft:"10px", marginTop:"15px", width:"400px"}}>
                <Col sm="6">
                  <TextFieldComponents id="fromTimeValueText" helperText="hh:mm" label="During The Time Of:" value = "08:00" fieldState="enabled"  activeModeClass="dark-content"/>
                </Col>
                <Col sm="6">
                  <TextFieldComponents id="toTimeValueText" helperText="hh:mm" label="To:" value = "18:00" fieldState="enabled" activeModeClass="dark-content"/>
                </Col>
                </Row>
                <Row style={{marginLeft:"10px", marginTop:"20px", width:"400px"}}>
                </Row>
                <Row style={{marginLeft:"10px", marginTop:"10px", width:"400px"}}>
                <Col sm="6">
                  <SwithComponent activeModeClass="dark-content" activeState="Enabled" label="Weekdays"/>     
                </Col>
                <Col sm="6">
                  <SwithComponent activeModeClass="dark-content" activeState="Disabled" label="Weekends"/>          
                </Col>
                </Row>
                <Row style={{marginLeft:"10px", marginTop:"10px", width:"400px"}}>
                  <Col sm="6">
                    <Button activeModeClass="dark-content">Test</Button>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="6">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <CardTitle tag="h6">Current Alarms</CardTitle>
                </Col>
                <Col sm="6">

                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div style={{height: "600px", width: "100%", marginTop:"-40px" }}>
            
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
      </>
    );
  }
}

export default Assign;