import React from "react";
import classNames from "classnames";
import { Line, Bar , Pie } from "react-chartjs-2";

import CallIcon from '@material-ui/icons/Call';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import CallMadeIcon from '@material-ui/icons/CallMade';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import TimeBasedChart from "components/AmCharts/AreaTimeBasedChart.jsx";
import MonthlyChart from "components/AmCharts/MonthlyChart.jsx"
import HeatMap from "components/AmCharts/HeatMap.jsx";
import PeakTrunkUtilChart from "components/AmCharts/PeakTrunkUtilization.jsx";
import Top10Departments from "components/AmCharts/Top10CallsByDept.jsx";
import Top10Dialed from "../components/Tables/Top10Dialed";
import Top10MostExpensive from "components/Tables/Top10MostExpensive.js";
import Top10MostUsed from "components/Tables/Top10MostUsedExt.js";
import Top10Received from "components/Tables/Top10Received.js";
import AlarmTable from "components/Tables/Alarms"
import StockedColCharge from "../components/OthersCharts/ApexCharts/StockedColumnCharge";

import CallsByType from "components/AmCharts/CallsByTypeWidget.jsx";
import LiveViewTable from "components/Tables/LiveViewTable.js";
import HourlyChartFilterDlg from "components/MaterialUI/Dialogs/FiltersDialog_HourlyChart.js";
import TrunkUChartFilterDlg from "components/MaterialUI/Dialogs/FiltersDialog_Trunk.js";
import TopDeptChartFilterDlg from "components/MaterialUI/Dialogs/FiltersDialog_Dept.js";
import CallClassChartFilterDlg from "components/MaterialUI/Dialogs/FiltersDialog_CallClassChart.js";
import CallsTableChartFilterDlg from "components/MaterialUI/Dialogs/FiltersDialog_CallsTable.js";
import AlertTotalCallsDlg from "components/MaterialUI/Dialogs/AlertDialog_TotalCalls.js";
import AlertIncCallsDlg from "components/MaterialUI/Dialogs/AlertDialog_IncCalls.js";
import AlertOutCallsDlg from "components/MaterialUI/Dialogs/AlertDialog_OutgoingCalls.js";
import AlertIntCallsDlg from "components/MaterialUI/Dialogs/AlertDialog_InternalCalls.js";
import AlertTrunkUtilDlg from "components/MaterialUI/Dialogs/AlertDialog_TrunkUtilization.js";
import AlertCallsByDeptDlg from "components/MaterialUI/Dialogs/AlertDialog_CallsByDept.js";
import AlertCallClassDlg from "components/MaterialUI/Dialogs/AlertDialog_CallClass.js";
import LiveCallAlertDialog from "components/MaterialUI/Dialogs/AlertDialog_LiveCalls.js";
import SettingsTrunkDialog from "components/MaterialUI/Dialogs/TrunkBoxSettingsDialog.js";
import FilterListIcon from "@material-ui/icons/FilterList";
import Tooltip from '@material-ui/core/Tooltip';
import DateTimePicker from "components/MaterialUI/DateTimePicker_MonthlyChart";
import IconButton from '@material-ui/core/IconButton';
import {TimePickerFromDate} from "Helper/Utils.js";
import {TimePickerToDate} from "Helper/Utils.js";
import {withAuth0} from "@auth0/auth0-react"
import CheckBoxList from "components/OthersCharts/CheckBoxList/multi-select-check.js";
import AbandVsAnswChart from "components/AmCharts/AbandonedVsHandled.jsx";

import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Row,
  Col,
  Collapse
} from "reactstrap";

// core components
import {
  chart_1_2_3_options
} from "variables/charts.js";

import {
  topDeptDataDaily,
  topDeptDataWeekly,
  topDeptDataMonthly,
  callClassDataDaily,
  callClassDataWeekly,
  callClassDataMonthly
} from "variables/dashboardCharts.js";
import dayjs from "dayjs";
import CheckBoxExternalCallType from "../components/OthersCharts/CheckBoxList/multi-select-check-externalCallTypes";
import ApiCall from "../Helper/Api";

function get_filters(obj) {
    return {
        timeRangeStart: obj.state.startDateRange,
        timeRangeEnd: obj.state.endDateRange,
        divAIds: obj.state.divAIds,
        divBIds: obj.state.divBIds,
        divCIds: obj.state.divCIds,
        externalCallTypes: obj.state.externalCallTypes,
    }
}

function active_class_mode() {
    if(document.body.classList.contains('white-content')) {
        return 'white-content';
    }

    return 'dark-content';
}

function getPrevStartEndDate(state) {
    let prevStartDate = "";
    let prevEndDate = "";
    if (state.bigChartData === "data1") {
      prevStartDate = dayjs(state.filterDateFrom).subtract(1, 'day').toISOString();
      prevEndDate = dayjs(state.filterDateFrom).toISOString();
    } else if (state.bigChartData === "data2"){
      prevStartDate = dayjs(state.filterDateFrom).subtract(1, 'week').startOf('week').toISOString();
      prevEndDate = dayjs(prevStartDate).endOf('week');
    } else if(state.bigChartData === "data3"){
      prevStartDate = dayjs(state.filterDateFrom).subtract(1, 'month').startOf('month').toISOString();
      prevEndDate = dayjs(prevStartDate).endOf('month').toISOString();
    }

	return [prevStartDate, prevEndDate];
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      bigChartData: "data1",
      cardsFooterRange: "Prev Day",
      totalCalls: 0,
      prevTotalCalls: 0,
      prevTotalCallsIcon: "tim-icons icon-minimal-up",
      incCalls: 0,
      prevIncCalls: 0,
      prevIncCallsIcon: "tim-icons icon-minimal-up",
      outCalls: 0,
      prevOutCalls: 0,
      prevOutCallsIcon: "tim-icons icon-minimal-down",
      intCalls: 0,
      prevIntCalls: 0,
      prevIntCallsIcon: "tim-icons icon-minimal-up",
      answCalls:0,
      abandCalls:0,
      busyCalls:0,
      prevAnswCalls:0,
      prevAbandCalls:0,
      prevBusyCalls:0,
      handledRate:'0 %',
      prevHandledRate:'0 %',
      activeModeClass:"dark-content",
      selTableView:"live-view",
      openHourlyChartDialog:false,
      openTrunkUChartDlg:false,
      openTopDeptChartDlg:false,
      openCallClassChartDlg:false,
      openCallsTableChartDlg:false,
      openTotalCallsAlertDlg:false,
      openIncCallsAlertDlg:false,
      openOutCallsAlertDlg:false,
      openIntCallsAlertDlg:false,
      openTrunkUtilAlertDlg:false,
      openCallsByDeptAlertDlg:false,
      openCallClassAlertDlg:false,
      classnameVar: "modal-black",
      apiToken:null,
      recentCalls : [],
      openLiveCallAlertDlg:false,
      firstTopWidgetTitle:"Total Calls",
      secondTopWidgetTitle:"Incoming",
      thirdTopWidgetTitle:"Outgoing",
      fourthTopWidgetTitle:"Abandoned",
      fithWidgetTitle:"Today's Calls",
      sixWidgetTitle:"Abandoned Vs Handled Calls",
      sevenWidgetTitle:"Top 10 Branches By Total Calls",
      eightWidgetTitle:"Call distribution By Call Class",
      nineWidgetTitle: "Calls Live View",
      widgetName:"sixCard",
      openSettDialog:false,
      selWidgetTitleText:"Call Volume By Trunk",
      widgetTitleTextState:"",
      selCardName:"",
      selectOptions: "0",
      sixWidgetSelOption : "1",
      isOpen:false,
      apiDateFrom:"",
      recentCallsLineChart:{},
      topTenDialedCallsChart : {},
      topTenRecCallsChart : {},
      topTenUsedExtsChart : {},
      deptSelected: [],
      divSelected: [],
      branchSelected: [],
      filterDateFrom: TimePickerFromDate,
      filterDateTo: TimePickerToDate,
      startDateRange: TimePickerFromDate,
      endDateRange: TimePickerToDate,
      divAIds:"",
      divBIds:"",
      divCIds:"",
      externalCallTypes: [],
      statsValue: "TotalCalls",
      firstCardStat:"TotalCalls",
      secondCardStat:"Incoming",
      thirdCardStat:"Outgoing",
      fourthCardStat:"Abandoned",
      callClassSelVal:"INC,LOC,LTOLL,STATE,LD,EMG",
      showDlgStats:0,
      showDlgCallType:0,
      showDlgSixWidgetOption:0,
      totalDivAItems:0,
      totalDivBItems:0,
      totalDivCItems:0,
      totalExtItems:0,
      ratesEnabled:"0",
      trunkEnabled:"0",
    };
    this.handleWidgetTitleState = this.handleWidgetTitleState.bind(this);
  }
  getTotals = async () =>{
      const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);
      const result = await api.getDivs('A');
    const totalDivsA = result.data.divs.length;
    this.setState({totalDivAItems:totalDivsA})
    
    const result1 = await api.getDivs('B');
    const totalDivsB = result1.data.divs.length;
    this.setState({totalDivBItems:totalDivsB})
    
    const result2 = await api.getDivs('C');
    const totalDivsC = result2.data.divs.length;
    this.setState({totalDivCItems:totalDivsC})
  }
  openFilterTab =()=>{this.setState({ isOpen: !this.state.isOpen})};
  dateFromOnChange = (e) => {
    this.setState({filterDateFrom : e.target.value});
    this.setState({startDateRange:  e.target.value})
    this.setState({endDateRange:  e.target.value})
    
  }
  dateToOnChange = (e) => {
    this.setState({filterDateTo : e.target.value});
  }

  handleDivAOnChange = (obj) => {
      const divs = obj.map((item) => item.value).join();
      this.setState({deptSelected: obj, divAIds: divs})
  }

  handleDivBOnChange = (obj)=>{
   this.setState({divSelected: obj})
   if(this.state.totalDivBItems === obj.length || obj.length > 20){
    this.setState({divBIds:""})
   }
   else{
    const divs = obj.map((item) => item.value).join();
    this.setState({divBIds:divs})
   }
 }
 handleDivCOnChange = (obj)=>{
    this.setState({branchSelected: obj})
    if(this.state.totalDivCItems === obj.length || obj.length > 20){
      this.setState({divCIds:""})
    }
    else{
      const divs = obj.map((item) => item.value).join();
      this.setState({divCIds:divs})
    }
 }

 handleExternalCallClassOnChange = (obj)=>{
     this.setState({externalCallTypes: obj});
 }

  handleAlarmFilteClick = () => {    
    this.setState({ openLiveCallAlertDlg:true });
  }
  onChangeStatValue = (e) => {
    this.setState({statsValue : e.target.value})
    if(this.state.selCardName === "firstCard"){
      let value = e.target.value;
      this.setState({firstCardStat : value })
    }
    if(this.state.selCardName === "secondCard"){
      let value = e.target.value;
      this.setState({secondCardStat : value })
    }
    if(this.state.selCardName === "thirdCard"){
      let value = e.target.value;
      this.setState({thirdCardStat : value })
    }
    if(this.state.selCardName === "fourthCard"){
      let value = e.target.value;
      this.setState({fourthCardStat : value })
    }
    this.setState({widgetTitleTextState: e.target.value})
  }

  onChangeCallClassValue = (e,value) => {
    const selVal = Array.prototype.map.call(value, function(item) { return item.value; }).join(",");
    this.setState({ callClassSelVal: selVal.split(',') });
  }

  onChangeSixWidgetOption = (e) => {
    let value = e.target.value;
    if(value == 1){
      this.setState({widgetTitleTextState:"Abandoned Vs Handled Calls"});
      this.setState({sixWidgetTitle:"Abandoned Vs Handled Calls"});
    }
    else{
      this.setState({widgetTitleTextState:"Call Volume By trunk"});
      this.setState({sixWidgetTitle:"Call Volume By trunk"});
    }
    this.setState({sixWidgetSelOption : value })
  }

  handleOnApplyFiltersClick = () => {
    if(this.state.bigChartData === "data1"){
      this.setState({startDateRange: this.formatDate()})
      this.setState({endDateRange: dayjs(this.formatDate()).add(1, 'day').toISOString()});
    } else if(this.state.bigChartData === "data2"){
      this.setState({startDateRange: this.getFirstDateWeek()})
      this.setState({endDateRange: this.getLastDateWeek()})
    } else if(this.state.bigChartData === "data3"){
      this.setState({startDateRange: this.getFirstDateMonth()})
      this.setState({endDateRange: this.getLastDateMonth()})
    }

    this.setState({isOpen:false})
    this.getTotalCalls().catch();
    this.getPreviousTotalCalls().catch();
    this.getCallStatusStats().catch();
    this.getPrevCallStatusStats().catch();

    if(this.state.selTableView === "live-view"){
      this.getRecentCallsLineChartData().catch();
    }
    else if(this.state.selTableView === "top10-dialed") {
      this.getTopTenDialedCallsChart().catch();
    }
    else if(this.state.selTableView === "top10-received"){
      this.getTopTenReceivedCallsChart().catch();
    }
    else if(this.state.selTableView === "top10-most-used-ext"){
      this.getTopTenUsedExtsChart().catch();
    }
  }

  formatDate = () => {
    const d = dayjs(this.state.filterDateFrom);
    return d.toISOString();
  }

  getFirstDateWeek = () => {
    const d = dayjs(this.state.filterDateFrom);
    return d.startOf('week').toISOString();
  }

  getLastDateWeek = () => {
    const d = dayjs(this.state.filterDateFrom);
    return d.endOf('week').toISOString();
  }

  getFirstDateMonth = () => {
    const d = dayjs(this.state.filterDateFrom);
    return d.startOf('month').toISOString();
  }

  getLastDateMonth = () => {
    const d = dayjs(this.state.filterDateFrom);
    return d.endOf('month').toISOString();
  }

  postDashboardSett= async (dashSett) => { //add the filters
    try{      
      const token = await this.props.auth0.getAccessTokenSilently();
        const api = new ApiCall(token);

      const res = await api.postDashboardSettings(dashSett);
      if(res.status === 200) {
        console.log("success");
        return;
      }
      console.log("error"); 
      alert("Error");
    }
    catch(err) {
      console.log(err);
      
    }  
  };

  getDashboardSett = async ()=> { //add the filters
    const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);

       api
         .getDashboardSettings()
         .then((response) => {
            this.setState({firstTopWidgetTitle: response.data.dashboard.firstWidgetTitle});
            this.setState({secondTopWidgetTitle: response.data.dashboard.secondWidgetTitle});
            this.setState({thirdTopWidgetTitle: response.data.dashboard.thirdWidgetTitle});
            this.setState({fourthTopWidgetTitle: response.data.dashboard.fourthWidgetTitle});
            this.setState({fithWidgetTitle: response.data.dashboard.fithWidgetTitle});
            this.setState({sixWidgetTitle: response.data.dashboard.sixWidgetTitle});
            this.setState({sevenWidgetTitle: response.data.dashboard.sevenWidgetTitle});
            this.setState({eightWidgetTitle: response.data.dashboard.eightWidgetTitle});
            this.setState({nineWidgetTitle: response.data.dashboard.nineWidgetTitle});    
            this.setState({firstCardStat: response.data.dashboard.firstWidgetSelOpt});    
            this.setState({secondCardStat: response.data.dashboard.secondWidgetSelOpt});    
            this.setState({thirdCardStat: response.data.dashboard.thirdWidgetSelOpt});    
            this.setState({fourthCardStat: response.data.dashboard.fourthWidgetSelOpt});    
            this.setState({callClassSelVal: response.data.dashboard.selCallClass});    
            this.setState({sixWidgetSelOption: response.data.dashboard.sixWidgetSelOpt}); 
            this.setState({ratesEnabled: response.data.dashboard.ratesEnabled});  
            this.setState({trunkEnabled: response.data.dashboard.trunkEnabled});   
          }) 
         .catch((err) => console.log(err));      
  };

  getTotalCalls = async ()=> { //add the filters
    const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);

       api
         .getCallsStatistics(get_filters(this))
         .then((response) => {
            this.setState({
              totalCalls: response.data.todayTotalCalls,
              incCalls: response.data.todayIncCalls,
              outCalls: response.data.todayOutCalls,
              talkAvg: response.data.todayTalkAvg,
              intCalls: response.data.todayIntCalls
            });
          }) 
         .catch((err) => console.log(err));      
  };

  getPreviousTotalCalls = async ()=> { //add the filters
    const [prevStartDate, prevEndDate] = getPrevStartEndDate(this.state);

    const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);

	  const filters = {...get_filters(this)};
	  filters.timeRangeStart = prevStartDate;
	  filters.timeRangeEnd = prevEndDate;

       api
         .getCallsStatistics(filters)
         .then((response) => {
            this.setState({
              prevTotalCalls : response.data.todayTotalCalls,
              prevIncCalls : response.data.todayIncCalls,
              prevIntCalls : response.data.todayIntCalls,
              prevOutCalls: response.data.todayOutCalls,
            });
          }) 
         .catch((err) => console.log(err));      
  };
  
  getCallStatusStats = async ()=> { //add the filters
    const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);

       api
         .getCallStatusStatistics(get_filters(this))
         .then((response) => {
            this.setState({
              answCalls: response.data.answered,
              abandCalls: response.data.abandoned,
              busyCalls: response.data.busy,
            });
            if(this.state.incCalls > 0)
            {
              this.setState({
                handledRate: Math.round(((this.state.incCalls - response.data.abandoned) / this.state.incCalls) * 100) +  " %",
              });
            }
            else{
              this.setState({handledRate: "0 %",})
            }
          }) 
         .catch((err) => console.log(err));      
  };

  getPrevCallStatusStats = async ()=> { //add the filters
    const [prevStartDate, prevEndDate] = getPrevStartEndDate(this.state);

    const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);
	  const filters = {...get_filters(this)};
	  filters.timeRangeStart = prevStartDate;
	  filters.timeRangeEnd = prevEndDate;

       api
         .getCallStatusStatistics(filters)
         .then((response) => {
            this.setState({
              prevAnswCalls: response.data.answered,
              prevAbandCalls: response.data.abandoned,
              prevBusyCalls: response.data.busy,
            });
            if(this.state.prevIncCalls > 0)
            {
              this.setState({
                prevHandledRate: Math.round(((this.state.prevIncCalls - response.data.abandoned) / this.state.prevIncCalls) * 100) + " %",
              });
            }
            else{
              this.setState({
                prevHandledRate: "0 %",
              });
            }
          }) 
         .catch((err) => console.log(err));      
  };

  getRecentCallsLineChartData = async ()=> {
    const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);

       api
         .getRecentCallsLineChart(get_filters(this))
         .then((response) => {
           this.setState({
            recentCallsLineChart: {
              data: canvas => {
                let ctx = canvas.getContext("2d");

                let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

                gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
                gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
                gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
                return {
                  labels: response.data.callsTime.map(label => (dayjs(label).format("HH:mm"))),
                  datasets: [
                    {
                      label: "Calls",
                      fill: true,
                      backgroundColor: gradientStroke,
                      borderColor: "#1f8ef1",
                      color: "#FFF",  
                      borderWidth: 2,
                      borderDash: [],
                      borderDashOffset: 0.0,
                      pointBackgroundColor: "#1f8ef1",
                      pointBorderColor: "rgba(255,255,255,0)",
                      pointHoverBackgroundColor: "#1f8ef1",
                      pointBorderWidth: 20,
                      pointHoverRadius: 4,
                      pointHoverBorderWidth: 15,
                      pointRadius: 0,
                      data: response.data.callsTotals
                    }
                  ]
                };
              },
              options: chart_1_2_3_options
            }
           })
         })
         .catch((err) => console.log(err));
  };
  getTopTenDialedCallsChart = async() => {
    const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);

       api
         .getTopTenDialedChart(get_filters(this))
         .then((response) => {
          this.setState({
            topTenDialedCallsChart:{
              data: {
                labels: response.data.ext,
                datasets: [
                  {
                    label: "Calls",
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    backgroundColor:["#5e72e4", "#5603ad" , "#8965e0", "#f3a4b5", "#f5365c",
                      "#fb6340" , "#ffd600", "#2dce89","#2bffc6", "#11cdef"],
                    borderWidth: 0,
                    data: response.data.totalCalls
                  }
                ]
              },
              options: {
                cutoutPercentage: 50,
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                  display: true,
                  fontColor: "#FFF",  
                  bodyFontColor: "#FFF",
                  fontSize: 14,
                  labels: {
                    fontSize: 14,
                    fontColor: "#FFF",
                  },
                },
                tooltips: {
                  backgroundColor: "#344675",
                  titleFontColor: "#FFF",
                  bodyFontColor: "#FFF",
                  bodySpacing: 4,
                  xPadding: 12,
                  mode: "nearest",
                  intersect: 0,
                  position: "nearest"
                },
                
                scales: {
                  yAxes: [
                    {
                      display: 0,
                      ticks: {
                        display: false
                      },
                      gridLines: {
                        drawBorder: false,
                        zeroLineColor: "transparent",
                        color: "rgba(255,255,255,0.05)"
                      }
                    }
                  ],
                  xAxes: [
                    {
                      display: 0,
                      barPercentage: 1.6,
                      gridLines: {
                        drawBorder: false,
                        color: "rgba(255,255,255,0.1)",
                        zeroLineColor: "transparent"
                      },
                      ticks: {
                        display: false
                      }
                    }
                  ]
                }
              }
            }

          })
         })
         .catch((err) => console.log(err));


  }

  getTopTenReceivedCallsChart = async() => {
    const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);

       api
         .getTopTenReceivedChart(get_filters(this))
         .then((response) => {
           console.log(response.data)
          this.setState({
            topTenRecCallsChart:{
              data: {
                labels: response.data.ext,
                datasets: [
                  {
                    label: "Calls",
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    backgroundColor:["#5e72e4", "#5603ad" , "#8965e0", "#f3a4b5", "#f5365c",
                      "#fb6340" , "#ffd600", "#2dce89","#2bffc6", "#11cdef"],
                    borderWidth: 0,
                    data: response.data.totalCalls
                  }
                ]
              },
              options: {
                cutoutPercentage: 50,
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                  display: true,
                  fontColor: "#FFF",
                  bodyFontColor: "#FFF",
                  fontSize: 14,
                  labels: {
                    fontSize: 14,
                    fontColor: "#FFF",
                  },
                },
                tooltips: {
                  backgroundColor: "#344675",
                  titleFontColor: "#FFF",
                  bodyFontColor: "#FFF",
                  bodySpacing: 4,
                  xPadding: 12,
                  mode: "nearest",
                  intersect: 0,
                  position: "nearest"
                },
                scales: {
                  yAxes: [
                    {
                      display: 0,
                      ticks: {
                        display: false
                      },
                      gridLines: {
                        drawBorder: false,
                        zeroLineColor: "transparent",
                        color: "rgba(255,255,255,0.05)"
                      }
                    }
                  ],
                  xAxes: [
                    {
                      display: 0,
                      barPercentage: 1.6,
                      gridLines: {
                        drawBorder: false,
                        color: "rgba(255,255,255,0.1)",
                        zeroLineColor: "transparent"
                      },
                      ticks: {
                        display: false
                      }
                    }
                  ]
                }
              }
            }

          })
         })
         .catch((err) => console.log(err));
  }

  getTopTenUsedExtsChart = async() => {
    const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);

    api.getTopTenMostUsedExtChart(get_filters(this))
         .then((response) => {
          this.setState({
            topTenUsedExtsChart:{
              data: canvas => {
                let ctx = canvas.getContext("2d");
                const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

                gradientStroke.addColorStop(1, "rgba(253,93,147,0.8)");
                gradientStroke.addColorStop(0, "rgba(253,93,147,0)"); //blue colors
                return {
                  labels: response.data.ext,
                  datasets: [
                    {
                      label: "Calls",
                      fill: true,
                      backgroundColor: gradientStroke,
                      hoverBackgroundColor: gradientStroke,
                      borderColor: "#ff5991",
                      borderWidth: 2,
                      borderDash: [],
                      borderDashOffset: 0.0,
                      data: response.data.totalCalls
                    }
                  ]
                };
              },
              options: {
                maintainAspectRatio: false,
                legend: {
                  display: true,
                  fontColor: "#FFF",  
                  bodyFontColor: "#FFF",
                  fontSize: 14,
                  labels: {
                    fontSize: 14,
                    fontColor: "#FFF",
                  },
                },
                tooltips: {
                  backgroundColor: "#344675",
                  titleFontColor: "#FFF",
                  bodyFontColor: "#FFF",
                  bodySpacing: 4,
                  xPadding: 12,
                  mode: "nearest",
                  intersect: 0,
                  position: "nearest"
                },
                responsive: true,
                scales: {
                  yAxes: [
                    {
                      gridLines: {
                        drawBorder: false,
                        color: "rgba(253,93,147,0.1)",
                        zeroLineColor: "transparent"
                      },
                      ticks: {
                        suggestedMin: 5,
                        suggestedMax: 30,
                        padding: 5,
                        fontColor: "#FFF"
                      }
                    }
                  ],
                  xAxes: [
                    {
                      gridLines: {
                        drawBorder: false,
                        color: "rgba(253,93,147,0.1)",
                        zeroLineColor: "transparent"
                      },
                      ticks: {
                        padding: 20,
                        fontColor: "#FFF"
                      }
                    }
                  ]
                }
              }

            }

          })
         })
         .catch((err) => console.log(err));
  }
  getDashboardExternalPreference = async() => {
      const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);
      api.getIni("DashboardOptionExternal").then(value => {
          if(value === 'External') {
              this.setState({
                  externalCallTypes: [{label: "External", value: 7}],
              });
          } else {
              this.setState({
                  externalCallTypes: [],
              });
          }
      })
  }

  componentDidMount() {
    this.getDashboardExternalPreference().then(() => {
        this.getDashboardSett().catch();
        this.getTotals().catch();
        const getData = () => {
            this.getTotalCalls().catch();
            this.getPreviousTotalCalls().catch();
            this.getCallStatusStats().catch();
            this.getPrevCallStatusStats().catch();
            if (this.state.selTableView === "live-view") {
                this.getRecentCallsLineChartData().catch();
            } else if (this.state.selTableView === "top10-dialed") {
                this.getTopTenDialedCallsChart().catch();
            } else if (this.state.selTableView === "top10-received") {
                this.getTopTenReceivedCallsChart().catch();
            } else if (this.state.selTableView === "top10-most-used-ext") {
                this.getTopTenUsedExtsChart().catch();
            }
        }

        getData();
        this.intervalId = setInterval(getData, 8000);
    });
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  setBgChartData = name => {
    this.setState({
      bigChartData: name
    });
 
    if (name === "data1"){
      this.setState({startDateRange: this.formatDate()})
      this.setState({endDateRange: dayjs(this.formatDate()).add(1, 'day').toISOString()});
      this.setState({fithWidgetTitle : "Today's Calls"});
      this.setState({fithWidgetName : "Today's Calls"});
    } else if (name === "data2"){
      this.setState({startDateRange: this.getFirstDateWeek()})
      this.setState({endDateRange: this.getLastDateWeek()})
      this.setState({fithWidgetTitle : "This Week's Calls"});
      this.setState({fithWidgetName : "This Week's Calls"});
    } else if (name === "data3"){
      this.setState({startDateRange: this.getFirstDateMonth()})
      this.setState({endDateRange: this.getLastDateMonth()})
      this.setState({fithWidgetTitle : "This Month's Calls"});
      this.setState({fithWidgetName : "This Month's Calls"});
    }

    // this.getTotalCalls()
    if(name === "data1"){
      this.setState({ cardsFooterRange: "Prev Day"});
    }
    if(name === "data2"){
      this.setState({ cardsFooterRange: "Prev Week"});
    }
    if(name === "data3"){
      this.setState({ cardsFooterRange: "Prev Month"});
    }
    if(this.state.totalCalls < this.state.prevTotalCalls)
    {
      this.setState({ prevTotalCallsIcon: "tim-icons icon-minimal-down"});
    }
    else if(this.state.totalCalls > this.state.prevTotalCalls){
      this.setState({ prevTotalCallsIcon: "tim-icons icon-minimal-up"});
    }
    if(this.state.incCalls < this.state.prevIncCalls)
    {
      this.setState({ prevIncCallsIcon: "tim-icons icon-minimal-down"});
    }
    else if(this.state.incCalls > this.state.prevIncCalls){
      this.setState({ prevIncCallsIcon: "tim-icons icon-minimal-up"});
    }
    if(this.state.outCalls < this.state.prevOutCalls)
    {
      this.setState({ prevOutCallsIcon: "tim-icons icon-minimal-down"});
    }
    else if(this.state.outCalls > this.state.prevOutCalls){
      this.setState({ prevOutCallsIcon: "tim-icons icon-minimal-up"});
    }
    if(this.state.intCalls < this.state.prevIntCalls)
    {
      this.setState({ prevIntCallsIcon: "tim-icons icon-minimal-down"});
    }
    else if(this.state.intCalls > this.state.prevIntCalls){
      this.setState({ prevIntCallsIcon: "tim-icons icon-minimal-up"});
    }
    this.getTotalCalls().catch();
    this.getPreviousTotalCalls().catch();
    this.getCallStatusStats().catch();
    this.getPrevCallStatusStats().catch();
  };
  
  setTableToShow = (viewName, cardTitle) => {
    this.setState({
      selTableView: viewName,
      nineWidgetTitle: cardTitle
    });
    if(viewName === "live-view"){
      this.getRecentCallsLineChartData().catch();
    }
    else if(viewName === "top10-dialed") {
      this.getTopTenDialedCallsChart().catch();
    }
    else if(viewName === "top10-received"){
      this.getTopTenReceivedCallsChart().catch();
    }
    else if(viewName === "top10-most-used-ext"){
      this.getTopTenUsedExtsChart().catch();
    }
  };

  setClassType = () =>{
    if(document.body.classList.contains("white-content")){this.setState({classnameVar: "",activeModeClass:"white-content"})}
    else if(document.body.classList.contains("dark-content")){this.setState({classnameVar: "modal-black", activeModeClass:"dark-content"})}
  }
  handleClickOpenHourlyChartDlg = () => {
    this.setState({
      openHourlyChartDialog: true
    });
    this.setClassType()
  };
  handleClickCloseHourlyChartDlg = () => {
    this.setState({
      openHourlyChartDialog: false
    });
    this.setClassType()
  };

  handleClickOpenTrunkUChartDlg = () => {
    this.setState({
      openTrunkUChartDlg: true
    });
    this.setClassType()
  };
  handleClickCloseTrunkUChartDlg = () => {
    this.setState({
      openTrunkUChartDlg: false
    });
    this.setClassType()
  };

  handleClickOpenTopDeptChartDlg = () => {
    this.setState({
      openTopDeptChartDlg: true
    });
    this.setClassType()
  };
  handleClickCloseTopDeptChartDlg = () => {
    this.setState({
      openTopDeptChartDlg: false
    });
    this.setClassType()
  };

  handleClickOpenCallClassChartDlg = () => {
    this.setState({
      openCallClassChartDlg: true
    });
    this.setClassType()
  };
  handleClickCloseCallClassChartDlg = () => {
    this.setState({
      openCallClassChartDlg: false
    });
    this.setClassType()
  };

  handleClickOpenCallsTableChartDlg = () => {
    this.setState({
      openCallsTableChartDlg: true
    });
    this.setClassType()
  };
  handleClickCloseCallsTableChartDlg = () => {
    this.setState({
      openCallsTableChartDlg: false
    });
    this.setClassType()
  };

  handleClickOpenTotalCallsAlertDlg = () => {
    this.setState({
      openTotalCallsAlertDlg: true
    });
    this.setClassType()
  };
  handleClickCloseTotalCallsAlertDlg = () => {
    this.setState({
      openTotalCallsAlertDlg: false
    });
    this.setClassType()
  };

  handleClickCloseIncCallsAlertDlg = () => {
    this.setState({
      openIncCallsAlertDlg: false
    });
    this.setClassType()
  };

  handleClickCloseOutCallsAlertDlg = () => {
    this.setState({
      openOutCallsAlertDlg: false
    });
    this.setClassType()
  };

  handleClickCloseIntCallsAlertDlg = () => {
    this.setState({
      openIntCallsAlertDlg: false
    });
    this.setClassType()
  };

  handleClickOpenTrunkUtilAlertDlg = () => {
    this.setState({
      openTrunkUtilAlertDlg: true
    })
    this.setClassType()
  };
  handleClickCloseTrunkUtilAlertDlg = () => {
    this.setState({
      openTrunkUtilAlertDlg: false
    });
    this.setClassType()
  };

  handleClickCloseCallsDeptAlertDlg = () => {
    this.setState({
      openCallsByDeptAlertDlg: false
    });
    this.setClassType()
  };

  handleClickOpenCallClassAlertDlg = () => {
    this.setState({
      openCallClassAlertDlg: true
    });
    this.setClassType()
  };
  handleClickCloseCallClassAlertDlg = () => {
    this.setState({
      openCallClassAlertDlg: false
    });
    this.setClassType()
  };
  handleClickCloseLiveCallDialog = () => {
    this.setState({
      openLiveCallAlertDlg: false
    });
    this.setClassType()
  };

  handleWidgetTitleState = (e) => {
    this.setState({widgetTitleTextState:  e.target.value})
  }
  handleOpenSettDialog = (cardName) => {
    if(cardName === "firstCard"){
      this.setState({widgetTitleTextState:this.state.firstTopWidgetTitle});
      this.setState({statsValue : this.state.firstCardStat});
      this.setState({showDlgStats : 1}); 
      this.setState({showDlgCallType : 0}); 
      this.setState({showDlgSixWidgetOption : 0}); 
    }
    if(cardName === "secondCard"){
      this.setState({widgetTitleTextState:this.state.secondTopWidgetTitle});
      this.setState({statsValue : this.state.secondCardStat});
      this.setState({showDlgStats : 1});
      this.setState({showDlgCallType : 0}); 
      this.setState({showDlgSixWidgetOption : 0}); 
    }
    if(cardName === "thirdCard"){
      this.setState({widgetTitleTextState:this.state.thirdTopWidgetTitle});
      this.setState({statsValue : this.state.thirdCardStat});
      this.setState({showDlgStats : 1});
      this.setState({showDlgCallType : 0}); 
      this.setState({showDlgSixWidgetOption : 0}); 
    }
    if(cardName === "fourthCard"){
      this.setState({widgetTitleTextState:this.state.fourthTopWidgetTitle});
      this.setState({statsValue : this.state.fourthCardStat})
      this.setState({showDlgStats : 1});
      this.setState({showDlgCallType : 0}); 
      this.setState({showDlgSixWidgetOption : 0}); 
    }
    if(cardName === "fithCard"){
      this.setState({widgetTitleTextState:this.state.fithWidgetTitle});
      this.setState({showDlgStats : 0});
      this.setState({showDlgCallType : 0}); 
      this.setState({showDlgSixWidgetOption : 0}); 
    }
    if(cardName === "sixCard"){
      this.setState({widgetTitleTextState:this.state.sixWidgetTitle});
      this.setState({showDlgStats : 0});
      this.setState({showDlgCallType : 0}); 
      this.setState({showDlgSixWidgetOption : 1}); 
    }
    if(cardName === "sevenCard"){
      this.setState({widgetTitleTextState:this.state.sevenWidgetTitle});
      this.setState({showDlgStats : 0});
      this.setState({showDlgCallType : 0}); 
      this.setState({showDlgSixWidgetOption : 0});
    }
    if(cardName === "eighthCard"){
      this.setState({widgetTitleTextState:this.eightWidgetTitle});
      this.setState({showDlgStats : 0});
      this.setState({showDlgCallType : 1}); 
      this.setState({showDlgSixWidgetOption : 0});
    }
    if(cardName === "nineCard"){
      this.setState({widgetTitleTextState:this.nineWidgetTitle});
      this.setState({showDlgStats : 0});
      this.setState({showDlgCallType : 0}); 
      this.setState({showDlgSixWidgetOption : 0});
    }
    this.setState({openSettDialog: true});
    this.setState({selCardName:cardName});
    this.getDashboardSett().catch();

  }
  handleCloseSettDialog = () => {
   
    let card1Title = this.state.firstTopWidgetTitle;
    let card2Title = this.state.secondTopWidgetTitle;
    let card3Title = this.state.thirdTopWidgetTitle;
    let card4Title = this.state.fourthTopWidgetTitle;
    let card5Title = this.state.fithWidgetTitle;
    let card6Title = this.state.sixWidgetTitle;
    let card7Title = this.state.sevenWidgetTitle;
    let card8Title = this.state.eightWidgetTitle;
    let card9Title = this.state.nineWidgetTitle;

    if(this.state.selCardName === "firstCard"){
      this.setState({firstTopWidgetTitle:this.state.widgetTitleTextState});
      card1Title = this.state.widgetTitleTextState;
    }
    if(this.state.selCardName === "secondCard"){
      this.setState({secondTopWidgetTitle:this.state.widgetTitleTextState});
      card2Title = this.state.widgetTitleTextState;
    }
    if(this.state.selCardName === "thirdCard"){
      this.setState({thirdTopWidgetTitle:this.state.widgetTitleTextState});
      card3Title = this.state.widgetTitleTextState;
    }
    if(this.state.selCardName === "fourthCard"){
      this.setState({fourthTopWidgetTitle:this.state.widgetTitleTextState});
      card4Title = this.state.widgetTitleTextState;
    }
    if(this.state.selCardName === "fithCard"){
      this.setState({fithWidgetTitle:this.state.widgetTitleTextState});
      card5Title = this.state.widgetTitleTextState;
    }
    if(this.state.selCardName === "sixCard"){
      this.setState({sixWidgetTitle:this.state.widgetTitleTextState});
      card6Title = this.state.widgetTitleTextState;
    }
    if(this.state.selCardName === "sevenCard"){
      this.setState({sevenWidgetTitle:this.state.widgetTitleTextState});
      card7Title = this.state.widgetTitleTextState;
    }
    if(this.state.selCardName === "eightCard"){
      this.setState({eightWidgetTitle:this.state.widgetTitleTextState});
      card8Title = this.state.widgetTitleTextState;
    }
    if(this.state.selCardName === "nineCard"){
      this.setState({nineWidgetTitle:this.state.widgetTitleTextState});
      card9Title = this.state.widgetTitleTextState;
    }
    let sett = {};
    sett.firstWidgetTitle = card1Title;
    sett.secondWidgetTitle = card2Title;
    sett.thirdWidgetTitle = card3Title;
    sett.fourthWidgetTitle = card4Title;
    sett.fithWidgetTitle = card5Title;
    sett.sixWidgetTitle = card6Title;
    sett.sevenWidgetTitle = card7Title;
    sett.eightWidgetTitle = card8Title;
    sett.nineWidgetTitle = card9Title;
    sett.firstWidgetSelOpt = this.state.firstCardStat;
    sett.secondWidgetSelOpt = this.state.secondCardStat;
    sett.thirdWidgetSelOpt = this.state.thirdCardStat;
    sett.fourthWidgetSelOpt = this.state.fourthCardStat;
    sett.fithWidgetSelOpt = "";
    sett.sixWidgetSelOpt = this.state.sixWidgetSelOption;
    sett.sevenWidgetSelOpt = "";
    sett.eightWidgetSelOpt = "";
    sett.nineWidgetSelOpt = "";
    sett.selCallClass = this.state.callClassSelVal;
    sett.ratesEnabled = this.state.ratesEnabled;
    sett.trunkEnabled = this.state.trunkEnabled;
    let dashSett = {};
    dashSett.dashboard = sett;
    
    this.postDashboardSett(dashSett).catch();
    this.setState({openSettDialog: false});
  }

  render() {
    return (
      <>      
       
        <AlertTrunkUtilDlg open={this.state.openTrunkUtilAlertDlg} handleClose={()=>this.handleClickCloseTrunkUtilAlertDlg()} className={this.state.classnameVar} activeModeClass={this.state.activeModeClass} />
        <HourlyChartFilterDlg open={this.state.openHourlyChartDialog} handleClose={()=>this.handleClickCloseHourlyChartDlg()} className={this.state.classnameVar} activeModeClass={this.state.activeModeClass} />
        <TrunkUChartFilterDlg open={this.state.openTrunkUChartDlg} handleClose={()=>this.handleClickCloseTrunkUChartDlg()} className={this.state.classnameVar} activeModeClass={this.state.activeModeClass} />
        <TopDeptChartFilterDlg open={this.state.openTopDeptChartDlg} handleClose={()=>this.handleClickCloseTopDeptChartDlg()} className={this.state.classnameVar} activeModeClass={this.state.activeModeClass} />
        <CallClassChartFilterDlg open={this.state.openCallClassChartDlg} handleClose={()=>this.handleClickCloseCallClassChartDlg()} className={this.state.classnameVar} activeModeClass={this.state.activeModeClass} />
        <CallsTableChartFilterDlg open={this.state.openCallsTableChartDlg} handleClose={()=>this.handleClickCloseCallsTableChartDlg()} className={this.state.classnameVar} activeModeClass={this.state.activeModeClass} />
        <AlertTotalCallsDlg open={this.state.openTotalCallsAlertDlg} handleClose={()=>this.handleClickCloseTotalCallsAlertDlg()} className={this.state.classnameVar} activeModeClass={this.state.activeModeClass} />
        <AlertIncCallsDlg open={this.state.openIncCallsAlertDlg} handleClose={()=>this.handleClickCloseIncCallsAlertDlg()} className={this.state.classnameVar} activeModeClass={this.state.activeModeClass} />
        <AlertIntCallsDlg open={this.state.openIntCallsAlertDlg} handleClose={()=>this.handleClickCloseIntCallsAlertDlg()} className={this.state.classnameVar} activeModeClass={this.state.activeModeClass} />
        <AlertOutCallsDlg open={this.state.openOutCallsAlertDlg} handleClose={()=>this.handleClickCloseOutCallsAlertDlg()} className={this.state.classnameVar} activeModeClass={this.state.activeModeClass} />
        <AlertCallsByDeptDlg open={this.state.openCallsByDeptAlertDlg} handleClose={()=>this.handleClickCloseCallsDeptAlertDlg()} className={this.state.classnameVar} activeModeClass={this.state.activeModeClass} />
        <AlertCallClassDlg open={this.state.openCallClassAlertDlg} handleClose={()=>this.handleClickCloseCallClassAlertDlg()} className={this.state.classnameVar} activeModeClass={this.state.activeModeClass} />
        <LiveCallAlertDialog open={this.state.openLiveCallAlertDlg} handleClose={()=>this.handleClickCloseLiveCallDialog()} className={this.state.classnameVar} activeModeClass={this.state.activeModeClass} />
        <SettingsTrunkDialog 
        open={this.state.openSettDialog} 
        handleClose={this.handleCloseSettDialog} 
        className={this.state.classnameVar} 
        activeModeClass={this.state.activeModeClass} 
        titleText={this.state.widgetTitleTextState}
        textBoxClickEv={this.handleWidgetTitleState}
        selectValue = {this.state.statsValue}
        onChangeSelect = {this.onChangeStatValue}
        callClassVal = {this.state.callClassSelVal}
        onChangeCallClassSelect = {this.onChangeCallClassValue}
        showStats = {this.state.showDlgStats}
        showCallTypesFilter = {this.state.showDlgCallType}
        showSixWidgetOption = {this.state.showDlgSixWidgetOption}
        selSixWidgetOtion = {this.state.sixWidgetSelOption}
        trunkEnabled = {this.state.trunkEnabled}
        onChangeSixWidOptSelect = {this.onChangeSixWidgetOption}
        >
          
        </SettingsTrunkDialog>
        
        <div id = "mainContent" className="content">
          <Row>
          <Col md="6" style={{marginLeft:"0px"}}>
        <div id = "filterBtn" style={{marginTop:"-35px"}}>
        <Tooltip title="Dashboard Filters">
         <IconButton aria-label="Filters" onClick={() => this.openFilterTab()}>
         <FilterListIcon />
         <h5>Filters</h5>
         </IconButton>
         </Tooltip>
        </div>   
        
        </Col> 
          <Col sm="6" style={{marginTop:"-18px"}}>
              <ButtonGroup
                className="btn-group-toggle float-right"
                data-toggle="buttons"
              >
                <Button
                  color="warning"
                  id="0"
                  size="sm"
                  tag="label"
                  className={classNames("btn-simple", {
                    active: this.state.bigChartData === "data1"
                  })}
                  onClick={() => this.setBgChartData("data1")}
                >
                  <input defaultChecked name="options" type="radio" />
                  <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    Daily
                  </span>
                  <span className="d-block d-sm-none">
                    <i className="tim-icons icon-single-02" />
                  </span>
                </Button>
                <Button
                  color="warning"
                  id="1"
                  size="sm" 
                  tag="label"
                  className={classNames("btn-simple", {
                    active: this.state.bigChartData === "data2"
                  })}
                  onClick={() => this.setBgChartData("data2")}
                >
                  <input name="options" type="radio" />
                  <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    Weekly
                  </span>
                  <span className="d-block d-sm-none">
                    <i className="tim-icons icon-gift-2" />
                  </span>
                </Button>
                <Button
                  color="warning"
                  id="2"
                  size="sm"
                  tag="label"
                  className={classNames("btn-simple", {
                    active: this.state.bigChartData === "data3"
                  })}
                  onClick={() => this.setBgChartData("data3")}
                >
                  <input name="options" type="radio" />
                  <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    Monthly
                  </span>
                  <span className="d-block d-sm-none">
                    <i className="tim-icons icon-tap-02" />
                  </span>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
        {/*dashboard filters*/}
        <Collapse isOpen={this.state.isOpen}>
        { <div style={{height:'350px', marginTop:'15px', marginLeft:'20px'}}>
        <Row >
          <Col sm="6">
          <DateTimePicker  activeModeClass="dark-content" label="From Date"  date = {this.state.filterDateFrom} onChange= {this.dateFromOnChange} />
          </Col>
        </Row>
        <Row style={{marginTop:'30px', marginLeft:'-7px'}}>
        <Col sm="6">
          <CheckBoxList onChange={(obj)=>this.handleDivAOnChange(obj)} value= {this.state.deptSelected} level="A" activeModeClass="dark-content" label="Select Department(s)" />
        </Col>  
        <Col sm="6">
          <CheckBoxList onChange = {(obj)=>this.handleDivBOnChange(obj)} value= {this.state.divSelected} level="B" activeModeClass="dark-content" label="Select Branch(s)" />
        </Col>  
        
        </Row>
        <Row style={{marginTop:'30px', marginLeft:'-7px'}}>
        <Col sm="6">
          <CheckBoxList onChange = {(obj)=>this.handleDivCOnChange(obj)} value= {this.state.branchSelected} level="C" activeModeClass="dark-content" label="Select Region(s)" />
        </Col>
        <Col sm="4">
            <CheckBoxExternalCallType onChange = {(obj)=>this.handleExternalCallClassOnChange(obj)}
                                      value= {this.state.externalCallTypes}
                                      level="" label="External/Internal" activeModeClass="dark-content" />
        </Col>
        </Row>
        
        <Row style={{marginTop:'25px', marginLeft:'-20px'}}>
         <Col sm="6"> </Col>
         <Col sm="6">  </Col>
        </Row>
        <Row style={{marginTop:'20px', marginLeft:'5x'}}>
          <Col sm="6"><Button color="warning" onClick={ ()=>this.handleOnApplyFiltersClick()}>Apply</Button></Col>   
        </Row>
        </div> }                           
        </Collapse>
        </Row>
          <Row style={{marginTop:"5px"}}>
          
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-warning">
                      <CallIcon style={{ color: '#FFF', height:'30px' , width:'30px', marginTop:"10px"}}/>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <CardTitle tag="h4" style={{cursor:"grab"}}>{this.state.firstTopWidgetTitle}</CardTitle>
                        <CardTitle tag="h2" style={{cursor:"grab"}}>
                        {this.state.firstCardStat === "TotalCalls" &&  this.state.totalCalls}
                        {this.state.firstCardStat === "Incoming" &&  this.state.incCalls}
                        {this.state.firstCardStat === "Outgoing" &&  this.state.outCalls}
                        {this.state.firstCardStat === "Internal" &&  this.state.intCalls}
                        {this.state.firstCardStat === "Abandoned" &&  this.state.abandCalls}
                        {this.state.firstCardStat === "Answered" &&  this.state.answCalls}
                        {this.state.firstCardStat === "Busy" &&  this.state.busyCalls}
                        {this.state.firstCardStat === "HandledRate" &&  this.state.handledRate}
                        </CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter style={{marginTop:"-30px"}}>
                  <hr />   
                    <div className="stats" style={{ marginTop:"-20px"}}>
                      <div style = {{height:"20px", fontColor:"#FFF"}} />
                      <h4 style = {{ color:"#FFF"}}>
                      {this.state.cardsFooterRange}: {this.state.firstCardStat === "TotalCalls" &&  this.state.prevTotalCalls}
                      {this.state.firstCardStat === "Incoming" &&  this.state.prevIncCalls}
                      {this.state.firstCardStat === "Outgoing" &&  this.state.prevOutCalls}
                      {this.state.firstCardStat === "Internal" &&  this.state.prevIntCalls}
                      {this.state.firstCardStat === "Abandoned" &&  this.state.prevAbandCalls}
                      {this.state.firstCardStat === "Answered" &&  this.state.prevAnswCalls}
                      {this.state.firstCardStat === "Busy" &&  this.state.prevBusyCalls}
                      {this.state.firstCardStat === "HandledRate" &&  this.state.prevHandledRate}
                      </h4>
                    </div>
                    <div className="stats" style={{marginLeft:"80%", marginTop:"-35px"}}>
                      <UncontrolledDropdown>
                      <DropdownToggle
                        caret
                        className="btn-icon"
                        color="link"
                        data-toggle="dropdown"
                        type="button"
                      >
                        <i className="tim-icons icon-settings-gear-63" />
                      </DropdownToggle>
                      <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                      <DropdownItem
                      href=""
                      onClick={()=>{ this.handleOpenSettDialog("firstCard")}}
                      >
                        Settings
                      </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>      
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-primary">
                      <CallReceivedIcon style={{ color: '#FFF', height:'30px' , width:'30px', marginTop:"10px"}}/>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                      <CardTitle tag="h4" style={{cursor:"grab"}}>{this.state.secondTopWidgetTitle}</CardTitle>
                        <CardTitle tag="h2" style={{cursor:"grab"}}>
                        {this.state.secondCardStat === "TotalCalls" &&  this.state.totalCalls}
                        {this.state.secondCardStat === "Incoming" &&  this.state.incCalls}
                        {this.state.secondCardStat === "Outgoing" &&  this.state.outCalls}
                        {this.state.secondCardStat === "Internal" &&  this.state.intCalls}
                        {this.state.secondCardStat === "Abandoned" &&  this.state.abandCalls}
                        {this.state.secondCardStat === "Answered" &&  this.state.answCalls}
                        {this.state.secondCardStat === "Busy" &&  this.state.busyCalls}
                        {this.state.secondCardStat === "HandledRate" &&  this.state.handledRate}
                        </CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter style={{marginTop:"-30px"}}>
                  <hr />
                  <div className="stats" style={{ marginTop:"-20px"}}>
                  <div style = {{height:"20px"}} />
                    <h4 style = {{ color:"#FFF"}} > {this.state.cardsFooterRange}: {this.state.secondCardStat === "TotalCalls" &&  this.state.prevTotalCalls}
                    {this.state.secondCardStat === "Incoming" &&  this.state.prevIncCalls}
                    {this.state.secondCardStat === "Outgoing" &&  this.state.prevOutCalls}
                    {this.state.secondCardStat === "Internal" &&  this.state.prevIntCalls}
                    {this.state.secondCardStat === "Abandoned" &&  this.state.prevAbandCalls}
                    {this.state.secondCardStat === "Answered" &&  this.state.prevAnswCalls}
                    {this.state.secondCardStat === "Busy" &&  this.state.prevBusyCalls}
                    {this.state.secondCardStat === "HandledRate" &&  this.state.prevHandledRate}
                    </h4>
                  </div>
                  <div className="stats" style={{marginLeft:"80%", marginTop:"-35px"}}>
                      <UncontrolledDropdown>
                      <DropdownToggle
                        caret
                        className="btn-icon"
                        color="link"
                        data-toggle="dropdown"
                        type="button"
                      >
                        <i className="tim-icons icon-settings-gear-63" />
                      </DropdownToggle>
                      <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                      {/* <DropdownItem
                      href=""
                      onClick={()=>this.handleClickOpenTopDeptChartDlg()}
                      >
                        Filters
                      </DropdownItem>
                      <DropdownItem
                      href=""
                      onClick={()=>this.handleClickOpenIncCallsAlertDlg()}
                      >
                        Manage Alerts
                      </DropdownItem> */}
                      <DropdownItem
                      href=""
                      onClick={()=>{ this.handleOpenSettDialog("secondCard")}}
                      >
                        Settings
                      </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>      
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-success">
                      <CallMadeIcon style={{ color: '#FFF', height:'30px' , width:'30px', marginTop:"10px"}}/>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <CardTitle tag="h4" style={{cursor:"grab"}}>{this.state.thirdTopWidgetTitle}</CardTitle>
                        <CardTitle tag="h2" style={{cursor:"grab"}}>
                        {this.state.thirdCardStat === "TotalCalls" &&  this.state.totalCalls}
                        {this.state.thirdCardStat === "Incoming" &&  this.state.incCalls}
                        {this.state.thirdCardStat === "Outgoing" &&  this.state.outCalls}
                        {this.state.thirdCardStat === "Internal" &&  this.state.intCalls}
                        {this.state.thirdCardStat === "Abandoned" &&  this.state.abandCalls}
                        {this.state.thirdCardStat === "Answered" &&  this.state.answCalls}
                        {this.state.thirdCardStat === "Busy" &&  this.state.busyCalls}
                        {this.state.thirdCardStat === "HandledRate" &&  this.state.handledRate}
                        </CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter style={{marginTop:"-30px"}}>
                  <hr />
                  <div className="stats" style={{ marginTop:"-20px"}}>
                  <div style = {{height:"20px"}} />
                  <h4 style = {{ color:"#FFF"}} > {this.state.cardsFooterRange}: {this.state.thirdCardStat === "TotalCalls" &&  this.state.prevTotalCalls}
                  {this.state.thirdCardStat === "Incoming" &&  this.state.prevIncCalls}
                  {this.state.thirdCardStat === "Outgoing" &&  this.state.prevOutCalls}
                  {this.state.thirdCardStat === "Internal" &&  this.state.prevIntCalls}
                  {this.state.thirdCardStat === "Abandoned" &&  this.state.prevAbandCalls}
                  {this.state.thirdCardStat === "Answered" &&  this.state.prevAnswCalls}
                  {this.state.thirdCardStat === "Busy" &&  this.state.prevBusyCalls}
                  {this.state.thirdCardStat === "HandledRate" &&  this.state.prevHandledRate}
                  </h4>
                  </div>
                  <div className="stats" style={{marginLeft:"80%", marginTop:"-35px"}}>
                      <UncontrolledDropdown>
                      <DropdownToggle
                        caret
                        className="btn-icon"
                        color="link"
                        data-toggle="dropdown"
                        type="button"
                      >
                        <i className="tim-icons icon-settings-gear-63" />
                      </DropdownToggle>
                      <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                      <DropdownItem
                      href=""
                      onClick={()=>{ this.handleOpenSettDialog("thirdCard")}}
                      >
                        Settings
                      </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>  
                  </div>      
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-danger">
                      <PhoneCallbackIcon style={{ color: '#FFF', height:'30px' , width:'30px', marginTop:"10px"}}/>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <CardTitle tag="h4" style={{cursor:"grab"}}>{this.state.fourthTopWidgetTitle}</CardTitle>
                        <CardTitle tag="h2" style={{cursor:"grab"}}>
                        {this.state.fourthCardStat === "TotalCalls" &&  this.state.totalCalls}
                        {this.state.fourthCardStat === "Incoming" &&  this.state.incCalls}
                        {this.state.fourthCardStat === "Outgoing" &&  this.state.outCalls}
                        {this.state.fourthCardStat === "Internal" &&  this.state.intCalls}
                        {this.state.fourthCardStat === "Abandoned" &&  this.state.abandCalls}
                        {this.state.fourthCardStat === "Answered" &&  this.state.answCalls}
                        {this.state.fourthCardStat === "Busy" &&  this.state.busyCalls}
                        {this.state.fourthCardStat === "HandledRate" &&  this.state.handledRate}
                        </CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter style={{marginTop:"-30px"}}>
                  <hr />
                  <div className="stats" style={{ marginTop:"-20px"}}>
                  <div style = {{height:"20px"}} />
                  <h4 style = {{ color:"#FFF"}}> {this.state.cardsFooterRange}: {this.state.fourthCardStat === "TotalCalls" &&  this.state.prevTotalCalls}
                  {this.state.fourthCardStat === "Incoming" &&  this.state.prevIncCalls}
                  {this.state.fourthCardStat === "Outgoing" &&  this.state.prevOutCalls}
                  {this.state.fourthCardStat === "Internal" &&  this.state.prevIntCalls}
                  {this.state.fourthCardStat === "Abandoned" &&  this.state.prevAbandCalls}
                  {this.state.fourthCardStat === "Answered" &&  this.state.prevAnswCalls}
                  {this.state.fourthCardStat === "Busy" &&  this.state.prevBusyCalls}
                  {this.state.fourthCardStat === "HandledRate" &&  this.state.prevHandledRate}
                  </h4>
                  </div>
                  <div className="stats" style={{marginLeft:"80%", marginTop:"-35px"}}>
                      <UncontrolledDropdown>
                      <DropdownToggle
                        caret
                        className="btn-icon"
                        color="link"
                        data-toggle="dropdown"
                        type="button"
                      >
                        <i className="tim-icons icon-settings-gear-63" />
                      </DropdownToggle>
                      <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                      {/* <DropdownItem
                      href=""
                      onClick={()=>this.handleClickOpenTopDeptChartDlg()}
                      >
                        Filters
                      </DropdownItem>
                      <DropdownItem
                      href=""
                      onClick={()=>this.handleClickOpenIntCallsAlertDlg()}
                      >
                        Manage Alerts
                      </DropdownItem> */}
                      <DropdownItem
                      href=""
                      onClick={()=>{ this.handleOpenSettDialog("fourthCard")}}
                      >
                        Settings
                      </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>      
                </CardFooter>
              </Card>
            </Col>
            <Col xs="12">
            <Card className="card-chart">
            <CardHeader>
            <CardTitle tag="h4">
              <i className="tim-icons icon-check-2 text-warning" /> {this.state.fithWidgetTitle}
            </CardTitle>
            <UncontrolledDropdown>
            <DropdownToggle
              caret
              className="btn-icon"
              color="link"
              data-toggle="dropdown"
              type="button"
            >
              <i className="tim-icons icon-settings-gear-63" />
            </DropdownToggle>
            <DropdownMenu aria-labelledby="dropdownMenuLink" right>
            {/* <DropdownItem
            href=""
            onClick={()=>this.handleClickOpenHourlyChartDlg()}
            >
              Filters
            </DropdownItem> */}
            <DropdownItem
              href=""
              onClick={()=>{ this.handleOpenSettDialog("fithCard")}}
            >
              Settings
            </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          </CardHeader>
              <CardBody>
                <div className="chart-area" style={{marginTop:"-35px", height:"290px"}}>

                {this.state.bigChartData === "data1" &&
                    <TimeBasedChart activeModeClass={active_class_mode()} totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                }
                {this.state.bigChartData === "data2" &&
                   <HeatMap activeModeClass={active_class_mode()} totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                }
                {this.state.bigChartData === "data3" &&
                   <MonthlyChart activeModeClass={active_class_mode()} totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                }
                </div>
              </CardBody>
            </Card>
          </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                
                  <CardTitle tag="h4">
                    <i className="tim-icons icon-check-2 text-primary" />{" "}
                    {this.state.sixWidgetTitle}
                  </CardTitle>
                  <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    className="btn-icon"
                    color="link"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <i className="tim-icons icon-settings-gear-63" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                    {/* <DropdownItem
                      href=""
                      onClick={()=>this.handleClickOpenTrunkUChartDlg()}
                    >
                      Filters
                    </DropdownItem>
                    <DropdownItem
                      href=""
                      onClick={()=>this.handleClickOpenTrunkUtilAlertDlg()}
                    >
                      Manage Alerts
                    </DropdownItem> */}
                    <DropdownItem
                      href=""
                      onClick={()=>this.handleOpenSettDialog("sixCard")}
                    >
                      Settings
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {this.state.sixWidgetSelOption === "0" &&
                  <div className="chart-area" style= {{height:"250px"}}>
                    {this.state.bigChartData === "data1" && document.body.classList.contains("white-content") &&           
                      <PeakTrunkUtilChart activeModeClass="white-content" dataType="daily" totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                    }
                    {this.state.bigChartData === "data1" && !document.body.classList.contains("white-content") &&             
                      <PeakTrunkUtilChart activeModeClass="dark-content" dataType="daily" totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                    }
                    {this.state.bigChartData === "data2" && document.body.classList.contains("white-content") &&           
                      <PeakTrunkUtilChart activeModeClass="white-content" dataType="weekly" totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                    }
                    {this.state.bigChartData === "data2" && !document.body.classList.contains("white-content") &&             
                      <PeakTrunkUtilChart activeModeClass="dark-content" dataType="weekly" totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                    }
                    {this.state.bigChartData === "data3" && document.body.classList.contains("white-content") &&           
                      <PeakTrunkUtilChart activeModeClass="white-content" dataType="monthly" totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                    }
                    {this.state.bigChartData === "data3" && !document.body.classList.contains("white-content") &&             
                      <PeakTrunkUtilChart activeModeClass="dark-content" dataType="monthly" totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                    }
                  </div>
                  }
                  {(this.state.sixWidgetSelOption === "1" && this.state.totalCalls) &&
                   <div className="chart-area" style= {{height:"250px"}}>
                    { document.body.classList.contains("white-content") &&           
                      <AbandVsAnswChart activeModeClass="white-content"  totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                    }
                    {!document.body.classList.contains("white-content") &&             
                      <AbandVsAnswChart activeModeClass="dark-content" totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                    }
                    
                  </div> 
                 }
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h4">
                    <i className="tim-icons icon-check-2 text-info" />{" "}
                    {this.state.sevenWidgetTitle}
                  </CardTitle>
                  <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    className="btn-icon"
                    color="link"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <i className="tim-icons icon-settings-gear-63" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                  {/* <DropdownItem
                    href=""
                    onClick={()=>this.handleClickOpenTopDeptChartDlg()}
                  >
                    Filters
                  </DropdownItem>
                  <DropdownItem
                    href=""
                      onClick={()=>this.handleClickOpenCallsDeptAlertDlg()}
                    >
                      Manage Alerts
                  </DropdownItem> */}
                  <DropdownItem
                      href=""
                      onClick={()=>{ this.handleOpenSettDialog("sevenCard")}}
                      >
                        Settings
                      </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  <div className="chart-area" style= {{height:"250px"}}>
                    {this.state.bigChartData === "data1" && document.body.classList.contains("white-content") &&           
                      <Top10Departments activeModeClass="white-content" totalCalls={this.state.totalCalls} data={topDeptDataDaily} filters={get_filters(this)} />
                    }
                    {this.state.bigChartData === "data1" && !document.body.classList.contains("white-content") &&             
                      <Top10Departments activeModeClass="dark-content" totalCalls={this.state.totalCalls} data={topDeptDataDaily} filters={get_filters(this)} />
                    }
                    {this.state.bigChartData === "data2" && document.body.classList.contains("white-content") &&           
                      <Top10Departments activeModeClass="white-content" totalCalls={this.state.totalCalls} data={topDeptDataWeekly} filters={get_filters(this)} />
                    }
                    {this.state.bigChartData === "data2" && !document.body.classList.contains("white-content") &&             
                      <Top10Departments activeModeClass="dark-content" totalCalls={this.state.totalCalls} data={topDeptDataWeekly} filters={get_filters(this)} />
                    }
                    {this.state.bigChartData === "data3" && document.body.classList.contains("white-content") &&           
                      <Top10Departments activeModeClass="white-content" totalCalls={this.state.totalCalls} data={topDeptDataMonthly} filters={get_filters(this)} />
                    }
                    {this.state.bigChartData === "data3" && !document.body.classList.contains("white-content") &&             
                      <Top10Departments activeModeClass="dark-content" totalCalls={this.state.totalCalls} data={topDeptDataMonthly} filters={get_filters(this)} />
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h4">
                    <i className="tim-icons icon-check-2 text-success" /> {this.state.eightWidgetTitle}
                  </CardTitle>
                  <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    className="btn-icon"
                    color="link"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <i className="tim-icons icon-settings-gear-63" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                  <DropdownItem
                      href=""
                      onClick={()=>{ this.handleOpenSettDialog("eighthCard")}}
                      >
                        Settings
                      </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  <div className="chart-area" style= {{height:"250px"}}>
                    {this.state.bigChartData === "data1" && document.body.classList.contains("white-content") &&           
                      <CallsByType activeModeClass="white-content" totalCalls={this.state.totalCalls} data={callClassDataDaily} filters={get_filters(this)} selCallClass = {this.state.callClassSelVal} />
                    }
                    {this.state.bigChartData === "data1" && !document.body.classList.contains("white-content") &&             
                      <CallsByType activeModeClass="dark-content" totalCalls={this.state.totalCalls} data={callClassDataDaily } filters={get_filters(this)} selCallClass = {this.state.callClassSelVal} />
                    }
                    {this.state.bigChartData === "data2" && document.body.classList.contains("white-content") &&           
                      <CallsByType activeModeClass="white-content" totalCalls={this.state.totalCalls} data={callClassDataWeekly} filters={get_filters(this)} selCallClass = {this.state.callClassSelVal} />
                    }
                    {this.state.bigChartData === "data2" && !document.body.classList.contains("white-content") &&             
                      <CallsByType activeModeClass="dark-content" totalCalls={this.state.totalCalls} data={callClassDataWeekly} filters={get_filters(this)} selCallClass = {this.state.callClassSelVal} />
                    }
                    {this.state.bigChartData === "data3" && document.body.classList.contains("white-content") &&           
                      <CallsByType activeModeClass="white-content" totalCalls={this.state.totalCalls} data={callClassDataMonthly} filters={get_filters(this)} selCallClass = {this.state.callClassSelVal} />
                    }
                    {this.state.bigChartData === "data3" && !document.body.classList.contains("white-content") &&             
                      <CallsByType activeModeClass="dark-content" totalCalls={this.state.totalCalls} data={callClassDataMonthly} filters={get_filters(this)} selCallClass = {this.state.callClassSelVal} />
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>

          </Row>
          <Row>
            <Col lg="12">
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h4">
                    <i className="tim-icons icon-check-2 text-danger" /> {this.state.nineWidgetTitle}
                  </CardTitle>
                  <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    className="btn-icon"
                    color="link"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <i className="tim-icons icon-settings-gear-63" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                  {/* <DropdownItem
                      href=""
                      onClick={() => this.handleClickOpenCallsTableChartDlg()}
                    >
                      Filters
                    </DropdownItem> */}
                    <DropdownItem
                      href=""
                      onClick={()=>{ this.handleOpenSettDialog("nineCard")}}
                      >
                        Settings
                      </DropdownItem>
                    <DropdownItem
                      href=""
                      onClick={() => this.setTableToShow("live-view", "Calls Live View")}
                    >
                      Live Call View
                    </DropdownItem>
                    <DropdownItem
                      href=""
                      onClick={() => this.setTableToShow("top10-dialed", "Top 10 Dialed")}
                    >
                      Top 10 Dialed
                    </DropdownItem>
                    <DropdownItem
                      href=""
                      onClick={() => this.setTableToShow("top10-received", "Top 10 Received")}
                    >
                      Top 10 Received
                    </DropdownItem>
                    <DropdownItem
                    href=""
                    onClick={() => this.setTableToShow("top10-most-used-ext", "Top 10 Most Used Extensions")}
                  >
                    Top 10 Most Used Extensions
                  </DropdownItem>
                  {this.state.ratesEnabled == 1 &&
                                      <DropdownItem
                                      href=""
                                      onClick={() => this.setTableToShow("top10-most-expensive", "Top 10 More Expensive")}
                                    >
                                      Top 10 Most Expensive
                                    </DropdownItem>
                  }
                  <DropdownItem
                  href=""
                  onClick={() => this.setTableToShow("alarms", "Alarms")}
                >
                  Alarms
                </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="8" style={{marginTop:"-60px"}} >
                      {this.state.selTableView === "live-view" &&
                       <LiveViewTable activeModeClass={active_class_mode()} totalCalls={this.state.totalCalls} filterIconClick ={this.handleAlarmFilteClick} filters={get_filters(this)} />
                      }
                      {this.state.selTableView === "top10-dialed" &&
                        <Top10Dialed activeModeClass={active_class_mode()} totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                      }
                      {this.state.selTableView === "top10-received" &&
                        <Top10Received activeModeClass={active_class_mode()} totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                      }
                      {this.state.selTableView === "top10-most-used-ext" &&
                        <Top10MostUsed activeModeClass={active_class_mode()} totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                      }
                      {this.state.selTableView === "top10-most-expensive" &&
                        <Top10MostExpensive activeModeClass={active_class_mode()} totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                      }
                      {this.state.selTableView === "alarms" &&
                        <AlarmTable activeModeClass={active_class_mode()} totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                      }
                    </Col>
                    <Col  md="4" style={{marginTop:"-15px", color:'#fff'}}>
                      {this.state.selTableView === "live-view" &&
                        <Line
                        data={this.state.recentCallsLineChart.data}
                        options={this.state.recentCallsLineChart.options}
                        />
                      }
                      {this.state.selTableView === "top10-dialed" &&
                          <Pie
                            data={this.state.topTenDialedCallsChart.data}
                            options={this.state.topTenDialedCallsChart.options}
                          />            
                      }
                      {this.state.selTableView === "top10-received" &&
                        <Pie
                        data={this.state.topTenRecCallsChart.data}
                        options={this.state.topTenRecCallsChart.options}
                       />
                      }
                      {this.state.selTableView === "top10-most-used-ext" &&
                        <Bar
                        data={this.state.topTenUsedExtsChart.data}
                        options={this.state.topTenUsedExtsChart.options}
                        />
                      }
                      {this.state.selTableView === "top10-most-expensive" && document.body.classList.contains("white-content") &&   
                       <StockedColCharge activeModeClass="white-content" totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                      }
                      {this.state.selTableView === "top10-most-expensive" && !document.body.classList.contains("white-content") &&   
                        <StockedColCharge activeModeClass="dark-content" totalCalls={this.state.totalCalls} filters={get_filters(this)} />
                      }
                     
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withAuth0(Dashboard);
