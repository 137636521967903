import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useState, useLayoutEffect, useCallback} from 'react';
import dayjs from 'dayjs'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 240,
    fontSize: '18px'
  },
}));

export default function DateAndTimePickersHourlyChart(props) {
  const classes = useStyles();
  const [themeType,setThemeType] = useState('dark');
  const [error, setError] = useState(false);
  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")}
    
  }, []);
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#4caf50',
      },
    },
   
  });

  const onChange = props.onChange;
  const changeHandler = useCallback((event) => {
    if(!dayjs(event.target.value).isValid()) {
      setError(true);
      return;
    }

    setError(false);
    props.onChange(event)}, [onChange]);

  return (
    <div>
    <MuiThemeProvider theme={THEME}>
    <form className={classes.container} noValidate>
      <TextField
        error={error}
        id="datetime-local"
        label={props.label}
        type="datetime-local"
        defaultValue={props.date? dayjs(props.date).format('YYYY-MM-DDTHH:mm') : "2021-05-10T00:00"}
        helperText={error ? "Invalid date" : ""}
        className={classes.textField}
        onChange={changeHandler}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </form>
    </MuiThemeProvider>
    </div>
  );
}
