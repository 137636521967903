import VectorMap from "views/Maps.js";
import Dashboard from "views/Dashboard.js";
import Home from "views/Home.js";
import UserProfile from "views/UserProfile.js";
import AssignExtensions from "views/AssignExtensions.js";
import AssignDepartments from "views/AssignDepartments.js";
import AssignDivB from "views/AssignDivB.js";
import AssignDivC from "views/AssignDivC.js";
import AssignDivD from "views/AssignDivD.js";
import AssignDivE from "views/AssignDivE.js";
import AssignHuntGroups from "views/AssignHuntGroups.js";
import AssignAccountCode from "views/AssignAccountCodes.js";
import AssignAuthCode from "views/AssignAuthCode.js";
import Importer from "views/Importer.js";
import ReportLayout from "views/ReportLayout.js";
import RatesLayout from "views/Rates.js";
import SpecialNumberLayout from "views/SpecialNumbers.js";
import ServiceChargeLayout from "views/ServiceCharge.js";
import ScheduledReports from "views/ScheduledReports.js"; 
import InteractionChordChart from "components/AmCharts/InteractionChordChart"
import UserInteraction from "views/UserInteraction.jsx";
import CallAlerts from "views/CallAlerts.js";

const routes = [
  
  {
    path: "/dashboard",
    name: "Home",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
    access:"Report,Admin",
    invisible:false
  },
  /*TODO grab the charts view */
  {
    collapse: true,
    name:"Visualizations",
    state:"VisualsCollapse",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-bar-32",
    access: "Admin",
    views: [
      {
        path: "/charts",
        name: "User Interactions",
        rtlName: "لوحة القيادة",
        mini:"UI",
        component: UserInteraction,
        layout: "/admin",
      },
      {
        path: "/home",
        name: "Summary",
        rtlName: "لوحة القيادة",
        mini:"SUM",
        component: Home,
        layout: "/admin",
      },
    ],
    invisible: false
  },
  {
    path: "/map",
    name: "Maps",
    rtlName: "خرائط",
    icon: "tim-icons icon-pin",
    component: VectorMap,
    layout: "/admin",
    access:"Report,Admin",
    invisible:false
  },
  // {
  //   path: "/real-time",
  //   name: "Real Time",
  //   rtlName: "قائمة الجدول",
  //   icon: "tim-icons icon-tv-2",
  //   component: RealTimeDash,
  //   layout: "/admin"
  // },
  {
    collapse: true,
    name: "Reports",
    state: "pagesCollapse",
    rtlName: "طباعة",
    icon: "tim-icons icon-paper",
    access:"Report,Admin",
    views: [
      {
        path: "/user-reports",
        name: "User Details",
        rtlName: "",
        mini: "UD",
        rtlMini: "ع",
        routeProps: {
          reportId: "extension_details",
          reportTitle:"User Details"
        },
        component: ReportLayout,
        layout: "/admin"
      },
      {
        path: "/report/user-summary",
        name: "User Summary",
        rtlName: "",
        mini: "US",
        rtlMini: "ع",
        routeProps: {
          reportId: "extension_summary",
          reportTitle: "User Summary"
        },
        component: ReportLayout,
        layout: "/admin"
      },
      {
        path: "/diva-summary-reports",
        name: "Department Summary",
        rtlName: "",
        mini: "Dept",
        rtlMini: "ع",
        routeProps: {
          reportId: "diva_summary",
          reportTitle:"Department Summary"
        },
        component: ReportLayout,
        layout: "/admin"
      },
      {
        path: "/divb-summary-reports",
        name: "Branch Summary",
        rtlName: "",
        mini: "Bran",
        routeProps: {
          reportId: "divb_summary",
          reportTitle:"Branch Summary"
        },
        rtlMini: "ع",
        component: ReportLayout,
        layout: "/admin"
      },
      {
        path: "/divc-summary-reports",
        name: "Region Summary",
        rtlName: "",
        mini: "Reg",
        routeProps: {
          reportId: "divc_summary",
          reportTitle:"Region Summary"
        },
        rtlMini: "ع",
        component: ReportLayout,
        layout: "/admin"
      },
      {
        path: "/hunt-by-hour-report",
        name: "Hunt By Hour",
        rtlName: "",
        mini: "Hunt",
        routeProps: {
          reportId: "hunt_by_hour",
          reportTitle:"Hunt By Hour"
        },
        rtlMini: "ع",
        component: ReportLayout,
        layout: "/admin"
      },
    ],
    invisible:false
  },  
  {
    path: "/reports-scheduled",
    name: "Scheduled Reports",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-watch-time",
    component: ScheduledReports,
    layout: "/admin",
    access:"Report,Admin",
    invisible:false
  },
  {
    path: "/user-profile",
    name: "Users",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-single-02",
    component: UserProfile,
    layout: "/admin",
    access:"Admin",
    invisible:false
  },
  {
    collapse: true,
    name: "Assign",
    state: "componentsCollapse",
    rtlName: "طباعة",
    icon: "tim-icons icon-tap-02",
    access:"Admin",
    views: [
      {
        path: "/assign-exts",
        name: "Extensions",
        rtlName: "عالتسعير",
        mini: "Ext",
        rtlMini: "ع",
        component: AssignExtensions,
        layout: "/admin"
      },
      {
        path: "/assign-departments",
        name: "Departments",
        rtlName: "عالتسعير",
        mini: "Dept",
        rtlMini: "ع",
        component: AssignDepartments,
        layout: "/admin"
      },
      {
        path: "/assign-divB",
        name: "Branches",
        rtlName: "عالتسعير",
        mini: "Bra",
        rtlMini: "ع",
        component: AssignDivB,
        layout: "/admin"
      },
      {
        path: "/assign-divC",
        name: "Regions",
        rtlName: "عالتسعير",
        mini: "Reg",
        rtlMini: "ع",
        component: AssignDivC,
        layout: "/admin"
      },
      {
        path: "/assign-divD",
        name: "Divisions",
        rtlName: "عالتسعير",
        mini: "Div",
        rtlMini: "ع",
        component: AssignDivD,
        layout: "/admin"
      },
      {
        path: "/assign-divE",
        name: "Organizations",
        rtlName: "عالتسعير",
        mini: "Org",
        rtlMini: "ع",
        component: AssignDivE,
        layout: "/admin"
      },
      {
        path: "/assign-hunt-groups",
        name: "Hunt Groups",
        rtlName: "عالتسعير",
        mini: "HG",
        rtlMini: "ع",
        component: AssignHuntGroups,
        layout: "/admin"
      },
      {
        path: "/assign-account-code",
        name: "Account Code",
        rtlName: "عالتسعير",
        mini: "AC",
        rtlMini: "ع",
        component: AssignAccountCode,
        layout: "/admin"
      },
      {
        path: "/assign-auth-code",
        name: "Authorization Code",
        rtlName: "عالتسعير",
        mini: "AuthC",
        rtlMini: "ع",
        component: AssignAuthCode,
        layout: "/admin"
      },
      {
        path: "/importer",
        name: "Importer",
        rtlName: "عالتسعير",
        mini: "Imp",
        rtlMini: "ع",
        component: Importer,
        layout: "/admin"
      }
    ],
    invisible:false
  },
  /*{
    collapse: true,
    name: "Alarms",
    rtlName: "طباعة",
    icon: "tim-icons icon-chart-bar-32",
    access: "Admin",
    views: [
      {
        path: "/emergency-alarms",
        name: "Emergency Call Alarm",
        rtlName: "",
        mini: "EMG",
        rtlMini: "ع",
        component: CallAlerts,
        layout: "/admin"
      },
    ]
  },*/
  {
    collapse: true,
    name: "Charge",
    state: "formsCollapse",
    rtlName: "طباعة",
    icon: "tim-icons icon-money-coins",
    views: [
      {
        path: "/setup-rates",
        name: "Rates",
        rtlName: "عالتسعير",
        mini: "R",
        rtlMini: "ع",
        component: RatesLayout,
        layout: "/admin"
      },
      {
        path: "/setup-spec-numbers",
        name: "Special Numbers",
        rtlName: "عالتسعير",
        mini: "SN",
        rtlMini: "ع",
        component: SpecialNumberLayout,
        layout: "/admin"
      },
      {
        path: "/setup-serv-charges",
        name: "Service Charges",
        rtlName: "عالتسعير",
        mini: "SC",
        rtlMini: "ع",
        component: ServiceChargeLayout,
        layout: "/admin"
      }
    ],
    invisible:false
  },
  // {
  //   collapse: true,
  //   name: "Alerts",
  //   state: "formsCollapse",
  //   rtlName: "طباعة",
  //   icon: "tim-icons icon-time-alarm",
  //   views: [
  //     {
  //       path: "/call-alerts",
  //       name: "Call Alerts",
  //       rtlName: "عالتسعير",
  //       mini: "CA",
  //       rtlMini: "ع",
  //       component: CallAlerts,
  //       layout: "/admin"
  //     }
  //   ] 
  // }
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   rtlName: "طباعة",
  //   icon: "tim-icons icon-settings",
  //   component: Settings,
  //   layout: "/admin"
  //  }
  // {
  //   collapse: true,
  //   name: "Pages",
  //   rtlName: "صفحات",
  //   icon: "tim-icons icon-image-02",
  //   state: "pagesCollapse",
  //   views: [
  //     {
  //       path: "/pricing",
  //       name: "Pricing",
  //       rtlName: "عالتسعير",
  //       mini: "P",
  //       rtlMini: "ع",
  //       component: Pricing,
  //       layout: "/auth"
  //     },
  //     {
  //       path: "/rtl-support",
  //       name: "RTL Support",
  //       rtlName: "صودعم رتل",
  //       mini: "RS",
  //       rtlMini: "صو",
  //       component: Rtl,
  //       layout: "/rtl"
  //     },
  //     {
  //       path: "/timeline",
  //       name: "Timeline",
  //       rtlName: "تيالجدول الزمني",
  //       mini: "T",
  //       rtlMini: "تي",
  //       component: Timeline,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/login",
  //       name: "Login",
  //       rtlName: "هعذاتسجيل الدخول",
  //       mini: "L",
  //       rtlMini: "هعذا",
  //       component: Login,
  //       layout: "/auth"
  //     },
  //     {
  //       path: "/register",
  //       name: "Register",
  //       rtlName: "تسجيل",
  //       mini: "R",
  //       rtlMini: "صع",
  //       component: Register,
  //       layout: "/auth"
  //     },
  //     {
  //       path: "/lock-screen",
  //       name: "Lock Screen",
  //       rtlName: "اقفل الشاشة",
  //       mini: "LS",
  //       rtlMini: "هذاع",
  //       component: Lock,
  //       layout: "/auth"
  //     },
  //     {
  //       path: "/user-profile",
  //       name: "User Profile",
  //       rtlName: "ملف تعريفي للمستخدم",
  //       mini: "UP",
  //       rtlMini: "شع",
  //       component: User,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Components",
  //   rtlName: "المكونات",
  //   icon: "tim-icons icon-molecule-40",
  //   state: "componentsCollapse",
  //   views: [
  //     {
  //       collapse: true,
  //       name: "Multi Level Collapse",
  //       rtlName: "انهيار متعدد المستويات",
  //       mini: "MLT",
  //       rtlMini: "ر",
  //       state: "multiCollapse",
  //       views: [
  //         {
  //           path: "/buttons",
  //           name: "Buttons",
  //           rtlName: "وصفت",
  //           mini: "B",
  //           rtlMini: "ب",
  //           component: Buttons,
  //           layout: "/admin"
  //         }
  //       ]
  //     },
  //     {
  //       path: "/buttons",
  //       name: "Buttons",
  //       rtlName: "وصفت",
  //       mini: "B",
  //       rtlMini: "ب",
  //       component: Buttons,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/grid-system",
  //       name: "Grid System",
  //       rtlName: "نظام الشبكة",
  //       mini: "GS",
  //       rtlMini: "زو",
  //       component: Grid,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/panels",
  //       name: "Panels",
  //       rtlName: "لوحات",
  //       mini: "P",
  //       rtlMini: "ع",
  //       component: Panels,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/sweet-alert",
  //       name: "Sweet Alert",
  //       rtlName: "الحلو تنبيه",
  //       mini: "SA",
  //       rtlMini: "ومن",
  //       component: SweetAlert,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/notifications",
  //       name: "Notifications",
  //       rtlName: "إخطارات",
  //       mini: "N",
  //       rtlMini: "ن",
  //       component: Notifications,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/icons",
  //       name: "Icons",
  //       rtlName: "الرموز",
  //       mini: "I",
  //       rtlMini: "و",
  //       component: Icons,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/typography",
  //       name: "Typography",
  //       rtlName: "طباعة",
  //       mini: "T",
  //       rtlMini: "ر",
  //       component: Typography,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Forms",
  //   rtlName: "إستمارات",
  //   icon: "tim-icons icon-notes",
  //   state: "formsCollapse",
  //   views: [
  //     {
  //       path: "/regular-forms",
  //       name: "Regular Forms",
  //       rtlName: "أشكال عادية",
  //       mini: "RF",
  //       rtlMini: "صو",
  //       component: RegularForms,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/extended-forms",
  //       name: "Extended Forms",
  //       rtlName: "نماذج موسعة",
  //       mini: "EF",
  //       rtlMini: "هوو",
  //       component: ExtendedForms,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/validation-forms",
  //       name: "Validation Forms",
  //       rtlName: "نماذج التحقق من الصحة",
  //       mini: "VF",
  //       rtlMini: "تو",
  //       component: ValidationForms,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/wizard",
  //       name: "Wizard",
  //       rtlName: "ساحر",
  //       mini: "W",
  //       rtlMini: "ث",
  //       component: Wizard,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Tables",
  //   rtlName: "الجداول",
  //   icon: "tim-icons icon-puzzle-10",
  //   state: "tablesCollapse",
  //   views: [
  //     {
  //       path: "/regular-tables",
  //       name: "Regular Tables",
  //       rtlName: "طاولات عادية",
  //       mini: "RT",
  //       rtlMini: "صر",
  //       component: RegularTables,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/extended-tables",
  //       name: "Extended Tables",
  //       rtlName: "جداول ممتدة",
  //       mini: "ET",
  //       rtlMini: "هور",
  //       component: ExtendedTables,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/react-tables",
  //       name: "React Tables",
  //       rtlName: "رد فعل الطاولة",
  //       mini: "RT",
  //       rtlMini: "در",
  //       component: ReactTables,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Maps",
  //   rtlName: "خرائط",
  //   icon: "tim-icons icon-pin",
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "/google-maps",
  //       name: "Google Maps",
  //       rtlName: "خرائط جوجل",
  //       mini: "GM",
  //       rtlMini: "زم",
  //       component: GoogleMaps,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/full-screen-map",
  //       name: "Full Screen Map",
  //       rtlName: "خريطة كاملة الشاشة",
  //       mini: "FSM",
  //       rtlMini: "ووم",
  //       component: FullScreenMap,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/vector-map",
  //       name: "Vector Map",
  //       rtlName: "خريطة المتجه",
  //       mini: "VM",
  //       rtlMini: "تم",
  //       component: VectorMap,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   rtlName: "الحاجيات",
  //   icon: "tim-icons icon-settings",
  //   component: Widgets,
  //   layout: "/admin"
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   rtlName: "الرسوم البيانية",
  //   icon: "tim-icons icon-chart-bar-32",
  //   component: Charts,
  //   layout: "/admin"
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   rtlName: "التقويم",
  //   icon: "tim-icons icon-time-alarm",
  //   component: Calendar,
  //   layout: "/admin"
  // }
];

export default routes;