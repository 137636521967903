import React from "react";
import ApiCall from "../Helper/Api";
import ReportTable from "components/ReportTable/ReportTable.js";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import Tooltip from '@material-ui/core/Tooltip';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SchedulerDialog from "components/MaterialUI/Dialogs/SchedulerDialog.js";
import {withRouter} from 'react-router-dom';
import CallIcon from '@material-ui/icons/Call';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import CallMadeIcon from '@material-ui/icons/CallMade';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import SettingsTrunkDialog from "components/MaterialUI/Dialogs/TrunkBoxSettingsDialog.js";
import {
  Card,
  CardBody,
  Row,
  Col,
  Collapse,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

import dayjs from 'dayjs'

import {withAuth0} from "@auth0/auth0-react"
import ReportFilterForm from "../components/ReportFilterForm/ReportFilterForm";

function getDateStart() {
    return dayjs().startOf('day');
}
function getDateEnd() {
    return dayjs().endOf('day');
}
function get_filters(obj) {
  return {
      timeRangeStart: obj.state.reportFilters.timeRangeStart,
      timeRangeEnd: obj.state.reportFilters.timeRangeEnd,
      divAIds: obj.state.reportFilters.divAIds,
      divBIds: obj.state.reportFilters.divBIds,
      divCIds: obj.state.reportFilters.divCIds,
      externalCallTypes: obj.state.reportFilters.CallClassCode,
  }
}
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    const searchParams = new URLSearchParams(this.props.location.search);
    this.reportFilters = {
      divAIds: searchParams.get('divAIds'),
      divBIds: searchParams.get('divBIds'),
      divCIds: searchParams.get('divCIds'),
      extIds: searchParams.get('extIds'),
      huntGIds: searchParams.get('huntGIds'),
      CallClassCode: searchParams.get('CallClassCode'),
      ExternalCallClassCode: searchParams.get('ExternalCallClassCode'),
      selExtObj: searchParams.get('selExtObj'),
      seldivAObj: searchParams.get('seldivAObj'),
      seldivBObj: searchParams.get('seldivBObj'),
      seldivCObj: searchParams.get('seldivCObj'),
      callClastSelObj: searchParams.get('callClassSelObj'),
      huntGroupSelObj: searchParams.get('huntGroupSelObj'),
      timeRangeStart: getDateStart().format("YYYY-MM-DDTHH:mm:ss"),
      timeRangeEnd: getDateEnd().format("YYYY-MM-DDTHH:mm:ss"),
      dayOfWeekFilter: searchParams.get('dayOfWeekFilter'),
      dayOfWeekFilterSelObj: searchParams.get('dayOfWeekFilterSelObj'),
      startTimeOfDay:searchParams.get('startTimeOfDay'),
      endTimeOfDay:searchParams.get('endTimeOfDay'),
    };
    this.state = {
      isOpen:false,
      is2Open:false,
      graphView:true,
      openSchedDialog:false,
      classnameVar: "modal-black",
      activeModeClass:"dark-content",
      reportFilters: this.reportFilters,
      prevDashSett : {},
      totalCalls:0,
      incCalls:0,
      outCalls:0,
      intCalls:0,
      abandCalls:0,
      answCalls:0,
      busyCalls:0,
      handledRate:0,
      callClassSelVal:"INC,LOC,LTOLL,STATE,LD,EMG",
      sixWidgetSelOption:"",
      trunkEnabled:"0",
      showDlgStats:1,
      showDlgCallType:0,
      showDlgSixWidgetOption:0,
      openSettDialog:false,
      selCardName:"",
      widgetTitleTextState:"",
      rptBox1Title:"Total Calls",
      rptBox2Title:"Incoming",
      rptBox3Title:"Outgoing",
      rptBox4Title:"Internal",
      rptBox1SelStat: "TotalCalls",
      rptBox2SelStat:"Incoming",
      rptBox3SelStat:"Outgoing",
      rptBox4SelStat:"Internal",
    };
  }
  
  componentDidMount() {
    this.getDashboardSett().catch();
    // this.getTotals().catch();
    const getData = () => {
      this.getTotalCalls().catch();
      this.getCallStatusStats().catch();
    }
    getData();
    this.intervalId = setInterval( getData ,8000);
  }

  handleOpenSettDialog = (cardName) => {
    if(cardName === "firstCard"){
      this.setState({widgetTitleTextState:this.state.rptBox1Title});
      this.setState({statsValue : this.state.rptBox1SelStat});
      this.setState({showDlgStats : 1}); 
      this.setState({showDlgCallType : 0}); 
      this.setState({showDlgSixWidgetOption : 0}); 
    }
    if(cardName === "secondCard"){
      this.setState({widgetTitleTextState:this.state.rptBox2Title});
      this.setState({statsValue : this.state.rptBox2SelStat});
      this.setState({showDlgStats : 1});
      this.setState({showDlgCallType : 0}); 
      this.setState({showDlgSixWidgetOption : 0}); 
    }
    if(cardName === "thirdCard"){
      this.setState({widgetTitleTextState:this.state.rptBox3Title});
      this.setState({statsValue : this.state.rptBox3SelStat});
      this.setState({showDlgStats : 1});
      this.setState({showDlgCallType : 0}); 
      this.setState({showDlgSixWidgetOption : 0}); 
    }
    if(cardName === "fourthCard"){
      this.setState({widgetTitleTextState:this.state.rptBox4Title});
      this.setState({statsValue : this.state.rptBox4SelStat})
      this.setState({showDlgStats : 1});
      this.setState({showDlgCallType : 0}); 
      this.setState({showDlgSixWidgetOption : 0}); 
    }
    this.setState({openSettDialog: true});
    this.setState({selCardName:cardName});
  
  }

  handleCloseSettDialog = () => {
    let card1Title = this.state.rptBox1Title;
    let card2Title = this.state.rptBox2Title;
    let card3Title = this.state.rptBox3Title;
    let card4Title = this.state.rptBox4Title;

    if(this.state.selCardName === "firstCard"){
      this.setState({rptBox1Title:this.state.widgetTitleTextState});
      card1Title = this.state.widgetTitleTextState;
    }
    if(this.state.selCardName === "secondCard"){
      this.setState({rptBox2Title:this.state.widgetTitleTextState});
      card2Title = this.state.widgetTitleTextState;
    }
    if(this.state.selCardName === "thirdCard"){
      this.setState({rptBox3Title:this.state.widgetTitleTextState});
      card3Title = this.state.widgetTitleTextState;
    }
    if(this.state.selCardName === "fourthCard"){
      this.setState({rptBox4Title:this.state.widgetTitleTextState});
      card4Title = this.state.widgetTitleTextState;
    }
  
    this.state.prevDashSett.rptBox1Title = card1Title;
    this.state.prevDashSett.rptBox2Title = card2Title;
    this.state.prevDashSett.rptBox3Title = card3Title;
    this.state.prevDashSett.rptBox4Title = card4Title;
    this.state.prevDashSett.rptBox1SelStat =  this.state.rptBox1SelStat;
    this.state.prevDashSett.rptBox2SelStat = this.state.rptBox2SelStat;
    this.state.prevDashSett.rptBox3SelStat = this.state.rptBox3SelStat; 
    this.state.prevDashSett.rptBox4SelStat = this.state.rptBox4SelStat;

    let dashSett = {};
    dashSett.dashboard = this.state.prevDashSett;;
    this.postDashboardSett(dashSett).catch();
    this.setState({openSettDialog: false});
  }

  getTotalCalls = async ()=> { //add the filters
    const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);

       api
         .getCallsStatistics(get_filters(this))
         .then((response) => {
            this.setState({
              totalCalls: response.data.todayTotalCalls,
              incCalls: response.data.todayIncCalls,
              outCalls: response.data.todayOutCalls,
              talkAvg: response.data.todayTalkAvg,
              intCalls: response.data.todayIntCalls
            });
          }) 
         .catch((err) => console.log(err));      
  };

  getDashboardSett = async ()=> { //add the filters
    const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);

       api
         .getDashboardSettings()
         .then((response) => {
            this.setState({prevDashSett : {...response.data.dashboard}});
            if(response.data.dashboard.rptBox1Title)
              this.setState({rptBox1Title: response.data.dashboard.rptBox1Title}); 
            if( response.data.dashboard.rptBox2Title)
              this.setState({rptBox2Title: response.data.dashboard.rptBox2Title}); 
            if( response.data.dashboard.rptBox3Title)
              this.setState({rptBox3Title: response.data.dashboard.rptBox3Title}); 
            if(response.data.dashboard.rptBox4Title)
              this.setState({rptBox4Title: response.data.dashboard.rptBox4Title}); 
            if(response.data.dashboard.rptBox1SelStat)
              this.setState({rptBox1SelStat: response.data.dashboard.rptBox1SelStat}); 
            if(response.data.dashboard.rptBox2SelStat)
              this.setState({rptBox2SelStat: response.data.dashboard.rptBox2SelStat}); 
            if(response.data.dashboard.rptBox3SelStat)
              this.setState({rptBox3SelStat: response.data.dashboard.rptBox3SelStat}); 
            if(response.data.dashboard.rptBox4SelStat)
              this.setState({rptBox4SelStat: response.data.dashboard.rptBox4SelStat}); 
          }) 
         .catch((err) => console.log(err));      
  };

  postDashboardSett= async (dashSett) => { //add the filters
    try{      
      const token = await this.props.auth0.getAccessTokenSilently();
        const api = new ApiCall(token);

      const res = await api.postDashboardSettings(dashSett);
      if(res.status === 200) {
        console.log("success");
        return;
      }
      console.log("error"); 
      alert("Error");
    }
    catch(err) {
      console.log(err);
    }  
  };
  
  getCallStatusStats = async ()=> { //add the filters
    const token = await this.props.auth0.getAccessTokenSilently();
      const api = new ApiCall(token);

       api
         .getCallStatusStatistics(get_filters(this))
         .then((response) => {
            this.setState({
              answCalls: response.data.answered,
              abandCalls: response.data.abandoned,
              busyCalls: response.data.busy,
            });
            if(this.state.incCalls > 0)
            {
              this.setState({
                handledRate: Math.round(((this.state.incCalls - response.data.abandoned) / this.state.incCalls) * 100) +  " %",
              });
            }
            else{
              this.setState({handledRate: "0 %",})
            }
          }) 
         .catch((err) => console.log(err));      
  };

  onChangeStatValue = (e) => {
    this.setState({statsValue : e.target.value})
    if(this.state.selCardName === "firstCard"){
      let value = e.target.value;
      this.setState({rptBox1SelStat : value })
    }
    if(this.state.selCardName === "secondCard"){
      let value = e.target.value;
      this.setState({rptBox2SelStat : value })
    }
    if(this.state.selCardName === "thirdCard"){
      let value = e.target.value;
      this.setState({rptBox3SelStat : value })
    }
    if(this.state.selCardName === "fourthCard"){
      let value = e.target.value;
      this.setState({rptBox4SelStat : value })
    }
    this.setState({widgetTitleTextState: e.target.value})
  }

  openFilterTab =()=>{this.setState({ isOpen: !this.state.isOpen, is2Open: false})};
  handleReportFilterChange = (field, value) => {this.reportFilters = {...this.reportFilters }; this.reportFilters[field] = value;};
  applyFilter = () => {
     this.setState({reportFilters: this.reportFilters});
     this.getTotalCalls().catch();
     this.getCallStatusStats().catch();
  }
  setClassType = () =>{
    if(document.body.classList.contains("white-content")){this.setState({classnameVar: "",activeModeClass:"white-content"})}
    else if(document.body.classList.contains("dark-content")){this.setState({classnameVar: "modal-black", activeModeClass:"dark-content"})}
  }
  render() {
    return (
      <>      
         <SchedulerDialog open={this.state.openSchedDialog}
              handleClose={()=>{this.setState({openSchedDialog:false})}}
              className={this.state.classnameVar}
              activeModeClass={this.state.activeModeClass} 
              extList = ""
              divAIds = ""
              divBIds = ""
              divCIds = ""
              divDIds = ""
              divEIds = ""
              reportFilters = {this.state.reportFilters}
              reportId = {this.props.reportId}
        />
        <SettingsTrunkDialog 
          open={this.state.openSettDialog} 
          handleClose={this.handleCloseSettDialog} 
          className={this.state.classnameVar} 
          activeModeClass={this.state.activeModeClass} 
          titleText={this.state.widgetTitleTextState}
          textBoxClickEv={this.handleWidgetTitleState}
          selectValue = {this.state.statsValue}
          onChangeSelect = {this.onChangeStatValue}
          callClassVal = {this.state.callClassSelVal}
          onChangeCallClassSelect = {this.onChangeCallClassValue}
          showStats = {this.state.showDlgStats}
          showCallTypesFilter = {this.state.showDlgCallType}
          showSixWidgetOption = {this.state.showDlgSixWidgetOption}
          selSixWidgetOtion = {this.state.sixWidgetSelOption}
          trunkEnabled = {this.state.trunkEnabled}
          onChangeSixWidOptSelect = {this.onChangeSixWidgetOption}
        >
          
        </SettingsTrunkDialog>
        <div className="content" >
        <Row style={{marginTop:"-35px", height: '100px'}}>
        <Col md="6" style={{marginLeft:"0px"}}>
        <div id = "filterBtn">
            <Tooltip title="More Filters">
                <IconButton aria-label="Filters" onClick={() => {this.openFilterTab()}}>
                    <FilterListIcon />
                    <h5>Filters</h5>
                </IconButton>
            </Tooltip>
        </div>
        <div id = "filterDateBtn" style={{ position:'relative' , top:'-57px', left:'80px'}}>
            <Tooltip title="Send via Email">
                <IconButton aria-label="Graph View" onClick={() => {this.setState({openSchedDialog : true}); this.setClassType()}}>
                    <ScheduleIcon />
                    <h5>Schedule It</h5>
                </IconButton>
            </Tooltip>
        </div>
        </Col>
        </Row>
        <Row style={{marginTop:"-15px"}}>
          <Col sm="12">
            <Collapse isOpen={this.state.isOpen}>
              <ReportFilterForm {...this.reportFilters} onChange={this.handleReportFilterChange} onApply={this.applyFilter} reportId={this.props.reportId}/>
            </Collapse>
          </Col>
        </Row>

          <Row style={{marginTop:"-25px"}}>
          <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-warning">
                      <CallIcon style={{ color: '#FFF', height:'30px' , width:'30px', marginTop:"10px"}}/>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <CardTitle tag="h4" style={{cursor:"grab"}}>{this.state.rptBox1Title}</CardTitle>
                        <CardTitle tag="h2" style={{cursor:"grab"}}>
                        {this.state.rptBox1SelStat === "TotalCalls" &&  this.state.totalCalls}
                        {this.state.rptBox1SelStat === "Incoming" &&  this.state.incCalls}
                        {this.state.rptBox1SelStat === "Outgoing" &&  this.state.outCalls}
                        {this.state.rptBox1SelStat === "Internal" &&  this.state.intCalls}
                        {this.state.rptBox1SelStat === "Abandoned" &&  this.state.abandCalls}
                        {this.state.rptBox1SelStat === "Answered" &&  this.state.answCalls}
                        {this.state.rptBox1SelStat === "Busy" &&  this.state.busyCalls}
                        {this.state.rptBox1SelStat === "HandledRate" &&  this.state.handledRate}
                        </CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter style={{marginTop:"-30px"}}>
                  <hr />   
                    <div className="stats" style={{ marginTop:"-20px"}}>
                      <div style = {{height:"20px", fontColor:"#FFF"}} />
                      {/* <h4 style = {{ color:"#FFF"}}>
                      {this.state.cardsFooterRange}: {this.state.firstCardStat === "TotalCalls" &&  this.state.prevTotalCalls}
                      {this.state.firstCardStat === "Incoming" &&  this.state.prevIncCalls}
                      {this.state.firstCardStat === "Outgoing" &&  this.state.prevOutCalls}
                      {this.state.firstCardStat === "Internal" &&  this.state.prevIntCalls}
                      {this.state.firstCardStat === "Abandoned" &&  this.state.prevAbandCalls}
                      {this.state.firstCardStat === "Answered" &&  this.state.prevAnswCalls}
                      {this.state.firstCardStat === "Busy" &&  this.state.prevBusyCalls}
                      {this.state.firstCardStat === "HandledRate" &&  this.state.prevHandledRate}
                      </h4> */}
                    </div>
                    <div className="stats" style={{marginLeft:"80%", marginTop:"-10px"}}>
                      <UncontrolledDropdown>
                      <DropdownToggle
                        caret
                        className="btn-icon"
                        color="link"
                        data-toggle="dropdown"
                        type="button"
                      >
                        <i className="tim-icons icon-settings-gear-63" />
                      </DropdownToggle>
                      <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                      <DropdownItem
                      href=""
                      onClick={()=>{ this.handleOpenSettDialog("firstCard")}}
                      >
                        Settings
                      </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>      
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-primary">
                      <CallReceivedIcon style={{ color: '#FFF', height:'30px' , width:'30px', marginTop:"10px"}}/>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                      <CardTitle tag="h4" style={{cursor:"grab"}}>{this.state.rptBox2Title}</CardTitle>
                        <CardTitle tag="h2" style={{cursor:"grab"}}>
                        {this.state.rptBox2SelStat === "TotalCalls" &&  this.state.totalCalls}
                        {this.state.rptBox2SelStat === "Incoming" &&  this.state.incCalls}
                        {this.state.rptBox2SelStat === "Outgoing" &&  this.state.outCalls}
                        {this.state.rptBox2SelStat === "Internal" &&  this.state.intCalls}
                        {this.state.rptBox2SelStat === "Abandoned" &&  this.state.abandCalls}
                        {this.state.rptBox2SelStat === "Answered" &&  this.state.answCalls}
                        {this.state.rptBox2SelStat === "Busy" &&  this.state.busyCalls}
                        {this.state.rptBox2SelStat === "HandledRate" &&  this.state.handledRate}
                        </CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter style={{marginTop:"-30px"}}>
                  <hr />
                  <div className="stats" style={{ marginTop:"-20px"}}>
                  <div style = {{height:"20px"}} />
                    {/* <h4 style = {{ color:"#FFF"}} > {this.state.cardsFooterRange}: {this.state.secondCardStat === "TotalCalls" &&  this.state.prevTotalCalls}
                    {this.state.secondCardStat === "Incoming" &&  this.state.prevIncCalls}
                    {this.state.secondCardStat === "Outgoing" &&  this.state.prevOutCalls}
                    {this.state.secondCardStat === "Internal" &&  this.state.prevIntCalls}
                    {this.state.secondCardStat === "Abandoned" &&  this.state.prevAbandCalls}
                    {this.state.secondCardStat === "Answered" &&  this.state.prevAnswCalls}
                    {this.state.secondCardStat === "Busy" &&  this.state.prevBusyCalls}
                    {this.state.secondCardStat === "HandledRate" &&  this.state.prevHandledRate}
                    </h4> */}
                  </div>
                  <div className="stats" style={{marginLeft:"80%", marginTop:"-10px"}}>
                      <UncontrolledDropdown>
                      <DropdownToggle
                        caret
                        className="btn-icon"
                        color="link"
                        data-toggle="dropdown"
                        type="button"
                      >
                        <i className="tim-icons icon-settings-gear-63" />
                      </DropdownToggle>
                      <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                      {/* <DropdownItem
                      href=""
                      onClick={()=>this.handleClickOpenTopDeptChartDlg()}
                      >
                        Filters
                      </DropdownItem>
                      <DropdownItem
                      href=""
                      onClick={()=>this.handleClickOpenIncCallsAlertDlg()}
                      >
                        Manage Alerts
                      </DropdownItem> */}
                      <DropdownItem
                      href=""
                      onClick={()=>{ this.handleOpenSettDialog("secondCard")}}
                      >
                        Settings
                      </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>      
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-success">
                      <CallMadeIcon style={{ color: '#FFF', height:'30px' , width:'30px', marginTop:"10px"}}/>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <CardTitle tag="h4" style={{cursor:"grab"}}>{this.state.rptBox3Title}</CardTitle>
                        <CardTitle tag="h2" style={{cursor:"grab"}}>
                        {this.state.rptBox3SelStat === "TotalCalls" &&  this.state.totalCalls}
                        {this.state.rptBox3SelStat === "Incoming" &&  this.state.incCalls}
                        {this.state.rptBox3SelStat === "Outgoing" &&  this.state.outCalls}
                        {this.state.rptBox3SelStat === "Internal" &&  this.state.intCalls}
                        {this.state.rptBox3SelStat === "Abandoned" &&  this.state.abandCalls}
                        {this.state.rptBox3SelStat === "Answered" &&  this.state.answCalls}
                        {this.state.rptBox3SelStat === "Busy" &&  this.state.busyCalls}
                        {this.state.rptBox3SelStat === "HandledRate" &&  this.state.handledRate}
                        </CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter style={{marginTop:"-30px"}}>
                  <hr />
                  <div className="stats" style={{ marginTop:"-20px"}}>
                  <div style = {{height:"20px"}} />
                  {/* <h4 style = {{ color:"#FFF"}} > {this.state.cardsFooterRange}: {this.state.thirdCardStat === "TotalCalls" &&  this.state.prevTotalCalls}
                  {this.state.thirdCardStat === "Incoming" &&  this.state.prevIncCalls}
                  {this.state.thirdCardStat === "Outgoing" &&  this.state.prevOutCalls}
                  {this.state.thirdCardStat === "Internal" &&  this.state.prevIntCalls}
                  {this.state.thirdCardStat === "Abandoned" &&  this.state.prevAbandCalls}
                  {this.state.thirdCardStat === "Answered" &&  this.state.prevAnswCalls}
                  {this.state.thirdCardStat === "Busy" &&  this.state.prevBusyCalls}
                  {this.state.thirdCardStat === "HandledRate" &&  this.state.prevHandledRate}
                  </h4> */}
                  </div>
                  <div className="stats" style={{marginLeft:"80%", marginTop:"-10px"}}>
                      <UncontrolledDropdown>
                      <DropdownToggle
                        caret
                        className="btn-icon"
                        color="link"
                        data-toggle="dropdown"
                        type="button"
                      >
                        <i className="tim-icons icon-settings-gear-63" />
                      </DropdownToggle>
                      <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                      <DropdownItem
                      href=""
                      onClick={()=>{ this.handleOpenSettDialog("thirdCard")}}
                      >
                        Settings
                      </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>  
                  </div>      
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-danger">
                      <PhoneCallbackIcon style={{ color: '#FFF', height:'30px' , width:'30px', marginTop:"10px"}}/>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <CardTitle tag="h4" style={{cursor:"grab"}}>{this.state.rptBox4Title}</CardTitle>
                        <CardTitle tag="h2" style={{cursor:"grab"}}>
                        {this.state.rptBox4SelStat === "TotalCalls" &&  this.state.totalCalls}
                        {this.state.rptBox4SelStat === "Incoming" &&  this.state.incCalls}
                        {this.state.rptBox4SelStat === "Outgoing" &&  this.state.outCalls}
                        {this.state.rptBox4SelStat === "Internal" &&  this.state.intCalls}
                        {this.state.rptBox4SelStat === "Abandoned" &&  this.state.abandCalls}
                        {this.state.rptBox4SelStat === "Answered" &&  this.state.answCalls}
                        {this.state.rptBox4SelStat === "Busy" &&  this.state.busyCalls}
                        {this.state.rptBox4SelStat === "HandledRate" &&  this.state.handledRate}
                        </CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter style={{marginTop:"-30px"}}>
                  <hr />
                  <div className="stats" style={{ marginTop:"-20px"}}>
                  <div style = {{height:"20px"}} />
                  {/* <h4 style = {{ color:"#FFF"}}> {this.state.cardsFooterRange}: {this.state.fourthCardStat === "TotalCalls" &&  this.state.prevTotalCalls}
                  {this.state.fourthCardStat === "Incoming" &&  this.state.prevIncCalls}
                  {this.state.fourthCardStat === "Outgoing" &&  this.state.prevOutCalls}
                  {this.state.fourthCardStat === "Internal" &&  this.state.prevIntCalls}
                  {this.state.fourthCardStat === "Abandoned" &&  this.state.prevAbandCalls}
                  {this.state.fourthCardStat === "Answered" &&  this.state.prevAnswCalls}
                  {this.state.fourthCardStat === "Busy" &&  this.state.prevBusyCalls}
                  {this.state.fourthCardStat === "HandledRate" &&  this.state.prevHandledRate}
                  </h4> */}
                  </div>
                  <div className="stats" style={{marginLeft:"80%", marginTop:"-10px"}}>
                      <UncontrolledDropdown>
                      <DropdownToggle
                        caret
                        className="btn-icon"
                        color="link"
                        data-toggle="dropdown"
                        type="button"
                      >
                        <i className="tim-icons icon-settings-gear-63" />
                      </DropdownToggle>
                      <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                      {/* <DropdownItem
                      href=""
                      onClick={()=>this.handleClickOpenTopDeptChartDlg()}
                      >
                        Filters
                      </DropdownItem>
                      <DropdownItem
                      href=""
                      onClick={()=>this.handleClickOpenIntCallsAlertDlg()}
                      >
                        Manage Alerts
                      </DropdownItem> */}
                      <DropdownItem
                      href=""
                      onClick={()=>{ this.handleOpenSettDialog("fourthCard")}}
                      >
                        Settings
                      </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>      
                </CardFooter>
              </Card>
            </Col>
            <Col lg="12">
              <Card className="card-chart">
                <CardBody>
                <div className="chart-area" style={{marginTop:"-20px", height:"100%"}}>
                <ReportTable
                    activeModeClass={document.body.classList.contains("white-content") ? "white-content" : "dark-content"}
                    reportId={this.props.reportId}
                    reportTitle={this.props.reportTitle}
                    reportFilters={this.state.reportFilters} /> :
                </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(withAuth0(Dashboard));
