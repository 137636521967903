import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { 
    Button,
    Modal, 
    ModalBody, 
    ModalFooter,
    Card,
    CardBody,
    Row,
    Col
} from 'reactstrap';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import dayjs from 'dayjs';
import InteractionTable from '../ReportTable/InteractionTable'

import { useExpoApi } from "Helper/Api";
import { setDefaultResultOrder } from "dns";
import { get } from "http";
import { filter } from "@amcharts/amcharts4/.internal/core/utils/Iterator";

//am4core.options.autoDispose = true;


/*const parseInteractionData = (interactionData)=>{
    let chatData = interactionData.filter((interaction)=>{ 
        interaction.interactionType === 'chat'
    });

    chatData = chatData.map( (interaction)=>{

    })
}*/

const InteractionChordChart = (props)=>{
    
    const getApi = useExpoApi();
    const [themeType,setThemeType] = useState('dark');
    const [showDetailModal, setShowDetailModal  ] = useState(false);
    const linkTemplateRef = useRef();
    //const  tableFilters= useRef();
    const [tableFilters, setTableFilters ] = useState(props.reportFilters);
    const chordChart = useRef();
    //const [tableUsers, setTableUsers] = useState();
    const tableUsers = useRef({toUser:"", fromUser:""});

    const displayChart = async (data)=>{

        const chart = am4core.create("InteractionChordChart", am4charts.ChordDiagram);
        chordChart.current = chart;

        chart.responsive.enabled = true;
        am4core.useTheme(am4themes_animated);

        var linkTemplate = chart.links.template;
        linkTemplateRef.current = linkTemplate;
        linkTemplate.strokeOpacity = 0;
        linkTemplate.fillOpacity = 0.1;
        linkTemplate.tooltipText = "{fromName} to {toName}:{value.value}";

        
        const detailDrillDown =  async (ev) =>{
            const fromAgentId= ev.target.dataItem._dataContext.agentId

            const toAgentId =  ev.target.dataItem._dataContext.receiverAgentId;
            
            tableUsers.current = {
                    fromUser : fromAgentId.toString(),
                    toUser : toAgentId.toString()
            
                };

            toggleShowDetailModel();

        }
        
        linkTemplate.readerTitle = "Click to get details";
        linkTemplate.showSystemTooltip = true; 

        linkTemplateRef.current.events.on("hit", detailDrillDown);            
        chart.data =data;
        chart.dataFields.fromName = "from";
        chart.dataFields.toName = "to";
        chart.dataFields.value = "value";

        chart.nodePadding = 0.5;
        chart.minNodeSize = 0.01;
        chart.startAngle = 80;
        chart.endAngle = chart.startAngle + 360;
        chart.sortBy = "value";
        
        var nodeTemplate = chart.nodes.template;
        nodeTemplate.readerTitle = "Click to show/hide or drag to rearrange";
        nodeTemplate.showSystemTooltip = true;
        nodeTemplate.propertyFields.fill = "color";
        nodeTemplate.tooltipText = "{name}: {total}";
        nodeTemplate.readerTitle = 'Click to show/hide user';

        nodeTemplate.events.on("over", function (event) {    
            var node = event.target;
            node.outgoingDataItems.each(function (dataItem) {
                if(dataItem.toNode){
                    dataItem.link.isHover = true;
                    dataItem.toNode.label.isHover = true;
                }
            })
            node.incomingDataItems.each(function (dataItem) {
                if(dataItem.fromNode){
                    dataItem.link.isHover = true;
                    dataItem.fromNode.label.isHover = true;
                }
            }) 
        
            node.label.isHover = true;   
        })

        // when rolled out from the node, make all the links rolled-out
        nodeTemplate.events.on("out", function (event) {
            var node = event.target;
            node.outgoingDataItems.each(function (dataItem) {        
                if(dataItem.toNode){
                    dataItem.link.isHover = false;                
                    dataItem.toNode.label.isHover = false;
                }
            })
            node.incomingDataItems.each(function (dataItem) {
                if(dataItem.fromNode){
                    dataItem.link.isHover = false;
                    dataItem.fromNode.label.isHover = false;
                }
            })

            node.label.isHover = false;
        })
        nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        nodeTemplate.draggable = false;

        
        let label = nodeTemplate.label;
        label.relativeRotation = 90;
        
        label.fillOpacity = 0.65;
        var labelHS = label.states.create("hover");
        labelHS.properties.fillOpacity = 1;

        var hoverState = linkTemplate.states.create("hover");
        hoverState.properties.fillOpacity = 0.7;
        hoverState.properties.strokeOpacity = 0.7;

        label = chart.nodes.template.label;
        label.fontSize = 15;
        label.fill = am4core.color("#ffffff");

    }
                         
    const getData = async ()=>{
        const api = await getApi();
        
        const filters= props.reportFilters;

        try{

            const interactions = await api.getInteractions(filters)
            return interactions.data;

        } catch (err){
            console.log(err);
        }
    }

    const toggleShowDetailModel= ()=>{setShowDetailModal(!showDetailModal)}
    
    const updateChart = (data)=>{

            chordChart.current.data = data
            chordChart.current.validateData();
            setTableFilters(props.reportFilters);
    }

    useEffect(() => {
        //am4core.unuseAllThemes();
        if(props.activeModeClass === "white-content"){  
            am4core.unuseTheme(am4themes_dark);
        }
        else{
            am4core.useTheme(am4themes_dark);
        }
        am4core.useTheme(am4themes_animated);
        am4core.addLicense("CH277440643");
        getData().then((data)=>
        {
            if(chordChart.current){
                updateChart(data);
            }else{
                displayChart(data);
            }
        }).catch(err => console.log(err));


    },[props.reportFilters] );

    const THEME = createMuiTheme({
        palette: {
          type: themeType,
          primary: {
            main: '#f8955e',
          },
          secondary: {
            main: '#1d8cf8',
          },
        },
      });
      //style={{ backgroundColor: 'green', position:"center" , marginTop:"-20px" , width:"700px", width:"100%"}} 
    return (
        <>
            <div id="InteractionChordChart" style={{ height:"600px"}}/>

            <MuiThemeProvider theme={THEME}>
                <Modal 
                    size="lg" className='modal-black' isOpen={showDetailModal} toggle={toggleShowDetailModel} >
                    <div className="modal-header"  style={{color: 'white', backgroundColor:'#1d253b', height:"50px"} }>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={toggleShowDetailModel}>
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        <h5 className="modal-title" style={{color:"white"}}>Interaction Details</h5>
                    </div>
                    <ModalBody style={{backgroundColor:'#1d253b'}}>
                        <Card style={{margin :"0 auto", float:'none'}}>
                            <CardBody>
                                        <InteractionTable foo={'bar'} filters={tableFilters} users={tableUsers.current}/>
                            </CardBody>
                        </Card>
                    </ModalBody>
                </Modal>
            </MuiThemeProvider>
        </>
    );
}

export default InteractionChordChart;