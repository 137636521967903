/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useRef, useLayoutEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ApiCall from "Helper/Api.js";
import {useExpoApi} from "../../Helper/Api.js"
am4core.options.autoDispose = true;
export default function PeakTrunkUtilization(props){
 
const [mode, setMode] = useState("light");
const [axis,setAxis] = useState([]);
const [graphData,setGraphData] = useState([]);
const getApi = useExpoApi();

let totalCallsNew = 0;
useLayoutEffect(() => {
    am4core.unuseAllThemes();
    
    if(props.activeModeClass === "white-content" ){  
        am4core.unuseTheme(am4themes_dark);
    }
    else{
        am4core.useTheme(am4themes_dark);
    }
    am4core.useTheme(am4themes_animated);
    am4core.addLicense("CH277440643");
    let chart = am4core.create("peakTrunkUChartDiv", am4charts.XYChart);

    // Increase contrast by taking evey second color
    chart.colors.step = 2;

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = "left";
    chart.exporting.menu.verticalAlign = "bottom";
    am4core.color("#f00", 0);
    const getData = async ()  => {
        // Create chart instance
        try {
            let dataResponse = "";
            if(props.dataType === "daily"){
                dataResponse = await api.getDailyTrunkUtilization(props.filters)
            }
            else if(props.dataType === "weekly"){
                dataResponse = await api.getWeeklyTrunkUtilization(props.filters)
            }
            else if(props.dataType === "monthly"){
                dataResponse = await api.getMonthlyTrunkUtilization(props.filters)
            }
            const json = await dataResponse.data;
            let dataAxisObj = [];
            let dailyGraphDataObject = [];

            dataAxisObj = json.trunks;

            const parseGraphData = (data) =>{
                let dataObject = [];
                let trunkDateObj = [];

                dataObject = data.trunks;

                //getting the dates and times
                dataObject.forEach((dataItem) => {
                    dataItem.data.forEach((dataObj) => {
                        if(trunkDateObj.indexOf(dataObj.time) === -1){ //checking if the value alreay exist in the array before inserting it
                            trunkDateObj.push(dataObj.time); 
                        }      
                    })
                });

                trunkDateObj.forEach((dateItem) => {
                    const trunkObject = {};
                    const dateTimeTrunk = dateItem;
                    trunkObject.date = new Date(dateTimeTrunk);

                    dataObject.forEach((dataItem) => {
                        //let dateTimeTrunk = new Date(2020, 8, 28, 8, 0);
                        let trunkAtrrName = "";
                        trunkAtrrName = dataItem.name ;
                        let trunkTotalCalls = 0;    
                        dataItem.data.forEach((subDataItem) => {
                            if(subDataItem.time === dateTimeTrunk){
                                trunkTotalCalls = subDataItem.count;
                            }
                        });

                        trunkObject[trunkAtrrName] = trunkTotalCalls;
                    });
                
                    dailyGraphDataObject.push(trunkObject);
                    
                });
                setGraphData(dailyGraphDataObject);
            }
            // Add data
            parseGraphData(json);
            
            chart.data = dailyGraphDataObject;
            
              
            var dateAxis = chart.xAxes.push(new am4charts.DateAxis());

            dateAxis.renderer.grid.template.location = 0;

            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

            // Create series
            function createSeries(field, name) {
                const series = chart.series.push(new am4charts.LineSeries());
                series.dataFields.valueY = field;
                series.dataFields.dateX = "date";
                series.name = name;
                series.tooltipText = "{dateX}: [b]{valueY}[/]";
                series.strokeWidth = 2;

                if(props.dataType === "daily") {
                    const bullet = series.bullets.push(new am4charts.CircleBullet());
                    bullet.circle.stroke = am4core.color("#fff");
                    bullet.circle.strokeWidth = 2;
                }

                return series;
            }

            dataAxisObj.forEach((d) => {
                createSeries( d.name,  d.name);    
            });

            // Add legend
            chart.legend = new am4charts.Legend();
            // Add cursor
            chart.cursor = new am4charts.XYCursor();

            

        }catch(err) {
           console.log(err);
        }
        
        
    }
    getData();
    chart.events.on('ready', () => {
        if(chart.data.length === 0){
            let title = chart.titles.create();
            title.text = "No data to display! Please configure your Trunks";
            title.fontSize = 12;
            title.marginBottom = 30;        
        }
        
    
    });
}, [props.totalCalls]);

return (
    
        <div id="peakTrunkUChartDiv" style={{ width: "100%", height: "100%" }} />        
    
);
}
