import React, { useState, useLayoutEffect } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Switch from "@material-ui/core/Switch";
import NotificationsIcon from "@material-ui/icons/Notifications";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 480,
    backgroundColor: "transparent"
  }
}));

function createData(title, id) {
  return { title, id };
}

const alertListRows = [
  createData("Total Calls Below 5", 1),
  createData("Total Calls above 100", 2)
]
export default function SwitchListSecondary(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([1]);
  const [themeType,setThemeType] = useState('dark');
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")}
    
  }, []);
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#1d8cf8',
      },
    },
  });

  return (
    <div className={classes.root}>
    <MuiThemeProvider theme={THEME}>
    <List
      subheader={<ListSubheader>Rules</ListSubheader>}
      className={classes.root}
    > 
      {(alertListRows).map(row => (
        <ListItem>
        <ListItemIcon>
          <NotificationsIcon />
        </ListItemIcon>
        <ListItemText
          id={row.id}
          primary={row.title}
        />
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            onChange={handleToggle(row.id)}
            checked={checked.indexOf(row.id) !== -1}
            inputProps={{ "aria-labelledby": row.id }}
          />
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>
          <IconButton edge="end" aria-label="delete">
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      ))}
    </List>
    </MuiThemeProvider>
    </div>
  );
}
