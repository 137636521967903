/* eslint-disable no-lone-blocks */
/* eslint-disable eqeqeq */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useLayoutEffect } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import SelectSimpleStat from "components/MaterialUI/SelectSimpleStatsOptions.js";
import CallClassSelect from "components/MaterialUI/SelectMultiple_CallClass.js";
import SixWidgetOption from "components/MaterialUI/SelectSimpleSixWidgetOpt.js";
import { 
  Button, 
  Modal, 
  ModalBody, 
  ModalFooter ,  
  Row,
  Col,
  Card,
  CardBody,
  Input
} from 'reactstrap';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '400px',
  },
  selectField: {
    width: '180px',
  },
}));

const TrunkSettingsDialog = (props) => {

  const classes = useStyles();
  const [stateTitle, setStateTitle] = useState("Enabled");
  const [themeType,setThemeType] = useState('dark');
  const [state, setState] = React.useState({
    checkedEn: true,
  });

  useLayoutEffect(() => {
    if(props.activeModeClass === "white-content"){  setThemeType("light")}
    else{setThemeType("dark")}
    
  }, []);
  const THEME = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#f8955e',
      },
      secondary: {
        main: '#1d8cf8',
      },
    },
  });
  return (
    <div>
    <MuiThemeProvider theme={THEME}>
    <Modal isOpen={props.open} toggle={props.handleClose} modalClassName={props.className}>
    <div className="modal-header">
      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.handleClose}>
        <i className="tim-icons icon-simple-remove"></i>
      </button>
      <h5 className="modal-title">Settings</h5>
    </div>
    <ModalBody>   
    <Col sm="12">
     <div style={{marginLeft:"-20px" ,fontSize:"12px"}}>
        <Card>
          <CardBody>
          <Row style={{marginLeft:"5px", marginRight:"5px", marginTop:"5px", width:"200px"}}>
          <label style={{marginLeft:"5px"}}>Card Title</label>
          <Input
                name="widgetName"
                placeholder="Widget Name"
                type="text"
                value={props.titleText}
                onChange={props.textBoxClickEv}
              
              />
          </Row>  
          {props.showStats == 1 &&
            <Row style={{marginLeft:"10px", marginTop:"20px", width:"200px"}}>
              <SelectSimpleStat value = {props.selectValue} onChange = {props.onChangeSelect}/>
            </Row>  
          }
           {props.showCallTypesFilter == 1 &&
            <Row style={{marginLeft:"10px", marginTop:"20px", width:"200px"}}>
              <CallClassSelect value = {props.callClassVal} onChange = {props.onChangeCallClassSelect}/>
            </Row>  
          }
          {props.showSixWidgetOption == 1 &&
            <Row style={{marginLeft:"10px", marginTop:"20px", width:"200px"}}>
              <SixWidgetOption value = {props.selSixWidgetOtion} onChange = {props.onChangeSixWidOptSelect} trunkEnabled = {props.trunkEnabled}/>
            </Row>  
          }
          
        </CardBody>
        </Card>
    </div>
    </Col>           
    </ModalBody>
    <ModalFooter>
    <Button color="warning" onClick={props.handleClose}>
      Close
    </Button>
    <Button color="warning" onClick={props.handleClose}>
      Save changes
    </Button>
    
    </ModalFooter>
    </Modal>
    </MuiThemeProvider>
    </div>
  );
}

export default TrunkSettingsDialog;